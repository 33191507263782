import React, { useEffect, useState } from "react";
import classes from "./Billing.module.scss";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  getBillingDetailsAPICall,
  updateBillingDetailsAPICall,
} from "../../Utils/Apihelper";
import { Toaster, toast } from "react-hot-toast";

const BillingInput = () => {
  const [billName, setbillName] = useState("");
  const [gstnum, setGstnum] = useState("");
  const navigate = useNavigate();
  const [billaddress, setbilladdress] = useState("");
  const [disable, setDisable] = useState(false);
  const [edit, setedit] = useState(false);
  const [firstime, setfirstime] = useState(false);

  useEffect(() => {
    getBillingDetailsAPICall()
      .then((res: any) => {
        setbillName(res.data.data.paymentDetails.billingName);
        setGstnum(res.data.data.paymentDetails.GST);
        setbilladdress(res.data.data.paymentDetails.billingAddress);
        if (
          res.data.data.paymentDetails.billingName === "" &&
          res.data.data.paymentDetails.billingAddress === ""
        ) {
          setfirstime(true);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (billName === "" && billaddress === "") {
          setfirstime(true);
        }
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {}, 2000);
  }, []);

  const handleUpdateDetails = () => {
    const requestBody = {
      billingName: billName,
      GST: gstnum,
      billingAddress: billaddress,
    };
    const requestBody1 = {
      billingName: billName,
      billingAddress: billaddress,
    };
    updateBillingDetailsAPICall(gstnum === "" ? requestBody1 : requestBody)
      .then((res: any) => {
        setbillName(res.data.data.billingName);
        setGstnum(res.data.data.GST);
        setbilladdress(res.data.data.billingAddress);
        toast.success(res.data.message);
        setfirstime(false);
      })
      .catch((error) => {
        toast.error(error.response.data.data.error);
      });
  };

  const handleBillNameInput = (event: any) => {
    (edit || firstime) && setbillName(event.target.value);
  };
  const handlegstnumInput = (event: any) => {
    (edit || firstime) && setGstnum(event.target.value);
  };
  const handleBilladdressInput = (event: any) => {
    (edit || firstime) && setbilladdress(event.target.value);
  };
  return (
    <>
      <Toaster />
      <div className={classes.wrapper}>
        <div className={classes.header}>Billing Details</div>
        <div className={classes.idSubject}>
          Billing Name<span style={{ color: "red" }}>*</span>
          <TextField
            disabled={firstime ? false : !edit}
            placeholder="Billing name"
            id="outlined-size-small"
            value={billName}
            onChange={handleBillNameInput}
            size="small"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>
        <div className={classes.idSubject}>
          GST number(Optional)
          <TextField
            disabled={firstime ? false : !edit}
            placeholder="GST number"
            id="outlined-size-small"
            value={gstnum}
            onChange={handlegstnumInput}
            size="small"
            variant="standard"
            inputProps={{ maxLength: 15 }}
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>
        <div className={classes.idSubject}>
          Billing Address<span style={{ color: "red" }}>*</span>
          <TextField
            disabled={firstime ? false : !edit}
            placeholder="Billing address"
            id="outlined-size-small"
            value={billaddress}
            variant="standard"
            onChange={handleBilladdressInput}
            size="small"
            inputProps={{ maxLength: 100 }}
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>

        <div
          style={{
            color: "red",
            fontSize: "12px",
            paddingTop: "1rem",
            visibility: !disable ? "hidden" : "visible",
          }}
        >
          {firstime && billName !== "" && billaddress !== "" && gstnum.length < 15
            ? "Please save your billing details and continue."
            : billName !== "" && billaddress !== "" && gstnum.length < 15
            ? "Please enter the correct GST number"
            : "Please fill all the required fields*"}
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          {edit || firstime ? (
            <div
              className={classes.continue}
              onClick={() => {
                if (
                  billName === "" ||
                  billaddress === "" ||
                  (gstnum !== "" && gstnum.length < 15)
                ) {
                  setDisable(true);
                  setTimeout(() => setDisable(false), 3000);
                } else {
                  handleUpdateDetails();
                  setDisable(false);
                  setedit(false);
                }
              }}
            >
              Save
            </div>
          ) : (
            <div
              className={classes.continue}
              onClick={() => {
                setedit(true);
              }}
            >
              Edit
            </div>
          )}

          <div
            className={classes.continue}
            onClick={() => {
              if (
                billName === "" ||
                billaddress === "" ||
                firstime ||
                (gstnum !== "" && gstnum.length < 15)
              ) {
                setDisable(true);
                setTimeout(() => setDisable(false), 3000);
              } else {
                navigate("/email-dashboard/billingdetails");
                setDisable(false);
              }
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingInput;

import React from "react";
import styles from "./CampaignPreviewModal.module.scss";
import previewImage from "../../../assets/Images/PreviewCampaignImage.svg";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";

import { Box, Button, IconButton, Modal, Stack } from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
};

interface props {
  open: any;
  handleClose: any;
  campaignName: string;
  groups: string;
  subject: string;
  purpose: string;
  senderName: string;
  senderEmail: string;
  template: any;
  templateimage:any;
}

const CampaignPreviewModal = ({
  open,
  handleClose,
  campaignName,
  groups,
  subject,
  purpose,
  senderName,
  senderEmail,
  template,
  templateimage
}: props) => {
  console.log("template.templateImage",templateimage)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <div className={styles.mainWrapper}>
          <Stack
            style={{
              width: "max-content",
              position: "absolute",
              right: "1px",
            }}
          >
            <IconButton onClick={handleClose} sx={{ background: "#EDF2F7" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>

          <div className={styles.leftContainer}>
            <p className={styles.leftContainerTitle}>Preview Campaign</p>
            <div className={styles.previewImageSection}>
              <img
                src={
                  `data:image/png;base64,${templateimage}`
                }
                className={styles.previewImage}
                alt=""
              />
            </div>
            <div className={styles.leftContainerfooter}></div>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.contentContainer}>
              <p className={styles.contentContainerTitle}>Campaign Name</p>
              <p className={styles.contentContainerdesc}>{campaignName}</p>
            </div>
            <div className={styles.contentContainer}>
              <p className={styles.contentContainerTitle}>Template Name</p>
              <p className={styles.contentContainerdesc}>
                {template.templateName}
              </p>
            </div>
            <div className={styles.contentContainer}>
              <p className={styles.contentContainerTitle}>Group </p>
              <p className={styles.contentContainerdesc}>{groups}</p>
            </div>
            <div className={styles.contentContainer}>
              <p className={styles.contentContainerTitle}>Subject</p>
              <p className={styles.contentContainerdesc}>{subject}</p>
            </div>
            <div className={styles.contentContainer}>
              <p className={styles.contentContainerTitle}>Purpose</p>
              <p className={styles.contentContainerdesc}>{purpose}</p>
            </div>
            <div className={styles.contentContainer}>
              <p className={styles.contentContainerTitle}>Sender details</p>
              <p className={styles.contentContainerdesc}>
                {senderName} - {senderEmail}
              </p>
            </div>
            <div className={styles.buttonsection}>
              {/* <div>
                <Button
                  variant="contained"
                  startIcon={<EditIcon sx={{ color: "#505780" }} />}
                  sx={{
                    background: "#E4ECF7",
                    color: "#505780",
                    borderRadius: "100px",
                    textTransform: "none",
                    padding: "6px 20px",
                    boxShadow: "none",

                    "&:hover": { background: "#E4ECF7" },
                  }}
                  onClick={handleClose}
                >
                  Edit Campaign
                </Button>
              </div> */}
              <div>
                <Button
                  variant="contained"
                  startIcon={<UpdateIcon />}
                  sx={{
                    background: "#4318FF",
                    borderRadius: "100px",
                    textTransform: "none",
                    padding: "6px 20px",
                    boxShadow: "none",

                    "&:hover": { background: "#4318FF" },
                  }}
                  onClick={handleClose}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default CampaignPreviewModal;

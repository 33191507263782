import React, { useEffect, useState } from "react";
import styles from "./MainLandingPageHeader.module.scss";
import logo from "../../../assets/Images/logoEnotifiers.svg";
import { Button, Drawer, IconButton, Menu, MenuItem } from "@mui/material";
import companytext from "../../../assets/Images/enotifiers-logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import product1 from "../../../assets/Images/product1.svg";
import product2 from "../../../assets/Images/product3.svg";
import product3 from "../../../assets/Images/product2.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Toaster, toast } from "react-hot-toast";
import ReactGA from "react-ga4";

const product = [
  {
    text: "Email",
    img: <EmailRoundedIcon />,
    upcoming: false,
  },
  {
    text: "SMS",
    img: <ForumRoundedIcon />,
    upcoming: true,
  },
  {
    text: "Whatsapp",
    img: <WhatsAppIcon />,
    upcoming: true,
  },
];

export default function MainLandingPageHeader({ scrollToRef }: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changeHeaderBg, setChangeHeaderBg] = useState(false);
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const handleClickEmail = () => {
    setAnchorEl(null);
    navigate("/email");
    setOpenMenu(false);
  };
  const handleClickSMS = () => {
    setAnchorEl(null);
    toast("Coming Soon!", {
      icon: "🕰️",
    });
    setOpenMenu(false);
  };
  const handleClickWhatsApp = () => {
    setAnchorEl(null);
    toast("Coming Soon!", {
      icon: "🕰️",
    });
    // navigate("/whatsapp");
    setOpenMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setChangeHeaderBg(true);
      } else {
        setChangeHeaderBg(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Toaster />
      <div
        className={styles.mainWrapper}
        style={{ background: changeHeaderBg ? "#F7F9FC" : "#F7F9FC" }}
      >
        <div className={styles.leftContainer}>
          <div className={styles.leftContainerLogoSection}>
            <img
              src={logo}
              className={styles.leftContaineLogo}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className={styles.centerContainer}>
          {pathName !== "/returnpolicy" &&
            pathName !== "/termsandconditions" && (
              <div className={styles.leftContainerLinksSection}>
                <p
                  className={styles.leftContainerLinks}
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Products <KeyboardArrowDownIcon />
                </p>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{ marginTop: "10px" }}
                >
                  <div
                    className={styles.menuItemsDesigned}
                    onClick={handleClickEmail}
                  >
                    <EmailRoundedIcon
                      sx={{ marginRight: "10px", fill: "4c6fff" }}
                    />
                    Email
                  </div>
                  <div
                    className={styles.menuItemsDesigned}
                    onClick={handleClickWhatsApp}
                  >
                    <WhatsAppIcon
                      sx={{ marginRight: "10px", fill: "4c6fff" }}
                    />
                    WhatsApp
                  </div>
                  <div
                    className={styles.menuItemsDesigned}
                    onClick={handleClickSMS}
                  >
                    <ForumRoundedIcon
                      sx={{ marginRight: "10px", fill: "4c6fff" }}
                    />{" "}
                    SMS 
                  </div>
                </Menu>
                <p
                  className={styles.leftContainerLinks}
                  onClick={() => {
                    window.open(
                      "https://docs.enotifiers.com/introduction/",
                      "_blank"
                    );
                  }}
                >
                  Developers
                </p>
                <p
                  className={styles.leftContainerLinks}
                  onClick={() => navigate("/pricings")}
                >
                  Pricing
                </p>

                <p
                  className={styles.leftContainerLinks}
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </p>
              </div>
            )}
        </div>
        <div className={styles.rightContainer}>
          <p
            className={styles.rightContainerLoginText}
            onClick={() => {
              navigate("/login");
              ReactGA.event({
                category: "TopbarGA",
                action: "login Clicked",
                label: "login button",
              });
            }}
          >
            Login
          </p>
          <div>
            <Button
              variant="contained"
              sx={{
                background: "4c6fff",
                borderRadius: "45px",
                boxShadow: "none",
                "&:hover": { background: "4c6fff" },
                textTransform: "capitalize",
              }}
              onClick={() => {
                navigate("/register");
                ReactGA.event({
                  category: "TopbarGA",
                  action: "Signup Clicked",
                  label: "signup button",
                });
              }}
            >
              Signup
            </Button>
          </div>
        </div>
        <div className={styles.mobileMenu}>
          <IconButton
            sx={{
              transform: openMenu ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.3s ease",
            }}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            {openMenu ? (
              <CloseIcon fontSize="small" color="action" />
            ) : (
              <MenuOpenIcon fontSize="small" color="action" />
            )}
          </IconButton>
        </div>
        <div className={styles.mobileDrawer}>
          <Drawer
            anchor={"right"}
            open={openMenu}
            onClose={() => {
              setOpenMenu(false);
            }}
            variant={"persistent"}
            sx={{
              "&.MuiDrawer-root .MuiDrawer-paper": {
                marginTop: "55px",
                width: "100%",
                padding: "20px",
                background: "#F7F9FC",
              },
              "&.MuiDrawer-root": {
                background: "none",
              },
            }}
          >
            <div className={styles.drawerContent}>
              <div className={styles.leftContainerLinksSectiondrwaer}>
                <p
                  className={styles.leftContainerLinks}
                  onClick={() => {}}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Products <KeyboardArrowDownIcon />
                </p>

                <p
                  className={styles.leftContainerLinks}
                  onClick={handleClickEmail}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <EmailRoundedIcon
                    sx={{ marginRight: "10px", fill: "4c6fff" }}
                  />
                  Email
                </p>
                <p
                  className={styles.leftContainerLinks}
                  onClick={handleClickWhatsApp}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  <WhatsAppIcon sx={{ marginRight: "10px", fill: "4c6fff" }} />
                  WhatsApp
                </p>
                <p
                  onClick={handleClickSMS}
                  className={styles.leftContainerLinks}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <ForumRoundedIcon
                    sx={{ marginRight: "10px", fill: "4c6fff" }}
                  />{" "}
                  SMS
                </p>

                <p
                  className={styles.leftContainerLinks}
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                >
                  Developers
                </p>

                <p
                  className={styles.leftContainerLinks}
                  onClick={() => {
                    setOpenMenu(false);
                    navigate("/pricings");
                  }}
                >
                  Pricing
                </p>

                <p
                  className={styles.leftContainerLinks}
                  onClick={() => {
                    navigate("/contactus");
                    setOpenMenu(false);
                  }}
                >
                  Contact Us
                </p>
              </div>
              <div className={styles.rightContainerDrwaer}>
                <p
                  className={styles.rightContainerLoginText}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </p>
                <div>
                  <Button
                    variant="contained"
                    sx={{
                      background: "4c6fff",
                      borderRadius: "45px",
                      boxShadow: "none",
                      padding: "10px 25px",
                      "&:hover": { background: "4c6fff" },
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Signup
                  </Button>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </>
  );
}

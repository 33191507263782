import classes from "./ForgotPassword.module.scss";
import { useFormik } from "formik";
import { forgotpwSchema, signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import {
  forgetPasswordAPICall,
  forgetPasswordApi,
} from "../../Utils/Apihelper";
import { useDispatch } from "react-redux";
import { setOtpFlag } from "../../Store/Project/ProjectSlice";
import signUp from "../../assets/Images/loginrightimage.svg";
import logingif from "../../assets/Images/loginrightplane.gif";
import { CircularProgress,OutlinedInput } from "@mui/material";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: forgotpwSchema,
      onSubmit: (values, action) => {
        // console.log("values", values);
        action.resetForm();
        const requestBody = {
          userId: values.email,
        };
        setloading(true);
        forgetPasswordAPICall(requestBody)
          .then((res) => {
            setloading(false);
            navigate("/otpScreen");
            // dispatch(setOtpFlag(true));
            const Token = res.data.accessToken;
            sessionStorage.setItem("Token", Token);
            sessionStorage.setItem("email", values.email);
          })
          .catch((err) => {
            // console.log(err.data);
            toast.error(err.response.data.message)
            setloading(false);
          });
      },
    });
  return (
    <>
    <Toaster/>
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <div className={classes.loginData}>
                <p className={classes.logintext}>Forget Password?</p>
                <p className={classes.modalDesc}>
                  No Worries, We'll send you reset instructions.{" "}
                </p>
                <form onSubmit={handleSubmit} className={classes.formWrapper}>
                  <div className={classes.inputBlock}>
                    <OutlinedInput
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Enter Registered Email ID"
                      value={values.email}
                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 50,
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      sx={{
                        background: "transparent",
                        borderRadius: "6px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "4c6fff",
                        },
                        "--Input-focusedHighlight": "4c6fff",
                        "&::before": {
                          transition: "box-shadow .15s ease-in-out",
                        },
                        "&:focus-within": {
                          borderColor: "4c6fff",
                        },
                      }}
                    />
                    {errors.email && touched.email ? (
                      <p className={classes.formError}>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className={classes.modalButtons}>
                    <Button
                      type="submit"
                      size="lg"
                      style={{
                        padding: "14px 50px",

                        borderRadius: "5px",
                      }}
                      loading={loading}
                      sx={{
                        backgroundColor: "4c6fff",
                        "&:disabled": {
                          backgroundColor: "#b9c7ff",
                        },
                        "&:hover": {
                          backgroundColor: "4c6fff",
                        },
                      }}
                      loadingIndicator={
                        <CircularProgress
                          sx={{ color: "#4c6fff" }}
                          size={"25px"}
                        />
                      }
                    >
                      Reset Password
                    </Button>
                    <a href="#" className={classes.link}>
                      <Link
                        to={"/login"}
                        style={{
                          textDecoration: "underline",
                          color: "4c6fff",
                        }}
                      >
                        Back to Login ?{" "}
                      </Link>
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
              <img src={logingif} alt="" className={classes.logingif} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React from "react";
import classes from "./EmailHeroSection.module.scss";
import bgImg from "../../../assets/Images/EmailHeroBg.svg";
import { useNavigate } from "react-router-dom";
import card1 from "../../../assets/Images/card1.svg";
import card2 from "../../../assets/Images/card2.svg";
import card3 from "../../../assets/Images/card3.svg";
import card4 from "../../../assets/Images/card4.svg";
import ReactGA from "react-ga4";


const EmailHeroSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.title}>
            Effortlessly Elevate Your Email Marketing
          </div>
          <div className={classes.subTitle}>
            Create, Customize, and Send Transactional and Promotional Emails
            Effortlessly
          </div>
          <div className={classes.btnWrap}>
            <div
              className={classes.btn1}
              onClick={() => {
                navigate("/register");
                ReactGA.event({
                  category: "TopbarGA",
                  action: "Signup Clicked",
                  label: "SignUp button",
                });
              }}
            >
              Sign Up for free
            </div>
            <div
              className={classes.btn2}
              onClick={() => {
                navigate("/contactus");
                ReactGA.event({
                  category: "TopbarGA",
                  action: "ContactUs Clicked",
                  label: "ContactUs button",
                });
              }}
            >
              Request a Demo
            </div>
          </div>
        </div>
      </div>
      <HeroSection2 />
    </>
  );
};

export default EmailHeroSection;

const HeroSection2 = () => {
  return (
    <>
      <div className={classes.s2Wrapper}>
        <div className={classes.s2Title}>
          Choose Us for Unparalleled Excellence
        </div>
        <div className={classes.s2Subtitle}>
          Choosing us means embracing a seamless blend of expertise and
          innovation, ensuring your projects are not just completed but elevated
          to new heights.
        </div>
        <div className={classes.s2Cards}>
          <div className={classes.s2EachCard}>
            <div className={classes.s2cardImg}>
              <img src={card1} alt="" className={classes.s2cardImg1} />
            </div>
            <div className={classes.s2cardTitle}>Easy-to-Use Templates</div>
            <div className={classes.s2cardSubtitle}>
              Choose from a wide range of professionally designed templates or
              create your own from scratch.
            </div>
          </div>
          <div className={classes.s2EachCard}>
            <div className={classes.s2cardImg}>
              <img src={card2} alt="" className={classes.s2cardImg1} />
            </div>
            <div className={classes.s2cardTitle}>Customization Options</div>
            <div className={classes.s2cardSubtitle}>
              Tailor your emails with customizable elements such as logos,
              colors, and fonts.
            </div>
          </div>
          <div className={classes.s2EachCard}>
            <div className={classes.s2cardImg}>
              <img src={card3} alt="" className={classes.s2cardImg1} />
            </div>
            <div className={classes.s2cardTitle}>
              Transactional Email 
            </div>
            <div className={classes.s2cardSubtitle}>
              Ensure timely delivery of transactional emails such as order
              confirm and account notifications.
            </div>
          </div>
          <div className={classes.s2EachCard}>
            <div className={classes.s2cardImg}>
              <img src={card4} alt="" className={classes.s2cardImg1} />
            </div>
            <div className={classes.s2cardTitle}>Promotional Campaigns</div>
            <div className={classes.s2cardSubtitle}>
              Launch targeted promotional campaigns to engage your audience and
              drive conversions.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

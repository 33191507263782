import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import classes from "./SubscribeDataTablecomp.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import searchIcn from "../../assets/Images/searchIcon.svg";
import {
  ISingleGroup,
  ISingleGroupDetails,
  ISubscriberList,
  getAllCatDetailsApi,
} from "../../Utils/Apihelper";
import StyledLoader from "../StyledLoader/StyledLoader";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoDataComponent from "./NoDataComponent";

interface props {
  data: any;
  columns: any;
  loading: any;
  group?: any;
  totalrows?:any;
  paginationCount?:any;
  handlePageChange?:any;
  pagination?:any;
}

const SubscriberGroupDataTableComp = ({
  data,
  loading,
  columns,
  group,
  totalrows,
  paginationCount,
  handlePageChange,
  pagination
}: props) => {
  // const [tableData, setTableData] = useState([])
  const handleChange = ({ selectedRows }: any) => {
    // console.log("Selected Rows: ", selectedRows);
  };
  const [query, setQuery] = useState("");
  const pathName = useLocation().pathname;

  const customStyles = {
    table: {
      style: {
        // border: "1px solid #D9D9D9",
        borderRadius: "8px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        // border:"1px solid #D9D9D9"
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },

    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",

        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "16px",
        wordBreak: "break-word",
      },
      draggingStyle: {},
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",

        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },

    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,

        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const filteredData = query.trim().length
    ? data.filter((a: ISingleGroupDetails) =>
        a.groupName.toLowerCase().includes(query.toLowerCase().trim())
      )
    : data;
  return (
    <>
      <div
        style={{
          borderRadius: "24px",
          padding: "15px 15px",
          backgroundColor: "white",
        }}
      >
        <div className={classes.tableWrapper}>
          {group && (
            <div className={classes.emailListHeader}>
              <div className={classes.listTitle}>Groups</div>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"right"}
                spacing={2}
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   // gap: "10px",
                //   justifyContent: "right",
                // }}
              >
                <div className={classes.searchInput}>
                  <div>
                    <img
                      src={searchIcn}
                      alt=""
                      className={classes.searchIcon}
                    />
                  </div>
                  <input
                    type="text"
                    className={classes.inputBox}
                    value={query}
                    placeholder="Search...."
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>

                {/* <div>
                <Button
                  variant="contained"
                  endIcon={<FileDownloadOutlinedIcon />}
                  sx={{
                    background: "#F9FBFF",
                    borderRadius: "13px",
                    textTransform: "none",
                    padding: "6px 40px",
                    boxShadow: "none",
                    color: "#3D3C42",

                    "&:hover": { background: "#F9FBFF", color: "#3D3C42" },
                  }}
                >
                  ExportAll
                </Button>
              </div> */}
              </Stack>
            </div>
          )}

          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
            }}
          >
            <DataTable
              data={filteredData}
              columns={columns}
              title=" "
              pagination={pagination}
              selectableRows={false}
              progressPending={loading}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              onSelectedRowsChange={handleChange}
              fixedHeader
              progressComponent={<StyledLoader />}
            />

            {data.length > 0 && !loading && (
              <Stack
                alignItems={"center"}
                // marginTop={data.length >= 9 ? "10px" : "300px"}
                marginTop={"10px"}
                marginBottom={"20px"}
              >
                <Pagination
                  onChange={(value: any, page: any) => {
                    handlePageChange(value, page);
                  }}
                  page={paginationCount}
                  count={totalrows}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            )}

            {/* {data.length > 0 && !loading && (
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                // marginTop={data.length >= 9 ? "10px" : "300px"}
                marginTop={"10px"}
                marginBottom={"20px"}
                marginLeft={"10px"}
                marginRight={"10px"}
              >
                <p style={{ color: "#B5B7C0", fontSize: "14px" }}>
                  Showing data 1 to 8 of 256K entries
                </p>
                <Pagination
                  onChange={(value: any, page: any) => {
                    handlePageChange(value, page);
                  }}
                  sx={{
                    button: {
                      color: "#404B52",
                      fontSize: "14px",
                      fontWeight: 600,
                      background: "#EEEEEE",
                    },
                    "& .MuiPaginationItem-root.Mui-selected": {
                      background: "#4C6FFF",
                      color: "white",
                    },
                  }}
                  page={paginationCount}
                  count={totalRows}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriberGroupDataTableComp;

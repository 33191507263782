import React, { useState } from "react";
import classes from "./ExploreKeyFeatures.module.scss";
import MLs2card1 from "../../../assets/Images/MLs2card1.svg";
import MLs2card2 from "../../../assets/Images/MLs2card2.svg";
import MLs2card3 from "../../../assets/Images/MLs2card3.svg";
import MLs2Icn1 from "../../../assets/Images/MLs2Icn1.svg";
import MLs2Icn2 from "../../../assets/Images/MLs2Icn2.svg";
import MLs2Icn3 from "../../../assets/Images/MLs2Icn3.svg";
import mls3img from "../../../assets/Images/MLs3Img.svg";
import MLs4Bg from "../../../assets/Images/MLs4BG.svg";
import MLS4ipad from "../../../assets/Images/MLs4Ipad.svg";
import { useNavigate } from "react-router-dom";


export default function ExploreKeyFeatures() {
  const navigate = useNavigate()
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.featWrap}>
          <div className={classes.left}>
            <img src={MLs2card1} alt="" className={classes.leftImg} />
          </div>
          <div className={classes.right}>
            <div className={classes.section1Wrap}>
              <div className={classes.logo}>
                <img src={MLs2Icn1} alt="" />
              </div>
              <div className={classes.t1}>Convert more customers at scale</div>
            </div>
            <div className={classes.t2}>
              Harness the Power of Personalized Communication to Drive Lasting
              Connections and Maximize Conversions
            </div>
            <div className={classes.t3}>
              <div className={classes.t3tabs}>Efficiently</div>
              <div className={classes.t3tabs}>Amplify</div>
              <div className={classes.t3tabs}>ConversionBoost</div>
            </div>
          </div>
        </div>
        <div className={classes.featWrap}>
          <div className={classes.right}>
            <div className={classes.section1Wrap}>
              <div className={classes.logo}>
                <img src={MLs2Icn2} alt="" />
              </div>
              <div className={classes.t1}>
                Keep Your Campaign Relevant and Your Brand Growing
              </div>
            </div>
            <div className={classes.t2}>
              Stay Ahead in the Marketing Game with E-Notifiers' Innovative
              Solutions and Expertise to reach your customers.
            </div>
            <div className={classes.t3}>
              <div className={classes.t3tabs}>Relevance</div>
              <div className={classes.t3tabs}>Growth</div>
              <div className={classes.t3tabs}>Success </div>
            </div>
          </div>
          <div className={classes.left}>
            <img src={MLs2card2} alt="" className={classes.leftImg} />
          </div>
        </div>
        <div className={classes.featWrap}>
          <div className={classes.left}>
            <img src={MLs2card3} alt="" className={classes.leftImg} />
          </div>
          <div className={classes.right}>
            <div className={classes.section1Wrap}>
              <div className={classes.logo}>
                <img src={MLs2Icn3} alt="" />
              </div>
              <div className={classes.t1}>Build customers for life</div>
            </div>
            <div className={classes.t2}>
              Create Lasting Bonds and Ensure Customer Loyalty with E-Notifiers'
              Personalized Strategic Solutions
            </div>
            <div className={classes.t3}>
              <div className={classes.t3tabs}>Loyalty</div>
              <div className={classes.t3tabs}>Longevity</div>
              <div className={classes.t3tabs}>Connection</div>
            </div>
          </div>
        </div>

        <div className={classes.section3}>
          <div className={classes.s3title}>
            Maximize Customer Lifetime Value with E-Notifiers
          </div>
          <div className={classes.s3Subtitle}>
            Empower Your Marketing Strategy with Predictive Insights and
            Targeted Segmentation
          </div>
          <img src={mls3img} alt="" className={classes.s3Img} />
        </div>

        <div className={classes.section4}>
          <div className={classes.left}>
            <div className={classes.s4Title}>Start Engaging Smarter Today</div>
            <div className={classes.s4Subtitle}>Unlock E-Notifiers' Predictive Insights Now</div>
            <div className={classes.s4Btn} onClick={()=>{navigate("/login")}}>Get Started</div>
          </div>
          <div className={classes.right}>
            <img src={MLS4ipad} alt="" className={classes.rightImg}/>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import classes from "./EditTemplate.module.scss";
import Input from "@mui/joy/Input";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  createTemplateApi,
  deleteTemplateApi,
  editTemplateApi,
  getAllTemplateApi,
  loadTemplateApi,
} from "../../Utils/Apihelper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import templateJson from "../../assets/html.json";
import Alert from "@mui/material/Alert";
import toast, { Toaster } from "react-hot-toast";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadTemplateJson,
  setLoadTemplateName,
} from "../../Store/Project/ProjectSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const longText = `
        * Promotional email template must contain "{{unsubscribe}} variables". 
        * For adding variables, use syntax {{Variable1}} inside your template \n
        * (optional) use {{name}} and {{email}} varibales to get subscribers Name and Email when sending promotional emails
`;
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const EditTemplate = () => {
  const emailEditorRef = useRef(null);
  const [templateName, setTemplateName] = useState("");
  const [html, setHtml] = useState("");
  const [json, setJson] = useState("");
  const [allTemplate, setAllTemplate] = useState([]);
  const [loadTemplate, setLoadTemplate] = React.useState({});
  const [selectTemplate, setSelectTemplate] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [confirmSave, setConfirmSave] = React.useState(false);
  const [renderEmailEditor, setrenderEmailEditor] = useState(true);
  const [loadedTemplateName, setLoadedTemplateName] = useState("");
  const [saveFileError, setSaveFileError] = useState(false);
  const [deleteFileError, setDeleteFileError] = useState(false);
  const [success, setsuccess] = useState(false);
  const loadJson = useSelector((state) => state.project.loadTemplateJson);
  const loadTempName = useSelector((state) => state.project.loadTemplateName);
  const [editorChange, setEditorChange] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  //   if (window.location.reload){
  //     navigate("/email-dashboard/allTemplates")
  //   }

  const handleClose = () => {
    setOpen(false);
  };
 // console.log(loadJson, "JSONNNNNNNNN");
  const Token = sessionStorage.getItem("ChangePwToken");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };

  const handleSave = () => {
    // const toastLoad = toast.loading("Loading");
    createTemplateApi(requestBody, config)
      .then((res) => {
      //  console.log(res.data);

        // toast.dismiss(toastLoad);
        toast.success("Saved Successsfully");
      })
      .catch((res) => {
      //  console.log(res.data);
        setSaveFileError(true);
        // toast.dismiss(toastLoad);
        toast.error("Save Failed");
      });
    setConfirmSave(false);
  };

  const handleCloseSave = () => {
    setConfirmSave(false);
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
     // console.log("exportHtml", html);

      const encodedString = btoa(html);
    //  console.log(encodedString, "encodeeeeee");
      
    });
  };

  const requestBody = {
    templateName: loadTempName,
    templateString: html,
    templateJson: json,
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((data) => {
      setJson(data);
    });
    function utf8ToBase64(str) {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const base64 = btoa(String.fromCharCode(...data));
      return base64;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      const encodedString = btoa(unescape(encodeURIComponent(html)));
      setHtml(encodedString);
    });
    setConfirmSave(true);
  };

  const handleEditDesign = () => {
    editTemplateApi(requestBody, config)
      .then((res) => {
       // console.log(res.data);
        if (res.data.code === "200") {
          toast.success("Successfully edited");
        } else {
          toast.error("Edit failed");
        }
       
        setTimeout(() => {
          // handleLoadTemplate();
          handleLoadTemplate();
          
        }, 10);
      })
      .catch((res) => {
       // console.log(res.data);
        setSaveFileError(true);
        toast.error("Edit failed");
      });
    setConfirmSave(false);
  };

  const loadJsonRequestBody = {
    templateName: selectTemplate,
  };

  // const handleLoadTemplate = () => {
  //     if (selectTemplate) {
  //     setrenderEmailEditor(false);
  //     console.log("before templatesALL");
  //     loadTemplateApi(loadJsonRequestBody, config)
  //         .then((res) => {
  //         setrenderEmailEditor(true);
  //         console.log("after templatesALL");
  //         setLoadTemplate(res.data.data.tempJson);
  //         setLoadedTemplateName(res.data.data.name);
  //         })
  //         .catch((res) => {
  //         console.log(res.data);
  //         });
  //     }
  // };

  const onReady = () => {
    setTimeout(() => {
      emailEditorRef.current.editor.loadDesign(loadJson);
    }, 1000);
  };

  const onLoad = () => {
    setTimeout(() => {
      emailEditorRef.current.editor.loadDesign(loadJson);
    }, 1000);
  };

  const onChange = () => {
    setEditorChange(true);
    alert("changes occured");
  };

  setTimeout(() => {
    saveFileError && setSaveFileError(false);
    deleteFileError && setDeleteFileError(false);
    success && setsuccess(false);
  }, 100);

  const handleLoadTemplate = () => {
    const loadJsonRequestBody = {
      templateName: loadTempName,
    };
    loadTemplateApi(loadJsonRequestBody, config)
      .then((res) => {
       // console.log("after templatesALL");
        // navigate("/email-dashboard/editTemplates")
        dispatch(setLoadTemplateName(res.data.data.name));
        dispatch(setLoadTemplateJson(res.data.data.tempJson));
      })
      .catch((res) => {
       // console.log(res.data);
      });
  };

  return (
    <div>
      {saveFileError && (
        <Alert severity="error">Please enter template Name</Alert>
      )}
      {deleteFileError && (
        <Alert severity="error">Please Select template Name to delete</Alert>
      )}
      {success && (
        <Alert severity="success" color="info">
          Action Successful
        </Alert>
      )}
      <Toaster />
      <div
        className={classes.backFeature}
        onClick={() => {
          navigate("/email-dashboard/allTemplates");
        }}
      >
        <ArrowBackIcon fontSize="small" />
        Back to all templates
      </div>
      <div className={classes.topbarWrap}>
        <div className={classes.buttonWrapper}>
          <TextField
            size="small"
            fullWidth
            id="outlined-basic"
            label="Template Name"
            variant="outlined"
            value={loadTempName}
            // onChange={(e) => {
            //   setTemplateName(e.target.value);
            // }}
          />
          <div className={classes.infoIcon}>
            <IconButton aria-label="Info">
              <BootstrapTooltip title={longText}>
                <InfoIcon />
              </BootstrapTooltip>
            </IconButton>
          </div>
        </div>

        <div className={classes.buttonWrapper}>
          <button
            onClick={() => {
              navigate("/email-dashboard/templates");
            }}
            className={classes.buttons}
          >
            Create New
          </button>
          <button onClick={exportHtml} className={classes.buttons}>
            Export HTML
          </button>
          <button onClick={saveDesign} className={classes.buttons}>
            Save Design
          </button>
        </div>
      </div>

      {renderEmailEditor ? (
        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
          minHeight={780}
          onChange={onChange}
          options={{ displayMode: "email" }}
        />
      ) : (
        <div className={classes.loadingspinner}>
          <div className={classes.square1} id="square1"></div>
          <div className={classes.square2} id="square2"></div>
          <div className={classes.square3} id="square3"></div>
          <div className={classes.square4} id="square4"></div>
          <div className={classes.square5} id="square5"></div>
        </div>
      )}

      <Dialog
        open={confirmSave}
        onClose={handleCloseSave}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"primary"}>
          Save Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to save this Template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSave}>Cancel</Button>
          <Button onClick={handleEditDesign} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditTemplate;

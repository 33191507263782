import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import classes from "./SubscribeDataTablecomp.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import searchIcn from "../../assets/Images/searchIcon.svg";
import {
  ISingleGroup,
  ISubscriberList,
  deleteSubscriverAPICall,
  getAllCatDetailsApi,
  getBounceEmails,
} from "../../Utils/Apihelper";
import StyledLoader from "../StyledLoader/StyledLoader";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoDataComponent from "./NoDataComponent";
import Delete from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";

interface props {
  data: any;
  columns: any;
  loading: any;
  totalRows: any;
  paginationCount: any;
  handlePageChange: any;
  groups: ISingleGroup[];
  selectedGroup: string;
  handleChangeGroups: any;
  handleSelectedRowsDelete: any;
  handleDeleteMultipleSubscribers: any;
}

const SubscribeDataTableComp = ({
  data,
  loading,
  columns,
  handlePageChange,
  paginationCount,
  totalRows,
  groups,
  selectedGroup,
  handleChangeGroups,
  handleSelectedRowsDelete,
  handleDeleteMultipleSubscribers,
}: props) => {
  // const [tableData, setTableData] = useState([])
  const [selected, setselected] = useState([]);
  const handleChange = ({ selectedRows }: any) => {
    console.log("Selected Rows: ", selectedRows);
    setselected(selectedRows);
    // setselectedArray(selectedRows);
    handleSelectedRowsDelete(selectedRows);
  };
  const [query, setQuery] = useState("");
  const pathName = useLocation().pathname;
  const [type, settype] = useState("Subscribed");
  const [checkBounce, setcheckBounce] = useState(false);
  const [bounceData, setbounceData] = useState([]);
  const [selectedArray, setselectedArray] = useState([]);

  const DeleteSubscriber = () => {
    const filtered = selected.map((a: any) => a.emailAddress);
    let obj: any = {
      groupName: selectedGroup,
      deleteSubscribersList: filtered,
    };
    deleteSubscriverAPICall(obj)
      .then((response: any) => {
        toast.success(
          response.data.message ? response.data.message : "Deleted Successfully"
        );
        handleBounceEmails();
        setcheckBounce(false);
      })
      .catch((error: any) => {
        toast.error("Error Deleting");
      });
  };

  const handleBounceEmails = () => {
    const req = {
      groupName: selectedGroup,
    };
    getBounceEmails(req)
      .then((res: any) => {
        console.log(res.data.data);
        setbounceData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const customStyles = {
    table: {
      style: {
        // border: "1px solid #D9D9D9",
        borderRadius: "8px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        // border:"1px solid #D9D9D9"
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },

    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",

        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "16px",
        wordBreak: "break-word",
      },
      draggingStyle: {},
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",

        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },

    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,

        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };
  const selectedData =
    type === "Subscribed" ? data.subscribersList : data.unSubscribersList;

  const filteredData = query.trim().length
    ? selectedData.filter(
        (a: any) =>
          a.email.toLowerCase().includes(query.toLowerCase().trim()) ||
          a.name.toLowerCase().includes(query.toLowerCase().trim())
      )
    : selectedData;

  const Bcolumns: any = [
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Email</div>,
      selector: "email",
      width: "13rem",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.emailAddress}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}> Mail Name </div>,
      selector: "name",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.mailName}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Bounce Type</div>,
      selector: "source",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {row.bouncyType}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Bounce SubType</div>
      ),
      selector: "dateTime",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.bounceSubType}</div>
      ),
    },
  ];
  return (
    <>
      <div
        style={{
          borderRadius: "24px",
          padding: "15px 15px",
          backgroundColor: "white",
        }}
      >
        <div className={classes.tableWrapper}>
          <div className={classes.emailListHeader}>
            <div className={classes.listTitle}>
              {checkBounce ? "Bounce Emails" : "My Subscribers"}

              {selected.length > 0 && (
                <Tooltip title="Delete Multiple">
                  <IconButton
                    onClick={() => {
                      if (checkBounce) {
                        DeleteSubscriber();
                      } else {
                        handleDeleteMultipleSubscribers();
                        setselected([]);
                      }
                    }}
                  >
                    <Delete sx={{ color: "red" }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={"center"}
              justifyContent={"right"}
              spacing={1}
              width={{ xs: "100%", sm: "70%" }}
              gap={{ xs: "20px", sm: "unset" }}
            >
              {!checkBounce && (
                <div className={classes.searchInput}>
                  <div>
                    <img
                      src={searchIcn}
                      alt=""
                      className={classes.searchIcon}
                    />
                  </div>
                  <input
                    type="text"
                    className={classes.inputBox}
                    value={query}
                    // autoFocus
                    placeholder="Search...."
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              )}
              {!checkBounce && (
                <Autocomplete
                  size="small"
                  options={["Subscribed", "Unsubscribed"]}
                  defaultValue={type}
                  inputValue={type}
                  value={type}
                  sx={{
                    borderRadius: "12px",
                   
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",

                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  onChange={(event: any, newValue: string) => {
                    settype(newValue);
                  }}
                  id="disable-clearable"
                  popupIcon={
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type"
                      label="Type"
                      InputLabelProps={{
                        sx: {
                          backgroundColor: "",
                          marginTop: "-8px",
                          fontSize: "16px",
                          fontWeight: 600,
                          marginLeft: "-15px",
                        },
                      }}
                      sx={{
                        width: 160,
                      }}
                    />
                  )}
                />
              )}

              {/* {pathName.includes("subscriberInfo") ? (
                ""
              ) : (
                <div>
                  <Button
                    variant="contained"
                    endIcon={<FileDownloadOutlinedIcon />}
                    sx={{
                      background: "#F9FBFF",
                      borderRadius: "13px",
                      textTransform: "none",
                      padding: "6px 40px",
                      boxShadow: "none",
                      color: "#3D3C42",

                      "&:hover": { background: "#F9FBFF", color: "#3D3C42" },
                    }}
                  >
                    ExportAll
                  </Button>
                </div>
              )} */}
              {!checkBounce && (
                <Autocomplete
                  size="small"
                  value={selectedGroup}
                  options={groups && groups.map((a) => a.groupName)}
                  onChange={(event, value) => handleChangeGroups(value)}
                  // onInputChange={(event, value) => console.log(value)}
                  sx={{
                    borderRadius: "12px",
                    width: "100%",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",

                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="disable-clearable"
                  popupIcon={
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Group"
                      label="Group"
                      InputLabelProps={{
                        sx: {
                          backgroundColor: "",
                          marginTop: "-8px",
                          fontSize: "16px",
                          fontWeight: 600,
                          marginLeft: "-15px",
                        },
                      }}
                    />
                  )}
                />
              )}
            </Stack>
          </div>
          <div
            className={classes.checkBounce}
            onClick={() => {
              setcheckBounce(!checkBounce);
              handleBounceEmails();
            }}
          >
            {checkBounce ? "Back to all Subscribers" : "  Check bounce emails"}
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
            }}
          >
            {checkBounce ? (
              <>
                <DataTable
                  data={bounceData}
                  columns={Bcolumns}
                  title=" "
                  pagination={true}
                  selectableRows={true}
                  progressPending={loading}
                  noDataComponent={<NoDataComponent />}
                  // selectableRows
                  customStyles={customStyles}
                  onSelectedRowsChange={handleChange}
                  fixedHeader
                  progressComponent={<StyledLoader />}
                />
              </>
            ) : (
              <>
                <DataTable
                  data={filteredData}
                  columns={columns}
                  title=" "
                  pagination={true}
                  selectableRows={true}
                  progressPending={loading}
                  noDataComponent={<NoDataComponent />}
                  // selectableRows
                  customStyles={customStyles}
                  onSelectedRowsChange={handleChange}
                  fixedHeader
                  progressComponent={<StyledLoader />}
                />
              </>
            )}

            {/* {data.length > 0 && !loading && (
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                // marginTop={data.length >= 9 ? "10px" : "300px"}
                marginTop={"10px"}
                marginBottom={"20px"}
                marginLeft={"10px"}
                marginRight={"10px"}
              >
                <p style={{ color: "#B5B7C0", fontSize: "14px" }}>
                  Showing data 1 to 8 of 256K entries
                </p>
                <Pagination
                  onChange={(value: any, page: any) => {
                    handlePageChange(value, page);
                  }}
                  sx={{
                    button: {
                      color: "#404B52",
                      fontSize: "14px",
                      fontWeight: 600,
                      background: "#EEEEEE",
                    },
                    "& .MuiPaginationItem-root.Mui-selected": {
                      background: "#4C6FFF",
                      color: "white",
                    },
                  }}
                  page={paginationCount}
                  count={totalRows}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribeDataTableComp;

import React, { useEffect } from "react";

import logo from "../../../assets/Images/enotifiers-logo.png";
import TermsPrivacyStyles from "./TermsandPrivacy.module.scss";
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import Footer from "../../../containers/LandingPage/Footer/Footer";
import ScrollHeader from "../../../containers/LandingPage/ScrollHeader/ScrollHeader";
const TermsPrivacy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ScrollHeader />

      <div className={TermsPrivacyStyles.mainSectionterms}>
        <div>
          <h1 className={TermsPrivacyStyles.mainHeading}>
            Terms and Conditions
          </h1>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Acceptance of Terms
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; By accessing and using E-Notifiers, you agree to comply with
            and be bound by the following terms and conditions. If you do not
            agree to these terms, please refrain from using the service.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Description of Service
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; E-Notifiers provides email marketing services, allowing users
            to create, send, and track email campaigns. The service may also
            include additional features, updates, or modifications at the
            discretion of the service provider.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            User Accounts and Security
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users are responsible for maintaining the confidentiality of
            their account credentials and are liable for all activities
            conducted through their accounts.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users must promptly notify Mindorigin of any unauthorised
            access to their accounts.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>User Conduct</p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users must comply with all applicable laws and regulations
            when using the service.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users must not engage in any activities that may harm the
            integrity or performance of the service.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users are prohibited from using the service for any unlawful
            or fraudulent purposes.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Content and Data Ownership
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users retain ownership of the content and data they upload to
            the service.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin does not claim ownership of user-generated content
            but may use it to provide and improve the service.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Privacy and Data Security
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin follows a privacy policy that governs the
            collection, use, and disclosure of user information. Users should
            review the privacy policy available on the website.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin implements reasonable security measures to protect
            user data but does not guarantee absolute security.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Payment and Subscription
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users may be required to pay subscription fees for access to
            certain features of the service. Payment terms are outlined on the
            website.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin reserves the right to modify subscription fees and
            features with notice to users.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Termination of Service
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin reserves the right to terminate or suspend user
            accounts for violations of these terms or for any other reason.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users may terminate their accounts at any time, and
            Mindorigin will provide instructions for account closure.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Limitation of Liability
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin shall not be liable for any direct, indirect,
            incidental, special, or consequential damages resulting from the use
            or inability to use the service.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Changes to Terms
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Mindorigin reserves the right to modify these terms at any
            time. Users will be notified of changes, and continued use of the
            service constitutes acceptance of the updated terms.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>Governing Law</p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; These terms and conditions are governed by the laws of India,
            and any disputes shall be resolved in the courts of India.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Contact Information
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull;  For questions or concerns about these terms, please contact
            Mindorigin at support@mindorigin.
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; By using E-Notifiers, you acknowledge that you have read,
            understood, and agree to these terms and conditions.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsPrivacy;

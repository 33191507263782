import React from "react";
import classes from "./Unsubscribe.module.scss";
import unsub from "../../assets/Images/unsubscribenewimage.svg";
import { unsubscribeAPICall, unsubscribeApi } from "../../Utils/Apihelper";
import toast, { Toaster } from "react-hot-toast";

const Unsubscribe = () => {
  const myKeysValues = window.location.search;
  const urlParams = new URLSearchParams(myKeysValues);
  const companyName = urlParams.get("key1");
  const email = urlParams.get("key3");
  const catagoryName = urlParams.get("key2");

  const handleUnsubscribe = () => {
    const requestBody = {
      companyName: companyName,
      email: email,
      categoryName: catagoryName,
    };
    unsubscribeAPICall(companyName, email, catagoryName)
      .then((res) => {
        // console.log(res.data);
        toast.success("Succesfully unsubscribed");
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error("Unsubscribe Failed");
      });
  };

  return (
    <>
      <Toaster />
      <div className={classes.wrapper}>
        {/* <div className={classes.logo}>NOTIFIERS (logo)</div> */}
        <div className={classes.unsubscribeContainer}>
          {/* <div className={classes.header}>Unsubscribe?</div> */}
          <div className={classes.unsubImageContainer}>
            <img src={unsub} alt="" className={classes.unsubImage} />
          </div>
          <div className={classes.text1}>Are you sure about unsubscribing?</div>
          <div className={classes.text2}>
            If you unsubscribe you will no longer recieve emails from Us!!
          </div>
          <div className={classes.buttons}>
            {/* <div
              className={classes.cancelBtn}
              onClick={() => {
                window.opener = null;
                window.open("", "_self");
                window.close();
              }}
            >
              Cancel !
            </div> */}
            <div className={classes.cancelBtn} onClick={handleUnsubscribe}>
              Unsubscribe Me
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;

import React, { useEffect, useState } from "react";
import styles from "./ContactUs.module.scss";
import MainLandingPageHeader from "../MainlandingPage/MainLandingPageHeader/MainLandingPageHeader";
import Footer from "../LandingPage/Footer/Footer";
import heroimage from "../../assets/Images/contactusimage.svg";
import contactusimage2 from "../../assets/Images/contactusimage2.svg";
import socialicon1 from "../../assets/Images/contactsocial1.svg";
import socialicon2 from "../../assets/Images/contactsocial2.svg";
import socialicon3 from "../../assets/Images/contactsocial3.svg";
import icon1 from "../../assets/Images/contactusicon1.svg";
import icon2 from "../../assets/Images/contactusicon2.svg";
import icon3 from "../../assets/Images/contactusicon3.svg";
import icon4 from "../../assets/Images/contactusicon4.svg";
import heart from "../../assets/Images/heart.svg";
import { Button, Checkbox, TextField } from "@mui/material";
import contactarrow from "../../assets/Images/contactarrowimage.svg";
import BackToTopButton from "../../components/BacktoTop/BackToTopComp";
import { contactUsAPICall } from "../../Utils/Apihelper";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [message, setmessage] = useState("");
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handlenameInput = (e) => {
    setname(e.target.value);
  };
  const handleEmailInput = (e) => {
    setemail(e.target.value);
  };
  const handlenumberInput = (e) => {
    setnumber(e.target.value);
  };
  const handleMessageInput = (e) => {
    setmessage(e.target.value);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleEnquiry = () => {
    const req = {
      name: name,
      email: email,
      message: message,
      mobileNumber: number,
    };
    contactUsAPICall(req)
      .then((res: any) => {
        toast.success(`${res.data.message} Our team will contact you soon.`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <>
      <MainLandingPageHeader />
      <div className={styles.mainWrapper}>
        <div className={styles.topWrapper}>
          <div className={styles.topLeft}>
            <p className={styles.topLefttitle}>We Would Love to Hear</p>
            <div className={styles.textHeart}>
              <p className={styles.topLefttitle}>From You</p>
              <img src={heart} alt="" />
            </div>

            <p className={styles.topLeftDesc}>
              Whether you have a question about our products, need assistance,
              or just want to say hello, we're here and ready to help. Our
              dedicated team is here to assist you.
            </p>

            {/* <div className={styles.socialMediaSection}>
              <img src={socialicon1} className={styles.socialIcon} alt="" />
              <img src={socialicon2} className={styles.socialIcon} alt="" />
              <img src={socialicon3} className={styles.socialIcon} alt="" />
            </div> */}
          </div>
          <div className={styles.topRight}>
            <img src={heroimage} className={styles.heroimage} alt="" />
          </div>
        </div>

        <div className={styles.contactussection}>
          <div className={styles.contactleft}>
            <div className={styles.topImage}>
              <img
                src={contactusimage2}
                className={styles.contactimage}
                alt=""
              />
            </div>
            <div className={styles.pandc}>
              <div className={styles.pandcText}>
                <p className={styles.pandctitle}>
                  Partnerships and Collaborations
                </p>
                <p className={styles.pandcemail}> support@enotifiers.com</p>
              </div>
              <img src={contactarrow} className={styles.contactarrow} alt="" />
            </div>
          </div>
          <div className={styles.contactright}>
            <div className={styles.contactrightTextfeildWrapper1}>
              <div className={styles.labels}>
                Name
                <TextField
                  placeholder="Enter First Name"
                  id="outlined-size-small"
                  size="small"
                  variant="standard"
                  value={name}
                  onChange={handlenameInput}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,

                    sx: {
                      height: "50px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    // minWidth: { xs: "100%", sm: "400px" },
                    width: "-webkit-fill-available",
                    background: "#FCFCFD",
                    borderRadius: "5px",
                    border: "1px solid #F1F1F3",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className={styles.contactrightTextfeildWrapper}>
              <div className={styles.labels}>
                Email
                <TextField
                  placeholder="Enter Your Email"
                  id="outlined-size-small"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={handleEmailInput}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,

                    sx: {
                      height: "50px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    // minWidth: { xs: "100%", sm: "400px" },
                    background: "#FCFCFD",
                    borderRadius: "5px",
                    border: "1px solid #F1F1F3",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
              <div className={styles.labels}>
                Phone
                <TextField
                  placeholder="Enter Phone Number"
                  id="outlined-size-small"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={number}
                  onChange={handlenumberInput}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,

                    sx: {
                      height: "50px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    // minWidth: { xs: "100%", sm: "400px" },
                    background: "#FCFCFD",
                    borderRadius: "5px",
                    border: "1px solid #F1F1F3",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className={styles.labels}>
              Message
              <TextField
                id="outlined-multiline-static"
                label=""
                placeholder="Enter Your Message"
                size="small"
                multiline
                fullWidth
                rows={5}
                value={message}
                onChange={handleMessageInput}
                sx={{
                  background: "#FCFCFD",
                  borderColor: "#F1F1F3",
                  "& label.Mui-focused": {
                    color: "#F1F1F3",
                    borderColor: "#F1F1F3",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#F1F1F3",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderColor: "#F1F1F3",
                    "&.Mui-focused fieldset": {
                      borderColor: "#F1F1F3",
                    },
                  },
                }}
              />
            </div>

            <div className={styles.bottomSend}>
              <div className={styles.checkbox}>
                <div>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    sx={{
                      "&.Mui-checked": {
                        color: "4c6fff",
                      },
                    }}
                  />
                </div>
                <p style={{ fontSize: "14px" }}>
                  I agree with Terms of Use and
                  <span
                    style={{
                      color: "4c6fff",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/termsandconditions")}
                  >
                    {" "}
                    privacy policy.{" "}
                  </span>
                </p>
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{
                    background: "4c6fff",
                    borderRadius: "4px",
                    textTransform: "none",
                    padding: "10px 30px",
                    boxShadow: "none",

                    "&:hover": { background: "4c6fff" },
                  }}
                  onClick={handleEnquiry}
                  disabled={
                    !checked ||
                    name === "" ||
                    email === "" ||
                    message === "" ||
                    number === ""
                  }
                >
                  SEND
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.contactDetailsSection}>
          <div className={styles.contactDetailsPhoneSection}>
            <div className={styles.iconimages}>
              <img src={icon1} className={styles.icon1} alt="" />
            </div>
            <p className={styles.ptext}>Phone</p>
            <p className={styles.pdata}>9874564587</p>
          </div>
          <div className={styles.contactDetailsPhoneSection}>
            <div className={styles.iconimages}>
              <img src={icon2} className={styles.icon1} alt="" />
            </div>
            <p className={styles.ptext}>Email</p>
            <p className={styles.pdata}>info@mindorigin.in</p>
          </div>
          <div className={styles.contactDetailsPhoneSection}>
            <div className={styles.iconimages}>
              <img src={icon3} className={styles.icon1} alt="" />
            </div>
            <p className={styles.ptext}>Website</p>
            <p className={styles.pdata}>www.mindorigin.in</p>
          </div>
          <div className={styles.contactDetailsPhoneSection1}>
            <div className={styles.iconimages}>
              <img src={icon4} className={styles.icon1} alt="" />
            </div>
            <p className={styles.ptext}>Location</p>
            <p className={styles.pdata1}>
              No.699, 34th cross ,Jayanagar 4th Block, Bangalore, Karnataka
            </p>
          </div>
        </div> */}
      </div>
      <Footer />
      <BackToTopButton />
    </>
  );
}

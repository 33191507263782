import React from 'react';
import classes from "./StyledLoader.module.scss"

export default function StyledLoader() {
  return (
    <div>
       <span className={classes.loader}></span>
    </div>
  );
}

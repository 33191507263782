import { useNavigate } from "react-router-dom";
import logo from "../../../assets/Images/enotifiers-logo.png";
import ReturnPolicyStyles from "./ReturnPolicy.module.scss";
import { Button } from "@mui/material";
import React, { useEffect } from "react";

import ScrollHeader from "../../../containers/LandingPage/ScrollHeader/ScrollHeader";
import Footer from "../../../containers/LandingPage/Footer/Footer";
const ReturnPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ScrollHeader />

      <div className={ReturnPolicyStyles.mainSectionterms}>
        <div>
          <h1 className={ReturnPolicyStyles.mainHeading}>Refund Policy</h1>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Refund Eligibility
          </p>

          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; E-Notifiers offers a 7-day refund policy for users who have
            subscribed to a paid plan.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;To be eligible for a refund, the request must be made within 7
            days of the date of purchase.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Refund Process
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Users must submit a refund request through the E-Notifiers
            platform or by contacting our customer support team within the
            stipulated 7-day period.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Refund requests should include the user's name, email
            address, the date of purchase, and a brief explanation of the reason
            for the refund.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Eligible Circumstances
          </p>

          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;Refunds will be considered in cases of dissatisfaction with
            the service or technical issues that prevent the proper use of the
            subscribed plan.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;Refunds will not be issued for cases of misuse, violation of
            terms of service, or other activities that breach our policies.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>Refund Amount</p>

          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;The refund will be processed for the full amount paid by the
            user for the plan.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Any additional fees, such as transaction charges, are
            non-refundable.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Refund Timeframe
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;Once the refund request is approved, the processing time may
            vary. Users can expect refunds to be issued within 5-10 business
            days.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Payment Method
          </p>

          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Refunds will be issued using the same payment method used for
            the original purchase.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;If a refund is not possible through the original payment
            method, alternative arrangements will be made in consultation with
            the user.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Plan Cancellation
          </p>

          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Refunds will be processed upon confirmation of plan
            cancellation.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Users are responsible for canceling their plans through the
            E-Notifiers platform before a refund can be initiated.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Changes to Refund Policy
          </p>

          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; E-Notifiers reserves the right to modify this refund policy
            at any time. Users will be notified of changes through the
            platform..
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull; Continued use of the service after changes to the refund
            policy constitutes acceptance of the updated terms.
          </p>
        </div>
        <div>
          <p className={ReturnPolicyStyles.toadjustheaderfont}>
            Contact Information
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;For refund-related inquiries or to submit a refund request,
            please contact our customer support team at support@mindorigin.in.
          </p>
          <p className={ReturnPolicyStyles.toalignpara}>
            &bull;By using E-Notifiers, you acknowledge that you have read,
            understood, and agree to the terms of this refund policy.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ReturnPolicy;

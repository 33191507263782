import React from 'react';
import styles from "./Campaignstatistics.module.scss"

 const Campaignstatistics =() => {
  return (
    <div>
      Campaignstatistics
    </div>
  );
}
export default Campaignstatistics;
import React, { useEffect, useState } from "react";
import styles from "./MainPricingPage.module.scss";
import MainLandingPageHeader from "../MainLandingPageHeader/MainLandingPageHeader";
import Footer from "../../LandingPage/Footer/Footer";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { Button, FormControlLabel } from "@mui/material";
import doneImage from "../../../assets/Images/Check_fill.svg";
import errorImage from "../../../assets/Images/checkerror.svg";
import carrow from "../../../assets/Images/comparisonrightarrow.svg";
import newsLetter from "../../../assets/Images/Newslatter.svg";

import { useNavigate } from "react-router-dom";
import APIDocs from "../../../components/Pages/APIDocs/APIDocs";
import Grid from "@mui/material/Grid";
import { priceCompare, pricing } from "../../../assets/data";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#4c6fff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

export default function MainPricingPage() {
  const [tab, setTab] = useState(1);
  const [yearly, setyearly] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  useEffect(() => {}, [yearly]);
  return (
    <>
      <MainLandingPageHeader />
      <div className={styles.mainWrapper}>
        <div className={styles.topContentContainer}>
          <p className={styles.topContentTitleText}>
            Unlock Your Marketing Potential
          </p>

          <p className={styles.topContentSubText}>
            Tailored Pricing for Email, SMS, and WhatsApp Outreach.
          </p>
        </div>

        <div className={styles.tabsWrapper}>
          <div className={styles.tabsCatagory}>
            <div
              className={styles.tabsItem}
              onClick={() => {
                activeTab(1);
              }}
              style={{
                backgroundColor: tab === 1 ? "#4c6fff" : "",
                color: tab == 1 ? "white" : "",
              }}
            >
              Email
            </div>
            <div
              className={styles.tabsItem}
              onClick={() => {
                activeTab(2);
              }}
              style={{
                backgroundColor: tab === 2 ? "#4c6fff" : "",
                color: tab == 2 ? "white" : "",
              }}
            >
              WhatsApp
            </div>
            <div
              className={styles.tabsItem}
              onClick={() => {
                activeTab(3);
              }}
              style={{
                backgroundColor: tab === 3 ? "#4c6fff" : "",
                color: tab == 3 ? "white" : "",
              }}
            >
              SMS
            </div>
          </div>
        </div>

        {tab === 1 ? (
          <>
            <p className={styles.saveText}>
              Save Up to 20% with Our Annual Subscription Plan
            </p>
            <div className={styles.chechBoxSection}>
              <p
                className={styles.monthlyetxt}
                style={{
                  fontWeight: yearly ? 500 : 600,
                  fontSize: yearly ? "14px" : "16px",
                }}
              >
                {" "}
                Monthly
              </p>
              <FormControlLabel
                control={<IOSSwitch />}
                label=""
                onChange={(event: any, checked: any) => {
                  setyearly(!yearly);
                }}
              />
              <p
                className={styles.monthlyetxt}
                style={{
                  fontWeight: yearly ? 600 : 500,
                  fontSize: yearly ? "16px" : "14px",
                }}
              >
                Yearly
              </p>
            </div>{" "}
            {/* <div className={styles.bottomContainerNew}>
              <div className={styles.bottomLeftSection}>
                <div className={styles.bottomLeftSectionContainer}>
                  <p className={styles.planType}>Free Forever</p>

                  <div className={styles.freeTextDev}>
                    <p className={styles.freeText}>₹0</p>
                    <p className={styles.freeTextDesc}>
                      Start your journey with essential features at no cost
                    </p>
                  </div>

                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>10k contacts </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>60K email per month</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Basic ready-made email templates
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Basic Statistics </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Logs Retained 10 days </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Email Support reply - 1 week{" "}
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Dedicated IP </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={errorImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>0 Subusers </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={errorImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>No Premium Templates </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={errorImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>No API Access </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <p className={styles.textcontent}> </p>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#4c6fff",
                        borderRadius: "4px",
                        textTransform: "none",
                        padding: "8px 0px",
                        width: "100%",
                        marginTop: "20px",
                        boxShadow: "none",
                        color: "white",
                        fontWeight: "600",
                        "&:hover": { background: "#4c6fff", color: "white" },
                      }}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Start for Free
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.bottomLeftSectionCenter}>
                <div className={styles.bottomLeftSectionContainer}>
                  <p
                    className={styles.planType}
                    style={{
                      padding: "0px 10px",
                      marginBottom: "10px",
                    }}
                  >
                    Standard
                  </p>

                  <div className={styles.freeTextDev}>
                    <p className={styles.freeText}>
                      {yearly ? "₹5000/Month" : "₹6250/Month"}
                    </p>
                    <p className={styles.freeTextDesc}>
                      For professional domain names investors with a big
                      portfolio.
                    </p>
                  </div>

                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Unlimited contacts</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>750K emails per month</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Advanced readymade email templates
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Real-time emails statistics
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Logs Retained 3 Months</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>API Access </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Dedicated IP </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Email Support reply - 1 day{" "}
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>3 users </p>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#4c6fff",
                        borderRadius: "4px",
                        textTransform: "none",
                        padding: "8px 0px",
                        width: "100%",
                        marginTop: "20px",
                        boxShadow: "none",
                        color: "white",
                        fontWeight: "600",
                        "&:hover": { background: "#4c6fff", color: "white" },
                      }}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Buy
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.bottomLeftSection}>
                <div className={styles.bottomLeftSectionContainer}>
                  <p className={styles.planType}>Professional</p>

                  <div className={styles.freeTextDev}>
                    <p className={styles.freeText}>
                      {yearly ? "₹10,400/Month" : "₹13,000/Month"}
                    </p>
                    <p className={styles.freeTextDesc}>
                      Unlock premium features for established businesses and
                      professionals
                    </p>
                  </div>

                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Unlimited contacts</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Unlimited Mails</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Advanced readymade email templates
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Real-time emails statistics
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Logs Retained 1 Year </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Email tracking and analysis
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>API Access</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>
                      Email Support reply - 1 day
                    </p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Custom Support</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>Dedicated IP</p>
                  </div>
                  <div className={styles.donetextsection}>
                    <img src={doneImage} className={styles.doneimage} />
                    <p className={styles.textcontent}>6 Users</p>
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#4c6fff",
                        borderRadius: "4px",
                        textTransform: "none",
                        padding: "8px 0px",
                        width: "100%",
                        marginTop: "20px",
                        boxShadow: "none",
                        color: "white",
                        fontWeight: "600",
                        "&:hover": { background: "#4c6fff", color: "white" },
                      }}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Buy
                    </Button>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className={styles.topContentContainer}
              style={{ marginTop: "40px" }}
            >
              <p className={styles.topContentTitleText}>Plan Comparison</p>

              <p className={styles.topContentSubText}>
                Choose the Right Fit for Your Needs
              </p>
            </div>
            <div className={styles.pcWrap}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{ background: "#FFF2F7", borderRadius: "10px 0 0 10px" }}
                >
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan1.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹{pricing.plan1.price.Monthly}
                    </div>
                    <div className={styles.planDuration}>Per Member</div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Free
                      </Button>
                    </div>
                    {pricing.plan1.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={6} md={3} sx={{ background: "#FFF6EC" }}>
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan2.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹
                      {!yearly
                        ? pricing.plan2.price.Monthly
                        : pricing.plan2.price.Yearly}
                    </div>
                    <div className={styles.planDuration}>
                      Per Member, Per Month
                    </div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Continue with Basic
                      </Button>
                    </div>
                    {pricing.plan2.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{ background: "rgba(139, 239, 243, 0.12)" }}
                >
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan3.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹
                      {!yearly
                        ? pricing.plan3.price.Monthly
                        : pricing.plan3.price.Yearly}
                    </div>
                    <div className={styles.planDuration}>
                      Per Member, Per Month
                    </div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Continue with Standard
                      </Button>
                    </div>
                    {pricing.plan3.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan4.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹{" "}
                      {yearly
                        ? pricing.plan4.price.Yearly
                        : pricing.plan4.price.Monthly}
                    </div>
                    <div className={styles.planDuration}>
                      Per Member, Per Month
                    </div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/contactus");
                        }}
                      >
                        Continue with Professional
                      </Button>
                    </div>
                    {pricing.plan4.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display:"flex",
                justifyContent:"center",
                margin:"2rem 0"
              }}
              onClick={()=>{navigate("/contactus")}}
            >
              <img
                src={newsLetter}
                alt=""
                style={{
                  width: "90%",
                }}
              />
            </div>
            <div className={styles.CtableWrap}>
              <div className={styles.title2}>Plan Comparision</div>
              <div className={styles.title3}>
                Choose the Right Fit for Your Needs
              </div>
              <div className={styles.Ctable}>
                <div className={styles.Csec1}>
                  <div className={styles.Csec1Head}>
                    Compare features by plan:
                  </div>
                  {priceCompare.features.map((a) => (
                    <div className={styles.Csec1Feat}>{a}</div>
                  ))}
                </div>
                <div className={styles.Csec2}>
                  <div className={styles.Csec1Head}>Free</div>
                  {priceCompare.free.map((a) => (
                    <div className={styles.Csec1Feat}>
                      {a === "true" ? (
                        <img src={doneImage} className={styles.doneimage} />
                      ) : a === "false" ? (
                        <img src={errorImage} className={styles.doneimage} />
                      ) : (
                        <div className={styles.Csec1FeatText}>{a}</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.Csec2}>
                  <div className={styles.Csec1Head}>Basic</div>
                  {priceCompare.Basic.map((a) => (
                    <div className={styles.Csec1Feat}>
                      {a === "true" ? (
                        <img src={doneImage} className={styles.doneimage} />
                      ) : a === "false" ? (
                        <img src={errorImage} className={styles.doneimage} />
                      ) : (
                        <div className={styles.Csec1FeatText}>{a}</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.Csec2}>
                  <div className={styles.Csec1Head}>Standard</div>
                  {priceCompare.standard.map((a) => (
                    <div className={styles.Csec1Feat}>
                      {a === "true" ? (
                        <img src={doneImage} className={styles.doneimage} />
                      ) : a === "false" ? (
                        <img src={errorImage} className={styles.doneimage} />
                      ) : (
                        <div className={styles.Csec1FeatText}>{a}</div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.Csec2}>
                  <div className={styles.Csec1Head}>Professional</div>
                  {priceCompare.Professional.map((a) => (
                    <div className={styles.Csec1Feat}>
                      {a === "true" ? (
                        <img src={doneImage} className={styles.doneimage} />
                      ) : a === "false" ? (
                        <img src={errorImage} className={styles.doneimage} />
                      ) : (
                        <div className={styles.Csec1FeatText}>{a}</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <APIDocs />
        )}
      </div>

      <Footer />
    </>
  );
}

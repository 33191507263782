import React from "react";
import nodataimage from "../../assets/Images/noDataImage.svg";
import { Button } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

export default function NoDataComponent() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        height: "60vh",
      }}
    >
      <img src={nodataimage} />
      <p>No Data Found</p>
      {/* <Button
        startIcon={
          <InsertLinkIcon
            sx={{ color: "white", transform: "rotate(145deg)" }}
          />
        }
        sx={{
          background: "#4C6FFF",
          borderRadius: "100px",
          textTransform: "none",
          padding: "6px 20px",
          boxShadow: "none",
          color: "white",
          "&:hover": { background: "#4C6FFF", color: "white" },
        }}
      >
        Create a Email Campaign{" "}
      </Button> */}
    </div>
  );
}

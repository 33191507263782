import React from "react";
import classes from "./Section4.module.scss";
import wplpim4 from "../../../../assets/Images/WPLPim4.svg";
import wplpim5 from "../../../../assets/Images/WPLPIm5.svg";

const Section4 = () => {
  return (
    <>
      <div className={classes.section3Wrap}>
        <div className={classes.section3Right}>
          <img src={wplpim5} alt="" className={classes.s3img3}/>
          <div className={classes.section3Head}>
            Elevate Engagement with Automated WhatsApp Chatbots
          </div>
          <div className={classes.section3Text}>
            Empower Your Business with Round-the-Clock Support
          </div>
          <div className={classes.section3SubText}>
            Unlock Continuous Interaction by Automating Conversations on Your
            WhatsApp Business Account, Ensuring Availability 24/7
          </div>
        </div>
        <div className={classes.section3Left}>
          <img src={wplpim4} alt="" className={classes.s3img2} />
        </div>
      </div>
    </>
  );
};

export default Section4;

import React, { useState, useRef, useEffect } from "react";
import EmailEditor from "react-email-editor";
import classes from "./EmailtemplateEditor.module.scss";
import { toPng } from "html-to-image";
import { TextField, FormHelperText } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed } from "../../Store/Project/ProjectSlice";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import {
  createTemplateApi,
  deleteTemplateAPICall,
  editTemplateAPICall,
} from "../../Utils/Apihelper";
import { Toaster, toast } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./EmailTemplateEditor.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const fonts = [
  {
    label: "Comic Sans",
    value: "'Comic Sans MS', cursive, sans-serif",
  },
  {
    label: "Lobster Two",
    value: "'Lobster Two',cursive",
    url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700",
  },
  {
    label: "Anton",
    value: "'Anton', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Anton",
  },
  {
    label: "Georgia",
    value: "Georgia, Times, 'Times New Roman', serif",
  },
  {
    label: "Helvetica",
    value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  {
    label: "Lucida",
    value: "'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif",
  },
  {
    label: "Lato",
    value: "'Lato', Tahoma, Verdana, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lato",
  },
  {
    label: "Andale Mono",
    value: "andale mono,times",
  },
  {
    label: "Arial",
    value: "arial,helvetica,sans-serif",
  },
  {
    label: "Arial Black",
    value: "arial black,avant garde,arial",
  },
  {
    label: "Book Antiqua",
    value: "book antiqua,palatino",
  },
  {
    label: "Comic Sans MS",
    value: "comic sans ms,sans-serif",
  },
  {
    label: "Courier New",
    value: "courier new,courier",
  },
  {
    label: "Georgia",
    value: "georgia,palatino",
  },
  {
    label: "Helvetica",
    value: "helvetica,sans-serif",
  },
  {
    label: "Impact",
    value: "impact,chicago",
  },
  {
    label: "Symbol",
    value: "symbol",
  },
  {
    label: "Tahoma",
    value: "tahoma,arial,helvetica,sans-serif",
  },
  {
    label: "Terminal",
    value: "terminal,monaco",
  },
  {
    label: "Times New Roman",
    value: "times new roman,times",
  },
  {
    label: "Trebuchet MS",
    value: "trebuchet ms,geneva",
  },
  {
    label: "Verdana",
    value: "verdana,geneva",
  },
  {
    label: "Lobster Two",
    value: "'Lobster Two',cursive",
    url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700",
  },
  {
    label: "Playfair Display",
    value: "'Playfair Display',serif",
    url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700",
  },
  {
    label: "Rubik",
    value: "'Rubik',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Rubik:400,700",
  },
  {
    label: "Source Sans Pro",
    value: "'Source Sans Pro',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700",
  },
  {
    label: "Open Sans",
    value: "'Open Sans',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700",
  },
  {
    label: "Crimson Text",
    value: "'Crimson Text',serif",
    url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700",
  },
  {
    label: "Montserrat",
    value: "'Montserrat',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Montserrat:400,700",
  },
  {
    label: "Old Standard TT",
    value: "'Old Standard TT',serif",
    url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700",
  },
  {
    label: "Lato",
    value: "'Lato',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lato:400,700",
  },
  {
    label: "Raleway",
    value: "'Raleway',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Raleway:400,700",
  },
  {
    label: "Cabin",
    value: "'Cabin',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Cabin:400,700",
  },
  {
    label: "Pacifico",
    value: "'Pacifico',cursive",
    url: "https://fonts.googleapis.com/css?family=Pacifico",
  },
];

const EmailTemplateEditor = () => {
  const emailEditorRef = useRef();
  const textFieldRef = useRef(null);
  const elementRef = useRef(null);
  const [html, setHtml] = useState("");
  const [encodedhtml, setEncodedHtml] = useState("");
  const [json, setJson] = useState("");
  const [urll, setUrll] = useState("");
  const [saveLoad, setSaveLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [confirmSave, setConfirmSave] = React.useState(false);
  const editTemp = sessionStorage.getItem("editFlag");
  const premiumClone = sessionStorage.getItem("PremiumClone");
  const loadJson = useSelector((state) => state.project.loadTemplateJson);
  const [templateNameEdited, setTemplateNameEdited] = useState(
    sessionStorage.getItem("TemplateName")
  );
  const [openTags, setOpenTags] = React.useState(false);
  const handleOpenTags = () => setOpenTags(true);
  const handleCloseTags = () => setOpenTags(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [disableSave, setdisableSave] = useState(false);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   var div = document.createElement("div");
  //   div.setAttribute("ref", elementRef);
  //   div.innerHTML = html;
  // }, [html]);

  useEffect(() => {
    elementRef.current.innerHTML = html;
  }, [html]);

  useEffect(() => {
    dispatch(setCollapsed(true));
    setTemplateNameEdited(sessionStorage.getItem("TemplateName"));
  }, []);

  const handleCloseSave = () => {
    setConfirmSave(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setHtml(html);
    });
  };

  // const htmlToImageConvert = () => {
  //   elementRef.current.className = "displayAll";
  //   console.log("elementRef.current", elementRef.current);
  //   toPng(elementRef.current, { cacheBust: false })
  //     .then((dataUrl) => {
  //       setUrll(dataUrl);
  //       const link = document.createElement("a");
  //       link.download = "my-image-name.png";
  //       link.href = dataUrl;
  //       link.click();
  //       elementRef.current.className = "displayNone";
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const htmlToImageConvert = (htmlParam) => {
    elementRef.current.innerHTML = htmlParam;
    elementRef.current.className = "displayAll";
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        setUrll(dataUrl);
        elementRef.current.className = "displayNone";
      })
      .catch((err) => {
        //  console.log("OOps something went wrong");
      });
  };

  const onLoad = () => {
    editTemp === "true" &&
      emailEditorRef.current?.editor.loadDesign(saveLoad ? json : loadJson);
  };

  const onReady = () => {
    emailEditorRef.current.editor.setBodyValues({
      backgroundColor: "white",
      fontFamily: {
        label: "Times New Roman",
        value: "times new roman,times",
      },
    });
  };

  // const handleInputChange = (event) => {
  //   setTemplateNameEdited(event.target.value);
  // };

  const handleInputChange = (event) => {
    const newValue = event.target.value.replace(/\s/g, "_");

    const isValidInput = /^[a-zA-Z0-9_]*$/.test(newValue);
    setErrorMessage(
      isValidInput
        ? ""
        : "Please enter only alphanumeric characters and underscores."
    );

    setTemplateNameEdited(newValue);
  };

  const saveDesign = () => {
    editTemp === "true" && setSaveLoad(true);
    emailEditorRef.current.editor.saveDesign((data) => {
      setJson(data);
    });
    function utf8ToBase64(str) {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const base64 = btoa(String.fromCharCode(...data));
      return base64;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      htmlToImageConvert(html);
      setHtml(html);
      const encodedString = utf8ToBase64(html);
      setEncodedHtml(encodedString);
    });

    setConfirmSave(true);
  };

  // console.log(urll, "url");

  const handleSave = () => {
    const requestBody = {
      templateName: templateNameEdited,
      templateType: sessionStorage.getItem("TemplateType"),
      templateCategory: sessionStorage.getItem("TemplateCategory"),
      templateString: html,
      templateJson: json,
    };
    const toastLoad = toast.loading("Saving...");
    setdisableSave(true);
    createTemplateApi(requestBody)
      .then((res) => {
        // console.log(res.data);
        toast.dismiss(toastLoad);
        sessionStorage.setItem("PremiumClone", "false");
        if (res.data.status === "success") {
          toast.success(res.data.message);
          setConfirmSave(false);
          navigate("/email-dashboard/allTemplates");
          setdisableSave(false);
        } else {
          setdisableSave(false);
          toast.error(
            res.data?.error?.message
              ? res.data.error.message
              : "Error Creating Template"
          );
        }
      })
      .catch((error) => {
        toast.dismiss(toastLoad);
        setdisableSave(false);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : "Error Creating Template"
        );
      });
  };

  const handleEditSave = () => {
    const requestBody = {
      oldTemplateName: sessionStorage.getItem("TemplateName"),
      newTemplateName: templateNameEdited,
      templateType: sessionStorage.getItem("TemplateType"),
      templateCategory: sessionStorage.getItem("TemplateCategory"),
      templateString: html,
      templateJson: json,
    };
    const toastLoad = toast.loading("Saving...");
    setdisableSave(true);

    editTemplateAPICall(requestBody)
      .then((res) => {
        // console.log(res.data);
        toast.dismiss(toastLoad);

        if (res.data.status === "success") {
          toast.success(res.data.message);
          setConfirmSave(false);
          navigate("/email-dashboard/allTemplates");
          setdisableSave(false);
          sessionStorage.setItem("editFlag", "false");
        } else {
          setdisableSave(false);
          toast.error(
            res.data?.error?.message
              ? res.data.error.message
              : "Error Updating Template"
          );
        }
      })
      .catch((error) => {
        setdisableSave(false);
        toast.dismiss(toastLoad);
        toast.error(
          error.response?.data?.data?.error
            ? error.response.data.data.error
            : "Error Updating Template"
        );
      });
  };

  const handleDelete = () => {
    const dltBody = {
      templateName: sessionStorage.getItem("TemplateName"),
      templateCategory: sessionStorage.getItem("TemplateCategory"),
    };
    deleteTemplateAPICall(dltBody)
      .then((res) => {
        navigate("/email-dashboard/allTemplates");
        sessionStorage.setItem("editFlag", "false");
        toast.success(
          res.data?.data?.info
            ? res.data.data.info
            : "Successfully Deleted the Template"
        );
      })
      .catch((err) => {
        toast.error("Deletion Failed");
      });

    setOpen(false);
  };

  const startTour = () => {
    handleClose();
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      steps: [
        {
          element: "#editor1",
          popover: {
            title: "Template Name",
            description:
              "Identifying label given to a template. It helps users easily recognize and select the desired template when creating campaigns or communications.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#editor2",
          popover: {
            title: "Guide to variable usage",
            description:
              "Create templates that can send emails with dynamic data by using variables.",
            side: "left",
            align: "start",
          },
        },

        {
          element: "#editor3",
          popover: {
            title: "Email Template Editor !",
            description:
              "AI powered custom drag and drop editor to create some stunning templates",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#editor4",
          popover: {
            title: "Don't forget to save!",
            description:
              "Enables users to preserve their custom-designed email layouts, styles, and content configurations for future use.",
            side: "left",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  };

  return (
    <>
      {/* <button onClick={exportHtml}>html</button> */}
      {/* <button onClick={htmlToImageConvert}>png</button> */}
      <Toaster />
      <div style={{ position: "relative" }}>
        <div
          ref={elementRef}
          className="displayNone"
          // style={{
          //   display:"none"
          // }}
        ></div>
        <div>
          <div className={classes.header}>
            <div className={classes.hLeft} id="editor1">
              <div>
                <TextField
                  value={templateNameEdited}
                  onChange={handleInputChange}
                  sx={{
                    minWidth: "300px",
                    backgroundColor: "#E3ECF7",
                    outline: "none",
                    height: "fit-content",
                  }}
                  placeholder="Template Name"
                  id="outlined-size-small"
                  size="small"
                  inputRef={textFieldRef}
                  inputProps={{ maxLength: 30 }}
                />
              </div>
              <div
                className={classes.igBtnGrp}
                style={{ backgroundColor: "#4C6FFF" }}
                onClick={() => {
                  if (textFieldRef.current) {
                    textFieldRef.current.focus();
                  }
                }}
              >
                <BorderColorOutlinedIcon fontSize="small" />
                Edit name
              </div>
              <Typography
                aria-owns={openPopover ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ ml: "12px", cursor: "pointer" }}
              >
                <InfoOutlinedIcon />
              </Typography>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1, fontSize: "14px" }}>
                  Note: For promotional templates "&#123;&#123; unsubscribe
                  &#125;&#125;" variable is must.
                </Typography>
              </Popover>
            </div>
            <div className={classes.hRight}>
              <div
                className={classes.hRight1}
                id="editor2"
                onClick={() => {
                  handleOpenTags();
                }}
              >
                {" "}
                <Tooltip title="Guide">
                  <IconButton>
                    <LocalOfferOutlinedIcon fontSize="small" />{" "}
                  </IconButton>
                </Tooltip>
              </div>
              <div
                className={classes.hRight1}
                onClick={() => {
                  startTour();
                }}
              >
                {" "}
                <Tooltip title="Demo">
                  <IconButton>
                    <TipsAndUpdatesOutlinedIcon fontSize="small" />{" "}
                  </IconButton>
                </Tooltip>
              </div>
              {editTemp === "true" && (
                <div
                  className={classes.hRight1}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Tooltip title="Delete">
                    <IconButton>
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {/* <div className={classes.hRight1}>
                <Tooltip title="Email">
                  <IconButton>
                    <RocketLaunchOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div> */}
              <div
                className={classes.save}
                onClick={() => {
                  saveDesign();
                }}
                id="editor4"
              >
                <SaveOutlinedIcon /> Save Template
              </div>
            </div>
          </div>

          <div style={{ height: "20px" }}>
            {errorMessage && (
              <p style={{ fontSize: "12px", color: "red" }}>{errorMessage}</p>
            )}
          </div>
          {/* <div ref={elementRef}></div> */}
          <div id="editor3">
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              onReady={onReady}
              minHeight={"74vh"}
              options={{
                displayMode: "email",
                editor: {
                  autoSelectOnDrop: true,
                },
                fonts: {
                  showDefaultFonts: true,
                  customFonts: fonts,
                },
              }}
              appearance={{}}
              style={{
                backgroundColor: "white",
              }}
            />
          </div>
          {/* <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            onReady={onReady}
            minHeight={"74vh"}
            options={{
              displayMode: "email",
              editor: {
                autoSelectOnDrop: true,
              },
              fonts: {
                showDefaultFonts: true,
                customFonts: fonts,
              },
            }}
            appearance={{}}
            style={{
              backgroundColor: "white",
            }}
          /> */}
        </div>
      </div>

      <Dialog
        open={confirmSave}
        onClose={handleCloseSave}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"primary"}>
          Save Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to save this Template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttons}>
            <div className={classes.cancelBtn} onClick={handleCloseSave}>
              Cancel
            </div>
            <div
              className={classes.createBtn}
              onClick={() => {
                if (!disableSave) {
                  if (premiumClone === "true" && editTemp === "true") {
                    handleSave();
                  } else if (editTemp === "true") {
                    handleEditSave();
                  } else {
                    handleSave();
                  }
                }
              }}
            >
              Save{" "}
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"red"}>
          Delete Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonsModal}>
            <div className={classes.cancelBtn} onClick={handleClose}>
              Cancel
            </div>
            <div
              className={classes.createBtn}
              onClick={() => {
                handleDelete();
              }}
            >
              Delete{" "}
            </div>
          </div>
        </DialogActions>
      </Dialog>

      <Modal open={openTags} onClose={handleCloseTags} disableAutoFocus={true}>
        <Box sx={style}>
          <div className={classes.modalWrap}>
            <div className={classes.modalTitle}>
              Welcome to the Variable Usage Guide!{" "}
            </div>
            <div className={classes.modalheader}>
              A Guide to Use Variables in Email Templates
            </div>
            <div className={classes.modalInfo}>
              This feature empowers you to personalize your emails by
              incorporating dynamic data through variables. Below, we'll guide
              you on how to use variables effectively in your email templates.
              This allows you to tailor your content for each recipient.
            </div>
            <div className={classes.modalEx}>
              <div className={classes.modalEx1}>
                <b>Embedding Variables in the Email Body</b> : To personalize
                the email body, simply include the variable within double curly
                braces, like this: Hello &#123;&#123; FirstName &#125;&#125;
              </div>
              <div className={classes.modalEx1}>
                &#x2022; Use only alphanumeric characters without spaces for
                naming the variables.
              </div>
              <div
                style={{
                  backgroundColor: "#D3D3D3",
                  height: "1px",
                  margin: "5px 0",
                }}
              ></div>
              <div className={classes.modalEx1}>
                &#123;&#123; name &#125;&#125; : This variable allows you to
                include the recipient's name.
              </div>
              <div className={classes.modalEx1}>
                &#123;&#123; email &#125;&#125; : Use this variable to
                dynamically include the recipient's email address.
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EmailTemplateEditor;

import React, { useEffect, useState } from "react";
import classes from "./CreateCamp.module.scss";
import LinkIcon from "@mui/icons-material/Link";
import TextField from "@mui/material/TextField";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Groups3Icon from "@mui/icons-material/Groups3";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import emptyTemp from "../../assets/Images/emptyTemp.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@mui/material/Autocomplete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import saveIcn from "../../assets/Images/saveIcn.svg";
import SendIcon from "@mui/icons-material/Send";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CampaignPreviewModal from "../../components/Modals/CampaignPreviewModal/CampaignPreviewModal";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import {
  EditEmailAPICall,
  GetAllTemplatesListAPICall,
  GetEachEmailAPICall,
  IGetAllGroupsResponse,
  ISingleGroup,
  createEmailApi,
  getAWSConnection,
  getAllCatList,
  getAllGroupsApi,
  getIdentitiesAPICall,
  sendPromotionalEmailAPICall,
} from "../../Utils/Apihelper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-hot-toast";
import CreateCategory from "../../components/Modals/CreateCategory/CreateCategory";
import { useDispatch, useSelector } from "react-redux";
import { setTemplatename } from "../../Store/Project/ProjectSlice";
import CreateGroup from "../../components/Modals/CreateGroup/CreateGroup";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import GetVariable from "../../components/Pages/GetVariable/GetVariable";

const options1 = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const options2 = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const options3 = ["AM", "PM"];

const CreateCamp = () => {
  const mailTemplateName = sessionStorage.getItem("mailTemplateName");
  const mailTemplateGroup = sessionStorage.getItem("mailTemplateGroup");
  const editEmail = sessionStorage.getItem("editEmail");
  const mailtype = sessionStorage.getItem("typeMail");
  const editEmailData = useSelector(
    (state: any) => state.project.editEmailData
  );
  const editEmailImage = useSelector(
    (state: any) => state.project.editEmailImage
  );
  const oldEmailName = sessionStorage.getItem("OldMailName");
  const [schedule, setSchedule] = useState(false);
  const [chooseGrp, setChooseGrp] = useState(true);
  const [preview, setPreview] = useState(false);
  const [emailName, setEmailName] = useState(
    editEmail === "true" ? editEmailData?.mailName : ""
  );
  const [sourceEmail, setSourceEmail] = useState(
    editEmail === "true" ? editEmailData?.sourceEmail : ""
  );
  const [subject, setSubject] = useState(
    editEmail === "true" ? editEmailData?.subject : ""
  );
  const [selectedGroup, setselectedGroup] = useState<string>(
    editEmail === "true" ? editEmailData?.groupName : ""
  );
  const [groups, setgroups] = useState<ISingleGroup[]>([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState(
    mailTemplateGroup
      ? mailTemplateGroup
      : editEmail === "true"
      ? editEmailData?.templateCategory
      : ""
  );
  const [template, setTemplate] = useState<any>(
    mailTemplateName
      ? { templateName: mailTemplateName }
      : editEmail === "true"
      ? {
          templateName: editEmailData?.templateName,
        }
      : {
          templateName: "",
          templateImage: "",
          templateType: "",
        }
  );
  const [description, setDescription] = useState(
    editEmail === "true" ? editEmailData?.description : ""
  );
  const [senderName, setsenderName] = useState(
    editEmail === "true" ? editEmailData?.addedBy : ""
  );
  const [templateList, setTemplateList] = useState([]);
  const [disabledSend, setdisabledSend] = useState(true);
  const [displayAWS, setDisaplayAWS] = useState(false);
  const [categoryModal, setcategoryModal] = useState(false);
  const [showCreateGroup, setshowCreateGroup] = useState(false);
  const [confirmSend, setConfirmSend] = React.useState(false);
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [sendTransactional, setSendTransactional] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [templateImage, setTemplateImage] = useState(
    editEmail === "true" ? editEmailImage?.templateImage : ""
  );
  const [selectedValue, setSelectedValue] = useState(
    editEmail === "true"
      ? editEmailData.mailType
      : mailtype
      ? mailtype
      : "promotional"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log("mailTemplateName", mailTemplateName, mailTemplateGroup);

  useEffect(() => {
    if (
      emailName === "" ||
      sourceEmail === "" ||
      description === "" ||
      categoryName === "" ||
      subject === "" ||
      selectedGroup === "" ||
      template === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    emailName,
    sourceEmail,
    categoryName,
    subject,
    selectedGroup,
    template,
    description,
  ]);

  useEffect(() => {
    getAllGroups();
    GetAWSConnection();
    handleGetAllCatList();
    GetIdentities();
  }, []);

  useEffect(() => {
    if (mailTemplateName) {
      const data: any = templateList.find(
        (a: any) => a.templateName === mailTemplateName
      );
      // console.log("data", data);
      setTemplateImage(data && data.templateImage);
    }
  }, [templateList]);

  useEffect(() => {
    if (categoryName !== "") {
      handleGetAllTemplateList();
    }
  }, [categoryName]);

  // useEffect(() => {
  //   categoryName !== "" &&
  //     setTemplate({ templateName: "", templateImage: "", templateType: "" });
  // }, [categoryName]);

  const handleCloseSend = () => {
    setConfirmSend(false);
  };

  const GetIdentities = () => {
    getIdentitiesAPICall()
      .then((response: any) => {
        if (response.data.data && response.data.data.verifiedEmails) {
          const verifiedEmail = response.data.data.verifiedEmails.filter(
            (email: any) => email.verification
          );

          // console.log("verifiedEmail",verifiedEmail)

          if (verifiedEmail) {
            setVerifiedEmails(verifiedEmail);
          }
        }
      })
      .catch((error: any) => {});
  };

  const GetAWSConnection = () => {
    getAWSConnection()
      .then((response: any) => {
        if (
          !response.data.data.aws_credentials.connection &&
          !response.data.data.aws_credentials.configuration
        ) {
          setDisaplayAWS(true);
        }
      })
      .catch((error: any) => {
        setDisaplayAWS(false);
      });
  };

  const handleGetAllCatList = () => {
    getAllCatList()
      .then((res) => {
        // console.log(res.data);
        setCategoryList(res.data.data);
      })
      .catch((res) => {
        // console.log(res.data);
      });
  };

  const handleGetAllTemplateList = () => {
    const reqBody = {
      templateCategory: categoryName,
    };
    GetAllTemplatesListAPICall(reqBody)
      .then((res: any) => {
        // console.log(res.data);
        setTemplateList(res.data.data.templates);
      })
      .catch((res) => {
        // console.log(res.data);
      });
  };

  const handleCreateEmail = () => {
    // console.log("clicked");
    const reqBody = {
      sourceEmail: sourceEmail,
      mailName: emailName,
      templateCategory: categoryName,
      templateName: template.templateName,
      subject: subject,
      description: description,
      groupName: selectedGroup,
    };
    createEmailApi(reqBody)
      .then((res: any) => {
        // console.log(res.data);
        setdisabledSend(false);
        toast.success(res.data.message);
      })
      .catch((error) => {
        setdisabledSend(true);
        // console.log("error", error);
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleEditEmail = () => {
    // console.log("clicked");
    const reqBody = {
      oldMailName: oldEmailName,
      sourceEmail: sourceEmail,
      newMailName: emailName,
      templateCategory: categoryName,
      templateName: template.templateName,
      subject: subject,
      description: description,
      groupName: selectedGroup,
    };
    EditEmailAPICall(reqBody)
      .then((res: any) => {
        // console.log(res.data);
        setdisabledSend(false);
        toast.success(res.data.message);
      })
      .catch((error) => {
        setdisabledSend(true);
        // console.log("error", error);
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handlePromotionalEmailSend = () => {
    const requestBody1 = new FormData();
    requestBody1.append("mailName", emailName);
    requestBody1.append("tags", "");

    sendPromotionalEmailAPICall(requestBody1)
      .then((res: any) => {
        // console.log(res.data);
        navigate("/email-dashboard/emails");
        toast.success(res.data.message);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const getAllGroups = () => {
    getAllGroupsApi()
      .then((response) => {
        const responseData: IGetAllGroupsResponse = response.data;
        setgroups(responseData.data);
        // console.log("getAllGroupsApi", responseData);
      })
      .catch((error: any) => {});
  };

  const handlePreviewClose = () => {
    setPreview(false);
  };

  const handleEmailName = (event: any) => {
    const newValue = event.target.value.replace(/\s/g, "_");

    const isValidInput = /^[a-zA-Z0-9_]*$/.test(newValue);
    setErrorMessage(
      isValidInput
        ? ""
        : "Please enter only alphanumeric characters and underscores."
    );

    setEmailName(newValue);
  };

  const handleSourcemailInput = (event: any) => {
    setSourceEmail(event.target.value);
  };

  const handleSubjectInput = (event: any) => {
    setSubject(event.target.value);
  };

  const handleDescriptionInput = (event: any) => {
    setDescription(event.target.value);
  };
  const handleSenderNameInput = (event: any) => {
    setsenderName(event.target.value);
  };

  const generateRandomName = () => {
    const randomNumber = Math.floor(Math.random() * 100000);
    return `UnnamedTemplate${randomNumber}`;
  };

  const handleGenerateRandomName = () => {
    const newTemplateName = generateRandomName();
    dispatch(setTemplatename(newTemplateName));
  };
  const handleClose = () => {
    setshowCreateGroup(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  const filteredTemplates = templateList.filter(
    (a: any) => a.templateType === selectedValue
  );
  // console.log("selectedValue", selectedValue);
  return (
    <>
      {sendTransactional ? (
        <GetVariable mailName={emailName} />
      ) : (
        <>
          <div className={classes.wrapper}>
            {displayAWS && (
              <div className={classes.connectionWarn}>
                <div className={classes.cwLeft}>
                  <div className={classes.cwText1}>
                    Connect your AWS account
                  </div>
                  <div className={classes.cwText2}>
                    To send campaign, you need to connect your AWS account
                    first.
                  </div>
                </div>
                <div className={classes.cwRight} onClick={()=>navigate("/email-dashboard/aws")}>
                  <LinkIcon />
                  Connect With AWS
                </div>
              </div>
            )}
            <div className={classes.nameInputWrap}>
              <div className={classes.niLeft}>
                <TextField
                  placeholder="Enter title"
                  id="outlined-size-small"
                  size="small"
                  variant="standard"
                  value={emailName}
                  onChange={handleEmailName}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,

                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    minWidth: { xs: "100%", sm: "400px" },
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
                {errorMessage && (
                  <p
                    style={{ fontSize: "12px", color: "red", padding: "10px" }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
              <div className={classes.niRight}>
                <div className={classes.nitext1}>
                  Email title are for internal use only,
                </div>
                <div className={classes.nitext2}>e.g. "New_year_saleTest5"</div>
              </div>
            </div>
            <div className={classes.importgroupWrap}>
              <div className={classes.igHeading}>Subscribers</div>
              <div className={classes.igInfo}>
                <InfoOutlinedIcon fontSize="small" /> Approximately 0 recipients
                will receive the email
              </div>
              <div className={classes.igButtons}>
                {/* <div
              className={classes.igBtn}
              style={{ backgroundColor: chooseGrp?"#E4ECF7":"#4C6FFF", color:!chooseGrp ?"white":"" }}
              onClick={() => {
                setChooseGrp(false);
              }}
            >
              All subscribers
            </div> */}
                {/* <div
              className={classes.igBtn}
              style={{
                backgroundColor: chooseGrp ? "#4C6FFF" : "#E4ECF7",
                color: chooseGrp ? "white" : "",
              }}
              onClick={() => {
                setChooseGrp(true);
              }}
            >
              Subscribers of a segment
            </div> */}
              </div>
              {chooseGrp && (
                <>
                  <div className={classes.selectgrp}>
                    <Autocomplete
                      size="small"
                      sx={{
                        marginTop: "5px",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "50%" },
                        background: "#EDF2F7",
                        outline: "none",
                        fontSize: "16px",
                        fontWeight: 500,
                        padding: "0 10px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-input": {
                          // padding: "4.5px 4px 2.5px 8px",
                          paddingTop: "2px !important",
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                      onChange={(event: any, newValue: any) => {
                        setselectedGroup(newValue);
                      }}
                      options={groups.map((a: ISingleGroup) => a.groupName)}
                      value={selectedGroup}
                      popupIcon={
                        <ArrowDropDownIcon
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                        />
                      }
                      id="combo-box-demo"
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select group"  />
                      )}
                    />
                    <div
                      className={classes.igBtnGrp}
                      style={{ backgroundColor: "#4C6FFF", marginLeft: "5px" }}
                      onClick={() => setshowCreateGroup(true)}
                    >
                      <Groups3Icon fontSize="small" />
                      Create New Group
                    </div>
                  </div>
                </>
              )}

              <div className={classes.igSelect}></div>
            </div>
            <div className={classes.InfoDataWrap}>
              <div className={classes.idHead}>Info</div>
              <div className={classes.idSubject}>
                Subject<span style={{ color: "red" }}>*</span>
                <TextField
                  placeholder="Subject"
                  id="outlined-size-small"
                  value={subject}
                  onChange={handleSubjectInput}
                  size="small"
                  variant="standard"
                  inputProps={{ maxLength: 100 }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    marginTop: "8px",
                    width: "-webkit-fill-available",
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
              <div className={classes.idSubject}>
                Email display name<span style={{ color: "red" }}>*</span>
                <TextField
                  placeholder="Email name"
                  id="outlined-size-small"
                  size="small"
                  value={description}
                  onChange={handleDescriptionInput}
                  variant="standard"
                  inputProps={{ maxLength: 100 }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    marginTop: "8px",
                    width: "-webkit-fill-available",
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
              <div className={classes.idSender}>
                <div className={classes.labels}>
                  Sender Name
                  <TextField
                    placeholder="Sender Name(Optional)"
                    id="outlined-size-small"
                    size="small"
                    variant="standard"
                    inputProps={{ maxLength: 40 }}
                    value={senderName}
                    onChange={handleSenderNameInput}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: "40px",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      },
                    }}
                    sx={{
                      marginTop: "8px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      borderRadius: "5px",
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#EDF2F7",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDF2F7",
                        },
                      },
                    }}
                  />
                </div>
                <div className={classes.labels}>
                  Sender email address
                  {/* <TextField
                placeholder="Sender email address"
                id="outlined-size-small"
                value={sourceEmail}
                onChange={handleSourcemailInput}
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              /> */}
                  <Autocomplete
                    size="small"
                    sx={{
                      marginTop: "5px",
                      borderRadius: "10px",
                      width: "100%",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                    options={
                      verifiedEmails && verifiedEmails.map((a: any) => a.email)
                    }
                    value={sourceEmail}
                    onChange={(event: any, newValue: string) => {
                      setSourceEmail(newValue);
                    }}
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Email" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={classes.templateHolderWrap}>
              <div className={classes.thHeader}>Content</div>
              <div className={classes.thSelection}>
                <div className={classes.thList}>
                  Select a category
                  <Autocomplete
                    size="small"
                    options={categoryList.map(
                      (a: any) => a.templateCategoryName
                    )}
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    value={categoryName}
                    onChange={(event, newValue) => {
                      setCategoryName(newValue);
                      setTemplate({
                        templateName: "",
                        templateImage: "",
                        templateType: "",
                      });
                      setTemplateImage("");
                      if (mailTemplateName) {
                        sessionStorage.setItem("mailTemplateName", "");
                      }
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Category" />
                    )}
                  />
                </div>
                <div className={classes.thList}>
                  Select Template
                  <Autocomplete
                    size="small"
                    // noOptionsText={"No Records Found"}
                    options={filteredTemplates.map((a: any) => a)}
                    getOptionLabel={(option) => option.templateName}
                    disabled={categoryName === ""}
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                    }}
                    PaperComponent={({ children }) => {
                      return (
                        <>
                          <div
                            style={{
                              zIndex: 10,
                              paddingLeft: "16px",
                              background: "white",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              borderRadius: "6px",
                              fontSize: "10px",
                              marginBottom: "5px",
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            <RadioGroup
                              row
                              value={selectedValue}
                              name="radio-buttons-group"
                              onChange={(event: any) =>
                                setSelectedValue(event.target.value)
                              }
                            >
                              <FormControlLabel
                                value="promotional"
                                control={<Radio />}
                                label="Promotional"
                                onClick={(event: any) =>
                                  setSelectedValue("promotional")
                                }
                              />
                              <FormControlLabel
                                value="transactional"
                                control={<Radio />}
                                label="Transactional"
                                onClick={(event: any) =>
                                  setSelectedValue("transactional")
                                }
                              />
                            </RadioGroup>
                          </div>
                          <Paper>{children}</Paper>
                        </>
                      );
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    value={template}
                    onChange={(event, newValue) => {
                      setTemplate(newValue);
                      setTemplateImage(newValue.templateImage);
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Template" />
                    )}
                  />
                </div>
              </div>
              <div className={classes.thContain}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow:
                      templateImage === ""
                        ? ""
                        : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                >
                  <img
                    src={
                      templateImage === ""
                        ? emptyTemp
                        : `data:image/png;base64,${templateImage}`
                    }
                    alt=""
                    className={classes.emptyTemp}
                    style={{
                      marginTop: templateImage === "" ? "2rem" : "",
                    }}
                  />
                </div>
                <div
                  className={classes.thBtn}
                  onClick={() => {
                    confirmAlert({
                      title: "",
                      message:
                        "Your existing data will not be saved.Are you sure you want create a new template?",
                      buttons: [
                        {
                          label: "No",
                          onClick: () => "",
                        },
                        {
                          label: "Yes",
                          onClick: () => {
                            setcategoryModal(true);
                            sessionStorage.setItem("editFlag", "false");
                            handleGenerateRandomName();
                          },
                        },
                      ],
                    });
                  }}
                >
                  <EditNoteOutlinedIcon fontSize="small" />
                  Create new template
                </div>
              </div>
            </div>
            {/* <div className={classes.scheduleWrapper}>
          <div className={classes.swHeader}>Delivery</div>
          <div className={classes.swButtons}>
            <div
              className={classes.swBtn}
              onClick={() => setSchedule(false)}
              style={{
                backgroundColor: schedule ? "#E4ECF7" : "#4C6FFF",
                color: schedule ? "" : "white",
              }}
            >
              Send Now
            </div>
            <div
              className={classes.swBtn}
              onClick={() => setSchedule(true)}
              style={{
                backgroundColor: schedule ? "#4C6FFF" : "#E4ECF7",
                color: schedule ? "white" : "",
              }}
            >
              Schedule
            </div>
          </div>

          {schedule && (
            <>
              <div className={classes.swSchedular}>
                <div className={classes.swDatePicker}>
                  Delivery date
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        components={{
                          OpenPickerIcon: KeyboardArrowDownIcon, // Use your custom icon component here
                        }}
                        sx={{
                          marginTop: "8px",
                          width: "-webkit-fill-available",
                          backgroundColor: "#E3ECF7",
                          outline: "none",
                        }}
                        slotProps={{
                          textField: {
                            placeholder: "Select Delivery date",
                            size: "small",
                            sx: {
                              background: "#EDF2F7",
                              width: "100%",
                              borderRadius: "10px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: 0,
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: 0,
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                            },
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className={classes.swTimePicker}>
                  Delivery Time
                  <div className={classes.swTimeComp}>
                    <Autocomplete
                      size="small"
                      sx={{
                        marginTop: "15px",
                        borderRadius: "10px",
                        width: "-webkit-fill-available",
                        background: "#EDF2F7",
                        outline: "none",
                        fontSize: "16px",
                        fontWeight: 500,
                        padding: "0 10px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-input": {
                          // padding: "4.5px 4px 2.5px 8px",
                          paddingTop: "2px !important",
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                      options={options1}
                      popupIcon={
                        <UnfoldMoreIcon
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                        />
                      }
                      id="disable-clearable"
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} placeholder="11" />
                      )}
                    />
                    <div style={{ marginTop: "15px" }}>:</div>
                    <Autocomplete
                      size="small"
                      options={options2}
                      sx={{
                        marginTop: "15px",
                        borderRadius: "10px",
                        width: "-webkit-fill-available",
                        background: "#EDF2F7",
                        outline: "none",
                        fontSize: "16px",
                        fontWeight: 500,
                        padding: "0 10px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-input": {
                          // padding: "4.5px 4px 2.5px 8px",
                          paddingTop: "2px !important",
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                      popupIcon={
                        <UnfoldMoreIcon
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                        />
                      }
                      id="disable-clearable"
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} placeholder="00" />
                      )}
                    />
                    <div style={{ marginTop: "15px" }}>:</div>
                    <Autocomplete
                      size="small"
                      options={options3}
                      sx={{
                        marginTop: "15px",
                        borderRadius: "10px",
                        width: "-webkit-fill-available",
                        background: "#EDF2F7",
                        outline: "none",
                        fontSize: "16px",
                        fontWeight: 500,
                        padding: "0 10px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-input": {
                          // padding: "4.5px 4px 2.5px 8px",
                          paddingTop: "2px !important",
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                      id="disable-clearable"
                      popupIcon={
                        <UnfoldMoreIcon
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                        />
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} placeholder="AM" />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.swRegionPicker}>
                Timezone
                <Autocomplete
                  size="small"
                  options={options3}
                  sx={{
                    marginTop: "15px",
                    borderRadius: "10px",
                    width:{xs:"100%", sm:"35%"},
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="disable-clearable"
                  popupIcon={
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Timezone" />
                  )}
                />
              </div>
            </>
          )}
        </div> */}
            {disabled && (
              <div style={{ fontSize: "12px", color: "red", padding: "10px" }}>
                Please enter all the required fields*
              </div>
            )}
            <div className={classes.footerTools}>
              <div className={classes.ftLeft}>
                {/* <img src={saveIcn} alt="" className={classes.ftlImg} />{" "}
                <div
                  className={classes.ftlText}
                  style={{ color: disabled ? "grey" : "" }}
                 
                >
                  Save Changes{" "}
                </div> */}
                <Button
                  variant="contained"
                  startIcon={<SaveOutlinedIcon />}
                  onClick={() => {
                    disabled === false &&
                      (editEmail === "true"
                        ? handleEditEmail()
                        : handleCreateEmail());
                  }}
                  disabled={disabled}
                  sx={{
                    background: "#E4ECF7",
                    borderRadius: "100px",
                    textTransform: "none",
                    padding: "6px 40px",
                    boxShadow: "none",
                    color: "#505780",
                    "&:hover": { background: "#E4ECF7" },
                  }}
                >
                  Save changes
                </Button>
              </div>
              <div className={classes.ftRight}>
                <Button
                  variant="contained"
                  startIcon={<MailOutlineIcon />}
                  onClick={() => {
                    setPreview(true);
                  }}
                  sx={{
                    background: "#E4ECF7",
                    borderRadius: "100px",
                    textTransform: "none",
                    padding: "6px 40px",
                    boxShadow: "none",
                    color: "#505780",
                    "&:hover": { background: "#E4ECF7" },
                  }}
                >
                  Preview
                </Button>
                <Button
                  variant="contained"
                  startIcon={<RocketLaunchIcon fontSize="small" />}
                  sx={{
                    background: "#4C6FFF",
                    borderRadius: "100px",
                    textTransform: "none",
                    padding: "6px 40px",
                    boxShadow: "none",

                    "&:hover": { background: "#4C6FFF" },
                  }}
                  disabled={disabledSend}
                  onClick={() => {
                    selectedValue === "transactional"
                      ? setSendTransactional(true)
                      : setConfirmSend(true);
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {preview && (
        <CampaignPreviewModal
          open={preview}
          handleClose={() => {
            handlePreviewClose();
          }}
          campaignName={emailName}
          groups={selectedGroup}
          subject={subject}
          purpose={description}
          senderName={senderName}
          senderEmail={sourceEmail}
          template={template}
          templateimage={templateImage !== "" ? templateImage : ""}
        />
      )}
      {categoryModal && (
        <CreateCategory
          open={categoryModal}
          handleClose={() => {
            setcategoryModal(false);
          }}
        />
      )}
      {showCreateGroup && (
        <CreateGroup
          open={showCreateGroup}
          handleClose={() => {
            handleClose();
          }}
          tab={2}
        />
      )}
      <Dialog
        open={confirmSend}
        onClose={handleCloseSend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"primary"}>
          Send Email
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you confirm to send this Email?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSend}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              handlePromotionalEmailSend();
            }}
          >
            Send
          </Button>
          {/* <div className={classes.buttons}>
            <div className={classes.cancelBtn} onClick={handleCloseSend}>
              Cancel
            </div>
            <div
              className={classes.createBtn}
              onClick={() => {
                handlePromotionalEmailSend();
              }}
            >
              Send{" "}
            </div>
          </div> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateCamp;

import React, { useEffect, useRef, useState } from "react";
import styles from "./AWSConfiguration.module.scss";
import awsimage from "../../../assets/Images/awsimage.svg";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import stepperimage from "../../../assets/Images/Process bar Meeting - Click.svg";
import stepperimagecompleted from "../../../assets/Images/stepperImageCompleted.svg";
import animivideo from "../../../assets/Images/awsanimationvideo.mp4";
import {
  AddEmailIdentityAPICall,
  ChangeAWSConnectionAPICall,
  CheckDomainStatusAPICall,
  DeleteDomainIdentityAPICall,
  DeleteEmailIdentityAPICall,
  GetDomainRecordsAPICall,
  IAddDomainIdentity,
  ICheckDomainStatus,
  IDeleteEmailIdentity,
  IInitaliseAWSConnection,
  addDomainIdentityAPICall,
  getAWSConnection,
  getAWSreport,
  getDeleteAWSNotifersAPICall,
  getIdentitiesAPICall,
  getSetupAWSNotifers,
  initaliseAWSConnection,
} from "../../../Utils/Apihelper";
import { Formik } from "formik";
import { checkAWSConnection } from "../../../Schemas/Schemas";
import LinkIcon from "@mui/icons-material/Link";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DomainRecordsModal from "./DomainRecordsModal";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import { confirmAlert } from "react-confirm-alert";
import RefreshIcon from "@mui/icons-material/Refresh";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      transition: "all 0.5s ease",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#4c6fff" : "#5FC322",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#4c6fff",
    boxSizing: "border-box",
  },
}));

export default function AWSConfiguration() {
  const contentRef = useRef<any>(null);
  const [loading, setloading] = useState(false);
  const [checked, setchecked] = useState(false);
  const [disabledText, setdisabledText] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState<any>({
    client_id: "",
    client_secret: "",
    region: "",
    configuration: false,
    connection: false,
  });
  const [domainName, setDomainName] = useState("");
  const [animationLoading, setAnimationlaoding] = useState(false);
  const [prefillLoading, setPrefillLoadingTrue] = useState(true);
  const [resetLoading, setResetLoading] = useState(false);
  const [connectawsLoading, setconnectawsLoading] = useState(false);
  const [domainaddloading, setdomainaddloading] = useState(false);
  const [openDomainRecordsModal, setopenDomainRecordsModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [openEmailDeleteModal, setopenEmailDeleteModal] = useState(false);
  const [domainVerificatesStatus, setDomainVerificationStatus] =
    useState(false);
  const [newMailAddress, setNewMailAddress] = useState("");
  const [domainrecordsList, setDomainRecordsList] = useState([]);
  const [verifiedEmailsList, setVerifiedEmailsList] = useState([]);
  const [enableDeleteDomain, setEnableDeleteDomain] = useState(false);
  const [domaineditloading, setdomainloading] = useState(false);
  const [changeConnection, setChangeConnection] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [report, setReport] = useState<any>({});
  const [deleteemailaddress, setdeleteemailaddress] = useState("");
  const [verificationMessage, setverificationMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setNewMailAddress("");
  };

  useEffect(() => {
    GetAWSConnection();
    GetIdentities();
    handleGetAWSReport();
 
  }, []);

  useEffect(() => {
    if (domainName !== "" && domainName.length>4) {
      handleCheckStatus();
 
    }
  }, [domainName]);

  const handleDeleteEmailIdentity = (emailAddress: any) => {
    setdeleteemailaddress(emailAddress);
    setopenEmailDeleteModal(true);
  };

  const handleDeleteDomain = () => {
    if (verifiedEmailsList.length > 0) {
      confirmAlert({
        title: "",
        message:
          "Please Delete All the Verified Emails and then Delete the Domain",
        buttons: [
          {
            label: "Ok",
            onClick: () => "",
          },
        ],
      });
    } else {
      setopenDeleteModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
  };

  const handleCloseEmailDeleteModal = () => {
    setopenEmailDeleteModal(false);
  };

  const handleSubmitEmailDeleteModal = () => {
    let obj: IDeleteEmailIdentity = {
      email: deleteemailaddress,
    };
    setopenEmailDeleteModal(false);
    DeleteEmailIdentityAPICall(obj)
      .then((response: any) => {
        GetIdentities();
        toast.success("Successfully Deleted");
        setdeleteemailaddress("");
      })
      .catch((error: any) => {
        // console.log("error", error);
        toast.error("Error Deleting");
        setdeleteemailaddress("");
      });
  };

  const handleSubmitDeleteModal = () => {
    let obj: IAddDomainIdentity = {
      domain: domainName,
    };
    setopenDeleteModal(false);
    setdomainloading(true);
    DeleteDomainIdentityAPICall(obj)
      .then((response: any) => {
        // console.log("response", response);
        setDomainName("");
        setdomainloading(false);
        if (response.data?.message) {
          toast.success(response.data.message);
        }

        GetIdentities();
      })
      .catch((error: any) => {
        toast.error("Eror Occurred");
        setdomainloading(false);
        setopenDeleteModal(false);
      });
  };

  const handleAddEmailIdentity = () => {
    let obj: IDeleteEmailIdentity = {
      email: newMailAddress,
    };
    AddEmailIdentityAPICall(obj)
      .then((response: any) => {
        // console.log("addResponse", response);
        handleClose();
        GetIdentities();
   
        toast.success("Successfully Added");
      })
      .catch((error: any) => {
        // console.log("error", error);
        toast.error(
          error.response.data?.error?.message
            ? error.response.data?.error?.message
            : "Error Adding Email"
        );
      });
  };

  const handleCheckStatus = () => {
    let obj: ICheckDomainStatus = {
      identity: domainName,
    };

    CheckDomainStatusAPICall(obj)
      .then((response: any) => {
        if (response.data?.data?.verificationStatus === false) {
          
          setTimeout(() => {
            handleCheckStatus();
          }, 3000);
        } else {
          GetIdentities();
        }
        // console.log("response", response);
        if (response.data?.message && response.data.data.verificationStatus) {
          setverificationMessage(response.data?.message);
          setTimeout(() => {
            setverificationMessage("");
          }, 5000);
        } else if (
          response.data.message &&
          response.data.data.verificationStatus === false
        ) {
          setverificationMessage("Domain Verification is under progress");
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        // toast.error("Error Occured");
      });
  };
  const handleCheckEmailStatus = (emailid:any) => {
    let obj: ICheckDomainStatus = {
      identity: emailid,
    };

    CheckDomainStatusAPICall(obj)
      .then((response: any) => {
        if (response.data?.data?.verificationStatus === false) {
          
          setTimeout(() => {
            handleCheckStatus();
          }, 3000);
        } else {
          GetIdentitiesNew(emailid);
        }
        // console.log("response", response);
        if (response.data?.message && response.data.data.verificationStatus) {
          setverificationMessage(response.data?.message);
          setTimeout(() => {
            setverificationMessage("");
          }, 5000);
        } else if (
          response.data.message &&
          response.data.data.verificationStatus === false
        ) {
          setverificationMessage("Domain Verification is under progress");
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        // toast.error("Error Occured");
      });
  };
  const GetDomainARecords = () => {
    GetDomainRecordsAPICall()
      .then((response: any) => {
        // console.log("domainrecords", response.data.data);
        if (response.data.data.domainRecords) {
          setDomainRecordsList(response.data.data.domainRecords);
        }
      })
      .catch((error: any) => {});
  };

  const GetIdentities = () => {
    getIdentitiesAPICall()
      .then((response: any) => {
        // console.log("identities", response.data.data.verifiedDomains[0].domain);
        if (response.data.data.verifiedDomains[0]) {
          setEnableDeleteDomain(true);
          setDomainName(response.data.data.verifiedDomains[0].domain);
          setDomainVerificationStatus(
            response.data.data.verifiedDomains[0].verification
          );

          setCheckStatus(true);
        } else {
          setDomainVerificationStatus(false);
          setEnableDeleteDomain(false);
          setCheckStatus(false);
        }
        if (response.data.data.verifiedEmails) {
          setVerifiedEmailsList(response.data.data.verifiedEmails);
        }
      })
      .catch((error: any) => {
        // console.log("erroriden", error);
        setDomainVerificationStatus(false);
        setEnableDeleteDomain(false);
        setCheckStatus(false);
      });
  };
  const GetIdentitiesNew = (emaildata: any) => {
    getIdentitiesAPICall()
      .then((response: any) => {
        // console.log("identities", response.data.data.verifiedDomains[0].domain);
        if (response.data.data.verifiedDomains[0]) {
          setEnableDeleteDomain(true);
          setDomainName(response.data.data.verifiedDomains[0].domain);
          setDomainVerificationStatus(
            response.data.data.verifiedDomains[0].verification
          );

          setCheckStatus(true);
        } else {
          setDomainVerificationStatus(false);
          setEnableDeleteDomain(false);
          setCheckStatus(false);
        }
        if (response.data.data.verifiedEmails) {
          setVerifiedEmailsList(response.data.data.verifiedEmails);

          const verData: any = response.data.data.verifiedEmails;
          const filteredData = verData.filter(
            (a: any) => emaildata === a.email
          );

          if (filteredData[0].verification === false) {
            toast.success("Email Verification is under progress");
          }
        }
      })
      .catch((error: any) => {
        // console.log("erroriden", error);
        setDomainVerificationStatus(false);
        setEnableDeleteDomain(false);
        setCheckStatus(false);
      });
  };

  const handleAddDomainIdentites = () => {
    let obj: IAddDomainIdentity = {
      domain: domainName,
    };
    setdomainaddloading(true);
    addDomainIdentityAPICall(obj)
      .then((response: any) => {
        // console.log("responsedomain", response);
        if (response.data.data.domainRecords) {
          setDomainRecordsList(response.data.data.domainRecords);
          setopenDomainRecordsModal(true);
        } else {
          setDomainRecordsList([]);
          setopenDomainRecordsModal(false);
        }
        GetIdentities();
        handleCheckStatus();
        setdomainaddloading(false);
      })
      .catch((error: any) => {
        // console.log("errordomain", error.status);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
        setdomainaddloading(false);
        if (error.response.data.error?.message) {
          toast.error(
            error.response.data.error?.message
              ? error.response.data.error.message
              : "Error Occured"
          );
        }
      });
  };
  const handleViewDomainRecords = () => {
    GetDomainARecords();
    setopenDomainRecordsModal(true);
  };

  const handleCloseRecordsModal = () => {
    setopenDomainRecordsModal(false);
  };

  const GetAWSConnection = () => {
    setPrefillLoadingTrue(false);
    getAWSConnection()
      .then((response: any) => {
        // console.log("response", response.data.data.aws_credentials);
        if (response.data.data.aws_credentials.connection) {
          setConnectionDetails({
            client_id: response.data.data.aws_credentials["client-id"],
            client_secret: response.data.data.aws_credentials.clientSecret,
            region: response.data.data.aws_credentials.region,
            configuration: response.data.data.aws_credentials.configuration,
            connection: response.data.data.aws_credentials.connection,
          });
          if (response.data.data.aws_credentials.configuration) {
            setchecked(true);
          }

          setdisabledText(true);
        } else {
          setConnectionDetails({
            client_id: "",
            client_secret: "",
            region: "",
            configuration: false,
            connection: false,
          });
          setchecked(false);
          setdisabledText(false);
        }
        setPrefillLoadingTrue(true);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setchecked(false);
        setdisabledText(false);
        setPrefillLoadingTrue(true);
      });
  };

  const handleSetupAWS = () => {
    setconnectawsLoading(true);
    getSetupAWSNotifers()
      .then((response: any) => {
        // console.log("responseawz", response);
        setAnimationlaoding(true);
        setTimeout(() => {
          setconnectawsLoading(false);
          toast.success(response.data.message);
          GetAWSConnection();
        }, 5500);
        // GetAWSConnection();
      })
      .catch((error: any) => {
        // console.log("erroraws", error);
        setconnectawsLoading(false);
        setAnimationlaoding(false);
        toast.error(
          error?.response?.data?.message
            ? error.response.data.message
            : "Error Occured"
        );
      });
  };

  const handleResetAWS = () => {
    if (domainVerificatesStatus) {
      confirmAlert({
        title: "",
        message: "Please Delete the Domain and then Rest the Settings",
        buttons: [
          {
            label: "Ok",
            onClick: () => "",
          },
        ],
      });
    } else {
      confirmAlert({
        title: "",
        message: "Are you sure you want to Reset?",
        buttons: [
          {
            label: "No",
            onClick: () => "",
          },
          {
            label: "Yes",
            onClick: () => resetAWSSettings(),
          },
        ],
      });
    }
  };

  const resetAWSSettings = () => {
    setResetLoading(true);
    getDeleteAWSNotifersAPICall()
      .then((response: any) => {
        contentRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });

        GetAWSConnection();
        setResetLoading(false);
        setAnimationlaoding(false);

        toast.success("Successfully Reseted");
      })
      .catch((error: any) => {
        setResetLoading(false);
        toast.error("Error Occured");
      });
  };

  const InitaliseAWSConnection = (values: any) => {
    let obj: IInitaliseAWSConnection = {
      client_id: values.client_id,
      client_secret: values.client_secret,
      region: values.region,
    };

    if (!changeConnection) {
      setloading(true);
      initaliseAWSConnection(obj)
        .then((response: any) => {
          setloading(false);
          // console.log("awsREsponse", response.data.message);
          GetAWSConnection();
          toast.success(response.data.message);
        })
        .catch((error: any) => {
          setloading(false);

          toast.error(
            error.response?.data?.message
              ? error.response.data.message
              : "Error Occured"
          );
        });
    } else {
      ChangeAWSConnection(values);
    }
  };
  const ChangeAWSConnection = (values: any) => {
    let obj: IInitaliseAWSConnection = {
      client_id: values.client_id,
      client_secret: values.client_secret,
      region: values.region,
    };
    setloading(true);
    ChangeAWSConnectionAPICall(obj)
      .then((response: any) => {
        setloading(false);
        // console.log("awsREsponse", response.data.message);
        setChangeConnection(false);
        GetAWSConnection();
        if (response.data.status === "failed") {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((error: any) => {
        setloading(false);

        setChangeConnection(false);
        // console.log("error", error);
        toast.error(
          error?.response?.data?.message
            ? error.response.data.message
            : "Error Occured"
        );
      });
  };

  const handleEditdetails = () => {
    if (connectionDetails.configuration) {
      window.alert("Please Reset to Default to Edit");
    } else {
      setdisabledText(false);
      setChangeConnection(true);
    }
  };

  const handleGetAWSReport = () => {
    getAWSreport()
      .then((response: any) => {
        setReport(response.data.data);
      })
      .catch((error: any) => {
        // console.log("error", error);
        // toast.error(error.response?.data?.error?.message ?error.response.data.error.message :"Error");
      });
  };

  const InitialValues = {
    client_id: connectionDetails.client_id,
    client_secret: connectionDetails.client_secret,
    region: connectionDetails.region,
  };

  return (
    <div>
      <Toaster />
      {resetLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <CircularProgress color="inherit" />
            </div>
            <div>
              <Typography>Reseting...</Typography>
            </div>
          </div>
        </Backdrop>
      )}
      <div className={styles.mainWapper} ref={contentRef}>
        <div
          className={styles.topContainer}
          style={{ background: checked ? "#D4FBBC" : "" }}
        >
          <div className={styles.leftc}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              {checked ? (
                <p className={styles.leftheader}> AWS account Connected</p>
              ) : (
                <p className={styles.leftheader}>Connect your AWS account</p>
              )}
              <div>
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={checked}
                />
              </div>
            </div>
            {checked ? (
              <p className={styles.leftDesc}>
                You can send your Campaigns now.
              </p>
            ) : (
              <p className={styles.leftDesc}>
                To send campaign, you need to connect your AWS account first.
              </p>
            )}
          </div>
        </div>
        <div className={styles.connectionSection}>
          <div className={styles.stepperSection}>
            {animationLoading ? (
              <video autoPlay={true} muted playsInline className={styles.video}>
                <source
                  src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/awsanimationvideo.mp4"
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={
                  connectionDetails.configuration
                    ? stepperimagecompleted
                    : stepperimage
                }
                alt=""
                className={styles.stepperImage}
              />
            )}
          </div>
          {prefillLoading ? (
            <Formik
              validationSchema={checkAWSConnection}
              initialValues={InitialValues}
              onSubmit={(values: any) => {
                // console.log("values", values);

                if (!disabledText) {
                  InitaliseAWSConnection(values);
                } else {
                  ChangeAWSConnection(values);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,

                setFieldValue,
              }) => {
                // console.log("errors", errors)
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className={styles.enableConnectionSection}>
                      <div className={styles.enableConnectionSectionTextFeild}>
                        <div className={styles.group}>
                          <p className={styles.lableText}>Access Key</p>
                          <TextField
                            fullWidth
                            variant="standard"
                            size="small"
                            name="client_id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter Client Id"
                            value={values.client_id}
                            disabled={disabledText}
                            error={
                              touched.client_id && Boolean(errors.client_id)
                            }
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                height: "40px",
                                padding: "0px 10px",
                                borderRadius: "5px",
                              },
                            }}
                            sx={{
                              background: "#EDF2F7",
                              borderRadius: "5px",
                              "& label.Mui-focused": {
                                color: "grey",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#EDF2F7",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#EDF2F7",
                                },
                                "&.Mui-disabled": {
                                  color: "red",
                                },
                              },
                              // "& .MuiInputBase-input.Mui-disabled": {
                              //   WebkitTextFillColor: "grey",
                              // },
                            }}
                          />
                          <FormHelperText>
                            <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                              {touched.client_id &&
                                errors.client_id?.toString()}
                            </p>
                          </FormHelperText>
                        </div>
                        <div className={styles.group}>
                          <p className={styles.lableText}>Secret Access Key</p>
                          <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Enter Client Secret"
                            name="client_secret"
                            size="small"
                            disabled={disabledText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.client_secret}
                            error={
                              touched.client_secret &&
                              Boolean(errors.client_secret)
                            }
                            // helperText={
                            //   touched.client_secret &&
                            //   errors.client_secret?.toString()
                            // }
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                height: "40px",
                                padding: "0px 10px",
                                borderRadius: "5px",
                              },
                            }}
                            sx={{
                              background: "#EDF2F7",
                              borderRadius: "5px",
                              "& label.Mui-focused": {
                                color: "grey",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#EDF2F7",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor: "#EDF2F7",
                                },
                              },
                            }}
                          />
                          <FormHelperText>
                            <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                              {touched.client_secret &&
                                errors.client_secret?.toString()}
                            </p>
                          </FormHelperText>
                        </div>
                      </div>
                      <div className={styles.group}>
                        <p className={styles.lableText}>SES Region</p>
                        <TextField
                          fullWidth
                          variant="standard"
                          size="small"
                          name="region"
                          placeholder="SES Region"
                          onChange={handleChange}
                          disabled={disabledText}
                          onBlur={handleBlur}
                          value={values.region}
                          error={touched.region && Boolean(errors.region)}
                          // helperText={touched.region && errors.region?.toString()}
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              height: "40px",
                              padding: "0px 10px",
                              borderRadius: "5px",
                            },
                          }}
                          sx={{
                            background: "#EDF2F7",
                            borderRadius: "5px",
                            "& label.Mui-focused": {
                              color: "grey",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#EDF2F7",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#EDF2F7",
                              },
                            },
                          }}
                        />
                        <FormHelperText>
                          <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                            {touched.region && errors.region?.toString()}
                          </p>
                        </FormHelperText>
                      </div>
                      <div style={{ fontSize: "14px", color: "black" }}>
                        <span style={{ fontWeight: "600" }}>Note</span>: Make
                        sure you have production level grant access for SES,SNS
                        and S3 from AWS to establish connection with Notifiers.
                      </div>
                      <div className={styles.butttonSection}>
                        <div>
                          {connectionDetails.connection &&
                            !connectionDetails.configuration && (
                              <Button
                                variant="contained"
                                startIcon={<EditIcon />}
                                sx={{
                                  background: "#4C6FFF",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                  padding: "8px 20px",
                                  boxShadow: "none",

                                  "&:hover": {
                                    background: "#4C6FFF",
                                  },
                                }}
                                onClick={handleEditdetails}
                              >
                                Edit Details
                              </Button>
                            )}
                        </div>
                        <div>
                          {values.client_id &&
                          values.client_secret &&
                          values.region &&
                          disabledText === false ? (
                            <Button
                              endIcon={
                                loading ? (
                                  <CircularProgress
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "white",
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                              type="submit"
                              variant="contained"
                              sx={{
                                background: "#4C6FFF",
                                borderRadius: "5px",
                                textTransform: "none",
                                padding: "8px 40px",
                                boxShadow: "none",

                                "&:hover": { background: "#4C6FFF" },
                              }}
                            >
                              {!changeConnection
                                ? "Check connection"
                                : "Change Connection"}
                            </Button>
                          ) : (
                            <>
                              {!connectionDetails.configuration && (
                                <Button
                                  variant="contained"
                                  disableElevation
                                  disableRipple
                                  sx={{
                                    background: "#4C6FFF",
                                    opacity: 0.3,
                                    borderRadius: "5px",
                                    textTransform: "none",
                                    padding: "8px 40px",
                                    boxShadow: "none",

                                    "&:hover": { background: "#4C6FFF" },
                                  }}
                                >
                                  Check connection
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                        <div>
                          {connectionDetails.connection &&
                            !connectionDetails.configuration && (
                              <Button
                                variant="contained"
                                endIcon={
                                  connectawsLoading ? (
                                    <CircularProgress
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )
                                }
                                startIcon={
                                  <LinkIcon
                                    sx={{ transform: "rotate(145deg)" }}
                                  />
                                }
                                sx={{
                                  background: checked ? "#5FC322" : "#F57920",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                  padding: "8px 20px",
                                  boxShadow: "none",

                                  "&:hover": {
                                    background: checked ? "#5FC322" : "#F57920",
                                  },
                                }}
                                onClick={handleSetupAWS}
                              >
                                Connect With AWS
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          ) : (
            <div className={styles.enableConnectionSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles.group}>
                  <p className={styles.lableText}>Client Id</p>
                  <TextField
                    fullWidth
                    variant="standard"
                    size="small"
                    name="client_id"
                    placeholder="Enter Client Id"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: "40px",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      },
                    }}
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: "5px",
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#EDF2F7",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDF2F7",
                        },
                      },
                    }}
                  />
                </div>
                <div className={styles.group}>
                  <p className={styles.lableText}>Client Secret</p>
                  <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Enter Client Secret"
                    name="client_secret"
                    size="small"
                    // helperText={
                    //   touched.client_secret &&
                    //   errors.client_secret?.toString()
                    // }
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: "40px",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      },
                    }}
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: "5px",
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#EDF2F7",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDF2F7",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className={styles.group}>
                <p className={styles.lableText}>SES Region</p>
                <TextField
                  fullWidth
                  variant="standard"
                  size="small"
                  name="region"
                  placeholder="SES Region"
                  // helperText={touched.region && errors.region?.toString()}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
              <div className={styles.butttonSection}>
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  sx={{
                    background: "#4C6FFF",
                    opacity: 0.3,
                    borderRadius: "5px",
                    textTransform: "none",
                    padding: "8px 40px",
                    boxShadow: "none",

                    "&:hover": { background: "#4C6FFF" },
                  }}
                >
                  Check connection
                </Button>
              </div>
            </div>
          )}

          <div className={styles.verifySection}>
            <div className={styles.verifySectionLeft}>
              <div className={styles.headSection}>
                <p className={styles.verifyText}>
                  {" "}
                  {domainVerificatesStatus
                    ? "Domain Verified"
                    : "Verify domain"}{" "}
                </p>
                <p className={styles.verifyTextDesc}>
                  {domainVerificatesStatus
                    ? "Domain Verification Process is Completed"
                    : "Complete the domain verification process to secure and authenticate your online presence."}
                </p>
              </div>
              <div className={styles.textfeildwrapper}>
                <div className={styles.group}>
                  <p className={styles.lableText}>Domain Name</p>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={domainName}
                    disabled={domainVerificatesStatus || enableDeleteDomain}
                    onChange={(event) => {
                      setDomainName(event.target.value);
                    }}
                    placeholder="Enter Domain Name"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: "40px",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {enableDeleteDomain && (
                            <Tooltip title="Change Domain">
                              <IconButton onClick={handleDeleteDomain}>
                                {domaineditloading ? (
                                  <CircularProgress
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                ) : (
                                  <DeleteOutlineRoundedIcon
                                    sx={{ color: "red" }}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: "5px",
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#EDF2F7",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDF2F7",
                        },
                      },
                    }}
                  />
                </div>
                {/* <div className={styles.group}>
                  <p className={styles.lableText}>Access key</p>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Access key"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: "40px",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      },
                    }}
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: "5px",
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#EDF2F7",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDF2F7",
                        },
                      },
                    }}
                  />
                </div> */}
              </div>
              <div className={styles.butttonSectionDomain}>
                {!domainVerificatesStatus && !enableDeleteDomain && (
                  <div
                    className={styles.butttonSection}
                    style={{ gap: "0px" }}
                  >
                    {!enableDeleteDomain && (
                      <div>
                        <Button
                          variant="contained"
                          endIcon={
                            domainaddloading ? (
                              <CircularProgress
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  color: "white",
                                }}
                              />
                            ) : (
                              ""
                            )
                          }
                          disabled={domainName === ""}
                          sx={{
                            background: "#4C6FFF",
                            borderRadius: "5px",
                            textTransform: "none",
                            padding: "8px 40px",
                            boxShadow: "none",
                            "&:hover": { background: "#4C6FFF" },
                            "&:disabled": {
                              background: "#4C6FFF",
                              opacity: 0.3,
                              color: "white",
                            },
                          }}
                          onClick={handleAddDomainIdentites}
                        >
                          Verify
                        </Button>
                      </div>
                    )}
                    {/* {!domainVerificatesStatus && checkStatus && (
                      <div>
                        <Button
                          variant="contained"
                          sx={{
                            background: "#4C6FFF",
                            borderRadius: "5px",
                            textTransform: "none",
                            padding: "8px 40px",
                            boxShadow: "none",
                            "&:hover": { background: "#4C6FFF" },
                            "&:disabled": {
                              background: "#4C6FFF",
                              opacity: 0.3,
                              color: "white",
                            },
                          }}
                          onClick={handleCheckStatus}
                        >
                          Check Status
                        </Button>
                      </div>
                    )} */}
                  </div>
                )}

                {checkStatus && (
                  <div className={styles.butttonSection}>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#4C6FFF",
                        borderRadius: "5px",
                        textTransform: "none",
                        padding: "8px 40px",
                        boxShadow: "none",
                        "&:hover": { background: "#4C6FFF" },
                        "&:disabled": {
                          background: "#4C6FFF",
                          opacity: 0.3,
                          color: "white",
                        },
                      }}
                      onClick={handleViewDomainRecords}
                    >
                      View Domian Records
                    </Button>
                  </div>
                )}
              </div>
              <p style={{ fontSize: "12px", marginTop: "10px" }}>
                {verificationMessage}
              </p>
            </div>

            <div className={styles.verifySectionRight}>
              <div className={styles.headSection}>
                <p className={styles.verifyText}>Verified mails</p>
                {domainVerificatesStatus && (
                  <div>
                    <Tooltip title="Add Emails">
                      <IconButton onClick={handleClick}>
                        <AddCircleRounded
                          sx={{ color: "#4C6FFF", cursor: "pointer" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 0,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div className={styles.addMailSection}>
                    <div className={styles.groupnew}>
                      <p className={styles.lableText}>Email Address</p>
                      <TextField
                        fullWidth
                        size="small"
                        variant="standard"
                        value={newMailAddress}
                        onChange={(event) => {
                          setNewMailAddress(event.target.value);
                        }}
                        onKeyDown={(e: any) => {
                          e.stopPropagation();
                        }}
                        placeholder="Enter Email Address"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            height: "40px",
                            padding: "0px 10px",
                            borderRadius: "5px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              {newMailAddress !== "" && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    background: "#4C6FFF",
                                    borderRadius: "5px",
                                    textTransform: "none",

                                    boxShadow: "none",
                                    "&:hover": { background: "#4C6FFF" },
                                  }}
                                  onClick={handleAddEmailIdentity}
                                >
                                  Add
                                </Button>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          background: "#EDF2F7",
                          borderRadius: "5px",
                          "& label.Mui-focused": {
                            color: "grey",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "#EDF2F7",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#EDF2F7",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </Menu>
              </div>
              <div className={styles.emailssection}>
                {verifiedEmailsList.length > 0 &&
                  verifiedEmailsList.map((a: any) => (
                    <div className={styles.userDetailsSection}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className={styles.userProfile}>
                          {a.email.slice(0, 2).toUpperCase()}
                        </div>
                        <div className={styles.userDetailsSectionContent}>
                          {/* <p className={styles.userName}>Ada Wong</p> */}
                          <p className={styles.useremail}>{a.email}</p>
                        </div>
                      </div>
                      <div>
                        {a.verification && (
                          <Tooltip title="Email Verified">
                            <IconButton
                              sx={{
                                color: "black",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}
                            >
                              <DoneOutlinedIcon
                                sx={{ color: "green", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!a.verification && (
                          <Tooltip title="Check Email Verification Status">
                            <IconButton
                              onClick={() => {
                                // GetIdentitiesNew(a.email);
                                handleCheckEmailStatus(a.email)
                              }}
                            >
                              <RefreshIcon
                                sx={{ color: "blue", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Delete Email">
                          <IconButton
                            onClick={() => {
                              handleDeleteEmailIdentity(a.email);
                            }}
                          >
                            <DeleteOutlineRoundedIcon
                              sx={{ color: "red", fontSize: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.configsettingsSection}>
            <p className={styles.configsettingsSectionHeader}>
              Configuration settings
            </p>
            <p className={styles.configsettingsSectionDesc}>
              To reset or remove a configuration, locate the settings or
              configuration file and either reset to default values .
            </p>
            <div className={styles.butttonSection}>
              <div>
                {" "}
                <Button
                  variant="contained"
                  sx={{
                    background: "#4C6FFF",
                    borderRadius: "5px",
                    textTransform: "none",
                    padding: "8px 40px",
                    boxShadow: "none",

                    "&:hover": { background: "#4C6FFF" },
                  }}
                  onClick={handleResetAWS}
                  disabled={!checked}
                >
                  Reset to default
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.configsettingsSection}>
            <p
              className={styles.configsettingsSectionHeader}
              style={{ color: "#6A81DE" }}
            >
              AWS connection guide
            </p>
            <p
              className={styles.configsettingsSectionDesc}
              style={{ color: "#425466", fontWeight: 400, lineHeight: "25px" }}
            >
              To establish a connection between your AWS account and our brand,
              kindly adhere to the detailed instructions{" "}
              <a href="https://enotifiers.s3.ap-south-1.amazonaws.com/docs/aws/AWS+Setup.pdf" style={{ color: "#4C6FFF", fontWeight: 500 }} target="_blank">
                provided in our step-by-step guide{" "}
              </a>{" "}
              or{" "}
              <a href="" style={{ color: "#4C6FFF", fontWeight: 500 }}>
                alternatively, view our video tutorial for a visual walkthrough.
              </a>
            </p>
          </div>
        </div>
      </div>
      {openDomainRecordsModal && domainrecordsList.length > 0 && (
        <DomainRecordsModal
          openDomainRecordsModal={openDomainRecordsModal}
          handleCloseRecordsModal={handleCloseRecordsModal}
          styles={styles}
          domainrecordsList={domainrecordsList}
        />
      )}

      <Dialog
        open={openDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{}}>
          {"Delete and Add New Domain"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to Delete the current domain. You need to add
            another domian after deleting the current domain
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Disagree</Button>
          <Button
            onClick={handleSubmitDeleteModal}
            autoFocus
            variant="contained"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEmailDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{}}>
          {"Delete Verified Email"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to Delete the Verified Email?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailDeleteModal}>Disagree</Button>
          <Button
            onClick={handleSubmitEmailDeleteModal}
            autoFocus
            variant="contained"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

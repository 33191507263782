import signUp from "../../assets/Images/loginrightimage.svg";
import logingif from "../../assets/Images/loginrightplane.gif";
import classes from "./EditCred.module.scss";
import { useFormik } from "formik";
import {
  editCredSchema,
  signUpSchema,
  signinSchema,
} from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { editCredientialsAPICall, loginApi } from "../../Utils/Apihelper";
import IconButton from "@mui/material/IconButton";
import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Footer from "../Footer/Footer";
import { CircularProgress } from "@mui/material";

const initialValues = {
  email: "",
  contactNumber: "",
};

const EditCred = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: editCredSchema,
      onSubmit: (values, action) => {
        const requestBody = {
          email: values.email,
          contactNumber: values.contactNumber,
        };

        setloading(true);
        editCredientialsAPICall(requestBody)
          .then((res) => {
            // console.log(res.data);
            setloading(false);
            const Token = res.data.data.accessToken;
            sessionStorage.setItem("Token", Token);
            sessionStorage.setItem("mobile", values.contactNumber);
            sessionStorage.setItem("email", values.email);
            navigate("/RegisterOtp");
          })
          .catch((res) => {
            // console.log(res, res);
            setloading(false);
            
              toast.error(res?.response?.data?.error?.message);
            
          });
      },
    });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toaster />
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <div className={classes.loginData}>
                <form onSubmit={handleSubmit}>
                  <p className={classes.logintext}>
                    Edit Credentials to Login for E-Notifiers
                  </p>
                  <div className={classes.inputBlock}>
                    <Input
                      name="email"
                      id="email"
                      size="lg"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 40, // Set the maximum character limit
                      }}
                      sx={{
                        background: "transparent",

                        "--Input-focusedHighlight": "4c6fff",
                        "&::before": {
                          transition: "box-shadow .15s ease-in-out",
                        },
                        "&:focus-within": {
                          borderColor: "4c6fff",
                        },
                      }}
                    />
                    {errors.email && touched.email ? (
                      <p className={classes.formError}>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className={classes.inputBlock}>
                    <Input
                      type="text"
                      autoComplete="off"
                      name="contactNumber"
                      id="contactNumber"
                      size="lg"
                      placeholder="Enter Phone Number"
                      value={values.contactNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{
                        background: "transparent",

                        "--Input-focusedHighlight": "4c6fff",
                        "&::before": {
                          transition: "box-shadow .15s ease-in-out",
                        },
                        "&:focus-within": {
                          borderColor: "4c6fff",
                        },
                      }}
                    />
                    {errors.contactNumber && touched.contactNumber ? (
                      <p className={classes.formError}>
                        {errors.contactNumber}
                      </p>
                    ) : null}
                  </div>
                  <div className={classes.modalButtons}>
                    <Button
                      type="submit"
                      size="lg"
                      loading={loading}
                      style={{
                        padding: "14px 50px",
                        // background: "4c6fff",
                        borderRadius: "5px",
                      }}
                      sx={{
                        backgroundColor: "4c6fff",
                        "&:disabled": {
                          backgroundColor: "#b9c7ff",
                        },
                        "&:hover": {
                          backgroundColor: "4c6fff",
                        },
                      }}
                      loadingIndicator={
                        <CircularProgress
                          sx={{ color: "#4c6fff" }}
                          size={"25px"}
                        />
                      }
                    >
                      SAVE CHANGES
                    </Button>
                    <a href="#" className={classes.link}>
                      <Link
                        to={"/login"}
                        style={{
                          textDecoration: "underline",
                          color: "4c6fff",
                        }}
                      >
                        Already have an account?
                      </Link>
                    </a>
                  </div>
                </form>
                <p className={classes.signUp}>
                  Create a new account?{" "}
                  <a href="#" className={classes.link}>
                    <Link
                      to={"/register"}
                      style={{ textDecoration: "underline", color: "4c6fff" }}
                    >
                      Sign Up now
                    </Link>
                  </a>
                </p>
              </div>
            </div>
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
              <img src={logingif} alt="" className={classes.logingif} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCred;

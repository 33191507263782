import React, { useEffect, useState } from "react";
import styles from "./EmailsList.module.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SubscribeDataTableComp from "../../components/SubscribeDataTableComp/SubscribeDataTableComp";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import EmailListDataTable from "../../components/EmailListDataTable/EmailListDataTable";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DrawIcon from "@mui/icons-material/Draw";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  GetAllMailDetailsAPICall,
  GetEachEmailAPICall,
  IGetAllMailDetails,
  deleteEmailAPICall,
  duplicateEmailAPICall,
} from "../../Utils/Apihelper";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  setEditEmailData,
  setEditEmailImage,
} from "../../Store/Project/ProjectSlice";

const EmailsList = () => {
  const [tab, setTab] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(10);
  const [paginationCount, setpaginationCount] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [query, setQuery] = useState("");
  const [openDuplicateModal, setopenDuplicateModal] = useState<any>({
    status: false,
    categoryName: "",
  });
  const [duplicateCamp, setDuplicateCamp] = useState("");
  const [deleteCamp, setDeleteCamp] = useState(false);
  const [emailData, setEmailData] = useState<any>({});
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getallMailDetails();
    sessionStorage.setItem("mailTemplateName", "");
    sessionStorage.setItem("mailTemplateGroup", "");
  }, [tab]);

  const getallMailDetails = () => {
    let obj: IGetAllMailDetails = {
      mail_type: tab === 1 ? "transactional" : "promotional",
    };
    GetAllMailDetailsAPICall(obj)
      .then((response: any) => {
        //  console.log("responseEmails", response);
        setData(response.data.data.mail_details);
      })
      .catch((error: any) => {
        // console.log("error", error);
      });
  };

  const handleCloseDuplicate = () => {
    setopenDuplicateModal({
      status: false,
      categoryName: "",
    });
    setDuplicateCamp("")
  };

  const handlePageChange = (value: any, pagenumber: any) => {
    setpaginationCount(pagenumber);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row) => {
    setAnchorEl(event.currentTarget);
    sessionStorage.setItem("TableRow", row.mailName);
  };

  const handleDuplicateEmail = () => {
    const obj = {
      original_mail_name: openDuplicateModal.categoryName,
      duplicate_mail_name: duplicateCamp,
    };
    duplicateEmailAPICall(obj)
      .then((response: any) => {
        // console.log("responseEmails", response);
        getallMailDetails();
        toast.success(response.data.message);
        handleCloseDuplicate();
      })
      .catch((error: any) => {
        // console.log("error", error);
        toast.error(error?.response?.data?.message ? error.response.data.message : "Error Duplicating Category")
      });
  };

  const getEachEmailDetails = (mailName) => {
    const reqBody = {
      mail_name: mailName,
    };

    GetEachEmailAPICall(reqBody)
      .then((res: any) => {
        //  console.log(res.data);
        sessionStorage.setItem("editEmail", "true");
        sessionStorage.setItem("OldMailName", mailName);
        navigate("/email-dashboard/emails/create");
        dispatch(setEditEmailData(res.data.data));
        dispatch(setEditEmailImage(res.data.image));
      })
      .catch((res) => {
        // console.log(res.data);
      });
  };

  const filteredData = data.filter(
    (data: any) =>
      data.mailName.toLowerCase().includes(query.trim()) ||
      data.mailName.toUpperCase().includes(query.trim())
  );

  const handleDeleteEmail = () => {
    const obj = {
      mailName: openDuplicateModal.categoryName,
    };
    deleteEmailAPICall(obj)
      .then((response: any) => {
        //  console.log("responseEmails", response);
        getallMailDetails();

        toast.success(response.data.message);
      })
      .catch((error: any) => {
        //  console.log("error", error);
        toast.error("failed");
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlerowClick = (row: any) => {};

  const handleViewDetails = (rowData: any) => {
    navigate("/email-dashboard/emails/campaignDetailsView");
  };

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }
  const columns = [
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Name</div>,
      selector: "mailName",
      width: "16rem",

      sortable: true,
      cell: (row: any) => (
        <div
          style={{ display: "flex", flexDirection: "column" }}
          onClick={() => {
            handlerowClick(row);
          }}
        >
          <div style={{ fontSize: "13px", padding: "8px" }}>{row.mailName}</div>
          {/* <div style={{ fontSize: "11px", paddingTop: "6px" }}>
            2024-01-18T16:30:00Z
          </div> */}
        </div>
      ),
    },

    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>CategoryType</div>
      ),
      selector: "subscriptionStatus",

      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {tab === 1 ? "transactional" : "Promotional"}
        </div>
      ),
    },
    // {
    //   name: <div style={{ fontSize: "13px", color: "black" }}>Recipient</div>,
    //   selector: "dateAdded",
    //   sortable: true,
    //   cell: (row: any) => <div style={{ fontSize: "13px" }}>Group name</div>,
    // },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Open Count </div>,
      selector: "Open",

      width: "120px",
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }} onClick={() => {}}>
          {row.Statistics.Open}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Delivery Count </div>
      ),
      selector: "lastUpdated",

      width: "140px",
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.Statistics.Delivery}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Bounce Count </div>
      ),
      selector: "lastUpdated",

      width: "135px",
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.Statistics.Bounce}</div>
      ),
    },
    {
      name: <b style={{ fontSize: "13px", color: "black" }}></b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "60px",
      cell: (row: any) => (
        <b>
          <>
            <Tooltip title="View Details">
              <IconButton
                onClick={(event) => {
                  handleClick(event, row);
                }}
                style={{
                  background: "#F7F8FF",
                  borderRadius: "10px",
                  height: "30px",
                }}
              >
                <MoreHorizOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.02))",
                  border: "1px solid #9AAAEA",
                  mt: 0.5,
                  background: "#F9FBFF",
                  borderRadius: "10px",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/email-dashboard/emails/campaignDetailsView");
                  handleClose();
                }}
                sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
              >
                <ListItemIcon>
                  <PreviewIcon fontSize="small" sx={{ color: "#000000" }} />
                </ListItemIcon>
                Preview Campaign
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDeleteCamp(false);
                  setopenDuplicateModal({
                    status: true,
                    categoryName: sessionStorage.getItem("TableRow"),
                  });
                }}
                sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
              >
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" sx={{ color: "#000000" }} />
                </ListItemIcon>
                Duplicate Campaign
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  getEachEmailDetails(sessionStorage.getItem("TableRow"));
                }}
                sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
              >
                <ListItemIcon>
                  <DrawIcon fontSize="small" sx={{ color: "#000000" }} />
                </ListItemIcon>
                Edit Campaign
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDeleteCamp(true);
                  setopenDuplicateModal({
                    status: true,
                    categoryName: sessionStorage.getItem("TableRow"),
                  });
                }}
                sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
              >
                <ListItemIcon>
                  <DeleteOutlineRoundedIcon
                    fontSize="small"
                    sx={{ color: "#000000" }}
                  />
                </ListItemIcon>
                Delete Campaign
              </MenuItem>
            </Menu>
          </>
        </b>
      ),
    },
  ];

  return (
    <>
      <Toaster />
      <div className={styles.mainWrapper}>
        <div className={styles.topContainer}>
          <div className={styles.leftSection}>
            <div className={styles.tabsWrapper}>
              <div className={styles.tabsCatagory}>
                <div
                  className={styles.tabsItem}
                  onClick={() => {
                    activeTab(1);
                  }}
                  style={{
                    backgroundColor: tab === 1 ? "#4C6FFF" : "",
                    color: tab == 1 ? "white" : "",
                  }}
                >
                  Transactional
                </div>
                <div
                  className={styles.tabsItem}
                  onClick={() => {
                    activeTab(2);
                  }}
                  style={{
                    backgroundColor: tab === 2 ? "#4C6FFF" : "",
                    color: tab == 2 ? "white" : "",
                  }}
                >
                  Promotional
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightSection}>
            <div>
              <Button
                variant="contained"
                startIcon={<LinkIcon />}
                onClick={() => {
                  navigate("/email-dashboard/emails/create");
                  sessionStorage.setItem("editEmail", "false");
                }}
                sx={{
                  background: "#4C6FFF",
                  borderRadius: "100px",
                  textTransform: "none",
                  padding: { xs: "6px 40px", sm: "6px 20px" },
                  boxShadow: "none",

                  "&:hover": { background: "#4C6FFF" },
                }}
              >
                Create an Email
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <EmailListDataTable
            data={filteredData}
            columns={columns}
            loading={loading}
            totalRows={totalRows}
            paginationCount={paginationCount}
            handlePageChange={handlePageChange}
            query={query}
            setQuery={setQuery}
          />
        </div>
        <Dialog
          open={openDuplicateModal.status}
          PaperProps={{
            sx: { borderRadius: "20px", padding: "0px 10px 10px 10px" },
          }}
        >
          {deleteCamp ? (
            <>
              <DialogTitle>Delete Email</DialogTitle>
              <DialogContent>
                <DialogContentText mb={1} sx={{ color: "red" }}>
                  Are you sure you want to delete this email?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDuplicate}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleDeleteEmail();
                    handleCloseDuplicate();
                  }}
                  variant="outlined"
                >
                  Delete
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>Duplicate Category</DialogTitle>
              <DialogContent>
                <DialogContentText mb={2}>
                  To duplicate this email, please enter duplicated email name
                  here.
                </DialogContentText>

                <div style={{ marginTop: "10px" }}>
                  <p className={styles.lableText}>New email name</p>
                  <TextField
                    focused
                    autoFocus
                    margin="dense"
                    id="name"
                    // label="New Template Name"
                    type="text"
                    fullWidth
                    size="small"
                    value={duplicateCamp}
                    onChange={(event) => {
                      const newValue = event.target.value.replace(/\s/g, "_");
                      setDuplicateCamp(newValue);
                    }}
                    placeholder="Enter new email name"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        height: "40px",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      },
                    }}
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: "5px",
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#EDF2F7",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EDF2F7",
                        },
                      },
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDuplicate}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleDuplicateEmail();
              
                  }}
                  disabled={duplicateCamp === ""}
                  variant="outlined"
                >
                  Duplicate
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </div>
    </>
  );
};

export default EmailsList;

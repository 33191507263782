import React, { useEffect, useState } from "react";
import styles from "./Subscribers.module.scss";
import Moment from "react-moment";
import "moment-timezone";
import {
  Button,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Menu,
  MenuItem,
  Autocomplete,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SubscribeDataTableComp from "../../components/SubscribeDataTableComp/SubscribeDataTableComp";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CampaignPreviewModal from "../../components/Modals/CampaignPreviewModal/CampaignPreviewModal";
import { useNavigate } from "react-router-dom";
import { SOURCE } from "../../Utils/constants";
import CreateGroup from "../../components/Modals/CreateGroup/CreateGroup";
import {
  DeleteGroupAPICall,
  EditGroupNameAPICall,
  ICreateSubscriberPayload,
  IEditGroupName,
  IGetAllGroupsDetailsResponse,
  IGetAllGroupsResponse,
  IGetGroupDetailsResponse,
  ISingleGroup,
  ISingleGroupDetails,
  ISubscriberList,
  createSubscriberApi,
  deleteSubscriverAPICall,
  getAllGroupsApi,
  getAllGroupsDetailsApi,
  getGroupsDetailsApi,
} from "../../Utils/Apihelper";
import AutoCompleteComp from "../../components/AutoCompleteComp/AutoCompleteComp";
import SubscriberGroupDataTableComp from "../../components/SubscribeDataTableComp/SubscriberGroupDataTableComp";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DrawIcon from "@mui/icons-material/Draw";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createSubscriber } from "../../Schemas/Schemas";
import { Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment-timezone";

const Subscribers = () => {
  const [tab, setTab] = useState(1);
  const [data, setData] = useState<ISingleGroupDetails>({
    createdAt: "",
    deletedSubscribersList: [],
    disable: false,
    groupName: "",
    modifiedAt: "",
    subscribersList: [],
    unSubscribersList: [],
    subscribers_adding_precentage: "",
  });
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(10);
  const [selectedGroup, setselectedGroup] = useState<string>("");
  const [groups, setgroups] = useState<ISingleGroup[]>([]);
  const [groupDetailsList, setgroupDetailsList] = useState<
    ISingleGroupDetails[]
  >([]);
  const [paginationCount, setpaginationCount] = useState(1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showCreateGroup, setshowCreateGroup] = useState(false);
  const [openEditGroupModal, setopenEditGroupModal] = useState({
    status: false,
    name: "",
  });
  const [newGroupname, setNewGroupName] = useState("");
  const [subscriberDetails, setSubscriberDetails] = useState<any>();
  const [deleteMultipleSub, setDeleteMultipleSub] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllGroups();
    getAllGroupDetails();
  }, []);
  useEffect(() => {
    if (selectedGroup) {
      getGroupDetails(selectedGroup);
    }
  }, [selectedGroup]);

  const handleCloseEditGroupModal = () => {
    setNewGroupName("");
    setopenEditGroupModal({
      status: false,
      name: "",
    });
  };
  const handleSubmitEditGroupName = () => {
    EditGroupName();
  };

  const EditGroupName = () => {
    let obj: IEditGroupName = {
      oldGroupName: openEditGroupModal.name,
      newGroupName: newGroupname,
    };
    EditGroupNameAPICall(obj)
      .then((response: any) => {
        setopenEditGroupModal({
          status: false,
          name: "",
        });
        setNewGroupName("");
        toast.success(
          response.data.message ? response.data.message : "Successfully Updated"
        );
        getAllGroupDetails();
      })
      .catch((error: any) => {
        toast.error("Group name already exit");
      });
  };

  const handleEditGroupName = (rowData: any) => {
    setopenEditGroupModal({
      status: true,
      name: rowData.groupName,
    });
  };
  const handleDeleteGroup = (rowData: any) => {
    // window.alert("jkkj")
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => DeleteGroup(rowData.groupName),
        },
      ],
    });
  };
  const handleDeleteSubscriber = () => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => DeleteSubscriber(),
        },
      ],
    });
  };
  const handleSelectedRowsDelete = (selectedData: any) => {
    const filtered = selectedData.map((a) => a.email);
    console.log("filtered", filtered);
    setDeleteMultipleSub(filtered);
  };

  const handleDeleteMultipleSubscribers = () => {
    handleDeleteSubscriber();
  };

  const DeleteSubscriber = () => {
    const ProfileDetails: any = sessionStorage.getItem("subscribeData");
    const stringify = JSON.parse(ProfileDetails);

    let obj: any = {
      groupName: selectedGroup,
      deleteSubscribersList:
        deleteMultipleSub.length > 0 ? deleteMultipleSub : [stringify.email],
    };
    deleteSubscriverAPICall(obj)
      .then((response: any) => {
        toast.success(
          response.data.message ? response.data.message : "Deleted Successfully"
        );
        setDeleteMultipleSub([]);
        setDeleteMultipleSub([]);
        getGroupDetails(selectedGroup);
      })
      .catch((error: any) => {
        toast.error("Error Deleting");
      });
  };

  const DeleteGroup = (groupname: any) => {
    let obj: any = {
      groupName: groupname,
    };

    DeleteGroupAPICall(obj)
      .then((response: any) => {
        toast.success(
          response.data.message ? response.data.message : "Deleted Successfully"
        );
        getAllGroupDetails();
      })
      .catch((error: any) => {
        // console.log("error.response",error.response)
        toast.error(error.response.data.data.error);
      });
  };

  const handleOpenDrawer = (status: boolean) => {
    setOpenDrawer(status);
  };

  const handleChangeGroups = (groupName: string) => {
    setselectedGroup(groupName);
  };
  const getGroupDetails = (groupName: string) => {
    const request: ISingleGroup = {
      groupName,
    };
    setLoading(true);
    getGroupsDetailsApi(request)
      .then((response) => {
        const responseData: IGetGroupDetailsResponse = response.data;
        setLoading(false);
        // console.log("getGroupsDetailsApi", responseData);
        setData(responseData.data.group[0]);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };
  const getAllGroups = () => {
    getAllGroupsApi()
      .then((response: any) => {
        const responseData: IGetAllGroupsResponse = response.data;
        setgroups(responseData.data);
        if (responseData.data.length) {
          setselectedGroup(responseData.data[0].groupName);
        }
        // console.log("getAllGroupsApi", response.data);
      })
      .catch((error: any) => {});
  };
  const getAllGroupDetails = () => {
    getAllGroupsDetailsApi()
      .then((response: any) => {
        const responseData: IGetAllGroupsDetailsResponse = response.data;
        setgroupDetailsList(responseData.data.all_groups);
      })
      .catch((error: any) => {});
  };

  const handlePageChange = (value: any, pagenumber: any) => {
    setpaginationCount(pagenumber);
  };
  const handleClose = () => {
    setshowCreateGroup(false);
  };

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
    getAllGroups();
  }

  const handleSubmitDrawer = (values: any) => {
    const request: ICreateSubscriberPayload = {
      email: values.email,
      groupName: values.groupName,
      name: values.name,
      source: SOURCE,
    };
    createSubscriberApi(request)
      .then((response: any) => {
        console.log("createSubscriberApi", response.data);
        setOpenDrawer(false);
        // window.alert("Subscriber Added Successfully");
        toast.success("Subscriber Added Successfully");
        getGroupDetails(selectedGroup);
      })
      .catch((error: any) => {
        toast.error(error.response.data.message);
      });
  };

  const handlePreviewSubscriber = (rowData: any) => {
    navigate("/email-dashboard/subscriberList/subscriberInfo");
  };

  return (
    <>
      <Toaster />
      <div className={styles.mainWrapper}>
        <div className={styles.topContainer}>
          <div className={styles.leftSection}>
            <div className={styles.tabsWrapper}>
              <div className={styles.tabsCatagory}>
                <div
                  className={styles.tabsItem}
                  onClick={() => {
                    activeTab(1);
                  }}
                  style={{
                    backgroundColor: tab === 1 ? "#4C6FFF" : "",
                    color: tab == 1 ? "white" : "",
                  }}
                >
                  Subscribers
                </div>
                <div
                  className={styles.tabsItem}
                  onClick={() => {
                    activeTab(2);
                  }}
                  style={{
                    backgroundColor: tab === 2 ? "#4C6FFF" : "",
                    color: tab == 2 ? "white" : "",
                  }}
                >
                  Groups
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rightSection}>
            {tab === 1 && (
              <div>
                <Button
                  variant="contained"
                  startIcon={<AddBoxOutlinedIcon />}
                  sx={{
                    background: "#4C6FFF",
                    borderRadius: "100px",
                    textTransform: "none",
                    padding: { xs: "6px 10px", sm: "6px 20px" },
                    boxShadow: "none",

                    "&:hover": { background: "#4C6FFF" },
                  }}
                  onClick={() => {
                    handleOpenDrawer(true);
                  }}
                >
                  Add single contact
                </Button>
              </div>
            )}
            <div>
              <Button
                variant="contained"
                startIcon={
                  tab === 1 ? (
                    <FileUploadOutlinedIcon />
                  ) : (
                    <AddBoxOutlinedIcon />
                  )
                }
                sx={{
                  background: "#505780",
                  borderRadius: "100px",
                  textTransform: "none",
                  padding: { xs: "6px 10px", sm: "6px 20px" },
                  boxShadow: "none",
                  "&:hover": { background: "#505780" },
                }}
                onClick={() => setshowCreateGroup(true)}
              >
                {tab === 1 ? "Import Subscribers" : "Create Group"}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.tableWrapper}>
          {tab === 1 ? (
            <>
              {!loading && (
                <SubscribersTab
                  data={data}
                  loading={loading}
                  totalRows={totalRows}
                  paginationCount={paginationCount}
                  handlePageChange={handlePageChange}
                  groups={groups}
                  selectedGroup={selectedGroup}
                  handleChangeGroups={handleChangeGroups}
                  setSubscriberDetails={setSubscriberDetails}
                  handleDeleteSubscriber={handleDeleteSubscriber}
                  handleSelectedRowsDelete={handleSelectedRowsDelete}
                  handlePreviewSubscriber={handlePreviewSubscriber}
                  handleDeleteMultipleSubscribers={
                    handleDeleteMultipleSubscribers
                  }
                />
              )}
            </>
          ) : (
            <>
              <GroupsTab
                data={groupDetailsList}
                loading={loading}
                totalRows={totalRows}
                paginationCount={paginationCount}
                handlePageChange={handlePageChange}
                groups={groups}
                selectedGroup={selectedGroup}
                handleChangeGroups={handleChangeGroups}
                handleEditGroupName={handleEditGroupName}
                handleDeleteGroup={handleDeleteGroup}
              />
              <Dialog
                open={openEditGroupModal.status}
                PaperProps={{
                  sx: {
                    borderRadius: "20px",
                    padding: "0px 10px 10px 10px",
                    width: "700px",
                  },
                }}
              >
                <DialogTitle>Edit Group Name</DialogTitle>
                <DialogContent>
                  <DialogContentText mb={2}>
                    Please enter new Group name here.
                  </DialogContentText>
                  <div>
                    <p
                      className={styles.lableText}
                      style={{ fontSize: "12px", fontWeight: 600 }}
                    >
                      Current Group Name
                    </p>
                    <TextField
                      margin="dense"
                      id="name"
                      // label="Old Template Name"
                      type="text"
                      fullWidth
                      size="small"
                      value={openEditGroupModal.name}
                      placeholder="Old Template Name"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          height: "40px",
                          padding: "0px 10px",
                          borderRadius: "5px",
                        },
                      }}
                      sx={{
                        background: "#EDF2F7",
                        borderRadius: "5px",
                        "& label.Mui-focused": {
                          color: "grey",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#EDF2F7",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EDF2F7",
                          },
                        },
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <p
                      className={styles.lableText}
                      style={{ fontSize: "12px", fontWeight: 600 }}
                    >
                      New Group Name
                    </p>
                    <TextField
                      focused
                      autoFocus
                      margin="dense"
                      id="name"
                      // label="New Template Name"
                      type="text"
                      fullWidth
                      size="small"
                      value={newGroupname}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
                        if (isValidInput) {
                          setNewGroupName(inputValue);
                        }
                      }}
                      placeholder="Enter New Group Name"
                      variant="standard"
                      inputProps={{ maxLength: 50 }}
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          height: "40px",
                          padding: "0px 10px",
                          borderRadius: "5px",
                        },
                      }}
                      sx={{
                        background: "#EDF2F7",
                        borderRadius: "5px",
                        "& label.Mui-focused": {
                          color: "grey",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#EDF2F7",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EDF2F7",
                          },
                        },
                      }}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseEditGroupModal}
                    sx={{ textTransform: "none" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmitEditGroupName();
                    }}
                    disabled={newGroupname === ""}
                    variant="contained"
                    sx={{ textTransform: "none" }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </div>
      </div>

      <div>
        <SingleContact
          open={openDrawer}
          handleClose={() => {
            handleOpenDrawer(false);
          }}
          handleSubmit={handleSubmitDrawer}
          groups={groups}
          selectedGroup={selectedGroup}
        />
      </div>

      {showCreateGroup && (
        <CreateGroup
          open={showCreateGroup}
          handleClose={() => {
            handleClose();
          }}
          tab={tab}
        />
      )}
    </>
  );
};
export default Subscribers;

interface GroupsTabProps {
  data: ISingleGroupDetails[];
  loading: boolean;
  totalRows: any;
  paginationCount: any;
  handlePageChange: any;
  groups: ISingleGroup[];
  selectedGroup: string;
  handleChangeGroups: any;
  handleEditGroupName: any;
  handleDeleteGroup: any;
}
const GroupsTab = ({
  data,
  loading,
  totalRows,
  paginationCount,
  handlePageChange,
  groups,
  selectedGroup,
  handleChangeGroups,
  handleEditGroupName,
  handleDeleteGroup,
}: GroupsTabProps) => {
  const columns = [
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Name</div>,
      selector: "email",
      width: "13rem",
      sortable: true,
      cell: (row: ISingleGroupDetails) => (
        <div style={{ fontSize: "13px" }} onClick={() => {}}>
          {row.groupName}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Date Added </div>,
      selector: "name",
      sortable: true,
      cell: (row: ISingleGroupDetails) => (
        <div style={{ fontSize: "13px" }}>{formatDate(row.createdAt)}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Date Modified</div>
      ),
      selector: "source",
      sortable: true,
      cell: (row: ISingleGroupDetails) => (
        <div style={{ fontSize: "13px" }}>{formatDate(row.modifiedAt)}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Active</div>,
      selector: "dateTime",
      sortable: true,
      cell: (row: ISingleGroupDetails) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {row.disable ? "Disabled" : "Active"}
        </div>
      ),
    },

    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Actions</div>,
      selector: "",
      sortable: false,

      cell: (row: any) => (
        <b>
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <IconButton
              onClick={() => {
                handleEditGroupName(row);
              }}
              style={{
                background: "#F7F8FF",
                borderRadius: "10px",
                height: "30px",
              }}
            >
              <Tooltip title="Edit Group">
                <EditIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                    color: "#505780",
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDeleteGroup(row);
              }}
              style={{
                background: "#F7F8FF",
                borderRadius: "10px",
                height: "30px",
              }}
            >
              <Tooltip title="Delete Group">
                <DeleteIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                    color: "#4c6fff",
                  }}
                />
              </Tooltip>
            </IconButton>
          </Stack>
        </b>
      ),
    },
  ];

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };
  return (
    <>
      <div className={styles.tableWrapper}>
        <SubscriberGroupDataTableComp
          data={data}
          columns={columns}
          loading={loading}
          group={true}
          pagination={true}
        />
      </div>
    </>
  );
};
interface SubscribersTabProps {
  data: any;
  loading: boolean;
  totalRows: any;
  paginationCount: any;
  handlePageChange: any;
  groups: ISingleGroup[];
  selectedGroup: string;
  handleChangeGroups: any;
  setSubscriberDetails: any;
  handleDeleteSubscriber: any;
  handleSelectedRowsDelete: any;
  handlePreviewSubscriber: any;
  handleDeleteMultipleSubscribers: any;
}
const SubscribersTab = ({
  data,
  loading,
  totalRows,
  paginationCount,
  handlePageChange,
  groups,
  selectedGroup,
  handleChangeGroups,
  setSubscriberDetails,
  handleDeleteSubscriber,
  handlePreviewSubscriber,
  handleSelectedRowsDelete,
  handleDeleteMultipleSubscribers,
}: SubscribersTabProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const unixTimestamp = 198784740;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off = new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };

  const columns = [
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Email</div>,
      selector: "email",
      width: "17rem",
      sortable: true,
      cell: (row: any) => <div style={{ fontSize: "13px" }}>{row.email}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Name </div>,
      selector: "name",
      sortable: true,
      cell: (row: any) => <div style={{ fontSize: "13px" }}>{row.name}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Source</div>,
      selector: "source",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>{row.source}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Date Added</div>,
      selector: "dateTime",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{formatDate(row.dateTime)}</div>
      ),
    },

    {
      name: <b style={{ fontSize: "13px", color: "black" }}></b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "60px",
      cell: (row: any) => (
        <b>
          <>
            <Tooltip title="View Details">
              <IconButton
                onClick={(event: any) => {
                  handleClick(event);
                  setSubscriberDetails(row);
                  sessionStorage.setItem("subscribeData", JSON.stringify(row));
                }}
                style={{
                  background: "#F7F8FF",
                  borderRadius: "10px",
                  height: "30px",
                }}
              >
                <MoreHorizOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.02))",
                  border: "1px solid #9AAAEA",
                  mt: 0.5,
                  background: "#F9FBFF",
                  borderRadius: "10px",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              onClose={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handlePreviewSubscriber();
                }}
                sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
              >
                <ListItemIcon>
                  <PreviewIcon fontSize="small" sx={{ color: "#000000" }} />
                </ListItemIcon>
                Preview Subscriber
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  handleDeleteSubscriber();
                }}
                sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
              >
                <ListItemIcon>
                  <DeleteOutlineRoundedIcon
                    fontSize="small"
                    sx={{ color: "#000000" }}
                  />
                </ListItemIcon>
                Delete Subscriber
              </MenuItem>
            </Menu>
          </>
        </b>
      ),
    },
  ];
  return (
    <>
      <div className={styles.tableWrapper}>
        <SubscribeDataTableComp
          data={data}
          columns={columns}
          loading={loading}
          totalRows={totalRows}
          paginationCount={paginationCount}
          handlePageChange={handlePageChange}
          groups={groups}
          selectedGroup={selectedGroup}
          handleChangeGroups={handleChangeGroups}
          handleSelectedRowsDelete={handleSelectedRowsDelete}
          handleDeleteMultipleSubscribers={handleDeleteMultipleSubscribers}
        />
      </div>
    </>
  );
};

interface SingleContactProps {
  open: boolean;
  handleClose: any;
  handleSubmit: any;
  selectedGroup: any;
  groups: ISingleGroup[];
}
const SingleContact = ({
  open,
  handleClose,
  handleSubmit,
  selectedGroup,
  groups,
}: SingleContactProps) => {
  const [values, setvalues] = useState({
    name: "",
    email: "",
    groupName: selectedGroup,
  });

  // console.log("selectedGroup", selectedGroup);

  const handleChange = (name: string, value: string) => {
    setvalues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    handleSubmit(values);
  };
  const InitialValues = values;

  return (
    <Drawer
      anchor={"right"}
      open={open}
      id="new"
      // onClose={toggleDrawer("right", false)}
    >
      {open && (
        <Formik
          validationSchema={createSubscriber}
          initialValues={InitialValues}
          onSubmit={(values: any) => {
            // console.log("values", values);
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,

            setFieldValue,
          }) => {
            // console.log("errors", errors);
            // console.log("values", values);
            return (
              <form noValidate onSubmit={handleSubmit}>
                <div className={styles.drwaerWrapper}>
                  <div className={styles.drawerHeaderSection}>
                    <p className={styles.drawerHeader}>Create a Subscriber</p>
                    <div style={{ cursor: "pointer" }} onClick={handleClose}>
                      <CancelPresentationIcon sx={{ color: "white" }} />
                    </div>
                  </div>
                  {open && (
                    <div className={styles.createSection}>
                      <div className={styles.textfeild}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label=""
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.name && Boolean(errors.name)}
                          placeholder="Name"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              height: "40px",
                              padding: "0px 10px",
                              borderRadius: "5px",
                            },
                          }}
                          sx={{
                            borderRadius: "5px",
                            background: "#EDF2F7",
                            "& label.Mui-focused": {
                              color: "grey",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "red",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#EDF2F7",
                              },
                              "&.Mui-focused-visible fieldset": {
                                borderColor: "#EDF2F7",
                              },
                            },
                          }}
                        />
                        <FormHelperText>
                          <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                            {touched.name && errors.name?.toString()}
                          </p>
                        </FormHelperText>
                      </div>
                      <div className={styles.textfeild}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          value={values.email}
                          placeholder="Email"
                          name="email"
                          label=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              height: "40px",
                              padding: "0px 10px",
                              borderRadius: "5px",
                            },
                          }}
                          sx={{
                            background: "#EDF2F7",
                            borderRadius: "5px",
                            "& label.Mui-focused": {
                              color: "grey",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#EDF2F7",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#EDF2F7",
                              },
                            },
                          }}
                        />
                        <FormHelperText>
                          <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                            {touched.email && errors.email?.toString()}
                          </p>
                        </FormHelperText>
                      </div>
                      <div className={styles.textfeild}>
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            marginBottom: "10px",
                            marginLeft: "5px",
                          }}
                        >
                          Select Group
                        </p>
                        <Autocomplete
                          size="small"
                          value={values.groupName}
                          onChange={(event: any, newValue: string) => {
                            setFieldValue("groupName", newValue);
                          }}
                          options={groups.map((a) => a.groupName)}
                          sx={{
                            borderRadius: "10px",
                            // width: "40%",
                            background: "#EDF2F7",
                            outline: "none",
                            fontSize: "16px",
                            fontWeight: 500,
                            padding: "0 10px",
                            color: "#000000",
                            boxShadow: "none",
                            "& .MuiOutlinedInput-input": {
                              // padding: "4.5px 4px 2.5px 8px",
                              paddingTop: "2px !important",
                              cursor: "pointer",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                          }}
                          popupIcon={
                            <KeyboardArrowDownIcon
                              style={{ cursor: "pointer" }}
                              fontSize="small"
                            />
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Groups"
                              name="groupName"
                              error={
                                touched.groupName && Boolean(errors.groupName)
                              }
                            />
                          )}
                        />
                        <FormHelperText>
                          <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                            {touched.groupName && errors.groupName?.toString()}
                          </p>
                        </FormHelperText>
                      </div>
                      <div className={styles.buttonSection}>
                        <div>
                          <Button
                            variant="contained"
                            startIcon={<CloseOutlinedIcon />}
                            sx={{
                              background: "#505780",
                              borderRadius: "100px",
                              textTransform: "none",
                              padding: "6px 20px",
                              boxShadow: "none",
                              "&:hover": { background: "#505780" },
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div>
                          <Button
                            // onClick={onSubmit}
                            type="submit"
                            variant="contained"
                            startIcon={<AddBoxOutlinedIcon />}
                            sx={{
                              background: "#4C6FFF",
                              borderRadius: "100px",
                              textTransform: "none",
                              padding: "6px 20px",
                              boxShadow: "none",
                              "&:hover": { background: "#4C6FFF" },
                            }}
                          >
                            Create
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </Drawer>
  );
};

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import classes from "../../components/SubscribeDataTableComp/SubscribeDataTablecomp.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import searchIcn from "../../assets/Images/searchIcon.svg";
import {
  ISingleGroup,
  ISingleGroupDetails,
  ISubscriberList,
  getAllCatDetailsApi,
} from "../../Utils/Apihelper";
import StyledLoader from "../../components/StyledLoader/StyledLoader";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoDataComponent from "../../components/SubscribeDataTableComp/NoDataComponent";

interface props {
  data: any;
  columns: any;
  loading: any;
}

const TemplatesDataTableComp = ({ data, loading, columns }: props) => {
  // const [tableData, setTableData] = useState([])
  const handleChange = ({ selectedRows }: any) => {
   // console.log("Selected Rows: ", selectedRows);
  };
  const [query, setQuery] = useState("");
  const pathName = useLocation().pathname;

  const customStyles = {
    table: {
      style: {
        // border: "1px solid #D9D9D9",
        borderRadius: "8px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        // border:"1px solid #D9D9D9"
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },

    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",

        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "12px",
        paddingRight: "16px",
        wordBreak: "break-word",
      },
      draggingStyle: {},
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",

        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },

    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,

        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const filteredData = query.trim().length
    ? data.filter((a: any) =>
        a.templateCategoryName.toLowerCase().includes(query.toLowerCase().trim())
      )
    : data;
  return (
    <>
      <div
        style={{
          borderRadius: "24px",
          padding: "15px 15px",
          backgroundColor: "white",
        }}
      >
        <div className={classes.tableWrapper}>
          <div className={classes.emailListHeader}>
            <div className={classes.listTitle}>Categories</div>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"right"}
              spacing={2}
            >
              <div className={classes.searchInput}>
                <div>
                  <img src={searchIcn} alt="" className={classes.searchIcon} />
                </div>
                <input
                  type="text"
                  className={classes.inputBox}
                  value={query}
                  placeholder="Search...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </Stack>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
            }}
          >
            <DataTable
              data={filteredData}
              columns={columns}
              title=" "
              pagination={true}
              selectableRows={false}
              progressPending={loading}
              noDataComponent={<NoDataComponent />}
              // selectableRows
              customStyles={customStyles}
              onSelectedRowsChange={handleChange}
              fixedHeader
              progressComponent={<StyledLoader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatesDataTableComp;

import React, { useEffect, useState } from "react";
import classes from "./UserDetails.module.scss";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { getUserDetailsAPICall } from "../../../Utils/Apihelper";

const UserDetails = () => {
  const ProfileDetails: any = sessionStorage.getItem("profileDetails");
  const stringify = JSON.parse(ProfileDetails);
  const [planDetails, setplanDetails] = useState<any>();

  useEffect(() => {
    getUserDetails();
    setTimeout(() => {
      getUserDetails();
    }, 5000);
  }, []);

  const getUserDetails = () => {
    getUserDetailsAPICall()
      .then((res: any) => {
        setplanDetails(res.data.data.subscription.products.email);
      })
      .catch((err) => {});
  };

  const handleLogOut = () => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Logout?",

      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => Logout(),
        },
      ],
    });
  };

  const Logout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>User Details</div>
        <div className={classes.contentWrapper}>
          <div className={classes.pLeft}>
            <div className={classes.pProfile}>
              <div className={classes.ppImage}>
                <Avatar
                  alt=""
                  src="/static/images/avatar/1.jpg"
                  style={{ background: "#4c6fff", color: "white" }}
                >
                  {stringify &&
                    stringify.username &&
                    stringify.username[0].toUpperCase()}
                </Avatar>
              </div>
              <div className={classes.ppNameEmail}>
                <div className={classes.ppName}>
                  {" "}
                  {stringify && stringify.username}
                </div>
                <div className={classes.ppEmail}>
                  {stringify && stringify.email}
                </div>
              </div>
            </div>
            <div className={classes.listTitle}>Plan Details</div>
            <div className={classes.pProfile}>
              <div className={classes.ppNameEmail}>
                <div className={classes.ppName}>
                  {" "}
                  {planDetails && planDetails.subscriptionType}&nbsp;
                  {planDetails &&
                    planDetails.subscriptionType !== "Basic" &&
                    "👑"}
                </div>
                <div className={classes.ppEmail}>
                  {planDetails && planDetails.subscriptionType !== "Basic"
                    ? ` Your current plan ends on 
                ${planDetails && planDetails.subscriptionExpiry}`
                    : "Subscribe to Enotifiers to access premium features"}
                </div>
                <div className={classes.ppEmail}>
                  Subscribers Limit : <span style={{color:"black",fontWeight:"500"}}> {planDetails?.subscribers}</span>
                </div>
                <div className={classes.ppEmail}>
                  Emails Limit : <span style={{color:"black",fontWeight:"500"}}> {planDetails?.limit}</span>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                sx={{
                  background: "4c6fff",
                  borderRadius: "4px",
                  textTransform: "none",
                  padding: "8px 0px",
                  width: "50%",
                  marginTop: "20px",
                  boxShadow: "none",
                  color: "white",
                  fontWeight: "600",
                  "&:hover": { background: "4c6fff", color: "white" },
                }}
                onClick={() => {
                  handleLogOut();
                }}
              >
                Logout
              </Button>
            </div>

            {/* <div className={classes.pEmailWrap}>
              <div className={classes.pEmail}>
                Email Address
                <TextField
                  placeholder="Category Name"
                  id="outlined-size-small"
                  size="small"
                  variant="standard"
                  //   value={emailName}
                  //   onChange={handleEmailName}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    marginTop: "8px",
                    width: "100%",
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
              </div>
              <div className={classes.pVerify}>Verified</div>
            </div> */}
            {/* <div className={classes.pButtonL}>Update Brand</div> */}
          </div>
          <div className={classes.pRight}>
            <div className={classes.pDetailsField}>
              Username
              <TextField
                placeholder="Username"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={stringify && stringify.username}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Phone Number
              <TextField
                placeholder="Phone Number"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={stringify && stringify.contact}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Company Name
              <TextField
                placeholder="Company Name"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={stringify && stringify.companyDetails}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Address
              <TextField
                placeholder="Address"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={stringify && stringify.address}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              City
              <TextField
                placeholder="City"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={stringify && stringify.city}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>

            {/* <div className={classes.pButtonR}></div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;

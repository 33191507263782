export const data = [
  {
    email_campaign_name: "Summer Sale Campaign",
    number_of_recipients: 10000,
    opened_emails: 5000,
    clicked_emails: 1000,
  },
  {
    email_campaign_name: "New Product Announcement",
    number_of_recipients: 15000,
    opened_emails: 8000,
    clicked_emails: 2000,
  },
  {
    email_campaign_name: "Holiday Discount Offer",
    number_of_recipients: 12000,
    opened_emails: 6000,
    clicked_emails: 1500,
  },
  {
    email_campaign_name: "Flash Sale Reminder",
    number_of_recipients: 8000,
    opened_emails: 4000,
    clicked_emails: 800,
  },
  {
    email_campaign_name: "Newsletter Subscription",
    number_of_recipients: 20000,
    opened_emails: 10000,
    clicked_emails: 2500,
  },
  {
    email_campaign_name: "VIP Exclusive Offer",
    number_of_recipients: 5000,
    opened_emails: 3000,
    clicked_emails: 500,
  },
  {
    email_campaign_name: "Product Review Request",
    number_of_recipients: 18000,
    opened_emails: 9000,
    clicked_emails: 1800,
  },
  {
    email_campaign_name: "Abandoned Cart Reminder",
    number_of_recipients: 14000,
    opened_emails: 7000,
    clicked_emails: 1200,
  },
  {
    email_campaign_name: "Customer Feedback Survey",
    number_of_recipients: 25000,
    opened_emails: 12000,
    clicked_emails: 3000,
  },
  {
    email_campaign_name: "Limited Time Offer",
    number_of_recipients: 6000,
    opened_emails: 3500,
    clicked_emails: 700,
  },
];

export const pricing = {
  plan1: {
    planName: "Free",
    price: { Monthly: "0", Yearly: "0" },
    features: [
      "2000 contacts",
      "10,000 Emails",
      "Insightful Analytics",
      "Logs retained for 10 days",
      "Support within 1week",
      "Dynamic Templates",
    ],
  },
  plan2: {
    planName: "Basic",
    price: { Monthly: "5,999", Yearly: "4,799" },
    features: [
      "20,000 contacts",
      "2,00,000 Emails",
      "Insightful Analytics",
      "Dynamic Templates",
      "Access to premium templates",
      "Logs retained for 1 months",
      "Guarenteed support within 2 working days",
      "Enotifiers API access",
      "Deliverability Insights",
    ],
  },
  plan3: {
    planName: "Standard",
    price: { Monthly: "11,999", Yearly: "9,599" },
    features: [
      "50,000 contacts",
      "5,00,000 Emails",
      "Insightful Analytics",
      "Dynamic Templates",
      "Access to premium templates",
      "3 subusers support",
      "Logs retained for 3 months",
      "Guarenteed support within 24hrs",
      "Enotifiers API access",
      "Deliverability Insights",
    ],
  },
  plan4: {
    planName: "Professional",
    price: { Monthly: "22,999", Yearly: "18,999" },
    features: [
      "2,00,000 contacts",
      "20,00,000 Emails",
      "Insightful Analytics",
      "Dynamic Templates",
      "Access to premium templates",
      "6 subusers support",
      "Logs retained for 1 year",
      "Campaign report download",
      "Guarenteed support within 6hrs",
      "Enotifiers API access",
      "Deliverability Insights",
      "Technical Support",
    ],
  },
};

export const priceCompare = {
  features: [
    "Number of contacts",
    "Number of Emails",
    "Setup Guide",
    "Info Center",
    "Insightful Analytics",
    "Dynamic Templates",
    "Premium templates",
    "Subusers",
    "Logs retainment",
    "Report download",
    "Personalized support",
    "Dedicated IP",
    "Enotifiers API access",
    "Deliverability Insights",
    "Technical Support",
    "Email validations",
    "Universal Links",
    "Real-Time Analytics",
    "Bounce Classifications",
    "Engagement Tracking",
    "Custom preloaded templates",
    "API Key Permissions",
    "Advance reports",
    "Campaign statistics",
  ],
  free: [
    "2000",
    "10,000",
    "true",
    "true",
    "true",
    "true",
    "false",
    "false",
    "10days",
    "false",
    "false",
    "false",
    "false",
    "true",
    "false",
    "false",
    "true",
    "true",
    "false",
    "true",
    "false",
    "false",
    "true",
    "true",
  ],
  Basic: [
    "20,000",
    "2,00,000",
    "true",
    "true",
    "true",
    "true",
    "true",
    "false",
    "1 month",
    "false",
    "false",
    "true",
    "false",
    "true",
    "false",
    "false",
    "true",
    "true",
    "false",
    "true",
    "false",
    "false",
    "true",
    "true",
  ],
  standard: [
    "50,000",
    "5,00,000",
    "true",
    "true",
    "true",
    "true",
    "true",
    "3 subusers",
    "3 months",
    "false",
    "false",
    "true",
    "true",
    "true",
    "true",
    "false",
    "true",
    "true",
    "true",
    "true",
    "false",
    "true",
    "true",
    "true",
  ],
  Professional: [
    "2,00,000",
    "20,00,000",
    "true",
    "true",
    "true",
    "true",
    "true",
    "6 subusers",
    "1 year",
    "true",
    "true",
    "true",
    "true",
    "true",
    "true",
    "false",
    "true",
    "true",
    "true",
    "true",
    "false",
    "true",
    "true",
    "true",
  ],
 
};

import React, { createElement, useEffect, useState } from "react";
import classes from "./CampaignDetails.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Paper } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment-timezone";
import {
  campaignActivityApi,
  catagoryStatApi,
  createOneSubApi,
  downloadCSVApiCall,
  downloadPdfAPI,
  downloadcsvAPI,
  editCampaignApi,
  getAllTemplateApi,
  getCatagoryDetailsApi,
  getVariablesApi,
  getVerifiedEmailApi,
  getallcatagoryApi,
  logDownloadAPICall,
  promotionalEmailSendApi,
  sendAdminEmailApi,
  subscriberBulkAddApi,
} from "../../Utils/Apihelper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setSourceEmail } from "../../Store/Project/ProjectSlice";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EmailReports from "../EmailReports/EmailReports";
import { jsPDF } from "jspdf";

// interface props {
//   email: string;
// }
const mailType = ["Public", "Private"];
const allCampType = ["promotional", "transactional"];
const optin = ["Single Optin", "Double Optin"];

const CampaignDetails = ({ tabNumber, maildata }) => {
  const [open, setOpen] = React.useState(false);
  const [openSub, setOpenSub] = React.useState(false);
  const [allTemplate, setAllTemplate] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const campaign = useSelector((state) => state.project.campaign);
  const [detailsData, setDetailsData] = React.useState("");
  const [getJson, setgetJson] = useState();
  const [templateName, settemplateName] = useState("");
  const [success, setsuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [alertText, setalertText] = useState("");
  const [campstat, setCampstat] = useState("");
  const [promotional, setPromotional] = useState(false);
  const [allEmails, setAllEmails] = useState([]);
  const [emailSub, setEmailSub] = useState("");
  const [nameSub, setNameSub] = useState("");
  const [catagorySub, setCatagorySub] = useState([]);
  const [tab, setTab] = useState(1);
  // const [catagorValueSub, setCatagorValueSub] = useState([]);
  const [fileUpload, setFileUpload] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);

  // ********
  // const [sourceEmail, setSourceEmail] = useState("");
  const [catName, setCatName] = useState("");
  const [emailtype, setEmailtype] = useState("");
  const [template, settemplate] = useState("");
  const [subject, setsubject] = useState("");
  const [purpose, setpurpose] = useState("");
  const [type, settype] = useState("");
  const [optinValues, setOptinValue] = useState("");
  // ********
  const clone = useSelector((state) => state.project.clone);
  const [activity, setActivity] = useState([]);
  const sourceEmailRX = useSelector((state) => state.project.sourceEmail);
  const subjectRX = useSelector((state) => state.project.subject);
  const purposeRX = useSelector((state) => state.project.purpose);
  const optinvaluesRX = useSelector((state) => state.project.optinvalues);
  const templatenameRX = useSelector((state) => state.project.templatename);
  const typeRX = useSelector((state) => state.project.type);
  const campaignnameRX = useSelector((state) => state.project.campaignNamerx);
  const emailtypeRX = useSelector((state) => state.project.emailtype);
  const [expanded4, setExpanded4] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    handleGetVariable();
  };

  const handleChange4 = (panel) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false);
  };
  // function activeTab(tabNumber) {
  //   setTab(tabNumber);
  // }

  const handleClickOpenSub = () => {
    setOpenSub(true);
  };
  const handleCloseSub = () => {
    setOpenSub(false);
  };

  const handleFileUpload = () => {
    setFileUpload(true);
  };

  const handleFileUploadClose = () => {
    setFileUpload(false);
  };
  fail && setTimeout(() => setFail(false), 4000);
  const handleClose = () => {
    setOpen(false);
  };

  const Token = sessionStorage.getItem("ChangePwToken");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };
  // console.log(campaign);
  const campaignStored = localStorage.getItem("campaign");
  const campaignName = {
    categoryName: campaign ? campaign : campaignStored,
  };
  const templateNameBody = {
    templateName: templateName,
  };
  const catagorValueSub = [campaignStored];
  const requestBodySub = {
    email: emailSub,
    name: nameSub,
    categories: catagorValueSub,
  };

  const createOneSub = () => {
    createOneSubApi(requestBodySub)
      .then((res) => {
        // console.log(res.data);
        handleCloseSub();
        preloadData();
        getallcatagoryApi(config);
        setEmailSub("");
        setNameSub("");
        toast.success("Subscriber added successfully");
        // window.location.reload();
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error("Subscriber couldn't be added");
      });
  };
  useEffect(() => {
    // preloadData();
    // campActivity();
    // handleCatagoryStat();
    // getallcatagoryApi(config)
    //   .then((res) => {
    //     setCatagorySub(res.data.data);
    //   })
    //   .catch((res) => {});
    // getVerifiedEmailApi()
    //   .then((res) => {
    //     setAllEmails(res.data.data);
    //   })
    //   .catch((res) => {});
    // getAllTemplateApi(config)
    //   .then((res) => {
    //     setAllTemplate(res.data.data);
    //   })
    //   .catch((res) => {});
  }, []);

  const handleUploadClick = () => {
    handleCloseSub();
    handleFileUpload();
  };

  const preloadData = () => {
    getCatagoryDetailsApi(campaignName, config)
      .then((res) => {
        //  console.log(res.data);
        setSourceEmail(detailsData.sourceEmail);
        setDetailsData(res.data.data);
        res.data.data.categoryType === "promotional" && setPromotional(true);
        settemplateName(res.data.data.templateName);
      })
      .catch((res) => {
        //  console.log(res.data);
      });
  };

  const handleCatagoryStat = () => {
    catagoryStatApi(campaignName)
      .then((res) => {
        //  console.log(res.data);
        setCampstat(res.data.data);
      })
      .catch((res) => {
        //  console.log(res.data);
      });
  };

  const handleEdit = () => {
    const editBody = {
      categoryName: campaignnameRX,
      sourceEmail: sourceEmailRX,
      templateName: templatenameRX,
      subject: subjectRX,
      purpose: purposeRX,
      type: typeRX,
      optin: optinvaluesRX,
      categoryType: emailtypeRX,
    };
    editCampaignApi(editBody)
      .then((res) => {
        //  console.log(res.data);
        navigate("/email-dashboard/allCampaigns");
        if (res.data.code === "200") {
          toast.success("Campaign Edited");
        } else {
          toast.error(res.data.error.message);
        }
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error("Campaign couldn't be edited");
      });
  };

  // console.log(detailsData.sourceEmail);
  const files = acceptedFiles.map((file) => (
    <li className={classes.filename} key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const handleEmailSending = () => {
    const requestBody1 = new FormData();
    requestBody1.append("categoryName", campaignStored);
    requestBody1.append("templateName", templateName);
    requestBody1.append("file", acceptedFiles[0]);
    const requestBody2 = new FormData();
    requestBody2.append("categoryName", campaignStored);
    requestBody2.append("templateName", templateName);

    if (promotional) {
      promotionalEmailSendApi(requestBody2)
        .then((res) => {
          if (res.data.ResponseMetadata.HTTPStatusCode === 200) {
            toast.success("Campaign sent");
            setOpen(false);
          } else {
            toast.error(res.data.data.message);

            setOpen(false);
          }
        })
        .catch((res) => {
          //  console.log(res.data);
          setFail(true);
          setOpen(false);
        });
    } else {
      sendAdminEmailApi(requestBody1, config)
        .then((res) => {
          // console.log(res.data);

          if (res.data.message) {
            toast.success("Campaign sent");
            setOpen(false);
          } else {
            toast.error(res.data.message);

            setOpen(false);
          }
        })
        .catch((res) => {
          // console.log(res.data);
          toast.error("Couldn't be sent");

          setOpen(false);
        });
    }
  };

  const handleGetVariable = () => {
    getVariablesApi(templateNameBody, config)
      .then((res) => {
        //  console.log(res, "Variablesss");
        setgetJson(res.data);
        res.data.status === "Failed" ? setFail(true) : setFail(false);
        res.data.status === "Failed" && toast.error(res.data.error.message);
        res.data.status !== "Failed" ? setOpen(true) : setOpen(false);
      })
      .catch((res) => {
        setFail(true);
        //  console.log(res.data);
      });
  };

  const campActivity = () => {
    campaignActivityApi(campaignName)
      .then((res) => {
        //  console.log(res.data);
        setActivity(res.data.data);
      })
      .catch((res) => {
        //  console.log(res.data);
      });
  };

  const csvData = "emails,names";
  const handleDownload = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
  };
  const requestBodyBulk = new FormData();
  requestBodyBulk.append("category_names", catagorValueSub);
  requestBodyBulk.append("file", acceptedFiles[0]);

  const bulkAddApi = () => {
    subscriberBulkAddApi(requestBodyBulk)
      .then((res) => {
        //  console.log(res.data);
        toast.success("Subscribers added successfully");
        setFileUpload(false);
        preloadData();
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error("Subscribers couldn't be added");
        setFileUpload(false);
      });
  };
  success && setTimeout(setsuccess(false), 3000);

  // *************************

  const formatDate = (utcTime) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off =  new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime()+localTime._offset * 60 * 1000);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };

  const downloadCsv = (csvContent) => {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Report.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleLogDownload = (id) => {
    const requestBody = {
      mailName: maildata.data.mailName,
      uniqueID: id,
    };
    logDownloadAPICall(requestBody)
      .then((res) => {
        //  console.log(res.data);
        downloadCsv(res.data);
        toast.success("Download started");
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error("Failed");
      });
  };

  // *****************

  const handleLogCSVDownload = (id) => {
    const requestBody = {
      mailName: maildata.data.mailName,
      uniqueID: id,
    };
    downloadcsvAPI(requestBody)
      .then((res) => {
        //  console.log(res.data);
        downloadCsv(res.data);
        toast.success("Download started");
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error(res.response.data.message);
      });
  };

  const handleLogPdfDownload = (id) => {
    const requestBody = {
      mailName: maildata.data.mailName,
      uniqueID: id,
    };
    downloadPdfAPI(requestBody)
      .then((res) => {
        //  console.log(res.data);
        // downloadCsv(res.data);
        // const pdf = new jsPDF();
        // pdf.text(res.data, 10, 10);
        // pdf.save("report.pdf");
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.pdf");
        document.body.appendChild(link);
        link.click();
        toast.success("Download started");
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error(res.response.data.message);
      });
  };

  return (
    <>
      <Toaster />
      {maildata.overAllStats && tabNumber === 2 && (
        <div className={classes.subscriberInfoWrapper}>
          <Box mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#4C6FFF" }}
                  >
                    {maildata?.overAllStats.Send
                      ? maildata?.overAllStats.Send
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Total Sent</div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#5FC322" }}
                  >
                    {maildata.overAllStats.Delivery
                      ? maildata.overAllStats.Delivery
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Delivered</div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#5FC322" }}
                  >
                    {maildata.overAllStats.Open
                      ? maildata.overAllStats.Open
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Open Mails</div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#5FC322" }}
                  >
                    {maildata.overAllStats.Click
                      ? maildata.overAllStats.Click
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Clicked</div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#F57920" }}
                  >
                    {maildata.overAllStats.DeliveryDelay
                      ? maildata.overAllStats.DeliveryDelay
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Delivery Delay</div>
                </div>
              </Grid>

              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#F57920" }}
                  >
                    {maildata.overAllStats.Bounce
                      ? maildata.overAllStats.Bounce
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Bounce</div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#F57920" }}
                  >
                    {maildata.overAllStats.Complaint
                      ? maildata.overAllStats.Complaint
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Complaint</div>
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.data1}>
                  <div
                    className={classes.dataNumInfo}
                    style={{ color: "#F57920" }}
                  >
                    {maildata.overAllStats["Rendering Failure"]
                      ? maildata.overAllStats["Rendering Failure"]
                      : 0}
                  </div>

                  <div className={classes.dataFooter}>Rendering Failure</div>
                </div>
              </Grid>
            </Grid>

            <p className={classes.emailInfoText}>Sent Emails Info</p>
            <div className={classes.section3Accordion}>
              {maildata.data.email_info &&
                maildata.data.email_info.length > 0 &&
                maildata.data.email_info.map((a, index) => (
                  <div>
                    <Accordion
                      sx={{ boxShadow: "none", padding: "10px" }}
                      expanded={expanded4 === index}
                      onChange={handleChange4(index)}
                    >
                      <AccordionSummary
                        expandIcon={<KeyboardArrowDownIcon />}
                        aria-controls={`panel1-content${index}`}
                        id={`panel1bh-header${index}`}
                        sx={{
                          background:
                            expanded4 === index ? "rgb(250, 251, 255)" : "",
                          "&:hover": {
                            background: "rgb(250, 251, 255)",
                            borderRadius: "5px",
                          },
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={6}
                            md={1}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <p className={classes.section3AccordionTitle}>
                              {index + 1}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={5}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <p className={classes.section3AccordionTitle}>
                              {formatDate(a.update_time)}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={3}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <div
                              className={classes.tableStatus}
                              style={{
                                backgroundColor:
                                  a?.email_status === "completed"
                                    ? "#5FC322"
                                    : "#4C6FFF",
                                
                              }}
                            >
                              {a?.email_status ? a?.email_status : ""}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={3}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <div
                              className={classes.dwnloadWrap}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <div
                                className={classes.dwnload}
                                onClick={() => {
                                  handleLogCSVDownload(a.id);
                                }}
                              >
                                CSV{" "}
                                <FileDownloadOutlinedIcon fontSize="small" />
                              </div>
                              <div
                                className={classes.dwnload}
                                onClick={() => {
                                  handleLogPdfDownload(a.id);
                                }}
                              >
                                PDF{" "}
                                <FileDownloadOutlinedIcon fontSize="small" />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "10px 0px" }}>
                        <p
                          style={{
                            marginBottom: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            marginLeft: "10px",
                          }}
                        >
                          Id:{" "}
                          <span
                            style={{
                              color:
                                a?.email_status === "completed"
                                  ? "#5FC322"
                                  : "#4C6FFF",
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            {a.id}
                          </span>
                        </p>
                        <p
                          style={{
                            marginBottom: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            marginLeft: "10px",
                          }}
                        >
                          Status:{" "}
                          <span
                            style={{
                              color:
                                a?.email_status === "completed"
                                  ? "#5FC322"
                                  : "#4C6FFF",
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                          >
                            {a?.email_status ? a?.email_status : ""}
                          </span>
                        </p>

                        <Grid container spacing={2}>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#4C6FFF" }}
                              >
                                {a.events?.Send ? a?.events?.Send : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Total Sent
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#5FC322" }}
                              >
                                {a.events?.Delivery ? a.events?.Delivery : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Delivered
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#5FC322" }}
                              >
                                {a.events?.Open ? a.events?.Open : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Open Mails
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#5FC322" }}
                              >
                                {a.events?.Click ? a.events?.Click : 0}
                              </div>

                              <div className={classes.dataFooter}>Clicked</div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#F57920" }}
                              >
                                {a.events?.DeliveryDelay
                                  ? a.events?.DeliveryDelay
                                  : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Delivery Delay
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#F57920" }}
                              >
                                {a.events?.Bounce ? a.events?.Bounce : 0}
                              </div>

                              <div className={classes.dataFooter}>Bounce</div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#F57920" }}
                              >
                                {a.events?.Complaint ? a.events?.Complaint : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Complaint
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#F57920" }}
                              >
                                {a?.events && a?.events["Rendering Failure"]
                                  ? a?.events["Rendering Failure"]
                                  : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Rendering Failure
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#5FC322" }}
                              >
                                {a?.success_email_count
                                  ? a?.success_email_count
                                  : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Success Count
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#F57920" }}
                              >
                                {a?.failure_email_count
                                  ? a?.failure_email_count
                                  : 0}
                              </div>

                              <div className={classes.dataFooter}>
                                Failure Count
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <div className={classes.data1}>
                              <div
                                className={classes.dataNumInfo}
                                style={{ color: "#4C6FFF", fontSize: "16px" }}
                              >
                                {a?.templateName ? a?.templateName : ""}
                              </div>

                              <div className={classes.dataFooter}>
                                Template Name
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              {maildata.data.email_info &&
                maildata.data.email_info.length === 0 && (
                  <div
                    style={{
                      padding: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    No Data Found
                  </div>
                )}
            </div>
          </Box>
        </div>
      )}

      {tabNumber === 3 && (
        // <Box sx={{ flexGrow: 1 }} mt={2}>
        //   <Grid container>
        //     <Grid item xs={12} md={12}>
        //       <div className={classes.activityNotesWrapper}>
        //         <Grid item xs={12} md={12}>
        //           <div className={classes.activityWrapper}>
        //             <div className={classes.label}>Log Details</div>
        //             {/* <div className={classes.activities}>
        //             {activity.length === 0 ? (
        //               activity.map((data) => (
        //                 <>
        //                   <div className={classes.activityDate}>
        //                     {data.date}
        //                   </div>
        //                   {data.activities.map((res) => (
        //                     <>
        //                       <div className={classes.gActions}>
        //                         {res.time.slice(11, 16)} &nbsp; This
        //                         Campaign was{" "}
        //                         {res.eventType === "open"
        //                           ? "Opened by"
        //                           : res.eventType === "delivery"
        //                           ? "Delivered to"
        //                           : "Clicked by"}
        //                         <span className={classes.actionHeader}>
        //                           {" "}
        //                           {res.destinationMail.map((camp) => (
        //                             <>{camp}</>
        //                           ))}
        //                         </span>{" "}
        //                         .
        //                       </div>
        //                     </>
        //                   ))}
        //                 </>
        //               ))
        //             ) : (
        //               <div className={classes.no}>
        //                 No campaign activities yet.
        //               </div>
        //             )}
        //           </div> */}
        //             <div className={classes.Logs}>
        //               <div className={classes.LogHeader}>
        //                 <div className={classes.sentSection}>
        //                   Emails Sent On
        //                 </div>
        //                 <div className={classes.download}>
        //                   DownLoad Logs
        //                 </div>
        //               </div>
        //               {maildata.data.email_info &&
        //                 maildata.data.email_info.length > 0 &&
        //                 maildata.data.email_info.map((a, index) => (
        //                   <div className={classes.LogsContent}>
        //                     <div className={classes.sentSection}>
        //                       {formatDate(a.update_time)}
        //                     </div>
        //                     <div className={classes.download}>
        //                       <IconButton
        //                         onClick={() => {
        //                           handleLogDownload(a.id);
        //                         }}
        //                       >
        //                         <FileDownloadIcon />
        //                       </IconButton>
        //                     </div>
        //                   </div>
        //                 ))}
        //               {maildata.data.email_info &&
        //                 maildata.data.email_info.length === 0 && (
        //                   <div
        //                     style={{
        //                       padding: "50px",
        //                       display: "flex",
        //                       alignItems: "center",
        //                       justifyContent: "center",
        //                       color: "grey",
        //                       fontSize: "14px",
        //                       fontWeight: 600,
        //                     }}
        //                   >
        //                     No Logs Found
        //                   </div>
        //                 )}
        //             </div>
        //           </div>
        //         </Grid>
        //         {/* <Grid item xs={6} md={7}>
        //         <div className={classes.notesWrapper}>
        //           <div className={classes.label}>
        //             {promotional ? "Subscribers" : "Sent"} &nbsp;
        //             {detailsData &&
        //             detailsData.subscribers &&
        //             detailsData.subscribers.length === 0
        //               ? 0
        //               : detailsData &&
        //                 detailsData.subscribers &&
        //                 detailsData.subscribers.length}
        //           </div>
        //           <div className={classes.notesHead}>
        //             {promotional
        //               ? detailsData &&
        //                 detailsData.subscribers &&
        //                 detailsData.subscribers.map((sub, i) => (
        //                   <h4>
        //                     {i + 1}. &nbsp; {sub}
        //                   </h4>
        //                 ))
        //               : detailsData.sent &&
        //                 detailsData.sent.map((date) => (
        //                   <>
        //                     <div className={classes.sentDate}>
        //                       {date.date}
        //                     </div>
        //                     <div className={classes.sent}>
        //                       {date.mail_list &&
        //                         date.mail_list.map((sub, i) => (
        //                           <h4>
        //                             {i + 1}. &nbsp; {sub}
        //                           </h4>
        //                         ))}
        //                     </div>
        //                   </>
        //                 ))}
        //           </div>
        //         </div>
        //       </Grid> */}
        //       </div>
        //     </Grid>
        //   </Grid>
        // </Box>
        <EmailReports logs={true} maildata={maildata} />
      )}

      {/* ************** */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send Email</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {promotional ? (
              "Are you sure you want to send this promotional email campaign ?"
            ) : (
              <>
                <section className="container">
                  <div {...getRootProps()} className={classes.uploadArea}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <FileUploadIcon fontSize="large" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input {...getInputProps()} />
                      <p>Select a CSV file to Upload or Drag and Drop</p>
                    </div>
                  </div>
                  <aside>
                    <h4>{files}</h4>
                  </aside>
                </section>

                <div className={classes.sendWrap}>
                  <div>
                    {" "}
                    <a
                      href={`data:text/csv;charset=utf-8,${escape(getJson)}`}
                      download="filename.csv"
                      className={classes.or}
                    >
                      Download Sample
                    </a>
                  </div>
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEmailSending} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSub}
        onClose={handleCloseSub}
        aria-labelledby="draggable-dialog-title"
        fullWidth={fullWidth}
      >
        <DialogTitle
          style={{ cursor: "move", fontSize: "30px" }}
          id="draggable-dialog-title"
        >
          <div className={classes.addSubTitle}>Add New Subscriber</div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.addSubNote}>
            Want to Subscribe more than one person at a time?{" "}
            <a onClick={handleUploadClick} style={{ cursor: "pointer" }}>
              Import a list
            </a>
          </div>
          <DialogContentText sx={{ mt: "20px" }}>
            <div className={classes.addSubWrap}>
              <TextField
                required
                id="outlined-required"
                label="Email"
                fullWidth
                size="small"
                value={emailSub}
                onChange={(event) => setEmailSub(event.target.value)}
              />
              <div className={classes.addSubFlex}>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  fullWidth
                  size="small"
                  value={nameSub}
                  onChange={(event) => setNameSub(event.target.value)}
                />
              </div>
              {/* <Autocomplete
                multiple
                id="combo-box-demo"
                options={catagorySub}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Group" />
                )}
                onChange={(event, newValue) => {
                  setCatagorValueSub(newValue);
                }}
                value={catagorValueSub}
              /> */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseSub}>
            Cancel
          </Button>
          <Button onClick={createOneSub}>Add </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fileUpload}
        onClose={handleFileUploadClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth={fullWidth}
      >
        <DialogTitle
          style={{ cursor: "move", fontSize: "30px" }}
          id="draggable-dialog-title"
        >
          <div
            className={classes.addSubTitle}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Upload File
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mt: "20px" }}>
            <section className="container">
              <div
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              ></div>

              <div {...getRootProps()} className={classes.uploadArea}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FileUploadIcon fontSize="large" />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input {...getInputProps()} />
                  <p>Select a CSV file to Upload or Drag and Drop</p>
                </div>
              </div>
              <aside>
                <h4>{files}</h4>
              </aside>
            </section>
            <div className={classes.sendWrap} onClick={handleDownload}>
              <div>
                {" "}
                <a className={classes.or}>Download Sample</a>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" autoFocus onClick={handleFileUploadClose}>
            Cancel
          </Button>
          <Button color="secondary" onClick={bulkAddApi}>
            Upload{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetails;

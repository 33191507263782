import React, { useEffect, useState } from "react";
import styles from "./Support.module.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getFAQAPIcall } from "../../../Utils/Apihelper";

export default function Support() {
  const QAData = {
    title: "e-notifiers FAQ’s",
    des: "If you are new to e-notifiers or looking for solutions to your queries, this guide will help you learn more about the platform & its features.",
    section1: {
      title: "Getting started with e-notifiers",
      qaarray1: [
        {
          question: "What is e-notifiers & how does it work?",
          answer:
            "E-notifiers are a notification system designed to keep users informed about updates, events, or important information. They work by allowing you to set up and customize notifications for specific events or triggers. Users typically subscribe to these notifications, and when the specified event occurs, they receive alerts through email, SMS, or other communication channels. E-notifiers are a convenient way to stay connected and informed in real-time.",
        },
        {
          question:
            "Do i need to be a designer or developer to use e-notifiers?",
          answer:
            "No, e-notifiers are designed for easy use by anyone. You don't need to be a designer or developer to effectively utilize our notification system.",
        },
      ],
      qaarray2: [
        {
          question: "Is there a cost associated with using e-notifiers?",
          answer:
            "E-notifiers has 2 plans for you 7000 Inr & 10,000 Inr. can be used for free as a newbie.",
        },
        {
          question: "Is there a cost associated with using e-notifiers?",
          answer:
            "E-notifiers has 2 plans for you 7000 Inr & 10,000 Inr. can be used for free as a newbie.",
        },
        {
          question: "Is there a cost associated with using e-notifiers?",
          answer:
            "E-notifiers has 2 plans for you 7000 Inr & 10,000 Inr. can be used for free as a newbie.",
        },
      ],
    },
    section2: {
      title: "You have Questions, We have answers.",
     
    },

  };

  const [hoveredDiv, setHoveredDiv] = useState(0);
  const [selectedDiv, setSelectedDiv] = useState(0);
  const [data, setData] = useState<any>([]);
  const [sectionData, setSectionData] = useState<any>([]);

  // const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expanded, setExpanded] = useState<Array<boolean>>([]);
  const [expanded2, setExpanded2] = React.useState<string | false>(false);
  const [expanded3, setExpanded3] = React.useState<string | false>(false);
  const [expanded4, setExpanded4] = React.useState<string | false>(false);

  useEffect(() => {
    getFAQAPIcall()
      .then((res: any) => {
        console.log(res.data);
        setData(res.data.data.popularQuestions);
        setSectionData(res.data.data.categories);
      })
      .catch((error) => {});
  }, []);


  const handleChange = (index: number) => () => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };
  // const handleChange2 =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded2(isExpanded ? panel : false);
  //   };
  // const handleChange3 =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded3(isExpanded ? panel : false);
  //   };
  // const handleChange4 =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded4(isExpanded ? panel : false);
  //   };

  const handleMouseEnterSection2 = (index: any) => {
    setHoveredDiv(index);
  };
  const handleMouseLeaveSection2 = (index: any) => {
    if (selectedDiv === 0) {
      setHoveredDiv(0);
    } else {
      setHoveredDiv(7);
    }
  };

  const handleClickDiv = (index: any) => {
    setSelectedDiv(index);
    setHoveredDiv(index);
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.topContainer}>
        <p className={styles.topContainerTitle}>{QAData.title}</p>
        <p className={styles.topContainerDes}>{QAData.des}</p>
      </div>
      <div className={styles.section1}>
        <p className={styles.section1Title}>{QAData.section1.title}</p>
        <div className={styles.section1QA}>
          <div className={styles.section1QALeft}>
            {QAData.section1.qaarray1.map((a: any) => (
              <div className={styles.section1QALeftContent}>
                <div className={styles.section1QALeftContentDot}></div>
                <div className={styles.section1QALeftContentdiv}>
                  <p className={styles.section1QALeftContentdivtitle}>
                    {a.question}
                  </p>
                  <p className={styles.section1QALeftContentdivDesc}>
                    {a.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.section1QARight}>
            {QAData.section1.qaarray2.map((a: any) => (
              <div className={styles.section1QALeftContent}>
                <div className={styles.section1QALeftContentDot}></div>
                <div className={styles.section1QALeftContentdiv}>
                  <p className={styles.section1QALeftContentdivtitle}>
                    {a.question}
                  </p>
                  <p className={styles.section1QALeftContentdivDesc}>
                    {a.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.section2}>
        <p className={styles.section2Title}> {QAData.section2.title} </p>
        <div className={styles.section2Content}>
          <div className={styles.section2ContentLeft}>
            {data.map((a: any, index: any) => (
              <div
                className={styles.section2ContentLeftContent}
                onMouseEnter={() => {
                  handleMouseEnterSection2(index);
                }}
                onMouseLeave={() => {
                  handleMouseLeaveSection2(index);
                }}
                style={{
                  background:
                    hoveredDiv === index || selectedDiv === index
                      ? "#FAFBFF"
                      : "white",
                }}
                onClick={() => {
                  handleClickDiv(index);
                }}
              >
                <div className={styles.section2ContentLeftContentDotTitle}>
                  <div
                    className={styles.section2ContentLeftContentDot}
                    style={{
                      backgroundColor:
                        hoveredDiv === index || selectedDiv === index
                          ? "#236AF2"
                          : "rgba(35, 106, 242, 0.20)",
                    }}
                  ></div>
                  <p className={styles.section2ContentLeftContentTitle}>
                    {a.question}
                  </p>
                </div>
                <div>
                  <ChevronRightIcon
                    sx={{
                      color:
                        hoveredDiv === index || selectedDiv === index
                          ? "#236AF2"
                          : "rgba(35, 106, 242, 0.20)",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.section2ContentRight}>
            <p className={styles.section2ContentRightTitle}>
              {data[selectedDiv]?.question}
            </p>
            <p className={styles.section2ContentRightDesc}>
              {data[selectedDiv]?.answer}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
      {sectionData.map((a: any, sectionIndex: number) => (
        <div key={sectionIndex} className={styles.section3}>
          <p className={styles.section3title}>{a?.name}</p>
          <div className={styles.section3AccordionSection}>
            {a.questions.map((q: any, index: number) => (
              <div key={index} className={styles.section3Accordion}>
                <Accordion
                  sx={{ boxShadow: "none", padding: "10px" }}
                  expanded={expanded[sectionIndex]}
                  onChange={handleChange(sectionIndex)}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel1-content${index}`}
                    id={`panel1bh-header${index}`}
                  >
                    <p className={styles.section3AccordionTitle}>
                      {q.question}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className={styles.section3AccordionDesc}>
                      {q.answer}
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      ))}

        {/* <div className={styles.section3}>
          <p className={styles.section3title}>{QAData.section4.title}</p>
          <div className={styles.section3AccordionSection}>
            {QAData.section4.dropDownData.map((a: any, index: any) => (
              <div className={styles.section3Accordion}>
                <Accordion
                  sx={{ boxShadow: "none", padding: "10px" }}
                  expanded={expanded2 === index}
                  onChange={handleChange2(index)}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel1-content${index}`}
                    id={`panel1bh-header${index}`}
                  >
                    <p className={styles.section3AccordionTitle}>{a.title}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className={styles.section3AccordionDesc}>{a.desc}</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.section3}>
          <p className={styles.section3title}>{QAData.section5.title}</p>
          <div className={styles.section3AccordionSection}>
            {QAData.section5.dropDownData.map((a: any, index: any) => (
              <div className={styles.section3Accordion}>
                <Accordion
                  sx={{ boxShadow: "none", padding: "10px" }}
                  expanded={expanded3 === index}
                  onChange={handleChange3(index)}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel1-content${index}`}
                    id={`panel1bh-header${index}`}
                  >
                    <p className={styles.section3AccordionTitle}>{a.title}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className={styles.section3AccordionDesc}>{a.desc}</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.section3}>
          <p className={styles.section3title}>{QAData.section6.title}</p>
          <div className={styles.section3AccordionSection}>
            {QAData.section6.dropDownData.map((a: any, index: any) => (
              <div className={styles.section3Accordion}>
                <Accordion
                  sx={{ boxShadow: "none", padding: "10px" }}
                  expanded={expanded4 === index}
                  onChange={handleChange4(index)}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel1-content${index}`}
                    id={`panel1bh-header${index}`}
                  >
                    <p className={styles.section3AccordionTitle}>{a.title}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className={styles.section3AccordionDesc}>{a.desc}</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapsed: false,
  onHover: false,
  dashboard: " ",
  otpFlag: false,
  subscriber: "",
  campaign: "",
  loadTemplateName: "",
  editTemp: false,
  loadTemplateJson: {},
  tableData: [],
  clone: false,
  sourceEmail: "",
  subject: "",
  purpose: "",
  optinvalues: "",
  templatename: "",
  type: "",
  campaignNamerx: "",
  emailtype: "",
  pPhone: "",
  pName: "",
  pEmail: "",
  refVar: null,
  demoModel: false,
  editEmailData: {},
  editEmailImage: "",
  countDownEnd:false,
  premiumModal:false,
};

export const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    resetaAllProjectDetails: () => initialState,

    setCollapsed(state, action) {
      state.collapsed = action.payload;
    },
    setOnHover(state, action) {
      state.onHover = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
    setOtpFlag(state, action) {
      state.otpFlag = action.payload;
    },
    setSubscriber(state, action) {
      state.subscriber = action.payload;
    },
    setCampaign(state, action) {
      state.campaign = action.payload;
    },
    setLoadTemplateName(state, action) {
      state.loadTemplateName = action.payload;
    },
    setLoadTemplateJson(state, action) {
      state.loadTemplateJson = action.payload;
    },
    setTableData(state, action) {
      state.tableData = action.payload;
    },
    setClone(state, action) {
      state.clone = action.payload;
    },
    setSourceEmail(state, action) {
      state.sourceEmail = action.payload;
    },
    setSubject(state, action) {
      state.subject = action.payload;
    },
    setPurpose(state, action) {
      state.purpose = action.payload;
    },
    setOptinvalues(state, action) {
      state.optinvalues = action.payload;
    },
    setTemplatename(state, action) {
      state.templatename = action.payload;
    },
    setEditTemp(state, action) {
      state.editTemp = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setCampaignNamerx(state, action) {
      state.campaignNamerx = action.payload;
    },
    setEmailType(state, action) {
      state.emailtype = action.payload;
    },
    setProfileEmail(state, action) {
      state.pEmail = action.payload;
    },
    setProfilePhone(state, action) {
      state.pPhone = action.payload;
    },
    setProfileName(state, action) {
      state.pName = action.payload;
    },
    setRefvar(state, action) {
      state.refVar = action.payload;
    },
    setDemoModel(state, action) {
      state.refVar = action.payload;
    },
    setEditEmailData(state, action) {
      state.editEmailData = action.payload;
    },
    setEditEmailImage(state, action) {
      state.editEmailImage = action.payload;
    },
    setCountDownEnd(state, action) {
      state.countDownEnd = action.payload;
    },
    setPremiumModal(state, action) {
      state.premiumModal = action.payload;
    },
  },
});

export const {
  setCollapsed,
  setOnHover,
  setDashboard,
  setOtpFlag,
  setSubscriber,
  setCampaign,
  setLoadTemplateName,
  setLoadTemplateJson,
  setTableData,
  setClone,
  setSourceEmail,
  setType,
  setTemplatename,
  setOptinvalues,
  setPurpose,
  setSubject,
  setCampaignNamerx,
  setEmailType,
  setProfileEmail,
  setEditTemp,
  setProfilePhone,
  setProfileName,
  setRefvar,
  setDemoModel,
  setEditEmailData,
  setEditEmailImage,
  setCountDownEnd,
  setPremiumModal
} = projectSlice.actions;

export default projectSlice.reducer;

import React from "react";
import classes from "./Section3.module.scss";

import WPLPim1 from "../../../../assets/Images/WPLPim1.svg";
import WPLPim2 from "../../../../assets/Images/WPLPim2.svg";
import WPLPim3 from "../../../../assets/Images/wplpIm3.svg";
import { motion } from "framer-motion";

const Section3 = () => {
  return (
    <>
      <div className={classes.section3Wrap}>
        <div className={classes.section3Left}>
          <motion.img
            src={WPLPim1}
            alt=""
            className={classes.s3img1}
            initial={{ y: 120, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            // exit={{ y: 30, opacity: 0 }}
            transition={{ delay: 1, duration: 1 }}
          />
          <img src={WPLPim2} alt="" className={classes.s3img2} />
        </div>
        <div className={classes.section3Right}>
          <img src={WPLPim3} alt="" />
          <div className={classes.section3Head}>
            Elevate Engagement with Automated WhatsApp Chatbots
          </div>
          <div className={classes.section3Text}>
            Empower Your Business with Round-the-Clock Support
          </div>
          <div className={classes.section3SubText}>
            Unlock Continuous Interaction by Automating Conversations on Your
            WhatsApp Business Account, Ensuring Availability 24/7
          </div>
        </div>
      </div>
    </>
  );
};

export default Section3;

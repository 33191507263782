import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import classes from "./PremiumModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setPremiumModal } from "../../../Store/Project/ProjectSlice";

const PremiumModal = () => {
  const dispatch = useDispatch();
  const premiumModal = useSelector((state: any) => state.project.premiumModal);

  return (
    <>
      <Dialog
        open={premiumModal}
        PaperProps={{
          sx: { borderRadius: "20px", padding: "0px 10px 10px 10px" },
        }}
      >
        <DialogTitle>Subscribe to Enotifiers</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}></DialogContentText>
          <div>
            <p className={classes.lableText}>
              You need Premium subscription to access this feature
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonsModal}>
            <div
              className={classes.cancelBtn}
              style={{cursor:"pointer"}}
              onClick={() => {
                dispatch(setPremiumModal(false));
              }}
            >
              Dismiss
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PremiumModal;

import { GetPaymentStatusAPICall } from "../../../Utils/Apihelper";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import DoneImage from "../../../assets/Images/doneicon.png";
import ErrorImage from "../../../assets/Images/errorImage.png";
import { useLocation } from "react-router-dom";
import { setCountDownEnd } from "../../../Store/Project/ProjectSlice";

const Phonepay = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const Token = queryParams.get("token");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };
  const Id = queryParams.get("merchantTransactionId");
  const plan = queryParams.get("plan");
  const planType = queryParams.get("planType");

  const [countDown, setCountDown] = useState(3);
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;

  useEffect(() => {
    checkStatus();
    console.log();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCountDown) => prevCountDown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (countDown < 0) {
      setCountDown(3);
      dispatch(setCountDownEnd(true));
    }
  }, [countDown]);

  const checkStatus = () => {
    const obj = {
      merchantTransactionId: Id,
      planName:plan,
      planType:planType
    };

    GetPaymentStatusAPICall(obj, config)
      .then((response: any) => {
        const responseData = response.data;
      })
      .catch((error: any) => {
        console.log("hi");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "150px",
      }}
    >
      {pathName.includes("phonepe/success") ? (
        <>
          <img src={DoneImage} style={{ width: "250px" }} />
          <div style={{ textAlign: "center", padding: "10px 10px" }}>
            <Typography variant="h5"> Payment Success </Typography>
            <Typography style={{ marginTop: "10px" }}>
              Your Payment is successfully executed
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
              The page will close automatically in {countDown} seconds, if not
              close manually to continue.
            </Typography>
          </div>
        </>
      ) : (
        <>
          <img src={ErrorImage} style={{ width: "250px" }} />
          <div style={{ textAlign: "center", padding: "10px 10px" }}>
            <Typography variant="h5"> Payment Failed </Typography>
            <Typography style={{ marginTop: "10px" }}>
              Your Payment has been Failed
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "12px", padding: "0 5px" }}>
              The page will close automatically in {countDown} seconds, if not
              close manually to continue.
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default Phonepay;

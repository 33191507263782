import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import toast, { Toaster } from "react-hot-toast";

interface props {
  openDomainRecordsModal: any;
  handleCloseRecordsModal: any;
  styles: any;
  domainrecordsList: any;
}

export default function DomainRecordsModal({
  openDomainRecordsModal,
  handleCloseRecordsModal,
  styles,
  domainrecordsList,
}: props) {
  const [copiedText, setCopiedText] = useState(null);

  const handleCopiedText = (data: any) => {
    toast.success("Copied");
    // setCopiedText(data);

    // setTimeout(() => {
    //   setCopiedText(null);
    // }, 3000);
  };

  return (
    <div>
      <Toaster />

      <Dialog
        open={openDomainRecordsModal}
        onClose={handleCloseRecordsModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "0px 0px 10px 0px",
            width: "100%",
            maxWidth: "100%",
          },
        }}
        style={{ width: "90vw", margin: "auto" }}
      >
        <DialogTitle id="alert-dialog-title">C NAME domain records</DialogTitle>
        <DialogContent>
          {domainrecordsList.length > 0 &&
            domainrecordsList.map((a: any, index: any) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={styles.groupnew}
                  style={{ margin: "10px 0px", width: "50%" }}
                >
                  <p className={styles.lableText}>Key</p>
                  <div style={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      variant="standard"
                      size="small"
                      name="client_id"
                      placeholder="Enter Client Id"
                      value={a.key}
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          height: "40px",
                          padding: "0px 10px",
                          borderRadius: "5px",
                        },
                      }}
                      sx={{
                        background: "#EDF2F7",
                        borderRadius: "5px",
                        "& label.Mui-focused": {
                          color: "grey",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#EDF2F7",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EDF2F7",
                          },
                        },
                      }}
                    />

                    <CopyToClipboard
                      text={a.key}
                      onCopy={() => {
                        handleCopiedText(index);
                      }}
                    >
                      <IconButton>
                        {copiedText === index ? (
                          <DoneIcon />
                        ) : (
                          <ContentCopyIcon />
                        )}
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </div>
                
                <div
                  className={styles.groupnew}
                  style={{ margin: "10px 0px", width: "50%" }}
                >
                  <p className={styles.lableText}>Value</p>
                  <div style={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      variant="standard"
                      size="small"
                      name="client_id"
                      placeholder="Enter Client Id"
                      value={a.value}
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          height: "40px",
                          padding: "0px 10px",
                          borderRadius: "5px",
                        },
                      }}
                      sx={{
                        background: "#EDF2F7",
                        borderRadius: "5px",
                        "& label.Mui-focused": {
                          color: "grey",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#EDF2F7",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EDF2F7",
                          },
                        },
                      }}
                    />

                    <CopyToClipboard
                      text={a.value}
                      onCopy={() => {
                        handleCopiedText(index + 1);
                      }}
                    >
                      <IconButton>
                        {copiedText === index + 1 ? (
                          <DoneIcon />
                        ) : (
                          <ContentCopyIcon />
                        )}
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                </div>
                {/* {index === domainrecordsList.length - 1 ? (
                  ""
                ) : (
                  <Divider sx={{ margin: "20px 0px" }} />
                )} */}
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRecordsModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import classes from "./SubscriberInfo.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Paper, Tooltip, IconButton } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Moment from "react-moment";
import moment from "moment-timezone";
import { styled } from "@mui/system";
import {
  deleteSubscriberApi,
  eachSubscriberAPICall,
  getAllSubscribersApi,
  getOneSubApi,
  subscriberActivityApi,
} from "../../Utils/Apihelper";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import TimelineIcon from "@mui/icons-material/Timeline";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import SubscribeDataTableComp from "../../components/SubscribeDataTableComp/SubscribeDataTableComp";

const tabledata = [
  {
    campaignName: "Ncb cancel demo",
    time: "11:26:06 Am (Draft)",
    recipientName: "Ada Wong",
    sendTime: "11:26:06 Am",
    delivered: "50",
    openRate: "100%",
    clickRate: "100%",
    unsubscribed: "10",
  },
  {
    campaignName: "Ncb cancel demo",
    time: "11:26:06 Am (Draft)",
    recipientName: "Ada Wong",
    sendTime: "11:26:06 Am",
    delivered: "50",
    openRate: "100%",
    clickRate: "100%",
    unsubscribed: "10",
  },
  {
    campaignName: "Ncb cancel demo",
    time: "11:26:06 Am (Draft)",
    recipientName: "Ada Wong",
    sendTime: "11:26:06 Am",
    delivered: "50",
    openRate: "100%",
    clickRate: "100%",
    unsubscribed: "10",
  },
  {
    campaignName: "Ncb cancel demo",
    time: "11:26:06 Am (Draft)",
    recipientName: "Ada Wong",
    sendTime: "11:26:06 Am",
    delivered: "50",
    openRate: "100%",
    clickRate: "100%",
    unsubscribed: "10",
  },
  {
    campaignName: "Ncb cancel demo",
    time: "11:26:06 Am (Draft)",
    recipientName: "Ada Wong",
    sendTime: "11:26:06 Am",
    delivered: "50",
    openRate: "100%",
    clickRate: "100%",
    unsubscribed: "10",
  },
  {
    campaignName: "Ncb cancel demo",
    time: "11:26:06 Am (Draft)",
    recipientName: "Ada Wong",
    sendTime: "11:26:06 Am",
    delivered: "50",
    openRate: "100%",
    clickRate: "100%",
    unsubscribed: "10",
  },
];

function SubscriberInfo({ email }) {
  const [data, setData] = useState(tabledata);
  const [stat, setStat] = useState([]);
  const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(10);
  const [paginationCount, setpaginationCount] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    getEachSubscriber();
  }, []);
  const ProfileDetails = sessionStorage.getItem("subscribeData");
  const stringify = JSON.parse(ProfileDetails);

  // const handlePageChange = (value, pagenumber) => {
  //   setpaginationCount(pagenumber);
  // };

  // const subActivity = () => {
  //   const requestBody = {
  //     subscriberEmail: email,
  //   };
  //   subscriberActivityApi(requestBody)
  //     .then((res) => {
  //      // console.log(res.data);
  //       setActivity(res.data.data);
  //     })
  //     .catch((res) => {
  //      // console.log(res.data);
  //     });
  // };

  // const getOneSubDetails = () => {
  //   const requestBody = {
  //     email: email,
  //   };
  //   getOneSubApi(requestBody)
  //     .then((res) => {
  //      // console.log(res.data);
  //       setData(res.data.data.subscriber_details);
  //       setStat(res.data.data);
  //     })
  //     .catch((res) => {
  //      // console.log(res.data);
  //     });
  // };

  // const handleDeleteSubscriber = (email) => {
  //   const requestBody = {
  //     email: email,
  //   };
  //   deleteSubscriberApi(requestBody)
  //     .then((res) => {
  //      // console.log(res.data);
  //       navigate("/email-dashboard/subscriberList");
  //       toast.success("Subscriber Deleted");
  //     })
  //     .catch((res) => {
  //     //  console.log(res.data);
  //       toast.error("Couldn't delete Subscriber");
  //     });
  // };

  const columns = [
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Campaign Name</div>
      ),
      selector: "campaignName",
      width: "13rem",
      sortable: true,
      cell: (row) => (
        <div>
          <span style={{ fontSize: "13px", fontWeight: 500, color: "black" }}>
            {row.campaignName}
          </span>{" "}
          <p style={{ fontSize: "12px" }}>{row.time}</p>
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Recipient name </div>
      ),
      selector: "recipientName",
      width: "10rem",
      sortable: true,
      cell: (row) => (
        <div style={{ fontSize: "13px" }}>{row.recipientName}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Send Time</div>,
      selector: "sendTime",
      sortable: true,
      cell: (row) => <div style={{ fontSize: "13px" }}>{row.sendTime}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Delivered </div>,
      selector: "delivered",
      sortable: true,
      cell: (row) => <div style={{ fontSize: "13px" }}>{row.delivered}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Open Rate</div>,
      selector: "openRate",
      sortable: true,
      cell: (row) => <div style={{ fontSize: "13px" }}>{row.openRate}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Click Rate</div>,
      selector: "clickRate",
      sortable: true,
      cell: (row) => <div style={{ fontSize: "13px" }}>{row.clickRate}</div>,
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Unsubscribed </div>
      ),
      selector: "unsubscribed",
      sortable: true,
      cell: (row) => <div style={{ fontSize: "13px" }}>{row.unsubscribed}</div>,
    },
  ];

  const getEachSubscriber = () => {
    const ProfileDetails = sessionStorage.getItem("subscribeData");
    const stringify = JSON.parse(ProfileDetails);

    eachSubscriberAPICall(stringify.email)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        toast.error("Error fetching");
      });
  };

  const formatDate = (utcTime) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off = new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);
    const options = {
      
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };

  return (
    <>
      <Toaster />
      <div className={classes.subscriberInfoWrapper}>
        <div className={classes.topbarContainer}>
          <div className={classes.emailContainer}>
            {/* <div className={classes.email}>{email}</div> */}
            <div className={classes.activeStatus}>Overview</div>
            <p className={classes.email}>
              {stringify.email}{" "}
              <span
                style={{ color: "#425466", fontSize: "12px", fontWeight: 400 }}
              ></span>
            </p>
          </div>
          {/* <div className={classes.unsubscribe}>
            <button
              className={classes.topbarRight}
              
            >
              <DeleteIcon sx={{ color: "#ee5252" }} fontSize="small" />
            </button>
          </div> */}
        </div>
        <Box sx={{ flexGrow: 1 }} mb={4}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2.4}>
              <div className={classes.data1}>
                <div
                  className={classes.dataNumInfo}
                  style={{ color: "#4C6FFF" }}
                >
                  {data?.statistics?.Delivery ? data?.statistics?.Delivery : 0}
                </div>

                <div className={classes.dataFooter}>Delivery</div>
              </div>
            </Grid>
            <Grid item xs={6} md={2.4}>
              <div className={classes.data1}>
                <div
                  className={classes.dataNumInfo}
                  style={{ color: "#5FC322" }}
                >
                  {data?.statistics?.Delivery ? data?.statistics?.Delivery : 0}
                </div>

                <div className={classes.dataFooter}>Send</div>
              </div>
            </Grid>

            <Grid item xs={6} md={2.4}>
              <div className={classes.data1}>
                <div
                  className={classes.dataNumInfo}
                  style={{ color: "#5FC322" }}
                >
                  {data?.statistics?.Open ? data?.statistics?.Open : 0}
                </div>

                <div className={classes.dataFooter}>Open </div>
              </div>
            </Grid>
            <Grid item xs={6} md={2.4}>
              <div className={classes.data1}>
                <div
                  className={classes.dataNumInfo}
                  style={{ color: "#5FC322" }}
                >
                  {data?.statistics?.Click ? data?.statistics?.Click : 0}
                </div>

                <div className={classes.dataFooter}>Click</div>
              </div>
            </Grid>
            <Grid item xs={6} md={2.4}>
              <div className={classes.data1}>
                <div
                  className={classes.dataNumInfo}
                  style={{ color: "#F57920" }}
                >
                  {data?.lenOfGroup ? data?.lenOfGroup : 0}
                </div>

                <div className={classes.dataFooter}>Groups</div>
              </div>
            </Grid>
            {/* <Grid item xs={6} md={2}>
              <div className={classes.data1}>
                <div
                  className={classes.dataNumInfo}
                  style={{ color: "#F57920" }}
                >
                  {stat.spam_rate ? stat.spam_rate : 0}%
                </div>

                <div className={classes.dataFooter}>Spam Rate</div>
              </div>
            </Grid> */}
          </Grid>
        </Box>
        <div className={classes.subDetailsScroll}>
          {data?.group?.map((data, index) => {
            return (
              <>
                <div className={classes.subscriberDetails}>
                  <div className={classes.subscriberDetailsTitle}>
                    <span style={{ fontWeight: "500", fontSize: "14px" }}>
                      Group {index + 1}
                    </span>
                  </div>

                  <Box sx={{ flexGrow: 1 }} mb={4}>
                    <Grid container spacing={4}>
                      <Grid item xs={6} md={4}>
                        <div className={classes.container}>
                          <div className={classes.label}>Group Name</div>
                          <div className={classes.data}>{data.groupName}</div>
                        </div>
                      </Grid>
                      {data.subscribersList.map((ele) => (
                        <>
                          <Grid item xs={6} md={4}>
                            <div className={classes.container}>
                              <div className={classes.label}>Email Address</div>

                              <div className={classes.data}>
                                {ele?.email ? ele.email : "-"}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <div className={classes.container}>
                              <div className={classes.label}>Name</div>

                              <div
                                className={classes.data}
                                style={{ textTransform: "capitalize" }}
                              >
                                {ele?.name ? ele.name : "-"}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <div className={classes.container}>
                              <div className={classes.label}>Date</div>

                              <div className={classes.data}>
                                {ele?.dateTime ? (
                                  <Moment
                                    date={ele?.dateTime}
                                    format="YYYY/MM/DD"
                                  />
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <div className={classes.container}>
                              <div className={classes.label}>Time</div>

                              <div className={classes.data}>
                                {ele?.dateTime
                                  ? formatDate(ele?.dateTime)
                                  : "-"}
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={6} md={4}>
                            <div className={classes.container}>
                              <div className={classes.label}>Source</div>

                              <div className={classes.data}>
                                {ele?.source ? ele.source : "-"}
                              </div>
                            </div>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Box>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SubscriberInfo;

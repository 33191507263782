import React, { useState } from "react";
import signUp from "../../assets/Images/registerrightimage.svg";
import classes from "./Register.module.scss";
import { useFormik } from "formik";
import { signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Utils/constants";
import { userRegisterApi } from "../../Utils/Apihelper";
import { Toaster, toast } from "react-hot-toast";
import Footer from "../../containers/Footer/Footer";
import { CircularProgress, IconButton, OutlinedInput } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const initialValues = {
  userName: "",
  email: "",
  password: "",
  contactNumber: "",
  companyname: "",
  address: "",
  city: "",
  country: "",
  zipcode: "",
};

const Register = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [showAccountNumber, setShowAccountNumber] = React.useState(false);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      // console.log("heyyyyyyyy", values, errors);

      const requestBody = {
        userName: values.userName,
        email: values.email,
        password: values.password,
        address: values.address,
        zipcode: values.zipcode,
        city: values.city,
        country: values.country,
        contactNumber: parseInt(values.contactNumber),
        companyName: values.companyname,
      };
      setloading(true);
      userRegisterApi(requestBody)
        .then((res) => {
          //  console.log(res.data);
          setloading(false);
          const Token = res.data.data.accessToken;
          sessionStorage.setItem("Token", Token);
          sessionStorage.setItem("mobile", values.contactNumber.toString());
          sessionStorage.setItem("email", values.email);
          navigate("/RegisterOtp");
        })
        .catch((error: any) => {
          // console.log(error.response.data.error.message);
          setloading(false);
          toast.error(
            error.response.data?.error?.message
              ? error.response.data.error.message
              : "Error Registering"
          );
        });
    },
  });

  const handleKeyPress = (event: any) => {
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  };
  const handleClickShowAccountNumber = () =>
    setShowAccountNumber((show) => !show);

  return (
    <>
      <Toaster />
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <p className={classes.modalTitle}>
                Sign up to E-Notifiers - it's free!
              </p>
              <p className={classes.modalDesc}></p>
              <div className={classes.loginData}>
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputBlockAlign}>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="text"
                        name="userName"
                        id="userName"
                        placeholder="Enter UserName"
                        value={values.userName}
                        onChange={(event: any) => {
                          const newValue = event.target.value.replace(
                            /\s/g,
                            "_"
                          );

                          setFieldValue("userName", newValue);
                        }}
                        // onChange={handleChange}
                        onBlur={handleBlur}
                        // size="lg"
                        
                        inputProps={{
                          maxLength: 25,
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />
                      {errors.userName && touched.userName ? (
                        <p className={classes.formError}>{errors.userName}</p>
                      ) : null}
                    </div>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="email"
                        autoComplete="off"
                        name="email"
                        id="email"
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 50,
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        // size="small"
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />
                      {errors.email && touched.email ? (
                        <p className={classes.formError}>{errors.email}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className={classes.inputBlockAlign}>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="number"
                        autoComplete="off"
                        name="contactNumber"
                        id="contactNumber"
                        // size="small"
                        placeholder="Enter Phone Number"
                        inputProps={{
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        value={values.contactNumber}
                        onChange={(event: any) => {
                          const inputValue = event.target.value;
                          if (inputValue.length <= 10) {
                            setFieldValue("contactNumber", inputValue);
                          }
                        }}
                        onBlur={handleBlur}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />

                      {errors.contactNumber && touched.contactNumber ? (
                        <p className={classes.formError}>
                          {errors.contactNumber}
                        </p>
                      ) : null}
                    </div>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type={showAccountNumber ? "text" : "password"}
                        autoComplete="off"
                        name="password"
                        id="password"
                        // size="lg"
                        placeholder="Create Password"
                     
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 20,
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                        endAdornment={
                          <IconButton
                            onClick={handleClickShowAccountNumber}
                            sx={{ zIndex: 100 }}
                          >
                            {showAccountNumber ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        }
                      />

                      {errors.password && touched.password ? (
                        <p className={classes.formError}>{errors.password}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className={classes.inputBlockAlign}>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="text"
                        autoComplete="off"
                        name="companyname"
                        id="companyname"
                        // size="lg"
                        placeholder="Enter Company Name"
                        value={values.companyname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />

                      {errors.companyname && touched.companyname ? (
                        <p className={classes.formError}>
                          {errors.companyname}
                        </p>
                      ) : null}
                    </div>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="text"
                        autoComplete="off"
                        name="country"
                        id="country"
                        // size="lg"
                        placeholder="Enter Country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 50,
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />

                      {errors.country && touched.country ? (
                        <p className={classes.formError}>{errors.country}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.inputBlockAlign}>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="text"
                        autoComplete="off"
                        name="address"
                        id="address"
                        // size="lg"
                        placeholder="Enter Company Address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 50,
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />

                      {errors.address && touched.address ? (
                        <p className={classes.formError}>{errors.address}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.inputBlockAlign}>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="text"
                        autoComplete="off"
                        name="city"
                        id="city"
                        // size="lg"
                        placeholder="Enter City"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 30,
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />

                      {errors.city && touched.city ? (
                        <p className={classes.formError}>{errors.city}</p>
                      ) : null}
                    </div>
                    <div className={classes.inputBlock}>
                      <OutlinedInput
                        type="number"
                        autoComplete="off"
                        name="zipcode"
                        id="zipcode"
                        // size="lg"
                        placeholder="Enter PinCode"
                        value={values.zipcode}
                        onChange={(event: any) => {
                          const inputValue = event.target.value;
                          if (inputValue.length <= 6) {
                            setFieldValue("zipcode", inputValue);
                          }
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 6,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        sx={{
                          background: "transparent",
                          borderRadius: "6px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "4c6fff",
                          },
                          "--Input-focusedHighlight": "4c6fff",
                          "&::before": {
                            transition: "box-shadow .15s ease-in-out",
                          },
                          "&:focus-within": {
                            borderColor: "4c6fff",
                          },
                        }}
                      />
                      {errors.zipcode && touched.zipcode ? (
                        <p className={classes.formError}>{errors.zipcode}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.modalButtons}>
                    <Button
                      type="submit"
                      size="lg"
                      style={{
                        padding: "14px 50px",
                        borderRadius: "5px",
                      }}
                      loading={loading}
                      sx={{
                        backgroundColor: "4c6fff",
                        "&:disabled": {
                          backgroundColor: "#b9c7ff",
                        },
                        "&:hover": {
                          backgroundColor: "4c6fff",
                        },
                      }}
                      loadingIndicator={
                        <CircularProgress
                          sx={{ color: "#4c6fff" }}
                          size={"25px"}
                        />
                      }
                    >
                      SIGNUP
                    </Button>
                    <a href="#" className={classes.link}></a>
                  </div>
                  {/* </Link> */}
                </form>
                <p className={classes.signUp}>
                  By continuing, you agree to E-notifiers’s{" "}
                  <a href="#" className={classes.link}>
                    <Link
                      to={"/termsandconditions"}
                      style={{ textDecoration: "underline", color: "4c6fff" }}
                    >
                      Terms of Service.
                    </Link>
                  </a>
                </p>
                <p className={classes.signUp}>
                  Read our
                  <a href="#" className={classes.link}>
                    <Link
                      to={"/refundpolicy"}
                      style={{
                        textDecoration: "underline",
                        color: "4c6fff",
                        marginLeft: "5px",
                      }}
                    >
                      Refund Policy
                    </Link>
                  </a>
                </p>
                <p className={classes.signUp}>
                  Already have an account?{" "}
                  <a href="#" className={classes.link}>
                    <Link
                      to={"/login"}
                      style={{ textDecoration: "underline", color: "4c6fff" }}
                    >
                      Sign In now
                    </Link>
                  </a>
                </p>
              </div>
            </div>
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

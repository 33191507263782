import React, { useEffect, useState } from "react";
import styles from "./CreateGroup.module.scss";
import previewImage from "../../../assets/Images/PreviewCampaignImage.svg";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import Radio from "@mui/material/Radio";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AlertBox from "../../AlertBox/AlertBox";
import {
  getAllGroupsApi,
  createGroupApi,
  IGetAllGroupsResponse,
  ISingleGroup,
  ICreateGroupResponse,
} from "../../../Utils/Apihelper";
import toast, { Toaster } from "react-hot-toast";

const options3 = ["AM", "PM"];
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "55vh",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
};
const mobilestyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  outline: "0",
  //   p: 4,
};

interface props {
  open: any;
  handleClose: any;
  tab: number;
}

const CreateGroup = ({ open, handleClose, tab }: props) => {
  const [selectedValue, setSelectedValue] = useState("Create");
  const [textValue, settextValue] = useState("");
  const [groups, setgroups] = useState<ISingleGroup[]>([]);
  const [selectedGroup, setselectedGroup] = useState<string>("");
  const [changeStyles, setChangeStyles] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getAllGroups();
    if (window.innerWidth < 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
  }, []);

  useEffect(() => {
    if (
      (textValue === "" && selectedValue === "Create") ||
      (selectedGroup === "" && selectedValue === "Import")
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    // if (selectedGroup === "" && selectedValue === "Import") {
    //   setDisabled(true);
    // } else {
    //   setDisabled(false);
    // }
  }, [textValue, selectedGroup]);

  const getAllGroups = () => {
    getAllGroupsApi()
      .then((response) => {
        const responseData: IGetAllGroupsResponse = response.data;
        setgroups(responseData.data);
        // console.log("getAllGroupsApi", responseData);
      })
      .catch((error: any) => {});
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedGroup !== "") {
      setselectedGroup("");
    }
    if (textValue !== "") {
      settextValue("");
    }
    setSelectedValue(event.target.value);
  };

  const handleTextChange = (event: any) => {
    const inputValue = event.target.value;
    const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
    if (isValidInput) {
      settextValue(inputValue);
    }
  };

  const handleSubmit = () => {
    if (selectedValue === "Create") {
      handleCreateGroup();
    } else {
      navigate("import");
    }
  };

  // console.log("selectedValue", selectedValue);

  const handleCreateGroup = () => {
    const request: ISingleGroup = {
      groupName: textValue.trim(),
    };
    createGroupApi(request)
      .then((response: any) => {
        const responseData: ICreateGroupResponse = response.data;
        toast.success(responseData.data.message);
        // window.alert(responseData.data.message);
        navigate("/email-dashboard/subscriberList/import");
        setSelectedValue("Import");
      })
      .catch((error: any) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <>
      <Toaster />
      <Modal
        disableAutoFocus={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={changeStyles ? mobilestyles : style}>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div className={styles.header}>Import Contacts </div>
              <div className={styles.para}>
                Import your contacts into an existing group or create a new
                group and proceed with the import process.
              </div>
              <div className={styles.radioWrap}>
                Type
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue="Create"
                    name="radio-buttons-group"
                    onChange={handleChange}
                    value={selectedValue}
                  >
                    <FormControlLabel
                      value="Create"
                      control={<Radio />}
                      label="Create a new group"
                    />
                    {tab === 1 && (
                      <FormControlLabel
                        value="Import"
                        control={<Radio />}
                        label="Import In existing group "
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                {/* <div className={styles.radio1}>Create a new group</div>
              <div className={styles.radio2}>Import In existing group </div> */}
              </div>
              <div className={styles.input}>
                {selectedValue === "Create" ? (
                  <>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        marginBottom: "0px",
                        marginLeft: "5px",
                      }}
                    >
                      Create a group name
                    </p>
                    <TextField
                      placeholder="Group Name"
                      id="outlined-size-small"
                      size="small"
                      variant="standard"
                      inputProps={{ maxLength: 50 }}
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          height: "40px",
                          padding: "0px 10px",
                          borderRadius: "5px",
                        },
                      }}
                      sx={{
                        marginTop: "12px",
                        width: "-webkit-fill-available",
                        borderRadius: "5px",
                        background: "#EDF2F7",
                        "& label.Mui-focused": {
                          color: "grey",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "red",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#EDF2F7",
                          },
                          "&.Mui-focused-visible fieldset": {
                            borderColor: "#EDF2F7",
                          },
                        },
                      }}
                      value={textValue}
                      onChange={handleTextChange}
                    />
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        marginBottom: "0px",
                        marginLeft: "5px",
                      }}
                    >
                      Select a group name
                    </p>

                    <Autocomplete
                      size="small"
                      value={selectedGroup}
                      onChange={(event: any, newValue: any) => {
                        setselectedGroup(newValue);
                        sessionStorage.setItem("selectedGroupImport", newValue);
                      }}
                      options={groups.map((a: ISingleGroup) => a.groupName)}
                      sx={{
                        marginTop: "10px",
                        borderRadius: "10px",
                        width: "-webkit-fill-available",
                        background: "#EDF2F7",
                        outline: "none",
                        fontSize: "16px",
                        fontWeight: 500,
                        padding: "0 10px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-input": {
                          // padding: "4.5px 4px 2.5px 8px",
                          paddingTop: "2px !important",
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                      popupIcon={
                        <KeyboardArrowDownIcon
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                        />
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select the group" />
                      )}
                    />
                  </>
                )}
              </div>
            </div>
            {disabled && (
              <div style={{ fontSize: "12px", color: "red", padding: "10px" }}>
                Please enter all the fields*
              </div>
            )}
            <div className={styles.buttons}>
              <div className={styles.cancelBtn} onClick={handleClose}>
                Cancel
              </div>
              <div
                className={styles.createBtn}
                onClick={() => {
                  disabled === false && handleSubmit();
                }}
              >
                {selectedValue}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateGroup;

import React, { useEffect, useRef, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import classes from "./DashSidebar.module.scss";
import Avatar from "@mui/material/Avatar";

import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import grid from "../../../assets/Images/grid.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useDispatch, useSelector } from "react-redux";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Tooltip from "@mui/material/Tooltip";
import EmailIcon from "@mui/icons-material/Email";
import TextField from "@mui/material/TextField";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useLocation, useNavigate } from "react-router-dom";
import { editUserApi, getUserDetailsAPICall } from "../../../Utils/Apihelper";
import SmsIcon from "@mui/icons-material/Sms";
import { Toaster, toast } from "react-hot-toast";
import LogoutIcon from "@mui/icons-material/Logout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CLOGO from "../../../assets/Images/CLOGO.svg";
import CNOTIFIERS from "../../../assets/Images/CNOTIFIERS.svg";
import MailIcon from "@mui/icons-material/Mail";
import GroupsIcon from "@mui/icons-material/Groups";
import TryIcon from "@mui/icons-material/Try";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import {
  setCollapsed,
  setPremiumModal,
} from "../../../Store/Project/ProjectSlice";
import { IconButton } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import product1 from "../../../assets/Images/product1.svg";
import product2 from "../../../assets/Images/product3.svg";
import product3 from "../../../assets/Images/product2.svg";

const product = [
  {
    text: "Email",
    img: product2,
    upcoming: false,
  },
  {
    text: "SMS",
    img: product1,
    upcoming: true,
  },
  {
    text: "Whatsapp",
    img: product3,
    upcoming: true,
  },
];

const DashSidebar = () => {
  const collapsed = useSelector((state) => state.project.collapsed);
  const dispatch = useDispatch();
  const [profileMenu, setprofileMenu] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [clientId, setClientId] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedkey, setIsCopiedkey] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [showKey, setShowKey] = React.useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [menuOpen, setmenuOpen] = useState(false);
  const [cred, setCred] = useState("");
  const [changeStyles, setChangeStyles] = useState(false);
  const dataRef = useRef(null);
  const planName = sessionStorage.getItem("planName");

  const ProfileDetails = JSON.parse(sessionStorage.getItem("profileDetails"));
  const productroute = sessionStorage.getItem("product");

  useEffect(() => {
    getUserDetails();
    const handleClickOutside = (event) => {
      if (dataRef.current && !dataRef.current.contains(event.target)) {
        setprofileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getUserDetails = () => {
    getUserDetailsAPICall()
      .then((res) => {
        sessionStorage.setItem(
          "planName",
          res.data.data.subscription.products.email.subscriptionType
        );
      })
      .catch((err) => {});
  };

  const handleClickProduct = (product) => {
    // sessionStorage.setItem("product", product);
    setprofileMenu(false);

    if (product !== "Email") {
      toast("Hey,this feature is under development!!", {
        icon: "⏱️",
      });
    }
    // if (product === "Email") {
    //   navigate("/email-dashboard");
    // } else {
    //   navigate("/sms-dashboard");
    // }
  };

  // console.log("ProfileDetails", ProfileDetails);

  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const pathName = useLocation().pathname;
  const handleClose = () => {
    setOpen(false);
    setShowKey(false);
  };
  const Token = sessionStorage.getItem("ChangePwToken");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };

  useEffect(() => {
    // handleCred();
    if (window.innerWidth < 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
  }, []);
  // const handleGenerateApi = () => {
  //   generateApikeyApi(config)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setClientId(res.data["data"]["credentials"]["client-id"]);
  //       setApiKey(res.data["data"]["credentials"]["client-secret"]);
  //     })
  //     .catch((res) => {
  //       console.log(res.data);
  //     });
  // };
  // const handleCred = () => {
  //   getCredApi()
  //     .then((res) => {
  //       // console.log(res.data);
  //       setCred(res.data.data);
  //       setEmail(res.data.data.email);
  //       setPhone(res.data.data.contactNumber);
  //       setUsername(res.data.data.userName);
  //       // dispatch(setProfileEmail(cred.email));
  //       // dispatch(setProfileName(cred.userName));
  //       // dispatch(setProfilePhone(cred.contactNumber));
  //     })
  //     .catch((res) => {
  //       // console.log(res.data);
  //     });
  // };

  const handleEdit = () => {
    const reqBody = {
      userName: username,
      email: email,
      contactNumber: phone,
    };
    editUserApi(reqBody)
      .then((res) => {
        // console.log(res.data);
        if (res.data.code === "200") {
          toast.success("Edit successfull");
        } else {
          toast.error(res.data.data.message);
        }
      })
      .catch((res) => {
        // console.log(res.data);
      });
  };
  const handleLogOut = () => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Logout?",

      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => Logout(),
        },
      ],
    });
  };

  const Logout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };
  return (
    <>
      <Toaster />
      <div
        className={classes.sidebar}
        style={{ transform: changeStyles && collapsed ? "translateX(0)" : "" }}
      >
        <Sidebar
          collapsed={!changeStyles ? collapsed : !collapsed}
          backgroundColor="#FFFFFF"
          width="220px"
          transitionDuration={600}
        >
          <div className={classes.wrapper}>
            <div className={classes.wrapperTop}>
              <div className={classes.brand}>
                {!collapsed && (
                  <div
                    className={classes.prWrap}
                    style={{ backgroundColor: profileMenu ? "#e9f0ff" : "" }}
                    onClick={() => {
                      setprofileMenu(!profileMenu);
                    }}
                  >
                    <img className={classes.prGrid} src={grid} alt="" />
                    {profileMenu && (
                      <>
                        <div className={classes.prMenu} ref={dataRef}>
                          {product.map((a) => (
                            <>
                              <div
                                className={classes.prEach}
                                onClick={() => handleClickProduct(a.text)}
                                style={{
                                  backgroundColor:
                                    a.text === productroute ? "#e9f0ff" : "",
                                }}
                              >
                                <div className={classes.prIcn}>
                                  <img src={a.img} alt="" />
                                </div>
                                <div className={classes.prText}>{a.text}</div>
                                {/* <div className={classes.upcoming}>
                                  Upcoming!
                                </div> */}
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}

                <img src={CLOGO} className={classes.clogo} />
                {!collapsed && (
                  <img src={CNOTIFIERS} className={classes.cname} />
                )}
                <img src={CNOTIFIERS} className={classes.cnameMobile} />
                {!collapsed && (
                  <p
                    style={{
                      fontSize: "10px",
                      marginLeft: "6px",
                      marginTop: "5px",
                    }}
                  >
                    (Beta)
                  </p>
                )}
              </div>
              {/* <div className={classes.prWrap}>
                <img src={grid} alt="" />
                <div>
                  Email
                </div>
              </div> */}
              {productroute === "Email" ? (
                <>
                  {" "}
                  <div className={classes.sidebarMenu}>
                    <Menu
                      menuItemStyles={{
                        button: {
                          color: "#A0AEC0",

                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "#4c6fff",
                          },
                        },
                      }}
                    >
                      <p
                        className={classes.maintext}
                        style={{
                          marginLeft: collapsed ? "-15px" : "0",
                          fontSize: collapsed ? "8px" : "",
                        }}
                      >
                        MAIN&nbsp;MENU
                      </p>
                      <Tooltip
                        title={collapsed ? "Dashboard" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo1">
                          <MenuItem
                            active
                            icon={
                              <AutoAwesomeMosaicIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/analytics"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("analytics");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background: pathName.includes(
                                "/email-dashboard/analytics"
                              )
                                ? "transparent"
                                : "",
                              color: pathName.includes(
                                "/email-dashboard/analytics"
                              )
                                ? "#4c6fff"
                                : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Dashboard
                          </MenuItem>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "Templates" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo3">
                          <MenuItem
                            active
                            icon={
                              <TryIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/allTemplates"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("allTemplates");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background:
                                pathName.includes(
                                  "/email-dashboard/allTemplates"
                                ) ||
                                pathName.includes("/email-dashboard/templates")
                                  ? "transparent"
                                  : "",
                              color:
                                pathName.includes(
                                  "/email-dashboard/allTemplates"
                                ) ||
                                pathName.includes("/email-dashboard/templates")
                                  ? "#4c6fff"
                                  : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Templates
                          </MenuItem>
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={collapsed ? "Subscribers" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo4">
                          <MenuItem
                            active
                            icon={
                              <GroupsIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/subscriberList"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("subscriberList");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background: pathName.includes(
                                "/email-dashboard/subscriberList"
                              )
                                ? "transparent"
                                : "",
                              color: pathName.includes(
                                "/email-dashboard/subscriberList"
                              )
                                ? "#4c6fff"
                                : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Subscribers
                          </MenuItem>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "Emails" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo5">
                          <MenuItem
                            active
                            icon={
                              <MailIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/emails"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("emails");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background: pathName.includes(
                                "/email-dashboard/emails"
                              )
                                ? "transparent"
                                : "",
                              color: pathName.includes(
                                "/email-dashboard/emails"
                              )
                                ? "#4c6fff"
                                : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Emails
                          </MenuItem>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "Reports" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo6">
                          <MenuItem
                            active
                            icon={
                              <AssessmentOutlinedIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/reports"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("reports");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background:
                                pathName.includes("/email-dashboard/reports") ||
                                pathName.includes("/email-dashboard/reports")
                                  ? "transparent"
                                  : "",
                              color:
                                pathName.includes("/email-dashboard/reports") ||
                                pathName.includes("/email-dashboard/reports")
                                  ? "#4c6fff"
                                  : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Reports
                          </MenuItem>
                        </div>
                      </Tooltip>
                      {/* <Tooltip
                    title={collapsed ? "Scheduling" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <MenuItem
                      active
                      icon={
                        <PersonAddAlt1Icon
                          sx={{
                            color: pathName.includes("/email-dashboard/scheduling")
                              ? "#4c6fff"
                              : "",
                            fontSize: "20px",
                          }}
                        />
                      }
                      onClick={() => {
                        navigate("scheduling");
                        if (changeStyles) {
                          dispatch(setCollapsed(!collapsed));
                        }
                      }}
                      style={{
                        background: pathName.includes("/email-dashboard/scheduling")
                          ? "transparent"
                          : "",
                        color: pathName.includes("/email-dashboard/scheduling")
                          ? "#4c6fff"
                          : "",
                        fontWeight: 400,
                        fontSize: "13px",
                        borderRadius: "12px",
                        height: "40px",
                      }}
                    >
                      {" "}
                      Scheduling
                    </MenuItem>
                  </Tooltip> */}
                      <Tooltip
                        title={collapsed ? "Configuration" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo7">
                          <MenuItem
                            active
                            icon={
                              <GroupWorkIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/aws"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("aws");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background: pathName.includes(
                                "/email-dashboard/aws"
                              )
                                ? "transparent"
                                : "",
                              color: pathName.includes("/email-dashboard/aws")
                                ? "#4c6fff"
                                : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Configuration
                          </MenuItem>
                        </div>
                      </Tooltip>

                      <p
                        className={classes.maintext}
                        style={{
                          marginTop: "20px",
                          marginLeft: collapsed ? "-10px" : "0",
                          fontSize: collapsed ? "8px" : "",
                        }}
                      >
                        MORE
                      </p>
                      <Tooltip
                        title={collapsed ? "Support" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <HeadsetMicIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/support"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("support");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/support"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/support")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Support
                        </MenuItem>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "account" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <ManageAccountsIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/account"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("account");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/account"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/account")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Account
                        </MenuItem>
                      </Tooltip>
                      {/* <a
                        href="https://docs.enotifiers.com/"
                        style={{
                          textDecoration: "none",
                          color: pathName.includes("/email-dashboard/apiDocs")
                            ? "#4c6fff"
                            : "inherit",
                        }}
                      > */}
                      <Tooltip
                        title={collapsed ? "API Docs" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <AutoAwesomeMotionIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/apiDocs"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            // navigate("apiDocs");
                            if (planName === "Basic") {
                              dispatch(setPremiumModal(true));
                            } else {
                              window.open(
                                "https://docs.enotifiers.com/introduction/",
                                "_blank"
                              );
                            }
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/apiDocs"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/apiDocs")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          API Docs
                        </MenuItem>
                      </Tooltip>

                      <Tooltip
                        title={collapsed ? "Pricing" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <CurrencyRupeeIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/pricing"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("pricing");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/pricing"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/pricing")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Pricing
                        </MenuItem>
                      </Tooltip>
                    </Menu>
                  </div>
                </>
              ) : productroute === "SMS" ? (
                <>
                  {" "}
                  <div className={classes.sidebarMenu}>
                    <Menu
                      menuItemStyles={{
                        button: {
                          color: "#A0AEC0",

                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "#4c6fff",
                          },
                        },
                      }}
                    >
                      <p
                        className={classes.maintext}
                        style={{
                          marginLeft: collapsed ? "-15px" : "0",
                          fontSize: collapsed ? "8px" : "",
                        }}
                      >
                        MAIN&nbsp;MENU
                      </p>
                      <Tooltip
                        title={collapsed ? "Dashboard" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo1">
                          <MenuItem
                            active
                            icon={
                              <AutoAwesomeMosaicIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/analytics"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("analytics");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background: pathName.includes(
                                "/email-dashboard/analytics"
                              )
                                ? "transparent"
                                : "",
                              color: pathName.includes(
                                "/email-dashboard/analytics"
                              )
                                ? "#4c6fff"
                                : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Dashboard
                          </MenuItem>
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={collapsed ? "Emails" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo4">
                          <MenuItem
                            active
                            icon={
                              <MailIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/emails"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("emails");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background: pathName.includes(
                                "/email-dashboard/emails"
                              )
                                ? "transparent"
                                : "",
                              color: pathName.includes(
                                "/email-dashboard/emails"
                              )
                                ? "#4c6fff"
                                : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Emails
                          </MenuItem>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "Reports" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <div id="demo6">
                          <MenuItem
                            active
                            icon={
                              <AssessmentOutlinedIcon
                                sx={{
                                  color: pathName.includes(
                                    "/email-dashboard/reports"
                                  )
                                    ? "#4c6fff"
                                    : "",
                                  fontSize: "20px",
                                }}
                              />
                            }
                            onClick={() => {
                              navigate("reports");
                              if (changeStyles) {
                                dispatch(setCollapsed(!collapsed));
                              }
                            }}
                            style={{
                              background:
                                pathName.includes("/email-dashboard/reports") ||
                                pathName.includes("/email-dashboard/reports")
                                  ? "transparent"
                                  : "",
                              color:
                                pathName.includes("/email-dashboard/reports") ||
                                pathName.includes("/email-dashboard/reports")
                                  ? "#4c6fff"
                                  : "",
                              fontWeight: 400,
                              fontSize: "13px",
                              borderRadius: "12px",
                              height: "40px",
                            }}
                          >
                            {" "}
                            Reports
                          </MenuItem>
                        </div>
                      </Tooltip>
                      {/* <Tooltip
                    title={collapsed ? "Scheduling" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <MenuItem
                      active
                      icon={
                        <PersonAddAlt1Icon
                          sx={{
                            color: pathName.includes("/email-dashboard/scheduling")
                              ? "#4c6fff"
                              : "",
                            fontSize: "20px",
                          }}
                        />
                      }
                      onClick={() => {
                        navigate("scheduling");
                        if (changeStyles) {
                          dispatch(setCollapsed(!collapsed));
                        }
                      }}
                      style={{
                        background: pathName.includes("/email-dashboard/scheduling")
                          ? "transparent"
                          : "",
                        color: pathName.includes("/email-dashboard/scheduling")
                          ? "#4c6fff"
                          : "",
                        fontWeight: 400,
                        fontSize: "13px",
                        borderRadius: "12px",
                        height: "40px",
                      }}
                    >
                      {" "}
                      Scheduling
                    </MenuItem>
                  </Tooltip> */}
                      <Tooltip
                        title={collapsed ? "Configuration" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <GroupWorkIcon
                              sx={{
                                color: pathName.includes("/email-dashboard/aws")
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("aws");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/aws"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/aws")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Configuration
                        </MenuItem>
                      </Tooltip>

                      <p
                        className={classes.maintext}
                        style={{
                          marginTop: "20px",
                          marginLeft: collapsed ? "-10px" : "0",
                          fontSize: collapsed ? "8px" : "",
                        }}
                      >
                        MORE
                      </p>
                      <Tooltip
                        title={collapsed ? "Support" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <HeadsetMicIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/support"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("support");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/support"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/support")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Support
                        </MenuItem>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "Settings" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <SettingsIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/settings"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("settings");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/settings"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes(
                              "/email-dashboard/settings"
                            )
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Settings
                        </MenuItem>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "API Docs" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <AutoAwesomeMotionIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/apiDocs"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("apiDocs");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                            // window.open(
                            //   "https://connect.thefirstock.com/docsv3/docs/get-started/packages-instalation/",
                            //   "_blank"
                            // );
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/apiDocs"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/apiDocs")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          API Docs
                        </MenuItem>
                      </Tooltip>
                      <Tooltip
                        title={collapsed ? "Pricing" : ""}
                        placement="right"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [2, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <MenuItem
                          active
                          icon={
                            <MonetizationOnIcon
                              sx={{
                                color: pathName.includes(
                                  "/email-dashboard/pricing"
                                )
                                  ? "#4c6fff"
                                  : "",
                                fontSize: "20px",
                              }}
                            />
                          }
                          onClick={() => {
                            navigate("pricing");
                            if (changeStyles) {
                              dispatch(setCollapsed(!collapsed));
                            }
                          }}
                          style={{
                            background: pathName.includes(
                              "/email-dashboard/pricing"
                            )
                              ? "transparent"
                              : "",
                            color: pathName.includes("/email-dashboard/pricing")
                              ? "#4c6fff"
                              : "",
                            fontWeight: 400,
                            fontSize: "13px",
                            borderRadius: "12px",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Pricing
                        </MenuItem>
                      </Tooltip>
                    </Menu>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div
                className={classes.profileContainer}
                // onClick={handleClickOpen}
              >
                <Avatar
                  alt=""
                  src=""
                  style={{ background: "#4c6fff", color: "white" }}
                >
                  {ProfileDetails &&
                    ProfileDetails.username &&
                    ProfileDetails.username[0].toUpperCase()}
                </Avatar>{" "}
                <div className={classes.data}>
                  {!collapsed && (
                    <>
                      <div className={classes.name}>
                        {ProfileDetails && ProfileDetails.username}
                      </div>
                      <div className={classes.email}>
                        {ProfileDetails && ProfileDetails.email}
                      </div>
                      {/* {!collapsed && (
                        <IconButton
                          size="small"
                          sx={{
                            position: "absolute",
                            right: "3px",
                            top: "5px",
                          }}
                          onClick={handleLogOut}
                        >
                          <Tooltip title="Logout" placement="right">
                            <ExitToAppIcon
                              sx={{ fontSize: "20px" }}
                              fontSize="small"
                            />
                          </Tooltip>
                        </IconButton>
                      )} */}
                    </>
                  )}
                  {changeStyles && (
                    <>
                      <div className={classes.name}>
                        {ProfileDetails && ProfileDetails.username}
                      </div>
                      <div className={classes.email}>
                        {" "}
                        {ProfileDetails && ProfileDetails.email}
                      </div>
                      {/* <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          right: "3px",
                          top: "5px",
                        }}
                        onClick={handleLogOut}
                      >
                        <Tooltip title="Logout" placement="right">
                          <ExitToAppIcon sx={{ fontSize: "20px" }} />
                        </Tooltip>
                      </IconButton> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { borderRadius: "6px" } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ pt: "1.5rem", fontWeight: "bold" }}
        >
          Profile
        </DialogTitle>
        <DialogContent
          sx={{
            p: "2rem",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
              borderRadius: "8px",
            },
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <div className={classes.profileModal}>
              <div className={classes.leftprofile}>
                <Avatar alt="" src="" style={{ height: "60px", width: "60px" }}>
                  F
                </Avatar>{" "}
                <div className={classes.userDetails}>
                  <div className={classes.username}>{cred.userName}</div>
                  <div className={classes.userEmail}>{cred.email}</div>
                </div>
              </div>
              <div className={classes.rightProfile}>
                <div
                  className={classes.editBtn}
                  onClick={() => {
                    setEdit(!edit);
                    edit && handleEdit();
                  }}
                >
                  <div className={classes.edit}>{edit ? "Save" : "Edit"}</div>
                  <EditNoteIcon fontSize="small" />
                </div>

                <div
                  className={classes.editBtn}
                  onClick={() => {
                    navigate("/");
                    sessionStorage.clear();
                    localStorage.clear();
                  }}
                >
                  <div className={classes.edit}>Logout</div>
                  <LogoutIcon fontSize="small" />
                </div>
              </div>
            </div>
            <div className={classes.profileModal2}>
              <div className={classes.profileModalTop}>
                <div className={classes.leftprofile}>Personel Information</div>
                <div className={classes.rightProfile}></div>
              </div>
              <div className={classes.personalInfo}>
                <div className={classes.personalInfoLeft}>
                  <div className={classes.infoTitle}>Name</div>
                  {edit ? (
                    <>
                      {" "}
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={username}
                        onChange={(e) => {
                          // dispatch(setProfileName(e.target.value));
                          setUsername(e.target.value);
                        }}
                        size="small"
                        sx={{ mb: "10px", mt: "3px", width: "16rem" }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className={classes.infoData}>{cred.userName}</div>
                    </>
                  )}
                  <div className={classes.infoTitle}>Email</div>
                  {edit ? (
                    <>
                      {" "}
                      <TextField
                        hiddenLabel
                        disabled
                        id="filled-hidden-label-small"
                        value={email}
                        onChange={(e) => {
                          // dispatch(setProfileEmail(cred.email));
                          setEmail(e.target.value);
                        }}
                        size="small"
                        sx={{ mb: "10px", mt: "3px", width: "16rem" }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className={classes.infoData}>{cred.email}</div>
                    </>
                  )}
                </div>
                <div className={classes.personalInfoRight}>
                  <div className={classes.infoTitle}>Phone Number</div>
                  {edit ? (
                    <>
                      {" "}
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={phone}
                        onChange={(e) => {
                          // dispatch(setProfilePhone(e.target.value));
                          setPhone(e.target.value);
                        }}
                        size="small"
                        sx={{ mb: "10px", mt: "3px", width: "16rem" }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className={classes.infoData}>
                        {cred.contactNumber}
                      </div>
                    </>
                  )}

                  <div className={classes.infoTitle}>Company Name</div>
                  {edit ? (
                    <>
                      {" "}
                      <TextField
                        hiddenLabel
                        disabled
                        id="filled-hidden-label-small"
                        value={cred && cred.companyDetails.companyName}
                        size="small"
                        sx={{ mb: "10px", mt: "3px", width: "16rem" }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className={classes.infoData}>
                        {cred && cred.companyDetails.companyName}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.profileModal3}>
              <div className={classes.profileModalTop}>
                <div className={classes.leftprofile}>Api Key</div>
              </div>

              <div className={classes.profileMenu}>
                {showKey ? (
                  <>
                    <div className={classes.apikeyTitle}>
                      <div>Client Id generated</div>
                      {isCopied && (
                        <div className={classes.copied}>Copied!</div>
                      )}
                    </div>

                    <div className={classes.apikeyWrap}>
                      <div className={classes.keyField}>{clientId}</div>
                      <div
                        className={classes.copyField}
                        onClick={() => {
                          navigator.clipboard.writeText(clientId);
                          setIsCopied(true);
                          setTimeout(() => {
                            setIsCopied(false);
                          }, 1000);
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </div>
                    </div>
                    <div className={classes.apikeyTitle}>
                      <div>Client secret key generated</div>
                      {isCopiedkey && (
                        <div className={classes.copied}>Copied!</div>
                      )}
                    </div>

                    <div className={classes.apikeyWrap}>
                      <div className={classes.keyField}>{apiKey}</div>
                      <div
                        className={classes.copyField}
                        onClick={() => {
                          navigator.clipboard.writeText(apiKey);
                          setIsCopiedkey(true);
                          setTimeout(() => {
                            setIsCopiedkey(false);
                          }, 1000);
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className={classes.generateButton}
                      onClick={() => {
                        setShowKey(true);
                      }}
                    >
                      Generate API key
                    </button>
                  </>
                )}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DashSidebar;

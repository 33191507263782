import React, { useEffect, useState } from "react";
import {
  getPaymentHistorysAPICall,
  getPaymentInvoiceAPICall,
  paymentHistoryAPICall,
} from "../../../Utils/Apihelper";
import classes from "./PaymentHistory.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Grid, IconButton, Paper } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { toast } from "react-hot-toast";

interface Payment {
  transactionId: string;
  amount: number;
  state: string;
  responseCode: string;
  paymentInstrument: {
    type: string;
    utr: string;
    upiTransactionId: string;
    cardNetwork: null;
    accountType: string;
  };
  paymentDate: string;
  expiry: string;
  planType: string;
  planName: string;
  orderNumber: string;
}

const PaymentHistory = () => {
  const [paymentdata, setpaymentdata] = useState([]);
  const [expanded4, setExpanded4] = useState(false);

  useEffect(() => {
    getPaymentHistorysAPICall()
      .then((res: any) => {
        setpaymentdata(res.data.data.paymentHistory);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  const handleChange4 = (panel) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false);
  };

  const handleLogPdfDownload = (id) => {
    getPaymentInvoiceAPICall(id)
      .then((res: any) => {
        toast.success("Download started");
        downloadPDF(res.data.data.invoiceBase64);
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error(res.response.data.message);
      });
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "invoice.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const paymentHistoryList: Payment[] = paymentdata.sort(
    (a: Payment, b: Payment) =>
      new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime()
  );

  return (
    <>
      <div className={classes.section3Accordion}>
        {paymentHistoryList &&
          paymentHistoryList.map((a: any, index: any) => (
            <>
              <div>
                <Accordion
                  sx={{ boxShadow: "none", padding: "10px" }}
                  expanded={expanded4 === index}
                  onChange={handleChange4(index)}
                >
                  <AccordionSummary
                    sx={{
                      background: "rgb(250, 251, 255)",
                      "&:hover": {
                        background: "rgb(250, 251, 255)",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        md={0.5}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <p className={classes.section3AccordionTitle}>
                          {index + 1}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <p className={classes.section3AccordionTitle}>
                          <span className={classes.subt}>Id:</span> &nbsp;
                          {a.transactionId}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <p className={classes.section3AccordionTitle}>
                          <span className={classes.subt}>Date:</span> &nbsp;
                          {a.paymentDate && a.paymentDate.slice(0, 10)}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <p className={classes.section3AccordionTitle}>
                          <span className={classes.subt}>Expiry:</span> &nbsp;
                          {a.expiry}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className={classes.tableStatus}
                          style={{
                            backgroundColor:
                              a.state === "FAILED" ? "red" : "#4C6FFF",
                          }}
                        >
                          {a.state}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={1.5}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className={classes.dwnloadWrap}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <div
                            className={classes.dwnload}
                            onClick={() => {
                              handleLogPdfDownload(a.transactionId);
                            }}
                          >
                            Invoice{" "}
                            <FileDownloadOutlinedIcon fontSize="small" />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "10px 0px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={3}>
                        <div className={classes.data1}>
                          <div className={classes.dataFooter}>Amount</div>
                          <div
                            className={classes.dataNumInfo}
                            style={{ color: "#5FC322" }}
                          >
                            {a.amount}/-
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div className={classes.data1}>
                          <div className={classes.dataFooter}>Payment Type</div>
                          <div
                            className={classes.dataNumInfo}
                            style={{ color: "#4C6FFF" }}
                          >
                            {a.paymentInstrument.type}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div className={classes.data1}>
                          <div className={classes.dataFooter}>Plan Type</div>
                          <div
                            className={classes.dataNumInfo}
                            style={{ color: "#5FC322" }}
                          >
                            {a.planType}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <div className={classes.data1}>
                          <div className={classes.dataFooter}>Plan Name</div>
                          <div
                            className={classes.dataNumInfo}
                            style={{ color: "#6FD332" }}
                          >
                            {a.planName}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </>
          ))}

        {paymentdata && paymentdata.length === 0 && (
          <div
            style={{
              padding: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            No Data Found
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentHistory;

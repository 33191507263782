import React, { useState } from "react";
import styles from "./Page2.module.scss";
import centerImage from "../../../assets/Images/templeteImage.svg";
import centergif1 from "../../../assets/Images/firstimage2.gif";
import vd from "../../../assets/Images/textbg.mp4";
import { motion } from "framer-motion";

const data = [
  {
    title: "Drag & drop template builder",
    subtext:
      "Easily stack modules to craft the template structure of your choice.",
  },
  {
    title: "Precision Dashboard & Analytics for Your Email.",
    subtext:
      "Gain detailed insights and analytics specifically tailored for your email.",
  },
  {
    title: "Real Time Tracking",
    subtext:
      "mpower Customer Connections with our Comprehensive Toolkit and Email Integrations",
  },
  {
    title: "Streamline with API Integration",
    subtext:
      "Stack your modules together in order to create the template structure you want.",
  },
];

const videos = [
  {
    video:
      "https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/landingtemplate4.mov",
  },
  {
    video:
      "https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/landingtemplate1.mov",
  },
  {
    video:
      "https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/landingtemplate2.mov",
  },
  {
    video:
      "https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/landingtemplate3.mov",
  },
];

export default function Page2() {
  const [displayBorder, setdisplayBorder] = useState(0);

  const handleClick = (index: any) => {
    setdisplayBorder(index);
  };

 // console.log("videos[displayBorder].video", videos[displayBorder].video);
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.topContainer}>
        <p className={styles.topContainerText}>
          WHY USE{" "}
          <div className={styles.spantext}>
            <video autoPlay={true} loop muted className={styles.video}>
              <source
                src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/textbg.mp4"
                type="video/mp4"
              />
            </video>
            <div className={styles.vdText}>E-NOTIFIERS !</div>
          </div>
        </p>
        <p className={styles.topContainerTextMobile}>
          WHY USE <span className={styles.linearg}> E-NOTIFIERS !</span>
          
        </p>
        <p className={styles.topContainerSubText}>
          Accelerate your email creation using{" "}
          <span style={{ fontWeight: 600 }}>E-Notifiers</span> intuitive
          drag-and-drop HTML editor. Seamlessly export emails to any sending
          platform with just a few clicks. And Track, analyze, and optimize your
          impact—so you can sell more
        </p>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomLeftContainer}>
          {/* <img src={centerImage} className={styles.templeteImage} />
          <img src={centergif1} className={styles.templeteImagegif} /> */}
          {displayBorder === 0 && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.videoTemplate}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                autoPlay={true}
                loop
                muted
                className={styles.videoTemplate}
              >
                <source src={videos[0].video} type="video/mp4" />
              </video>
            </motion.div>
          )}
          {displayBorder === 1 && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.videoTemplate}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                autoPlay={true}
                loop
                muted
                className={styles.videoTemplate}
              >
                <source src={videos[1].video} type="video/mp4" />
              </video>
            </motion.div>
          )}
          {displayBorder === 2 && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.videoTemplate}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                autoPlay={true}
                loop
                muted
                className={styles.videoTemplate}
              >
                <source src={videos[2].video} type="video/mp4" />
              </video>
            </motion.div>
          )}
          {displayBorder === 3 && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.videoTemplate}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                autoPlay={true}
                loop
                muted
                className={styles.videoTemplate}
              >
                <source src={videos[3].video} type="video/mp4" />
              </video>
            </motion.div>
          )}
        </div>
        <div className={styles.bottomRightContainer}>
          {data.map((a: any, index: any) => (
            <div
              className={styles.clickContainer}
              onClick={() => {
                handleClick(index);
              }}
            >
              {displayBorder === index && (
                <div className={styles.borderLeft}></div>
              )}
              <div>
                <p className={styles.clickContainerHeader}>{a.title}</p>
                <p className={styles.clickContainerText}>{a.subtext}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

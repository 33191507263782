import React, { useState } from "react";
import classes from "./ChangePassword.module.scss";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { changePasswordSchema } from "../../../Schemas/Schemas";
import { Button, FormHelperText } from "@mui/material";
import { changePasswordAPICall } from "../../../Utils/Apihelper";
import { toast } from "react-hot-toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [initial, setinitial] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  const navigate = useNavigate();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownCPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const InitialValues = {
    oldpassword: initial.oldpassword,
    newpassword: initial.newpassword,
    confirmpassword: initial.confirmpassword,
  };

  return (
    <Formik
      validationSchema={changePasswordSchema}
      initialValues={InitialValues}
      onSubmit={(values: any, action) => {
        const req = {
          oldPassword: values.oldpassword,
          newPassword: values.newpassword,
        };
        changePasswordAPICall(req)
          .then((res: any) => {
            toast.success(res.data.message);
            action.resetForm();
            setinitial({
              oldpassword: "",
              newpassword: "",
              confirmpassword: "",
            });
            navigate("/email-dashboard/account");
          })
          .catch((err) => {
            toast.error(err.response.data.data.error);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,

        setFieldValue,
      }) => {
        // console.log("errors", errors)
        return (
          <form noValidate onSubmit={handleSubmit}>
            <div className={classes.wrapper}>
              <div className={classes.header}>Change Password</div>
              <div className={classes.pDetailsField}>
                Old Password
                <TextField
                  placeholder="Old Password"
                  id="outlined-size-small"
                  autoComplete="off"
                  size="small"
                  variant="standard"
                  name="oldpassword"
                  value={values.oldpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.oldpassword && Boolean(errors.oldpassword)}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    marginTop: "8px",
                    minWidth: { xs: "80vw", sm: "400px" },
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
                <FormHelperText>
                  <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                    {touched.oldpassword && errors.oldpassword?.toString()}
                  </p>
                </FormHelperText>
              </div>
              <div className={classes.pDetailsField}>
                New Password
                <TextField
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  id="outlined-size-small"
                  size="small"
                  variant="standard"
                  autoComplete="off"
                  name="newpassword"
                  value={values.newpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.newpassword && Boolean(errors.newpassword)}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "8px",
                    minWidth: { xs: "80vw", sm: "400px" },
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
                <FormHelperText>
                  <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                    {touched.newpassword && errors.newpassword?.toString()}
                  </p>
                </FormHelperText>
              </div>
              <div className={classes.pDetailsField}>
                Confirm Password
                <TextField
                  placeholder="Confirm Password"
                  id="outlined-size-small"
                  type={showConfirmPassword ? "text" : "password"}
                  size="small"
                  autoComplete="off"
                  variant="standard"
                  name="confirmpassword"
                  value={values.confirmpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmpassword && Boolean(errors.confirmpassword)
                  }
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      height: "40px",
                      padding: "0px 10px",
                      borderRadius: "5px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCPassword}
                          onMouseDown={handleMouseDownCPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "8px",
                    minWidth: { xs: "80vw", sm: "400px" },
                    background: "#EDF2F7",
                    borderRadius: "5px",
                    "& label.Mui-focused": {
                      color: "grey",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#EDF2F7",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EDF2F7",
                      },
                    },
                  }}
                />
                <FormHelperText>
                  <p style={{ color: "#d32f2f", marginLeft: "10px" }}>
                    {touched.confirmpassword &&
                      errors.confirmpassword?.toString()}
                  </p>
                </FormHelperText>
              </div>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "none", marginTop: "20px" }}
                >
                  Update Password
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import styles from "./CampaignDetailsView.module.scss";
import { Button } from "@mui/material";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import CampaignDetails from "../CampaignDetails/CampaignDetails";
import emptyTemp from "../../assets/Images/emptyTemp.svg";
import {
  GetEachEmailAPICall,
  sendPromotionalEmailAPICall,
} from "../../Utils/Apihelper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Toaster, toast } from "react-hot-toast";
import GetVariable from "../../components/Pages/GetVariable/GetVariable";
import moment from "moment-timezone";

const CampaignDetailsView = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [confirmSend, setConfirmSend] = React.useState(false);
  const [maildata, setmailData] = useState<any>();
  const [sendTransactional, setSendTransactional] = useState(false);
  const [disable, setDisable] = useState(false);
  const mailName = sessionStorage.getItem("TableRow");

  const handleCloseSend = () => {
    setConfirmSend(false);
  };

  useEffect(() => {
    GetEachMailDetails();
  }, []);

  useEffect(() => {
    if (
      maildata?.data?.templateCategory === "" ||
      maildata?.data?.templateName === "" ||
      maildata?.data?.groupName === ""
    ) {
      setDisable(true);
    }
  }, [maildata]);

  // console.log("disable", disable);

  const GetEachMailDetails = () => {
    let obj: any = {
      mail_name: mailName,
    };
    GetEachEmailAPICall(obj)
      .then((response: any) => {
        //  console.log("mailresponse", response.data);
        setmailData(response.data);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  };
  const handlePromotionalEmailSend = () => {
    const requestBody1: any = new FormData();
    requestBody1.append("mailName", mailName);
    requestBody1.append("tags", "");

    sendPromotionalEmailAPICall(requestBody1)
      .then((res: any) => {
        //  console.log(res.data);
        navigate("/email-dashboard/emails");
        toast.success(res.data.message);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime:any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off =  new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime()+localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);

 
  };

  function convertUTCToLocal(utcTime) {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime:any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime()+localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);  
  }


  const disableButton = () => {
    if (
      maildata?.data?.templateCategory === "" ||
      maildata?.data?.templateName === "" ||
      maildata?.data?.groupName === ""
    ) {
      setDisable(true);
    }
  };

  // console.log("maildata.data", maildata?.data);
  return (
    <>
      <Toaster />
      {sendTransactional ? (
        <GetVariable mailName={mailName} />
      ) : (
        <>
          <div className={styles.tabsWrapper}>
            <div className={styles.tabsCatagory}>
              <div
                className={styles.tabsItem}
                onClick={() => {
                  activeTab(1);
                }}
                style={{
                  backgroundColor: tab === 1 ? "#4C6FFF" : "",
                  color: tab == 1 ? "white" : "",
                }}
              >
                Details
              </div>
              <div
                className={styles.tabsItem}
                onClick={() => {
                  activeTab(2);
                }}
                style={{
                  backgroundColor: tab === 2 ? "#4C6FFF" : "",
                  color: tab == 2 ? "white" : "",
                }}
              >
                Stats
              </div>
              <div
                className={styles.tabsItem}
                onClick={() => {
                  activeTab(3);
                }}
                style={{
                  backgroundColor: tab === 3 ? "#4C6FFF" : "",
                  color: tab == 3 ? "white" : "",
                }}
              >
                Logs
              </div>
            </div>
          </div>
          <div className={styles.mTWrap}>
            <p className={styles.mainTitle}>{mailName ? mailName : ""}</p>
            {disable && tab === 1 && (
              <p className={styles.errorText}>
                *Some fields are missing in the current email. Create a new
                email or edit the existing one to include all the deleted fields
                before sending it.
              </p>
            )}
          </div>
          {tab === 1 ? (
            <>
              {maildata && (
                <div className={styles.mainWrapper}>
                  <div className={styles.topWrapper}>
                    <div className={styles.leftSection}>
                      <p className={styles.statusText}>Status</p>
                      <div>
                        <Button
                          variant="contained"
                          disableElevation
                          disableFocusRipple
                          disableTouchRipple
                          sx={{
                            background:
                              maildata.data.email_info &&
                              maildata.data.email_info.length === 0
                                ? "#ea9f08"
                                : "#5FC322",
                            borderRadius: "10px",
                            textTransform: "none",
                            padding: "6px 20px",
                            boxShadow: "none",
                            cursor: "text",
                            "&:hover": {
                              background:
                                maildata.data.email_info &&
                                maildata.data.email_info.length === 0
                                  ? "#ea9f08"
                                  : "#5FC322",
                              boxShadow: "none",
                            },
                          }}
                        >
                          {maildata.data.email_info &&
                          maildata.data.email_info.length === 0
                            ? "Draft"
                            : "Sent"}
                        </Button>
                      </div>
                    </div>
                    <div className={styles.rightSection}>
                      <div>
                        <Button
                          variant="contained"
                          startIcon={<FlipCameraAndroidIcon />}
                          sx={{
                            background: "#4C6FFF",
                            borderRadius: "14px",
                            textTransform: "none",
                            padding: "6px 20px",
                            boxShadow: "none",

                            "&:hover": { background: "#4C6FFF" },
                          }}
                          onClick={() => {
                            if (
                              maildata?.data.groupName === "" ||
                              maildata?.data.templateCategory === "" ||
                              maildata?.data.templateName === ""
                            ) {
                              toast.error(
                                "Either Template or Category not found"
                              );
                            } else {
                              maildata.data.mailType === "transactional"
                                ? setSendTransactional(true)
                                : setConfirmSend(true);
                            }
                          }}
                          disabled={disable}
                        >
                          {maildata.data.email_info &&
                          maildata.data.email_info.length === 0
                            ? "Send Now"
                            : "Send Again"}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>Mail Type</p>
                    <p className={styles.contentWrapperDesc}>
                      {maildata.data.mailType}
                    </p>
                  </div>
                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>Group</p>
                    <p className={styles.contentWrapperDesc}>
                      {maildata.data.groupName}
                    </p>
                  </div>
                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>Subject</p>
                    <p className={styles.contentWrapperDesc}>
                      {maildata.data.subject}
                    </p>
                  </div>
                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>Sender Name</p>
                    <p className={styles.contentWrapperDesc}>
                      {" "}
                      {maildata.data.addedBy}
                    </p>
                  </div>
                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>
                      Sender Email Address
                    </p>
                    <p className={styles.contentWrapperDesc}>
                      {maildata.data.sourceEmail}
                    </p>
                  </div>

                  {/* <div className={styles.contentWrapper}>
            <p className={styles.contentWrapperTitle}>Attachments</p>
            <p className={styles.contentWrapperDesc}>0 Attachments</p>
          </div> */}
                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>Created at</p>
                    <p className={styles.contentWrapperDesc}>
                      {convertUTCToLocal(maildata.data.createdAt)} -{" "}
                      {maildata.data.addedBy}
                      {/* Wednesday, 17 January, 2024 05:055 PM - Asia/Dhaka */}
                    </p>
                  </div>

                  <div className={styles.contentWrapper}>
                    <p className={styles.contentWrapperTitle}>
                      Template Category
                    </p>
                    <p className={styles.contentWrapperDesc}>
                      {maildata.data.templateCategory}
                    </p>
                  </div>
                  <div
                    className={styles.contentWrapper}
                    style={{ borderBottom: "1px solid #c9c9c9" }}
                  >
                    <p className={styles.contentWrapperTitle}>Template Name</p>
                    <p className={styles.contentWrapperDesc}>
                      {maildata.data.templateName}
                    </p>
                  </div>
                  <p className={styles.contentWrapperTitleTemplate}>
                    Template Used
                  </p>
                  <div className={styles.templateImageSection}>
                    <img
                      src={
                        maildata.image && maildata.image.templateImage
                          ? `data:image/png;base64,${maildata.image.templateImage}`
                          : emptyTemp
                      }
                      alt=""
                      className={styles.emptyTemp}
                      style={{
                        width:
                          maildata.image && maildata.image.templateImage
                            ? ""
                            : "50%",
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {maildata && (
                <CampaignDetails tabNumber={tab} maildata={maildata} />
              )}
            </>
          )}
        </>
      )}
      <Dialog
        open={confirmSend}
        onClose={handleCloseSend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"primary"}>
          Send Email
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you confirm to send this Email?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSend}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              handlePromotionalEmailSend();
            }}
          >
            Send
          </Button>
          {/* <div className={styles.buttons}>
            <div className={styles.cancelBtn} onClick={handleCloseSend}>
              Cancel
            </div>
            <div
              className={styles.createBtn}
              onClick={() => {
                handlePromotionalEmailSend();
              }}
            >
              Send{" "}
            </div>
          </div> */}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CampaignDetailsView;

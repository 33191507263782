import React, { useEffect } from "react";
import TermsPrivacyStyles from "./PrivacyPolicy.module.scss";
import { useNavigate } from "react-router-dom";
import Footer from "../../../containers/LandingPage/Footer/Footer";
import ScrollHeader from "../../../containers/LandingPage/ScrollHeader/ScrollHeader";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ScrollHeader />

      <div className={TermsPrivacyStyles.mainSectionterms}>
        <div>
          <h1 className={TermsPrivacyStyles.mainHeading}>Privacy Policy</h1>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>Introduction</p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Enotifiers ("we") prioritise the protection and respect of
            your privacy, ensuring the security of personal information. This
            policy applies to our email marketing service and associated
            websites, including enotifiers.com and its subdomains.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Information Collection
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; We collect and process data, including information provided
            by you through forms, correspondence, and IP addresses during
            website visits. For paid services, we collect payment information
            through our secure payment processor, PhonePe.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Use of Personal Information
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; We utilise your data to provide requested
            information/services, send newsletters, fulfil contractual
            obligations, troubleshoot, optimise service, maintain website
            security, and for retargeting advertising.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>Retargeting</p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; We employ retargeted advertising through Google, Facebook,
            and Instagram, using cookies and email matching. We ensure
            compliance with user browser settings and refrain from presenting
            ads to existing customers.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Marketing Lists
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Marketing lists are stored securely on AWS servers in Mumbai
            and are accessible to authorised personnel and ESPs solely for email
            sending purposes. We do not sell or share marketing lists and handle
            complaints and requests for removal.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Data Storage and Security
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; All data is stored securely on our AWS servers, with access
            limited to authorised personnel. We prioritise privacy and employ
            industry-standard security measures. However, absolute immunity from
            unauthorised access cannot be guaranteed.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Disclosure of Information
          </p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; We may disclose personal information to affiliated companies,
            selected third parties, subcontractors, and law enforcement agencies
            for legal compliance, fraud prevention, and service provision
            purposes
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>Integrations</p>

          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Enotifiers integrates with email service providers and
            third-party apps, with data subject to their respective privacy
            policies. Users are responsible for their integrations and data
            sharing.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Retention and Legal Basis
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Data retention periods depend on the purpose of data
            collection and legal requirements. We process data based on consent,
            contractual obligations, legitimate interests, or legal
            requirements.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>User Rights</p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; Users have rights to access, correct, update, or request
            deletion of personal information, as well as to restrict processing,
            object to processing, or request data portability. Consent
            withdrawal for processing or marketing purposes is also possible.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Changes to Privacy Policy
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; We regularly review and update our privacy policy, informing
            users of any changes and ensuring awareness of data collection and
            usage practices.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>Complaints</p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; For inquiries or complaints regarding data usage, users can
            contact us via email. Unresolved complaints may be escalated to the
            Information Commissioner.
          </p>
        </div>
        <div>
          <p className={TermsPrivacyStyles.toadjustheaderfont}>
            Contact Information
          </p>
          <p className={TermsPrivacyStyles.toalignpara}>
            &bull; For further inquiries or questions, users can contact us at
            support@enotifiers.com.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import classes from "./Sms.module.scss";
import { useDropzone } from "react-dropzone";
import SampleFile from "../../assets/sample_csv_sms.csv";
import SendIcon from "@mui/icons-material/Send";
import { smsSendApi } from "../../Utils/Apihelper";
import toast, { Toaster } from "react-hot-toast";

const templateId = ["Public", "Private"];

const Sms = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [tab, setTab] = React.useState(1);
  const [sender, setsender] = React.useState("");
  const [variable, setvariable] = React.useState("");
  const [mobile, setmobile] = React.useState("");
  const [message, setmessage] = React.useState("");
  const [template, settemplate] = React.useState("");

  const requestBody1 = new FormData();
  requestBody1.append("sms_sender", sender);
  requestBody1.append("variables", variable);
  requestBody1.append("mobile", mobile);
  requestBody1.append("message", message);
  requestBody1.append("template_id", template);

  const requestBody2 = new FormData();
  requestBody2.append("file", acceptedFiles[0]);

  function activeTab(tabNumber) {
    setTab(tabNumber);
  }

  const handleBulkSend = () => {
    smsSendApi(requestBody2)
      .then((res) => {
      //  console.log(res.data);
        toast.success("SMS sent successfully")
      })
      .catch((err) => {
      //  console.log(err.data);
        toast.error("SMS couldn't be sent")
      });
  };
  const handleManualSend = () => {
    smsSendApi(requestBody1)
      .then((res) => {
      //  console.log(res.data);
        toast.success("SMS sent successfully")
      })
      .catch((err) => {
       // console.log(err.data);
        toast.error("SMS couldn't be sent")
      });
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  // const handleDownload = () => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = SampleFile; // Change this to the correct path of your CSV file
  //   downloadLink.download = "sample_csv_sms.csv";
  //   downloadLink.click();
  // };


  const csvData = 'Phone_Number,Template_ID,Header_Cli,Content,Variables'; // Replace with your CSV data

  const handleDownload = () => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <>
    <Toaster />
      <div className={classes.tabsWrapper}>
        <div className={classes.tabsCatagory}>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(1);
            }}
            style={{
              backgroundColor: tab === 1 ? "white" : "",
              color: tab == 1 ? "#8056D6" : "",
            }}
          >
            Bulk Send
          </div>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(2);
            }}
            style={{
              backgroundColor: tab === 2 ? "white" : "",
              color: tab == 2 ? "#8056D6" : "",
            }}
          >
            Manual Send
          </div>
        </div>
      </div>

      {tab === 2 ? (
        <>
          <Typography variant="h6" gutterBottom mt={4}>
            Enter Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="sender"
                name="sender"
                label="Sender"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                value={sender}
                onChange={(event) => {
                  setsender(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="variables"
                name="address1"
                label="Variables"
                fullWidth
                autoComplete="shipping address-line1"
                variant="standard"
                value={variable}
                onChange={(event) => {
                  setvariable(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="address2"
                name="address2"
                label="Mobile Number"
                fullWidth
                autoComplete="shipping address-line2"
                variant="standard"
                value={mobile}
                onChange={(event) => {
                  setmobile(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="city"
                name="city"
                label="Message"
                fullWidth
                autoComplete="shipping address-level2"
                variant="standard"
                value={message}
                onChange={(event) => {
                  setmessage(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="country"
                name="country"
                label="Template Id"
                fullWidth
                autoComplete="shipping country"
                variant="standard"
                value={template}
                onChange={(event) => {
                  settemplate(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <div>
            <button className={classes.button62} role="button" onClick={handleManualSend}>
              Send <SendIcon fontSize="small" />
            </button>
          </div>
        </>
      ) : (
        <>
          <div>
            <Typography variant="h6" gutterBottom mt={4}>
              Attach file
            </Typography>
            <div className={classes.bulkContainer}>
              <section className="container">
                <div {...getRootProps()} className={classes.uploadArea}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FileUploadIcon fontSize="large" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <input {...getInputProps()} />
                    <p>Select a CSV file to Upload or Drag and Drop</p>
                  </div>
                </div>
                <aside>
                  <h4>{files}</h4>
                </aside>
              </section>

              <div className={classes.sendWrap} onClick={handleDownload}>
                <div>
                  {" "}
                  <a
                   
                    className={classes.or}
                  >
                    Download Sample
                  </a>
                </div>
              </div>
            </div>
            <div>
              <button className={classes.button62} role="button" onClick={handleBulkSend}>
                Send <SendIcon fontSize="small" />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Sms;

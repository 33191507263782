import React, { useEffect, useState } from "react";
import classes from "./ApiKey.module.scss";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  generateCredentialsAPICall,
  getCredentialsAPICall,
} from "../../../Utils/Apihelper";
import { toast } from "react-hot-toast";

const ApiKey = () => {
  const [showKey, setShowKey] = React.useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedkey, setIsCopiedkey] = useState(false);
  const [clientId, setClientId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [loader, setLoader] = useState(true);

  const Token = sessionStorage.getItem("ChangePwToken");

  useEffect(() => {
    handleGetCredential();
  }, []);

  // const handleGenerateApi = () => {
  //   generateApikeyApi(config)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setClientId(res.data["data"]["credentials"]["client-id"]);
  //       setApiKey(res.data["data"]["credentials"]["client-secret"]);
  //     })
  //     .catch((res) => {
  //       console.log(res.data);
  //     });
  // };

  const handleGenerateCredential = () => {
    generateCredentialsAPICall()
      .then((res: any) => {
        setClientId(res.data.data.username);
        setApiKey(res.data.data.password);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader(false);
      });
  };

  const handleGetCredential = () => {
    getCredentialsAPICall()
      .then((res: any) => {
        setClientId(res.data.data.username);
        setApiKey(res.data.data.password);
        setLoader(false);
      })
      .catch((err) => {
        // toast.error(err.response.data.message);
        setLoader(false);
      });
  };

  return (
    <>
      {loader ? (
        <span className={classes.loader}></span>
      ) : (
        <>
          <div className={classes.profileModal3}>
            <div className={classes.profileModalTop}>
              <div className={classes.leftprofile}>Api Key</div>
            </div>

            <div className={classes.profileMenu}>
              {showKey || (clientId !== "" && apiKey !== "") ? (
                <>
                  <div className={classes.apikeyTitle}>
                    <div>Client Id generated</div>
                    {isCopied && <div className={classes.copied}>Copied!</div>}
                  </div>

                  <div className={classes.apikeyWrap}>
                    <div className={classes.keyField}>{clientId}</div>
                    <div
                      className={classes.copyField}
                      onClick={() => {
                        navigator.clipboard.writeText(clientId);
                        setIsCopied(true);
                        setTimeout(() => {
                          setIsCopied(false);
                        }, 1000);
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </div>
                  </div>
                  <div className={classes.apikeyTitle}>
                    <div>Client secret key generated</div>
                    {isCopiedkey && (
                      <div className={classes.copied}>Copied!</div>
                    )}
                  </div>

                  <div className={classes.apikeyWrap}>
                    <div className={classes.keyField}>{apiKey}</div>
                    <div
                      className={classes.copyField}
                      onClick={() => {
                        navigator.clipboard.writeText(apiKey);
                        setIsCopiedkey(true);
                        setTimeout(() => {
                          setIsCopiedkey(false);
                        }, 1000);
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </div>
                  </div>
                  <>
                    <button
                      className={classes.generateButton}
                      onClick={() => {
                        setShowKey(true);
                        handleGenerateCredential();
                      }}
                    >
                      Generate API key
                    </button>
                  </>
                </>
              ) : (
                <>
                  <button
                    className={classes.generateButton}
                    onClick={() => {
                      setShowKey(true);
                      handleGenerateCredential();
                    }}
                  >
                    Generate API key
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ApiKey;

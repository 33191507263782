import React, { useState } from "react";
import classes from "./BookMeetingSection.module.scss";
import { Button, Checkbox, TextField } from "@mui/material";
import crownimage from "../../../assets/Images/crown.svg";
import { contactUsAPICall } from "../../../Utils/Apihelper";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

interface props {
  displaybg?: any;
}

export default function BookMeetingSection({ displaybg }: props) {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [message, setmessage] = useState("");
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handlenameInput = (e) => {
    setname(e.target.value);
  };
  const handleEmailInput = (e) => {
    setemail(e.target.value);
  };
  const handlenumberInput = (e) => {
    setnumber(e.target.value);
  };
  const handleMessageInput = (e) => {
    setmessage(e.target.value);
  };

  const handleEnquiry = () => {
    const req = {
      name: name,
      email: email,
      message: message,
      mobileNumber: number,
    };
    contactUsAPICall(req)
      .then((res: any) => {
        toast.success(`${res.data.message} Our team will contact you soon.`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          Choose Us for Unparalleled Excellence
        </div>
        <div className={classes.subTitle}>
          Choosing us means embracing a seamless blend of expertise and
          innovation, ensuring your projects are not just completed but elevated
          to new heights.
        </div>
        <div className={classes.container}>
          <div className={classes.inputContainer}>
            <div className={classes.left}>
              <div className={classes.inputWrap}>
              <div className={classes.label}>Name</div>

                <TextField
                  id="outlined-basic"
                  fullWidth
                  size="small"
                  placeholder="Enter your name"
                  value={name}
                  onChange={handlenameInput}
                  InputProps={{
                    startAdornment: <PermIdentityIcon style={{ color: '#4C6FFF' }} fontSize="small"/>,
                  }}
                  variant="outlined"
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      marginLeft:"16px"
                    },
                    "& label.Mui-focused": {
                      color: "4c6fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "4c6fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5EFFF",
                      },
                    },
                  }}
                />
              </div>
              <div className={classes.inputWrap}>
                <div className={classes.label}>Email</div>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  size="small"
                  placeholder="Enter your email"

                  variant="outlined"
                  value={email}
                  onChange={handleEmailInput}
                  InputProps={{
                    startAdornment: <MailOutlineIcon style={{ color: '#4C6FFF' }} fontSize="small"/>,
                  }}
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      marginLeft:"16px"
                    },
                    "& label.Mui-focused": {
                      color: "4c6fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "4c6fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5EFFF",
                      },
                    },
                  }}
                />
              </div>
              <div className={classes.inputWrap}>
              <div className={classes.label}>Number</div>

                <TextField
                  id="outlined-basic"
                  fullWidth
                  size="small"
                  value={number}
                  placeholder="Enter your Phone number"

                  onChange={handlenumberInput}
                  InputProps={{
                    startAdornment: <PhoneAndroidIcon style={{ color: '#4C6FFF' }} fontSize="small"/>,
                  }}
                  variant="outlined"
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      marginLeft:"16px"
                    },
                    "& label.Mui-focused": {
                      color: "4c6fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "4c6fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5EFFF",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className={classes.right}>
              
              <div className={classes.inputWrap}>
              <div className={classes.label}>Email</div>
                <TextField
                  id="outlined-multiline-static"
                  size="small"
                  multiline
                  value={message}
                  onChange={handleMessageInput}
                  fullWidth
                  placeholder="Your message"
                  rows={4}
                  sx={{
                    "& input::placeholder": {
                      fontSize: "14px",
                      marginLeft:"16px"
                    },
                    borderColor: "#E5EFFF",
                    "& label.Mui-focused": {
                      color: "E5EFFF",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "4c6fff",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#E5EFFF",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{display:"flex",justifyContent:"center"}}>

          <Button
            variant="contained"
            sx={{
              background: "4c6fff",
              borderRadius: "11px",
              textTransform: "none",
              padding: "10px 40px",
              boxShadow: "none",
              
              "&:hover": { background: "4c6fff" },
            }}
            onClick={handleEnquiry}
            disabled={
              name === "" || email === "" || message === "" || number === ""
            }
          >
            Send message
          </Button>
          </div>
        </div>
      </div>
    </>
  );
}

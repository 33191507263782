import React, { useEffect, useRef } from "react";
import styles from "./MainLandingHero.module.scss";
import MainLandingPageHeader from "../MainLandingPageHeader/MainLandingPageHeader";
import CarouselSlider from "../MainLandingPageCarousel/MainLandingCarousel";
import MainlandingPageTabs from "../MainlandingPageTabs/MainlandingPageTabs";
import ExploreKeyFeatures from "../ExploreKeyFeatures/ExploreKeyFeatures";
import MainLandingFAQ from "../MainLandingFAQ/MainLandingFAQ";
import BookMeetingSection from "../../LandingPage/BookMeetingSection/BookMeetingSection";
import Footer from "../../LandingPage/Footer/Footer";
import BackToTopButton from "../../../components/BacktoTop/BackToTopComp";
import MainLandingPageMain from "../MainlandingPageMain/MainlandingPageMain";
import MainLandingHero from "../MainLandingHero/MainLandingHero";

export default function MainLandingHeroPage() {
  const pricingRef = useRef<any>(null);
  const templateRef = useRef<any>(null);
  const featuresRef = useRef<any>(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const scrollToRef = (ref: any) => {
    if (ref === "pricingRef") {
      pricingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (ref === "templateRef") {
      templateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (ref === "featuresRef") {
      featuresRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <MainLandingPageHeader scrollToRef={scrollToRef} />
      {/* <MainLandingPageMain /> */}
      <MainLandingHero/>
      <div className={styles.mainWrapper}>
        <div className={styles.topContentContainer}>
          <p className={styles.topContentTitleText}>
            Unlock the dynamic CRM Trio
          </p>

          <p className={styles.topContentSubText}>
            Break barriers and effortlessly connect with your audience through
            our integrated WhatsApp, SMS, and Email solutions
          </p>
        </div>
        <div className={styles.CarouselSlider}>
          <CarouselSlider />
        </div>
        <MainlandingPageTabs />
        <ExploreKeyFeatures />
        {/* <MainLandingFAQ /> */}
        <BookMeetingSection displaybg={true} />
      </div>
      <Footer />
      <BackToTopButton />
    </>
  );
}

import React, { useState } from 'react';
import classes from './PaymentSuccessful.module.scss';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import reminder from "../../../assets/Images/reminder.png";
import icon from "../../../assets/Images/hourglass.png";
import logo from "../../../assets/Images/enotifierslogo.png";
import expiredimage from '../../../../assets/Images/expiredimage.png';
import paymentsuccessful  from '../../../assets/Images/Group.png';
import { useNavigate } from "react-router-dom";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  
  interface props {
    expiring: boolean,
    expired: boolean,
  }


const PaymentSuccessful = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [open, setOpen] = React.useState(true);
  
    const navigate= useNavigate();
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
      sessionStorage.removeItem("paymentsuccess");
    };  

  return (
    <>
      <div className={classes.wrapper}>
  <div className={classes.container}>
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .MuiPaper-root": {
          width: "375px",
          height: "510px",
          borderRadius: "6px",
          margin: 0,
          padding: "15px"
        },
      }}
    >
      <div className={classes.image} style={{
        display: "flex",
        justifyContent: "center"
      }}>
          <img src={paymentsuccessful} alt="payment successful image" />
      </div>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          background: "#FFFFFF",
          padding: "2px",
        }}
      >
        <CloseIcon
          sx={{
            width: "16px",
            height: "16px",
            color: "#000000",
            borderRadius: "50%",
          }}
        />
      </IconButton>
      <DialogContent>
        <div className={classes.content}>
          <div className={classes.text}>
            <div className={classes.logo}>
              <img src={logo} alt="logo" />
            </div>
              <div className={classes.text1}>
              <div>Your Payment Was Successful!</div>
            </div>
            
            <div className={classes.messagebox}>
              <div className={classes.message}>
              Congratulations! Your subscription is now Active. <br></br>
              Dive in and enjoy all the powerful features waiting for you!
              </div>
            </div>
            
            <div className={classes.buttons}>
              <div className={classes.btn1}
              onClick={()=> {
                handleClose();
              }}
              >
                <button>Continue Explore</button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </BootstrapDialog>
  </div>
</div>
    </>
  )
}

export default PaymentSuccessful
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

interface Props {
  value: any;
  onChange: any;
  inputValue: any;
  options: any;
  placeholder: any;
  label: any;
}

export default function AutoCompleteComp({
  value,
  onChange,
  inputValue,
  options,
  placeholder,
  label,
}: Props) {
  return (
    <Autocomplete
      size="small"
      value={value}
      onChange={onChange}
      
      options={options}
      sx={{
        // marginTop: "32px",
        marginLeft:{xs:'0rem', sm:'2rem'} ,
        borderRadius: "10px",
        width: "100%",
        background: "#EDF2F7",
        outline: "none",
        fontSize: "16px",
        fontWeight: 500,
        padding: "0 10px",
        color: "#000000",
        boxShadow: "none",
        "& .MuiOutlinedInput-input": {
          // padding: "4.5px 4px 2.5px 8px",
          paddingTop: "2px !important",
          cursor: "pointer",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
      }}
    
      popupIcon={
        <KeyboardArrowDownIcon style={{ cursor: "pointer" }} fontSize="small" />
      }
     
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} label={label} />
      )}
    />
  );
}

import React from "react";
import error from "../../assets/Images/error.png";
import classes from "./ErrorBoundary.module.scss";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  nextPath(path) {
    this.props.history.push(path);
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
   
    // console.log("error is: ", error);
  }

  render() {
    if (this.state.hasError) {
   
      return (
        <>
          <img
            src={error}
            alt=""
            className={classes.errorimg}
            onClick={() => this.nextPath("/email-dashboard")}
          />
        </>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;

import React, { useRef } from "react";
import classes from "./WtspLandingPage.module.scss";
import ScrollHeader from "../LandingPage/ScrollHeader/ScrollHeader";
import heroSectionImage from "../../assets/Images/heroSectionImage.svg";
import EastIcon from "@mui/icons-material/East";
import wtsapp from "../../assets/Images/wtaspp.svg";
import BottomSection from "./LandingPageComps/BottomSection/BottomSection";
import Footer from "../LandingPage/Footer/Footer";
import CI1 from "../../assets/Images/CI1.svg";
import CI2 from "../../assets/Images/CI2.svg";
import CI3 from "../../assets/Images/CI3.svg";
import CI4 from "../../assets/Images/CI4.svg";
import WPLPim1 from "../../assets/Images/WPLPim1.svg";
import WPLPim2 from "../../assets/Images/WPLPim2.svg";
import WPLPim3 from "../../assets/Images/wplpIm3.svg";
import { motion } from "framer-motion";
import Section3 from "./LandingPageComps/Section3/Section3";
import Section4 from "./LandingPageComps/Section4/Section4";

const cardData = [
  {
    img: CI1,
    title: "Bulk Messaging",
    subTitle:
      " Send targeted campaigns to thousands of customers with just a few clicks.",
  },
  {
    img: CI2,
    title: "Automated Text & Responses",
    subTitle:
      "Save time and resources by automating responses to common inquiries.",
  },
  {
    img: CI3,
    title: "Personalization",
    subTitle:
      "Customize your messages with dynamic content to enhance engagement.",
  },
  {
    img: CI4,
    title: "Analytics and Insights",
    subTitle:
      "Track the performance of your campaigns in real-time with detailed analytics.",
  },
];

const WtspLandingPage = () => {
  const pricingRef = useRef<any>(null);
  const templateRef = useRef<any>(null);
  const featuresRef = useRef<any>(null);

  const scrollToRef = (ref: any) => {
    if (ref === "pricingRef") {
      pricingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (ref === "templateRef") {
      templateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (ref === "featuresRef") {
      featuresRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <ScrollHeader scrollToRef={scrollToRef} />
      <div className={classes.wrapper}>
        <div className={classes.heroSection}>
          <div className={classes.heroLeft}>
            <div className={classes.heroText}>
              Enhance contact engagement with efficient{" "}
              <span className={classes.textWrap}>
                {" "}
                <img src={wtsapp} alt="" style={{ height: "" }} />
                WhatsApp{" "}
              </span>
              workflows
            </div>
            <div className={classes.heroPara}>
              Enhance contact engagement with efficient WhatsApp workflows for
              seamless interaction and improved productivity, fostering stronger
              connections effortlessly.
            </div>
            <div className={classes.heroBtns}>
              <div className={classes.signup}>Sign Up for free</div>
              <div className={classes.expert}>
                Contact an expert <EastIcon />
              </div>
            </div>
          </div>
          <div className={classes.heroRight}>
            <img src={heroSectionImage} alt="" className={classes.heroImage} />
          </div>
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.topContainer}>
          <p className={classes.topContainerText}>
            Personalized WhatsApp Marketing &nbsp;
            <div className={classes.spantext}>
              <video autoPlay={true} loop muted className={classes.video}>
                <source
                  src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/textbg.mp4"
                  type="video/mp4"
                />
              </video>
              <div className={classes.vdText}>Solutions</div>
            </div>
          </p>
          <div className={classes.topContainerSubText}>
            <span style={{ color: "4c6fff" }}> Reach, engage, convert.</span>{" "}
            From bulk messaging to automated responses, we have your digital
            success covered.
          </div>
        </div>
        <div className={classes.shapeWrap}>
          {cardData.map((a: any) => (
            <>
              <div className={classes.cardWrap}>
                <div className={classes.cardimgwrap}>
                  <img src={a.img} alt="" className={classes.cardimg} />
                </div>
                <div className={classes.title}>{a.title}</div>
                <div className={classes.subtitle}>{a.subTitle}</div>
              </div>
            </>
          ))}
        </div>
      </div>

      <Section3 />
      <Section4 />
      <BottomSection />
      <Footer />
    </>
  );
};

export default WtspLandingPage;

import classes from "./SuccessModal.module.scss";
import Button from "@mui/joy/Button";

import { useNavigate } from "react-router-dom";

interface Props {
  heading: any;
  subHeading1: any;
  subHeading2: any;
}

const SuccessModal: React.FC<Props> = ({
  heading,
  subHeading1,
  subHeading2,
}) => {
  const navigate = useNavigate();
  const handleSuccess = () => {
    navigate("/login");
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>{heading}</h1>
              <p className={classes.modalDesc}>{subHeading1} </p>
              <p className={classes.modalDesc2}>{subHeading2} </p>
              <p className={classes.signUp}></p>
              <Button
                type="submit"
                size="lg"
                style={{
                  padding: "14px 50px",
                  borderRadius: "5px",
                }}
                sx={{
                  backgroundColor: "4c6fff",
                  "&:disabled": {
                    backgroundColor: "#b9c7ff",
                  },
                  "&:hover": {
                    backgroundColor: "4c6fff",
                  },
                }}
                onClick={handleSuccess}
              >
                Back to Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessModal;

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classes from "./Scheduling.module.scss";
import { Autocomplete, Button, TextField } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useState } from "react";

const options1 = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const options2 = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const options3 = ["AM", "PM"];

export default function Scheduling() {
  const [campaign, setCampaign] = useState("");
  const [deliveryDate, setDeliveryData] = useState(null);
  const [deliveryHour, setDeliveryHour] = useState("");
  const [deliveryMins, setDeliveryMins] = useState("");
  const [deliveryampm, setDeliveryampm] = useState("");

  // console.log("deliveryDate", deliveryDate);

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.wrapper}>
        <p className={classes.wrapperText}>Schedule Email Campaigns</p>
        <div className={classes.selectSection}>
          <div
            className={classes.swRegionPicker}
            style={{ marginBottom: "20px" }}
          >
            Select Campaign
            <Autocomplete
              size="small"
              options={options3}
              sx={{
                marginTop: "15px",
                borderRadius: "10px",
                width: "40%",
                background: "#EDF2F7",
                outline: "none",
                fontSize: "16px",
                fontWeight: 500,
                padding: "0 10px",
                color: "#000000",
                boxShadow: "none",
                "& .MuiOutlinedInput-input": {
                  // padding: "4.5px 4px 2.5px 8px",
                  paddingTop: "2px !important",
                  cursor: "pointer",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              value={campaign}
              onChange={(event: any, value: any) => setCampaign(value)}
              id="disable-clearable"
              popupIcon={
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                />
              }
              disableClearable
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Campaign" />
              )}
            />
          </div>
          <div className={classes.swSchedular}>
            <div className={classes.swDatePicker}>
              Delivery date
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    components={{
                      OpenPickerIcon: KeyboardArrowDownIcon, // Use your custom icon component here
                    }}
                    value={deliveryDate}
                    onChange={(date: any) => {
                      setDeliveryData(date.$d);
                    }}
                    sx={{
                      marginTop: "8px",
                      width: "-webkit-fill-available",
                      backgroundColor: "#EDF2F7",
                      outline: "none",
                    }}
                    slotProps={{
                      textField: {
                        placeholder: "Select Delivery date",
                        size: "small",
                        sx: {
                          background: "#EDF2F7",
                          width: "100%",
                          borderRadius: "10px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                            
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                        },
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className={classes.swTimePicker}>
              Delivery Time
              <div className={classes.swTimeComp}>
                <Autocomplete
                  size="small"
                  value={deliveryHour}
                  onChange={(event: any, value: any) => setDeliveryHour(value)}
                  sx={{
                    marginTop: "15px",
                    borderRadius: "10px",
                    // width: "30%",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  options={options1}
                  popupIcon={
                    <UnfoldMoreIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  id="disable-clearable"
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} placeholder="11" />
                  )}
                />
                <div style={{ marginTop: "15px" }}>:</div>
                <Autocomplete
                  size="small"
                  options={options2}
                  value={deliveryMins}
                  onChange={(event: any, value: any) => setDeliveryMins(value)}
                  sx={{
                    marginTop: "15px",
                    borderRadius: "10px",
                    // width: "30%",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  popupIcon={
                    <UnfoldMoreIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  id="disable-clearable"
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} placeholder="00" />
                  )}
                />
                <div style={{ marginTop: "15px" }}>:</div>
                <Autocomplete
                  size="small"
                  options={options3}
                  value={deliveryampm}
                  onChange={(event: any, value: any) => setDeliveryampm(value)}
                  sx={{
                    marginTop: "15px",
                    borderRadius: "10px",
                    // width: "30%",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="disable-clearable"
                  popupIcon={
                    <UnfoldMoreIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} placeholder="AM" />
                  )}
                />
              </div>
            </div>
          </div>
          {/* <div className={classes.swRegionPicker}>
            Timezone
            <Autocomplete
              size="small"
              options={options3}
              sx={{
                marginTop: "15px",
                borderRadius: "10px",
                width: "40%",
                background: "#EDF2F7",
                outline: "none",
                fontSize: "16px",
                fontWeight: 500,
                padding: "0 10px",
                color: "#000000",
                boxShadow: "none",
                "& .MuiOutlinedInput-input": {
                  // padding: "4.5px 4px 2.5px 8px",
                  paddingTop: "2px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              id="disable-clearable"
              popupIcon={
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                />
              }
              disableClearable
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Timezone" />
              )}
            />
          </div> */}
        </div>
      </div>
      <div className={classes.butttonSection}>
        {campaign !== "" &&
        deliveryDate &&
        deliveryHour !== "" &&
        deliveryMins !== "" &&
        deliveryampm !== "" ? (
          <Button
            variant="contained"
            startIcon={<ScheduleIcon />}
            sx={{
              background: "#4C6FFF",
              borderRadius: "100px",
              textTransform: "none",
              padding: "10px 20px",
              boxShadow: "none",

              "&:hover": { background: "#4C6FFF" },
            }}
          >
            Schedule
          </Button>
        ) : (
          <Button
            variant="contained"
            disableTouchRipple
            disableFocusRipple
            disableElevation
            startIcon={<ScheduleIcon />}
            sx={{
              background: "#4C6FFF",
              cursor: "not-allowed",
              opacity: 0.5,
              borderRadius: "100px",
              textTransform: "none",
              padding: "10px 20px",
              boxShadow: "none",

              "&:hover": { background: "#4C6FFF" },
            }}
          >
            Schedule
          </Button>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import styles from "./PricingSection.module.scss";
import vd from "../../../assets/Images/textbg.mp4";
import doneImage from "../../../assets/Images/Check_fill.svg";
import errorImage from "../../../assets/Images/checkerror.svg";
import carrow from "../../../assets/Images/comparisonrightarrow.svg";
import creditcardicon from "../../../assets/Images/credit-card-svgrepo-com 1.svg";
import premiumicon from "../../../assets/Images/premium-icon.svg fill.svg";
import { Button ,FormControlLabel} from "@mui/material";
import { motion } from "framer-motion";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { priceCompare, pricing } from "../../../assets/data";
import Grid from "@mui/material/Grid";



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "4c6fff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
}));

export default function PricingSection() {
  const [displaycontent, setdisplaycontent] = useState(false);
  const [widthset, setWidth] = useState("340px");
  const [tab, setTab] = useState(1);
  const [yearly, setyearly] = useState(false);
  const navigate = useNavigate();

  function activeTab(index: any) {
    setTab(index);
  }

  const handleMouseEnter = () => {
    // setTimeout(() => {
    //   setdisplaycontent(true);
    // }, 100);
  };
  const handleMouseLeave = () => {
    // setdisplaycontent(false);
  };
  useEffect(() => {}, [yearly]);
  return (
    <Element id="example-destination" name="example-destination">
      <div className={styles.mainWrapper}>
        <div className={styles.topContainer}>
          <p className={styles.topContainerText}>
            BEGIN AT NO
            <div className={styles.spantext}>
              <video autoPlay={true} loop muted className={styles.video}>
                <source
                  src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/textbg.mp4"
                  type="video/mp4"
                />
              </video>
              <div className={styles.vdText}>COST</div>
            </div>
          </p>
          <p className={styles.topContainerTextMobile}>BEGIN AT NO COST</p>
          <p className={styles.topContainerSubText}>
            Progress your plan as you expand.
          </p>
        </div>
        <>
            <p className={styles.saveText}>
              Save Up to 20% with Our Annual Subscription Plan
            </p>
            <div className={styles.chechBoxSection}>
              <p
                className={styles.monthlyetxt}
                style={{
                  fontWeight: yearly ? 500 : 600,
                  fontSize: yearly ? "14px" : "16px",
                }}
              >
                {" "}
                Monthly
              </p>
              <FormControlLabel
                control={<IOSSwitch />}
                label=""
                onChange={(event: any, checked: any) => {
                  setyearly(!yearly);
                }}
              />
              <p
                className={styles.monthlyetxt}
                style={{
                  fontWeight: yearly ? 600 : 500,
                  fontSize: yearly ? "16px" : "14px",
                }}
              >
                Yearly
              </p>
            </div>{" "}
            
            
            <div className={styles.pcWrap}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{ background: "#FFF2F7", borderRadius: "10px 0 0 10px" }}
                >
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan1.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹{pricing.plan1.price.Monthly}
                    </div>
                    <div className={styles.planDuration}>Per Member</div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Free
                      </Button>
                    </div>
                    {pricing.plan1.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={6} md={3} sx={{ background: "#FFF6EC" }}>
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan2.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹
                      {!yearly
                        ? pricing.plan2.price.Monthly
                        : pricing.plan2.price.Yearly}
                    </div>
                    <div className={styles.planDuration}>
                      Per Member, Per Month
                    </div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Continue with Basic
                      </Button>
                    </div>
                    {pricing.plan2.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{ background: "rgba(139, 239, 243, 0.12)" }}
                >
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan3.planName}
                    </div>
                    <div className={styles.planPrice}>
                      ₹
                      {!yearly
                        ? pricing.plan3.price.Monthly
                        : pricing.plan3.price.Yearly}
                    </div>
                    <div className={styles.planDuration}>
                      Per Member, Per Month
                    </div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Continue with Standard
                      </Button>
                    </div>
                    {pricing.plan3.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className={styles.planBlock}>
                    <div className={styles.planName}>
                      {pricing.plan4.planName}
                    </div>
                    <div
                      className={styles.planPrice}
                      
                    >
                       ₹ {yearly
                        ? pricing.plan4.price.Yearly
                        : pricing.plan4.price.Monthly}
                    </div>
                    <div className={styles.planDuration}>Per Member, Per Month</div>
                    <div className={styles.planPurchase}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#1B1F23",
                          borderRadius: "4px",
                          textTransform: "none",
                          padding: "8px 0px",
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                          boxShadow: "none",
                          color: "white",

                          "&:hover": { background: "#1B1F23", color: "white" },
                        }}
                        onClick={() => {
                          navigate("/contactus");
                        }}
                      >
                        Continue with Professional
                      </Button>
                    </div>
                    {pricing.plan4.features.map((a) => (
                      <>
                        <div className={styles.planFeaturesWrap}>
                          <img src={doneImage} className={styles.doneimage} />
                          <div className={styles.planFeatures}>{a}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
            
          </>
   
      </div>
    </Element>
  );
}

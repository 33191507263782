import classes from "./OtpScreen.module.scss";
import { useFormik } from "formik";
import { signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import {
  forgetPasswordOtpVerifyAPICall,
  loginOtpApi,
} from "../../Utils/Apihelper";
import { useDispatch, useSelector } from "react-redux";
import { setOtpFlag } from "../../Store/Project/ProjectSlice";
import Alert from "@mui/material/Alert";
import { Toaster, toast } from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import otpImage from "../../assets/Images/otprightimage.svg";
import { CircularProgress } from "@mui/material";

const OtpScreen = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [loginSuccess, setloginSuccess] = useState(false);
  const dispatch = useDispatch();
  const otpFlag = useSelector((state: any) => state.project.otpFlag);
  const emailId = sessionStorage.getItem("email")
    ? sessionStorage.getItem("email")
    : "your email";
  const initialValues = {
    email: "",
  };
  const [otp, setOtp] = useState("");
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        // console.log("values", values);
        // action.resetForm();
        handleVerify();
      },
    });
  

  useEffect(() => {
    handleVerify();
   if(otp.length !== 6){
    seterror(true)
   }
  }, [otp]);

  const handleOtp = (newValue: any) => {
    setOtp(newValue);
  };
  const handleVerify = () => {
    const requestBody = {
      otp: otp,
    };
    const Token = sessionStorage.getItem("Token");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
  seterror(false)
    otp.length === 6 && setloading(true);
    otp.length === 6 &&
      forgetPasswordOtpVerifyAPICall(requestBody, config)
        .then((res) => {
          setloading(false);
          navigate("/changePassword");
          // {
          //   otpFlag
          //     ? navigate("/changePassword")
          //     : navigate("/email-dashboard/analytics");
          // }
          // const ChangePwToken = res.data.accessToken;
          // sessionStorage.setItem("ChangePwToken", ChangePwToken);
          // dispatch(setOtpFlag(false));
        })
        .catch((err) => {
          //  console.log(err.data);
          setloading(false);
          toast.error("Please enter correct otp");
        });
  };
  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d+$/.test(keyValue);

    if (!isValidKey) {
      event.preventDefault();
    }
  };
  return (
    <>
      {loginSuccess && (
        <Alert severity="success" color="info">
          This is a success alert — check it out!
        </Alert>
      )}
      <Toaster />
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <div className={classes.loginData}>
                <p className={classes.modalTitle}>
                  Now let's verify your Email Address!
                </p>
                <p className={classes.modalDesc}>
                  Enter the OTP sent to {emailId} to verify your account
                </p>
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputBlock}>
                    <MuiOtpInput
                      length={6}
                      value={otp}
                      onChange={handleOtp}
                      onKeyPress={handleKeyPress}
                      sx={{
                        ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "4c6fff",
                          },
                        ".MuiOutlinedInput-input": {
                          padding: "0px !important",
                          height: "60px",
                        },
                      }}
                    />
                  </div>
                
                  <Button
                    type="submit"
                    size="lg"
                    loading={loading}
                    style={{
                      padding: "14px 100px",

                      borderRadius: "5px",
                      margin: "20px 0px",
                    }}
                    disabled={error}
                    onClick={handleVerify}
                    sx={{
                      backgroundColor: "4c6fff",
                      "&:disabled": {
                        backgroundColor: "#b9c7ff",
                      },
                      "&:hover": {
                        backgroundColor: "4c6fff",
                      },
                    }}
                    loadingIndicator={
                      <CircularProgress
                        sx={{ color: "#4c6fff" }}
                        size={"25px"}
                      />
                    }
                  >
                    SUBMIT
                  </Button>
                </form>
                {/* <p
                  className={classes.signUp}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ArrowBackIcon fontSize="small" /> Back to Login page
                </p> */}
              </div>
            </div>
            <div className={classes.modalRight}>
              <img src={otpImage} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpScreen;

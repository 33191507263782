import { useEffect } from "react";
import "./App.scss";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Router from "./components/Router/Router";
import ReactGA from "react-ga4";

ReactGA.initialize('G-P6L4ZTC6JT');



function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  return (
    <div className="app">
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </div>
  );
}

export default App;

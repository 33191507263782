import classes from "./RegisterOtp.module.scss";
import { useFormik } from "formik";
import { signUpSchema } from "../../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import { Link, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";
import SuccessModal from "../../../components/SuccessModal/SuccessModal";
import {
  IResendOTP,
  ResendOTPAPICall,
  registerOtpApi,
} from "../../../Utils/Apihelper";
import otpImage from "../../../assets/Images/otprightimage.svg";
import { CircularProgress } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

const RegisterOtp = () => {
  const [emailOtp, setEmailOtp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const mobileNumber: any = sessionStorage.getItem("mobile");
  const emailID: any = sessionStorage.getItem("email");
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        // console.log("values", values);
        action.resetForm();
      },
    });

  const handleEmailOtp = (newValue: any) => {
    setEmailOtp(newValue);
  };

  const handleMobileOtp = (newValue: any) => {
    setMobileOtp(newValue);
  };
  const handleVerify = () => {
    const requestBody = {
      contact_number_otp: mobileOtp,
      email_otp: emailOtp,
    };
    const Token = sessionStorage.getItem("Token");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    registerOtpApi(requestBody, config)
      .then((res) => {
        // console.log(res.data);
        setSuccessModal(true);
      })
      .catch((error: any) => {
        // console.log(error);
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Invalid OTP Entered"
        );
      });
  };

  const handleResendOTP = () => {
    let obj: IResendOTP = {
      contact_number: mobileNumber,
      email_id: emailID,
    };

    ResendOTPAPICall(obj)
      .then((response: any) => {
        // console.log("response", response);
        toast.success("Successfully sent the OTP");
      })
      .catch((error: any) => {
        // console.log("error", error.response.data);
        toast.error(
          error?.response?.data?.message
            ? error.response.data.message
            : "Error sending OTP"
        );
      });
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d+$/.test(keyValue);

    if (!isValidKey) {
      event.preventDefault();
    }
  };
  return (
    <>
      <Toaster />
      {successModal ? (
        <SuccessModal
          heading="Successfully Registered"
          subHeading1={
            "We have sent an email to your registered email address."
          }
          subHeading2={"Please verify your account and then login."}
        />
      ) : (
        <div className={classes.container}>
          <div className={classes.modal}>
            <div className={classes.modalContainer}>
              <div className={classes.modalLeft}>
                <div className={classes.loginData}>
                  <p className={classes.modalTitle}>
                    {" "}
                    Now let's verify your Email Address and Mobile!
                  </p>

                  <form onSubmit={handleSubmit}>
                    <p className={classes.modalDesc}>
                      Enter the OTP sent to +91 {mobileNumber} to verify your
                      number
                    </p>
                    <div className={classes.inputBlock}>
                      <MuiOtpInput
                        length={6}
                        value={mobileOtp}
                        onChange={handleMobileOtp}
                        onKeyPress={handleKeyPress}
                        sx={{
                          ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "4c6fff",
                            },
                          ".MuiOutlinedInput-input": {
                            padding: "0px !important",
                            height: "60px",
                          },
                        }}
                      />
                    </div>
                    <p className={classes.modalDesc}>
                      Enter the OTP sent to {emailID} to verify your email
                    </p>
                    <div className={classes.inputBlock}>
                      <MuiOtpInput
                        length={6}
                        value={emailOtp}
                        onChange={handleEmailOtp}
                        onKeyPress={handleKeyPress}
                        sx={{
                          ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "4c6fff",
                            },
                          ".MuiOutlinedInput-input": {
                            padding: "0px !important",
                            height: "60px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "4c6fff",
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/editCredentials");
                      }}
                    >
                      Do you wish to edit your credentials?
                    </div>

                    <div className={classes.modalButtons}>
                      <Button
                        type="submit"
                        size="lg"
                        style={{
                          padding: "14px 100px",
                          borderRadius: "5px",
                          margin: "20px 0px",
                        }}
                        onClick={handleVerify}
                        sx={{
                          backgroundColor: "4c6fff",
                          "&:disabled": {
                            backgroundColor: "#b9c7ff",
                          },
                          "&:hover": {
                            backgroundColor: "4c6fff",
                          },
                        }}
                        loadingIndicator={
                          <CircularProgress
                            sx={{ color: "#4c6fff" }}
                            size={"25px"}
                          />
                        }
                      >
                        SUBMIT
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                    >
                      Didn't receive OTP?
                      <span
                        style={{
                          marginLeft: "10px",
                          color: "4c6fff",
                          cursor: "pointer",
                        }}
                        onClick={handleResendOTP}
                      >
                        {" "}
                        Resend OTP
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              <div className={classes.modalRight}>
                <img src={otpImage} alt="" className={classes.signupImage} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterOtp;

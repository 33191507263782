import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import cardimage1 from "../../../assets/Images/carouselimage1.svg";
import cardimage2 from "../../../assets/Images/carouselimage2.svg";
import newimagemainlanding from "../../../assets/Images/newimagemainlanding.svg";
import styles from "./MainLandingCarousel.module.scss";
import cardimage3 from "../../../assets/Images/carouselimage3.svg";
import cardimagebg from "../../../assets/Images/carouselroundbg.svg";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { useNavigate } from "react-router-dom";

const anArrayOfNumbers = [
  <EmailIcon sx={{ fill: "inherit", fontSize: "inherit" }} />,
  <WhatsAppIcon sx={{ fill: "inherit", fontSize: "inherit" }} />,
  <QuestionAnswerRoundedIcon sx={{ fill: "inherit", fontSize: "inherit" }} />,
];

export default function CarouselSlider(props) {
  return (
    <div className={styles.carousel}>
      <Carousel
        animation="slide"
        duration={500}
        swipe={true}
        autoPlay={true}
        IndicatorIcon={anArrayOfNumbers}
        indicatorIconButtonProps={{
          style: {
            padding: "10px", // 1
            fill: "#D1D5DB", // 3
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            fill: "4c6fff", // 2
            // fontSize:'25px'
          },
        }}
      >
        <div style={{ width: "100%" }}>
          <Item />
        </div>
        <div style={{ width: "100%" }}>
          <Item2 />
        </div>
        <div style={{ width: "100%" }}>
          <Item3 />
        </div>
      </Carousel>
    </div>
  );
}

function Item() {
  const navigate = useNavigate();
  return (
    <div className={styles.CardWrapper}>
      <div className={styles.leftContainer}>
        <p className={styles.leftContainerText}>Get Started With </p>
        <p className={styles.leftContainerText}>Email Campaigns</p>
        <p className={styles.leftContainersubText}>
          Drive effective campaigns with seamless email integration, sending
          targeted newsletters and follow-ups directly from your CRM
        </p>
        <div className={styles.buttonWrapper}>
          <div>
            <Button
              variant="contained"
              sx={{
                background: "4c6fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": { background: "4c6fff" },
                textTransform: "none",
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              GetStarted
            </Button>
          </div>
          <div>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderColor: "4c6fff",
                color: "4c6fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": {
                  background: "transparent",
                  borderColor: "4c6fff",
                },
              }}
              onClick={() => {
                navigate("/contactus");
              }}
            >
              Book a Demo
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src={cardimage1} className={styles.cardImage1} alt="" />
      </div>
    </div>
  );
}
function Item2() {
  return (
    <div className={styles.CardWrapper2}>
      <div className={styles.leftContainer}>
        <p className={styles.leftContainerText}>Unlock More </p>
        <p className={styles.leftContainerText}>ROI With WhatsApp Marketing</p>
        <p className={styles.leftContainersubText}>
          Maximize engagement by integrating WhatsApp, delivering personalized
          offers and interactive content effortlessly
        </p>
        <div className={styles.buttonWrapper}>
          <div>
            <Button
              variant="contained"
              sx={{
                background: "4c6fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": { background: "4c6fff" },
                textTransform: "none",
              }}
              onClick={() => {}}
            >
              Coming Soon...
            </Button>
          </div>
          {/* <div>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderColor: "4c6fff",
                color: "4c6fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": {
                  background: "transparent",
                  borderColor: "4c6fff",
                },
              }}
              onClick={() => {}}
            >
              Book a Demo
            </Button>
          </div> */}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src={cardimage2} className={styles.cardImage2} alt="" />
      </div>
    </div>
  );
}
function Item3() {
  return (
    <div className={styles.CardWrapper3}>
      <div className={styles.leftContainer}>
        <p className={styles.leftContainerText}>Derive the best from SMS </p>
        <p className={styles.leftContainerText}>
          marketing assure your success
        </p>
        <p className={styles.leftContainersubText}>
          Expand marketing reach with instant SMS integration, sending
          promotions and updates directly to your audience
        </p>
        <div className={styles.buttonWrapper}>
          <div>
            <Button
              variant="contained"
              sx={{
                background: "4c6fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": { background: "4c6fff" },
                textTransform: "none",
              }}
              onClick={() => {}}
            >
              Coming Soon...
            </Button>
          </div>
          {/* <div>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderColor: "4c6fff",
                color: "4c6fff",
                borderRadius: "5px",
                boxShadow: "none",
                "&:hover": {
                  background: "transparent",
                  borderColor: "4c6fff",
                },
              }}
              onClick={() => {}}
            >
              Book a Demo
            </Button>
          </div> */}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src={cardimage3} className={styles.cardImage3} alt="" />
        <img
          src={newimagemainlanding}
          className={styles.cardImage3mobile}
          alt=""
        />
      </div>
    </div>
  );
}

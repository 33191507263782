import React, { useEffect, useState } from "react";
import classes from "./Topbar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCollapsed,
  setDemoModel,
} from "../../../Store/Project/ProjectSlice";
import { Avatar } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RoutesLabelJson from "../../../Utils/RoutesLabel.json";
import companylogo from "../../../assets/Images/CLOGO.svg";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EnotLogotext from "../../../assets/Images/CNOTIFIERS.svg";
import CNOTIFIERS from "../../../assets/Images/CNOTIFIERS.svg";
import EnotLogo from "../../../assets/Images/CLOGO.svg";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import ReactGA from "react-ga4";

const style = {
  position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 600,
  // bgcolor: "background.paper",
  // borderRadius: "10px",
  // boxShadow: 24,
  // p: 4,
};

const styleBox = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 0,
};

const Topbar = () => {
  const dispatch = useDispatch();
  const [changeStyles, setChangeStyles] = useState(false);
  const collapsed = useSelector((state: any) => state.project.collapsed);
  const dashboardScreen = useSelector((state: any) => state.project.dashboard);
  const labels: any = RoutesLabelJson;
  const pathName: string = useLocation().pathname;
  const title: string = labels[pathName];

  const [demoModel, setDemoModel] = React.useState(false);
  const handleOpen = () => {
    setDemoModel(true);
    ReactGA.event({
      category: "TopbarGA",
      action: "Demo Clicked",
      label: "Demo button",
    });
  };
  const handleClose = () => setDemoModel(false);

  const firsttimeUser = sessionStorage.getItem("firstTimeUser");

  useEffect(() => {
    if (firsttimeUser === "true") {
      setDemoModel(true);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
  }, []);

  const startTour = () => {
    handleClose();
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      showProgress: true,
      steps: [
        {
          element: "#demo1",
          popover: {
            title: "Welcome to your personalized dashboard!",
            description:
              "This centralized hub provides you with real-time insights and data visualization to help you manage your transactional, promotional, Deliver & account info.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo2",
          popover: {
            title: "Analytics of your email",
            description:
              "Here you can access the analytics of your email , its delivery details and acount info",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo3",
          popover: {
            title: "Email Templates Made Easy",
            description:
              "Elevate your emails with our gallery's stunning templates. Customize or create from scratch effortlessly. Upgrade your communication game.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo4",
          popover: {
            title: "Place to add your subscribers.",
            description:
              "Individuals who receive updates or notifications via email or other channels from E-notifiers platform are added here.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo5",
          popover: {
            title: "Keep your emails relevant and your brand growing",
            description:
              "Elevate your brand presence through precisely crafted email campaigns from E-Notifiers, ensuring content relevance and fostering sustained growth for your business.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo6",
          popover: {
            title: "Place to access general report.",
            description:
              "Details of email campaigns, provide valuable insights into various metrics and key performance indicators. ",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo7",
          popover: {
            title: "Unlock E-Notifiers' Potential with Amazon Web Services",
            description:
              "Gain access to the robust features of E-Notifiers by securing your Amazon Web Services account.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#demo",
          popover: {
            title: "Kickstart your E-Notifiers journey",
            description:
              " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est officiis fugiat odit doloremque  ",
            side: "left",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  };

  return (
    <>
      <div className={classes.topbarWrapper}>
        <div className={classes.container}>
          <div className={classes.dashTitle}>
            <div className={classes.mobileLogo}>
              <img src={companylogo} alt="" style={{ width: "30px" }} />
              <img
                src={CNOTIFIERS}
                className={classes.cname}
                style={{ width: "100px" }}
              />
              <p
                style={{
                  fontSize: "10px",
                  marginLeft: "3px",
                  marginTop: "5px",
                }}
              >
                (Beta)
              </p>
            </div>

            <div
              onClick={() => dispatch(setCollapsed(!collapsed))}
              className={classes.collapse}
              style={{
                transform: collapsed ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              {!collapsed ? (
                <MenuOpenIcon fontSize="small" color="action" />
              ) : (
                <>
                  {changeStyles ? (
                    <CloseIcon fontSize="small" color="action" />
                  ) : (
                    <MenuOpenIcon fontSize="small" color="action" />
                  )}
                </>
              )}
            </div>
            <div className={classes.crumbNav} style={{ marginLeft: "1rem" }}>
              <BreadCrumb />
            </div>
          </div>
          {!changeStyles && pathName.includes("/email-dashboard/analytics") && (
            <div className={classes.demostyle} onClick={handleOpen}>
              Demo?
            </div>
          )}
        </div>
      </div>
      <div>
        {demoModel && (
          <Modal
            open={demoModel}
            onClose={handleClose}
            disableAutoFocus={true}
            sx={style}
          >
            <Box sx={styleBox}>
              <div className={classes.tourWrap}>
                <div className={classes.tourHeader}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "-webkit-fill-available",
                    }}
                  >
                    <img src={EnotLogo} alt="" style={{ width: "40px" }} />
                    <img src={EnotLogotext} alt="" style={{ width: "110px" }} />
                  </div>
                </div>
                <div className={classes.tourBody}>
                  <div className={classes.tourWelcome}>
                    Welcome to E-notifiers Dashboard
                  </div>
                  <div className={classes.tourPara}>
                    Discover all the tools necessary to engage with your
                    customers right here. Plant the seeds for sustained growth
                    today.
                  </div>
                  <div className={classes.tourButtons}>
                    <div
                      className={classes.tourButton1}
                      onClick={() => handleClose()}
                    >
                      Skip tour
                    </div>
                    <div
                      className={classes.tourButton2}
                      onClick={() => {
                        startTour();
                      }}
                    >
                      Get to know E-Notifiers
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Topbar;

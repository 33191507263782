import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const signUpSchema = Yup.object({
  userName: Yup.string()
    .matches(/^[a-zA-Z0-9_]+$/, "Only alphanumeric characters and underscores are allowed")
    .min(2)
    .max(25)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .required("Please enter your name"),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    )
    .required("Please enter your email"),
  password: Yup.string()
    .min(6)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/,
      "Must have 1 upper,lowercase,number & symbol"
    )
    .required("Please enter your password"),
  contactNumber: Yup.string()
    .required("Mobile Number is Required")
    .max(10)
    .min(8)
    .matches(/^[0-9 ]*$/, "Enter Valid Number"),
  companyname: Yup.string()
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .required("Please enter your Company Name"),
  zipcode: Yup.string()
    .matches(/^\d{6}$/, "Pin code must be exactly 6 digits")
    .required("Pin code is required"),
  city: Yup.string()
    .min(2)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .required("Required field"),
  country: Yup.string()
    .min(2)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .required("Required field"),
  address: Yup.string()
    .min(6)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .required("Required field"),
});
export const signinSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    )
    .required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
});

export const editCredSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    )
    .required("Please enter your email"),
  contactNumber: Yup.string()
    .required("Mobile Number is Required")
    .max(10)
    .min(8)
    .matches(/^[0-9 ]*$/, "Enter Valid Number"),
});

export const forgotpwSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    )
    .max(50)
    .required("Please enter your email"),
});

export const confirmpwSchema = Yup.object({
  password: Yup.string()
    .min(6)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/,
      "Must have 1 upper,lowercase,number & symbol"
    )
    .required("Please enter your password"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
export const checkAWSConnection = Yup.object({
  client_id: Yup.string().required("Client Id is required"),
  client_secret: Yup.string().required("Client Secret is required"),
  region: Yup.string().required("Region is required"),
});
export const createSubscriber = Yup.object({
  name: Yup.string().matches(
    /^(?!.*\s{2,}).*$/,
    "Consecutive spaces are not allowed"
  ),

  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid Email"
    )
    .required("Please enter your email"),
  groupName: Yup.string().required("Select Group"),
});
export const changePasswordSchema = Yup.object({
  oldpassword: Yup.string().required("Old Password is required"),
  newpassword: Yup.string()
    .min(6)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/,
      "Must have 1 upper,lowercase,number & symbol"
    )
    .required("Please enter your password"),
  confirmpassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
});

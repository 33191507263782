import React, { useEffect } from 'react';

function PromptBeforeLeaving() {
  useEffect(() => {
    const confirmationMessage = 'You have unsaved changes. Do you really want to leave?';

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      {/* Your component content */}
    </div>
  );
}

export default PromptBeforeLeaving;
import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import classes from "./Template.module.scss";
import Input from "@mui/joy/Input";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  createTemplateApi,
  editTemplateApi,
  getAllTemplateApi,
  loadTemplateApi,
} from "../../Utils/Apihelper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import templateJson from "../../assets/html.json";
import Alert from "@mui/material/Alert";
import toast, { Toaster } from "react-hot-toast";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import useUnsavedChangesWarning from "../../hooks/UnsavedChanges";
import PromptBeforeLeaving from "../../hooks/UnsavedChanges";

const longText = `
* Promotional email template must contain "Unsubscribe section". 
* For adding variables, use syntax {{Variable1}} inside your template 
`;
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Template = () => {
  const emailEditorRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [html, setHtml] = useState("");
  const [json, setJson] = useState("");

  const [confirmSave, setConfirmSave] = React.useState(false);
  const [renderEmailEditor, setrenderEmailEditor] = useState(true);
  const [saveFileError, setSaveFileError] = useState(false);
  const [deleteFileError, setDeleteFileError] = useState(false);
  const [success, setsuccess] = useState(false);
  const navigate = useNavigate();

  // const top100Films = allTemplate.map((item)=>{
  //   return item;
  //   })

  const handleCloseSave = () => {
    setConfirmSave(false);
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
     // console.log("exportHtml", html);
      toast.success("Template html is logged in Console");
      const encodedString = btoa(html);
      setHtml(encodedString);
    });
  };

  const handleSave = () => {
    // const toastLoad = toast.loading("Loading")
    createTemplateApi(requestBody, config)
      .then((res) => {
      //  console.log(res.data);
        // toast.dismiss(toastLoad)

        if (res.data.code === "200") {
          toast.success("Saved Successsfully");
          navigate("/email-dashboard/allTemplates");
        } else {
          toast.error(res.data.error.message);
        }
      })
      .catch((res) => {
       // console.log(res.data);
        setSaveFileError(true);
        // toast.dismiss(toastLoad)
        toast.error("Template name required");
      });
    setConfirmSave(false);
  };

  // const togglePreview = () => {
  //   if (preview) {
  //     emailEditorRef.current?.editor?.hidePreview();
  //     setPreview(false);
  //   } else {
  //     emailEditorRef.current?.editor?.showPreview("desktop");
  //     setPreview(true);
  //   }
  // };
  const requestBody = {
    templateName: templateName,
    templateString: html,
    templateJson: json,
  };
  const Token = sessionStorage.getItem("ChangePwToken");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((data) => {
      setJson(data);
    });
    function utf8ToBase64(str) {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const base64 = btoa(String.fromCharCode(...data));
      return base64;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      const encodedString = utf8ToBase64(html);
      setHtml(encodedString);
    });
    setConfirmSave(true);
  };

  setTimeout(() => {
    saveFileError && setSaveFileError(false);
    deleteFileError && setDeleteFileError(false);
    success && setsuccess(false);
  }, 2000);

  // emailEditorRef.current.editor.loadBlank({
  //   backgroundColor: '#ffffff'
  // });
  useEffect(() => {
    const confirmationMessage =
      "You have unsaved changes. Do you really want to leave?";

    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    });

    return () => {
      window.removeEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      });
    };
  }, []);

  return (
    <div>
      <Toaster />
      <div
        className={classes.backFeature}
        onClick={() => {
          navigate("/email-dashboard/allTemplates");
        }}
      >
        <ArrowBackIcon fontSize="small" />
        Back to all templates
      </div>
      <div className={classes.topbarWrap}>
        <div className={classes.buttonWrapper}>
          <TextField
            size="small"
            fullWidth
            label="Template Name"
            variant="outlined"
            value={templateName}
            onChange={(event) => {
              const newValue = event.target.value.replace(/\s/g, "_");
              setTemplateName(newValue);
            }}
          />
          <div className={classes.infoIcon}>
            <IconButton aria-label="Info">
              <BootstrapTooltip title={longText}>
                <InfoIcon />
              </BootstrapTooltip>
            </IconButton>
          </div>
        </div>

        <div className={classes.buttonWrapper}>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className={classes.buttons}
          >
            Create New
          </button>
          <button onClick={exportHtml} className={classes.buttons}>
            Export HTML
          </button>
          <button onClick={saveDesign} className={classes.buttons}>
            Save Design
          </button>
        </div>
      </div>

      {renderEmailEditor ? (
        <EmailEditor ref={emailEditorRef} options={{ displayMode: "email" }} />
      ) : (
        <div className={classes.loadingspinner}>
          <div className={classes.square1} id="square1"></div>
          <div className={classes.square2} id="square2"></div>
          <div className={classes.square3} id="square3"></div>
          <div className={classes.square4} id="square4"></div>
          <div className={classes.square5} id="square5"></div>
        </div>
      )}

      {/* <button onClick={togglePreview}>Preview</button> */}

      <Dialog
        open={confirmSave}
        onClose={handleCloseSave}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"primary"}>
          Save Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to save this Template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSave}>Cancel</Button>
          <Button onClick={handleSave} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <PromptBeforeLeaving />
    </div>
  );
};

export default Template;

import React from "react";
import styles from "./MainlandingPageTabs.module.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import tabsImage1 from "../../../assets/Images/tabsimage1.svg";
import tabsImage2 from "../../../assets/Images/tabsimage2.svg";
import tabsImage3 from "../../../assets/Images/tabsimage3.png";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";

export default function MainlandingPageTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.topContentContainer}>
        <p className={styles.topContentTitleText}>
          Automated Communication for Enhanced Engagement
        </p>

        <p className={styles.topContentSubText}>
          Streamlined automation optimizes communication channels, amplifying
          engagement and fostering stronger connections with your audience
        </p>
      </div>
      <div className={styles.tabsWrapper}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "#E1E3E6", width:{xs:'100%', sm:'70%'} }}>
            <TabList
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              centered
              variant="scrollable"
              TabIndicatorProps={{
                style: { backgroundColor: "4c6fff", color: "4c6fff" },
              }}
              scrollButtons="auto"
            >
              <Tab
                icon={
                  <TabLabel
                    icon={<EmailIcon sx={{fill:'inherit', fontSize:'30px'}}/>}
                    label={"Email"}
                    bottomText={"Professional communication channel"}
                  />
                }
                iconPosition="top"
                label=""
                value="1"
                sx={{
                  textTransform: "capitalize",
                  margin: "0 3%",
                  fill: "#D1D5DB",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "0px",

                  "&.Mui-selected": {
                    fill: "4c6fff",
                  },
                }}
              />
              <Tab
                icon={
                  <TabLabel
                    icon={<QuestionAnswerRoundedIcon  sx={{fill:'inherit', fontSize:'30px'}}/>}
                    label={"SMS"}
                    bottomText={"Quick and concise messaging"}
                  />
                }
                iconPosition="top"
                label=""
                value="2"
                sx={{
                  textTransform: "capitalize",
                  fill: "#D1D5DB",
                  margin: "0 4%",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "0px",

                  "&.Mui-selected": {
                    fill: "4c6fff",
                  },
                }}
              />
              <Tab
                icon={
                  <TabLabel
                    icon={<WhatsAppIcon sx={{fill:'inherit', fontSize:'30px'}}/>}
                    label={"WhatsApp"}
                    bottomText={"Interactive channel for conversations"}
                  />
                }
                iconPosition="top"
                label=""
                value="3"
                sx={{
                  textTransform: "capitalize",
                  fill: "#D1D5DB",
                  margin: "0 3%",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "0px",

                  "&.Mui-selected": {
                    fill: "4c6fff",
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              padding: "0px",
            }}
          >
            <div className={styles.tabs}>
              <div className={styles.CardWrapper}>
                <div className={styles.leftContainer}>
                  <p className={styles.leftContainerText}>
                    Efficient Email CRM for Streamlined
                  </p>
                  <p className={styles.leftContainerText1}>
                    Promotional Activities
                  </p>
                  <p className={styles.leftContainersubText}>
                    Our Email CRM offers a streamlined solution for your
                    promotional activities. With a curated collection of
                    templates designed to captivate and convert, effortlessly
                    craft compelling campaigns and updates to drive engagement
                    and boost sales.
                  </p>
                  <p className={styles.leftContainersubText}>
                    From eye-catching campaigns to personalized updates, empower
                    your outreach and cultivate lasting customer relationships
                    with ease.
                  </p>
                  {/* <div className={styles.buttonWrapper}>
                    <div>
                      <Button
                        endIcon={<EastIcon />}
                        variant="contained"
                        sx={{
                          background: "4c6fff",
                          borderRadius: "50px",
                          boxShadow: "none",
                          padding: "10px 20px",
                          "&:hover": { background: "4c6fff" },
                          textTransform: "none",
                        }}
                        onClick={() => {}}
                      >
                        Learn More
                      </Button>
                    </div>
                  </div> */}
                </div>
                <div className={styles.rightContainer}>
                  <img src={tabsImage1} className={styles.cardImage1} alt="" />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              padding: "0px",
            }}
          >
            <div className={styles.tabs3}>
              <div className={styles.CardWrapper}>
                <div className={styles.leftContainer}>
                  <p className={styles.leftContainerText}>
                    Streamlined SMS Communication for Instant Engagement
                  </p>
                  <p className={styles.leftContainerText1}></p>
                  <p className={styles.leftContainersubText}>
                    Transform your outreach with our SMS solution, ensuring
                    instant engagement and fostering lasting connections. Share
                    updates, promotions and offers seamlessly. Personalized to
                    resonate with your audience and cultivate meaningful
                    relationships effortlessly.
                  </p>
                  <p className={styles.leftContainersubText}>
                    
                  </p>
                  {/* <div className={styles.buttonWrapper}>
                    <div>
                      <Button
                        endIcon={<EastIcon />}
                        variant="contained"
                        sx={{
                          background: "4c6fff",
                          borderRadius: "50px",
                          boxShadow: "none",
                          padding: "10px 20px",
                          "&:hover": { background: "4c6fff" },
                          textTransform: "none",
                        }}
                        onClick={() => {}}
                      >
                        Learn More
                      </Button>
                    </div>
                  </div> */}
                </div>
                <div className={styles.rightContainer}>
                  <img src={tabsImage3} className={styles.cardImage1} alt="" />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value="3"
            sx={{
              padding: "0px",
            }}
          >
            <div className={styles.tabs3}>
              <div className={styles.CardWrapper}>
                <div className={styles.leftContainer}>
                  <p className={styles.leftContainerText}>
                    Seamless WhatsApp Messaging for
                  </p>
                  <p className={styles.leftContainerText1}>Enhanced Outreach</p>
                  <p className={styles.leftContainersubText}>
                    Elevate your communication game with our WhatsApp messaging
                    solution. Seamlessly connect with your audience, share
                    updates, promotions, and offers instantly, and cultivate
                    strong customer relationships with personalized interactions
                    tailored to their preferences and needs.
                  </p>
                  <p className={styles.leftContainersubText}>
                    With our intuitive platform, optimize your messaging
                    campaigns for maximum impact, driving conversions and
                    boosting brand loyalty.
                  </p>
                  {/* <div className={styles.buttonWrapper}>
                    <div>
                      <Button
                        endIcon={<EastIcon />}
                        variant="contained"
                        sx={{
                          background: "4c6fff",
                          borderRadius: "50px",
                          boxShadow: "none",
                          padding: "10px 20px",
                          "&:hover": { background: "4c6fff" },
                          textTransform: "none",
                        }}
                        onClick={() => {}}
                      >
                        Learn More
                      </Button>
                    </div>
                  </div> */}
                </div>
                <div className={styles.rightContainer}>
                  <img src={tabsImage2} className={styles.cardImage1} alt="" />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}
export const TabLabel = ({ icon, label, bottomText }) => {
  return (
    <div className={styles.tabsLabel}>
      <div>{icon}</div>
      <p className={styles.labelText}>{label}</p>
      <p className={styles.bottomText}>{bottomText}</p>
    </div>
  );
};

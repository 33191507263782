import React, { useState } from "react";
import styles from "./ScrollHeader.module.scss";
import logo from "../../../assets/Images/logoEnotifiers.svg";
import { Button, Drawer, IconButton } from "@mui/material";
import companytext from "../../../assets/Images/enotifiers-logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

export default function ScrollHeader({ scrollToRef }: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.leftContainer}>
        <div className={styles.leftContainerLogoSection}>
          <img
            src={logo}
            className={styles.leftContaineLogo}
            onClick={() => navigate("/")}
          />
          {/* <img src={companytext} style={{marginTop:'3px'}} /> */}
          {/* <p className={styles.leftContaineLogoText}>E-Notifiers</p> */}
        </div>
        {pathName !== "/returnpolicy" &&
          pathName !== "/termsandconditions" &&
          pathName !== "/privacypolicy" && (
            <div className={styles.leftContainerLinksSection}>
              <p
                className={styles.leftContainerLinks}
                onClick={() => navigate("/whatsapp")}
              >
                Products
              </p>
              <p
                className={styles.leftContainerLinks}
                onClick={() => scrollToRef("templateRef")}
              >
                Templates
              </p>
              <p
                className={styles.leftContainerLinks}
                onClick={() => scrollToRef("featuresRef")}
              >
                Features
              </p>

              <p
                className={styles.leftContainerLinks}
                onClick={() => navigate("/pricings")}
              >
                Pricing
              </p>
            </div>
          )}
      </div>
      <div className={styles.rightContainer}>
        <p
          className={styles.rightContainerLoginText}
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </p>
        <div>
          <Button
            variant="contained"
            sx={{
              background: "4c6fff",
              borderRadius: "45px",
              boxShadow: "none",
              "&:hover": { background: "4c6fff" },
              textTransform: "capitalize",
            }}
            onClick={() => {
              navigate("/register");
            }}
          >
            Signup
          </Button>
        </div>
      </div>
      <div className={styles.mobileMenu}>
        <IconButton
          sx={{
            transform: openMenu ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
          }}
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          {openMenu ? (
            <CloseIcon fontSize="small" color="action" />
          ) : (
            <MenuOpenIcon fontSize="small" color="action" />
          )}
        </IconButton>
      </div>
      <div className={styles.mobileDrawer}>
        <Drawer
          anchor={"right"}
          open={openMenu}
          onClose={() => {
            setOpenMenu(false);
          }}
          variant={"persistent"}
          sx={{
            "&.MuiDrawer-root .MuiDrawer-paper": {
              marginTop: "55px",
              width: "100%",
              padding: "20px",
              background: "#F7F9FC",
            },
            "&.MuiDrawer-root": {
              background: "none",
            },
          }}
        >
          <div className={styles.drawerContent}>
            <div className={styles.leftContainerLinksSectiondrwaer}>
              <p
                className={styles.leftContainerLinks}
                onClick={() => {
                  setOpenMenu(false);
                  scrollToRef("templateRef");
                }}
              >
                Templates
              </p>
              <p
                className={styles.leftContainerLinks}
                onClick={() => {
                  scrollToRef("featuresRef");
                  setOpenMenu(false);
                }}
              >
                Features
              </p>

              <p
                className={styles.leftContainerLinks}
                onClick={() => {
                  scrollToRef("pricingRef");
                  setOpenMenu(false);
                }}
              >
                Pricing
              </p>
            </div>
            <div className={styles.rightContainerDrwaer}>
              <p
                className={styles.rightContainerLoginText}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </p>
              <div>
                <Button
                  variant="contained"
                  sx={{
                    background: "4c6fff",
                    borderRadius: "45px",
                    boxShadow: "none",
                    padding: "10px 25px",
                    "&:hover": { background: "4c6fff" },
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Signup
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

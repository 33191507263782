import useBreadcrumbs from "use-react-router-breadcrumbs";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link, { LinkProps } from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink, useLocation } from "react-router-dom";
import styles from "./BreadCrumb.module.scss";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

interface Props {
  match: any;
}

const DynamicUserBreadcrumb = ({ match }: Props) => {
  return (
    <span>
      {/* {routesJson[match.params.id]
        ? routesJson[match.params.id]
        : match.params.id} */}
      {match.params.id}
    </span>
  );
};

interface PropNew {
  someProp: any;
}

const CustomPropsBreadcrumb = ({ someProp }: PropNew) => (
  <span>{someProp}</span>
);

export default function BreadCrumb() {
  const editEmail = sessionStorage.getItem("editEmail");
  const pathName = useLocation().pathname;
  const routes: any = [
    { path: "/email-dashboard/analytics", breadcrumb: "Email Dashboard" },
    { path: "/sms-dashboard/analytics", breadcrumb: "SMS Dashboard" },
    {
      path: "/email-dashboard/subscriberList/subscriberInfo",
      breadcrumb: DynamicUserBreadcrumb,
    },
    { path: "/email-dashboard/subscriberList", breadcrumb: "Subscribers" },
    { path: "/email-dashboard/allTemplates", breadcrumb: "Templates" },
    
    // { path: "/mutual-fund/filter", breadcrumb: "All Mutual Funds" },
    // { path: "/mutual-fund/holdings", breadcrumb: "Holdings" },
    // { path: "/mutual-fund/orders", breadcrumb: "Orders" },
    // { path: "/mutual-fund/sip", breadcrumb: "SIPs" },
    // { path: "/etf-sgb", breadcrumb: "SGB" },
    // { path: "/ipo-ofc", breadcrumb: "IPOs" },
    // { path: "/mutual-fund/watchlist", breadcrumb: "Watchlist" },
    // { path: "/mutual-fund/portfolio", breadcrumb: "Portfolio" },
    // { path: "/email-dashboard/", breadcrumb: "" },
    // { path: "/mutual-fund/:sid/:id", breadcrumb: DynamicUserBreadcrumb },
    // {
    //   path: "/email-dashboard/analytics",
    //   breadcrumb: CustomPropsBreadcrumb,
    //   props: { someProp: "Dashboard" },
    // },
  ];
  const breadcrumbshook = useBreadcrumbs(routes, {
    disableDefaults: false,
    excludePaths: ["/", "/email-dashboard","/sms-dashboard"],
  });
  // console.log("breadcrumbshook",breadcrumbshook)
  return (
    <div style={{ fontSize: "12px" }} className={styles.wrapper}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon color="primary" sx={{ fontSize: "18px" }} />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbshook.map(({ match, breadcrumb }, index) => {
          if (breadcrumbshook.length === 1) return <>{breadcrumb}</>;
          if (index === breadcrumbshook.length - 1)
            return (
              <Typography color="primary.light" sx={{ fontSize: "13px" }}>
                  {editEmail === "true" &&
              pathName.includes("/email-dashboard/emails/create")
                ? "Edit"
                : breadcrumb}
           
              </Typography>
            );

          return (
            <LinkRouter
              underline="hover"
              color="primary"
              key={match.pathname}
              to={match.pathname}
              fontSize={13}
            >
                {breadcrumb}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

import React from "react";
import styles from "./BottomSection.module.scss";
import bottomimage from "../../../../assets/Images/whatsapptemplatebottomimage.svg";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import codeImage from "../../../../assets/Images/codethemeimage.svg";
import analysisimage from "../../../../assets/Images/analysisimage.svg";
import iconimage from "../../../../assets/Images/Invoicing.svg";

export default function BottomSection() {
  return (
    <>
      <div className={styles.topWrapper}>
        <div className={styles.lcontainer}>
          <img src={analysisimage} className={styles.analysisimage} alt="" />
        </div>
        <div className={styles.rcontainer}>
          <img src={iconimage} className={styles.iconimage} alt="" />
          <p className={styles.orangeText}>
            {" "}
            Enhance Performance and Expand Impact
          </p>
          <p className={styles.headtext}>Leveraging Intelligent Analytics</p>
          <p className={styles.description}>
            Utilize intelligent analytics powered by our Dynamic Automation
            Platform to boost essential business metrics, improving lead
            generation, conversion rates, and campaign effectiveness for
            tangible, significant outcomes.
          </p>
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
          <p className={styles.mainText}>Incorporate the</p>
          <p className={styles.mainText}>WhatsApp Business</p>
          <p className={styles.mainText}>Platform API rapidly.</p>

          <p className={styles.mainTextdesc}>
            Begin seizing opportunities to enhance communication scalability and
            boost engagement using a versatile API backed by developers.
          </p>
          <div>
            <Button
              variant="contained"
              sx={{
                background: "#0D1D24",
                textTransform: "none",
                padding: "10px 30px",
                borderRadius: "12px",
                boxShadow: "none",

                "&:hover": {
                  background: "#0D1D24",
                  boxShadow: "none",
                },
              }}
            >
              Try it for free
            </Button>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <img src={codeImage} className={styles.codeimage} alt="" />
        </div>
      </div>

      <div className={styles.mainWrapper}>
        <div className={styles.topContainer}>
          <p className={styles.topContainerText}>
            READY TO SEND YOUR &nbsp;
            <div className={styles.spantext}>
              <video autoPlay={true} loop muted className={styles.video}>
                <source
                  src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/textbg.mp4"
                  type="video/mp4"
                />
              </video>
              <div className={styles.vdText}>FIRST</div>
            </div>
          </p>
        </div>
        <div className={styles.topContainer}>
          <p className={styles.topContainerText}>MESSAGE ON</p>
          <p className={styles.topContainerSubText}>
            Begin quickly and effortlessly, without any commitments.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img src={bottomimage} className={styles.bottomImage} alt="" />
        </div>
        <div className={styles.buttonSection}>
          <div>
            <Button
              variant="contained"
              sx={{
                background: "#0D1D24",
                textTransform: "none",
                padding: "10px 30px",
                borderRadius: "12px",
                boxShadow: "none",

                "&:hover": {
                  background: "#0D1D24",
                  boxShadow: "none",
                },
              }}
            >
              Get Started
            </Button>
          </div>
          <div>
            <Button
              variant="text"
              endIcon={<EastIcon color="inherit" />}
              sx={{
                background: "#none",
                textTransform: "none",
                color: "#6C757A",
                "&:hover": {
                  background: "none",
                  color: "#FF5303",
                },
              }}
            >
              Contact an expert
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./constants";

const axiosInterceptor = axios.create({
  baseURL: BASE_URL,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = sessionStorage.getItem("Token");

    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      console.log("Logout", status);
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  sessionStorage.clear();
  window.location.href = "/login";
};

export const createSubscriberApi = (requestBody: ICreateSubscriberPayload) => {
  return axiosInterceptor.post("/api/V1/subscribers/add_one", requestBody);
};
export const createBulkSubscribersApi = (requestBody: any) => {
  return axiosInterceptor.post(
    "/api/V1/subscribers/add_bulk_subs",
    requestBody
  );
};
export const getAllGroupsDetailsApi = () => {
  return axiosInterceptor.get(`/api/V1/groups/get_all_groups_details`);
};

export const getAllGroupsApi = () => {
  return axiosInterceptor.get(`/api/V1/groups/get_all_groups`);
};
export const getGroupsDetailsApi = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/groups/get_group_details`, requestBody);
};
export const createGroupApi = (requestBody: ISingleGroup) => {
  return axiosInterceptor.post(`/api/V1/groups/create`, requestBody);
};
export const userRegisterApi = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/userRegister`, requestBody);
};

export const registerOtpApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(
    `/api/V1/verify_initial_otp`,
    requestBody,
    config
  );
};

export const loginApi = (requestBody: any) => {
  return axiosInterceptor.post(`/token`, requestBody);
};

export const loginOtpApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/verifyOTP`, requestBody, config);
};

export const forgetPasswordApi = (requestBody: any) => {
  return axiosInterceptor.post(`/forgetPassword`, requestBody);
};

export const changePasswordApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/changePassword`, requestBody, config);
};

// export const createTemplateApi = (requestBody: any, config: any) => {
//   return axiosInterceptor.post(`/templates/create`, requestBody, config);
// };

export const editTemplateApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/templates/edit`, requestBody, config);
};

export const getAllTemplateApi = (config: any) => {
  return axiosInterceptor.get(`/templates/get_all_templates_list`, config);
};

export const loadTemplateApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/templates/temp_details`, requestBody, config);
};

export const deleteTemplateApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/templates/delete`, requestBody, config);
};

export const getallcatagoryApi = (config: any) => {
  return axiosInterceptor.get(`/getAllCategories`, config);
};

export const getCatagoryDetailsApi = (requestbody: any, config: any) => {
  return axiosInterceptor.post(`/getCategory`, requestbody, config);
};

export const deleteAllCampaignApi = (config: any) => {
  return axiosInterceptor.delete(`/deleteAllCategories`, config);
};

export const deleteCampaignApi = (requestBody: any) => {
  return axiosInterceptor.delete(`/deleteCatogary`, { data: requestBody });
};
export const deleteSubscriberApi = (requestBody: any) => {
  return axiosInterceptor.delete(`/deleteSubscriber`, { data: requestBody });
};

export const createCategoryApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/categoryCreation`, requestBody, config);
};

export const getVariablesApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(`/templates/getvariables`, requestBody, config);
};

export const sendAdminEmailApi = (requestBody: any, config: any) => {
  return axiosInterceptor.post(
    `/emails/send_templated_mail_admin_transactional`,
    requestBody,
    config
  );
};

export const alldataStatApi = () => {
  return axiosInterceptor.post(`/all_data_statistics`);
};
export const catagoryStatApi = (requestBody: any) => {
  return axiosInterceptor.post(`/category_statistics`, requestBody);
};

export const getAllCatDetailsApi = () => {
  return axiosInterceptor.get(`/getAllCategoriesDetails`);
};

export const getAllTransactionalCategoriesApi = () => {
  return axiosInterceptor.get(`/getAllTransactionalCategories`);
};

export const getAllPromotionalCategoriesApi = () => {
  return axiosInterceptor.get(`/getAllPromotionalCategories`);
};
export const getVerifiedEmailApi = () => {
  return axiosInterceptor.get(`/getVerifiedEmails`);
};

export const getAllSubscribersApi = () => {
  return axiosInterceptor.get(`/getAllSubDetails`);
};
export const subscriberBulkAddApi = (requestBody: any) => {
  return axiosInterceptor.post(`/subscribers/create_bulk_subs`, requestBody);
};

export const promotionalEmailSendApi = (requestBody: any) => {
  return axiosInterceptor.post(
    `/emails/send_templated_mail_admin_promotional`,
    requestBody
  );
};

export const createOneSubApi = (requestBody: any) => {
  return axiosInterceptor.post(`/subscribers/create_one`, requestBody);
};
export const getAlltemplateDetailsApi = () => {
  return axiosInterceptor.get(`/templates/get_all_templates_details`);
};

export const getOneSubApi = (requestBody: any) => {
  return axiosInterceptor.post(`/each_subscriber_statistics`, requestBody);
};

export const unsubscribeApi = (requestBody: any) => {
  return axiosInterceptor.post(`/unsubscribeButton`, requestBody);
};

export const smsSendApi = (requestBody: any) => {
  return axiosInterceptor.post(`/sendOTP/sendSMSbulk/`, requestBody);
};

export const getPromotionalTableDataApi = () => {
  return axiosInterceptor.get(`/getAllPromotionalDetails`);
};

export const getTransactionalTableDataApi = () => {
  return axiosInterceptor.get(`/getAllTransactionalDetails`);
};

export const getTransactionalStatDataApi = () => {
  return axiosInterceptor.post(`/all_transactional_data_statistics`);
};

export const getPromotionalStatDataApi = () => {
  return axiosInterceptor.post(`/all_promotional_data_statistics`);
};
export const templateCloneApi = (requestBody: any) => {
  return axiosInterceptor.post(`/templates/duplicate`, requestBody);
};

export const editCampaignApi = (requestBody: any) => {
  return axiosInterceptor.post(`/editCategory`, requestBody);
};

export const duplicateCatagoryApi = (requestBody: any) => {
  return axiosInterceptor.post(`/duplicateCategory`, requestBody);
};
export const subscriberActivityApi = (requestBody: any) => {
  return axiosInterceptor.post(`/subscriber_activity`, requestBody);
};
export const campaignActivityApi = (requestBody: any) => {
  return axiosInterceptor.post(`/category_activity`, requestBody);
};

export const allgraphStatisticsApi = () => {
  return axiosInterceptor.post(`/monthwise_statistics`);
};
export const transactionalGraphStatisticsApi = () => {
  return axiosInterceptor.post(`/monthwise_transactional_statistics`);
};
export const promotionalGraphStatisticsApi = () => {
  return axiosInterceptor.post(`/monthwise_promotional_statistics`);
};

export const allDeliveryGraphApi = () => {
  return axiosInterceptor.post(`/device_bar_graph`);
};

export const editUserApi = (requestBody: any) => {
  return axiosInterceptor.put(`/editCredientials`, requestBody);
};
// **********************NotifiersUpdate*************************

export const unsubscribeAPICall = (company: any, emailId: any, group: any) => {
  return axiosInterceptor.get(
    `/api/V1/unsubscribe/${company}/${emailId}/${group}`
  );
};

export const eachSubscriberAPICall = (subscriber: any) => {
  return axiosInterceptor.get(`/api/V1/subscribers/stats/${subscriber}`);
};

export const getAllCatList = () => {
  return axiosInterceptor.get(
    `/api/V1/templatesCategory/get_all_template_categories`
  );
};

export const getFAQAPIcall = () => {
  return axiosInterceptor.get(`/api/V1/FAQ/get_FAQ_details`);
};

export const createTemplateApi = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/templates/create`, requestBody);
};

export const editTemplateAPICall = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/templates/edit`, requestBody);
};
export const forgetPasswordAPICall = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/forgetPassword`, requestBody);
};
export const editCredientialsAPICall = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/editUserDetailsRegistry`, requestBody);
};

export const changeForgetPasswordAPICall = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/change_forget_password`, requestBody);
};

export const forgetPasswordOtpVerifyAPICall = (
  requestBody: any,
  config: any
) => {
  return axiosInterceptor.post(
    `/api/V1/verify_password_otp`,
    requestBody,
    config
  );
};

export const createtemplateCategory = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/templatesCategory/create`, requestBody);
};

export const deleteEmailAPICall = (requestBody: any) => {
  return axiosInterceptor.delete(`/api/V1/mail/delete_mail`, {
    data: requestBody,
  });
};

export const deleteTemplateAPICall = (requestBody: any) => {
  return axiosInterceptor.delete(`/api/V1/templates/delete`, {
    data: requestBody,
  });
};
export const deleteSubscriverAPICall = (requestBody: any) => {
  return axiosInterceptor.delete(`/api/V1/subscribers/delete`, {
    data: requestBody,
  });
};

export const loadTemplateAPICall = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/templates/temp_details`, requestBody);
};

export const downloadcsvAPI = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/email/download_stats_csv`, requestBody);
};

export const downloadPdfAPI = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/email/download_stats_pdf`, requestBody);
};

export const getCatDetailsAPICall = () => {
  return axiosInterceptor.get(
    `/api/V1/templatesCategory/get_all_template_categories_details`
  );
};

export const PaymentApi = (requestBody: any) => {
  return axiosInterceptor.post(
    `https://enotifiers-api.thefirstock.tech/initialization_Payment`,
    requestBody
  );
};

export const createEmailApi = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/email/create_email`, requestBody);
};

export const GetAllStatsDashoardAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/email/getAllStats`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const deleteSingleTemplateAPICall = (requestBody: any) => {
  return axiosInterceptor.delete(`/api/V1/templatesCategory/delete`, {
    data: requestBody,
  });
};

export const EditGroupNameAPICall = (requestBody: IEditGroupName) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/groups/edit`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteGroupAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/api/V1/groups/delete`, { data: requestBody })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetEachEmailAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/getEachMailDetails`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const EditEmailAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/edit_email`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const sendPromotionalEmailAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/send_promotional_mail`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetAllTemplatesListAPICall = (requestBody: IGetAllTemplates) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/templates/get_all_templates`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DuplicateTemplatesAPICall = (requestBody: IDuplicateTemplate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/templates/duplicate`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetAllTemplatesAPICall = (requestBody: IGetAllTemplates) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/templates/get_all_templates_details`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const EditTemplateCategoryNameAPICall = (
  requestBody: IEdittemplateCategoryName
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/templatesCategory/edit`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const DeleteTemplateCategoryAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/api/V1/templatesCategory/delete`, { data: requestBody })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getAWSConnection = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/get_aws_connection`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getAWSreport = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/get_aws_report`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getSetupAWSNotifers = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/setup_aws_notifiers`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const getDeleteAWSNotifersAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/delete_aws_notifiers`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const getIdentitiesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/get_identities`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetDomainRecordsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/get_domain_a_records`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const initaliseAWSConnection = (
  requestBody: IInitaliseAWSConnection
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/initalise_aws_connection`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ChangeAWSConnectionAPICall = (
  requestBody: IInitaliseAWSConnection
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/change_aws_connection`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const addDomainIdentityAPICall = (requestBody: IAddDomainIdentity) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/add_domain_identity`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteEmailIdentityAPICall = (
  requestBody: IDeleteEmailIdentity
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/delete_email_identity`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteDomainIdentityAPICall = (
  requestBody: IAddDomainIdentity
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/delete_domain_identity`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AddEmailIdentityAPICall = (requestBody: IDeleteEmailIdentity) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/add_email_identity`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const CheckDomainStatusAPICall = (requestBody: ICheckDomainStatus) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/check_domain_n_email_verification_status`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetAllMailDetailsAPICall = (requestBody: IGetAllMailDetails) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/get_all_mail_details`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const logDownloadAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/download_stats`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const duplicateEmailAPICall = (requestBody: IDuplicateEmail) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/duplicate_email`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getVariablesAPICall = (requestBody: IGetVariables) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/templates/getvariables`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const sendTransactionalEmailAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/emails/send_transactional_mail`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const ResendOTPAPICall = (requestBody: IResendOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/resend_otp`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const advanceLogGetApicall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/email/advance_log_options`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const advanceLogGetApicall2 = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/advance_log_search_mail_based/post`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const advanceLogApicall = (requestBody: any, requestBody2: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/advance_log_search`, requestBody, {
        params: {
          pageNo: requestBody2.pageno,
          pageLimit: requestBody2.pagelimit,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getBounceEmails = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/email/getBounceByGroup`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getPremiumTemplatesApiCall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/templates/store/get_all_templates_details`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getPremiumTempDetails = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/templates/store/get_templates_details`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getPremiumTempClone = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/templates/store/clone_template`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const paymenyInitialization = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/initialization_Payment`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetPaymentStatusAPICall = (requestBody: any, config: any) => {
  return axios.post(
    `${BASE_URL}/api/V1/phonePe/paymentStatus`,
    requestBody,
    config
  );
};

export const GetPaymentStatusAPICall2 = (requestBody: any) => {
  return axiosInterceptor.post(`/api/V1/phonePe/paymentStatus`, requestBody);
};

export const getBillingDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/payment/details`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const updateBillingDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/payment/update`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const amountDetailsAPIcall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/payment/amountDetails`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const subcribeAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/service/subscriber/add`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const contactUsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/enquiry/create`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getUserDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/user/details`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const paymentHistoryAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/paymentHistory`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getCredentialsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/credentials/get`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const generateCredentialsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/credentials/generate`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getPaymentHistorysAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/payment/history`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getPaymentInvoiceAPICall = (transactionId: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/invoice/get/`, {
        params: { transactionId },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const changePasswordAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/password/update`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// DTOS
export interface IGetAllGroupsDetailsResponse {
  status: string;
  message: string;
  data: {
    all_groups: ISingleGroupDetails[];
  };
}

export interface IGetVariables {
  mailName: string;
}

export interface IGetAllGroupsResponse {
  status: string;
  message: string;
  data: ISingleGroup[];
}
export interface IGetGroupDetailsResponse {
  status: string;
  message: string;
  data: {
    group: ISingleGroupDetails[];
  };
}

export interface ICreateSubscriberPayload {
  email: string;
  name: string;
  groupName: string;
  source: string;
}

export interface ICreateGroupResponse {
  status: string;
  message: string;
  data: {
    message: string;
  };
}
export interface ISingleGroupDetails {
  groupName: string;
  subscribersList: ISubscriberList[];
  unSubscribersList: [];
  deletedSubscribersList: [];
  createdAt: string;
  modifiedAt: string;
  disable: false;
  subscribers_adding_precentage: string;
}

export interface ISubscriberList {
  dateTime: string;
  email: string;
  name: string;
  source: string;
}

export interface ISingleGroup {
  groupName: string;
}
export interface IGetAllTemplates {
  templateCategory: string;
}
export interface IEditGroupName {
  oldGroupName: string;
  newGroupName: string;
}
export interface IEdittemplateCategoryName {
  oldTemplateCategoryName: string;
  newTemplateCategoryName: string;
}
export interface IInitaliseAWSConnection {
  client_id: string;
  client_secret: string;
  region: string;
}
export interface IAddDomainIdentity {
  domain: string;
}
export interface ICheckDomainStatus {
  identity: string;
}
export interface IDeleteEmailIdentity {
  email: string;
}
export interface IDuplicateTemplate {
  originaltemplateName: string;
  duplicatetemplateName: string;
  newtemplateCategoryName: string;
  oldtemplateCategoryName: string;
}

export interface IGetAllMailDetails {
  mail_type: string;
}

export interface IDuplicateEmail {
  original_mail_name: string;
  duplicate_mail_name: string;
}

export interface IDeleteEmail {
  categoryName: string;
}
export interface IGetEachEmailDetails {
  mail_name: string;
}
export interface IResendOTP {
  contact_number: any;
  email_id: string;
}

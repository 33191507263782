import { useEffect, useRef, useState } from "react";
import classes from "./Mainboard.module.scss";
import Topbar from "../Topbar/Topbar";
import GeneralBoard from "../../GeneralBoard/GeneralBoard";
import { useDispatch, useSelector } from "react-redux";
import Campaigns from "../../Campaigns/Campaigns";
import SubscribersList from "../../SubscribersList/SubscribersList";
import Template from "../../Template/Template";
import CreateCampaigns from "../../CreateCampaigns/CreateCampaigns";
import SubscriberInfo from "../../SubscriberInfo/SubscriberInfo";
import CampaignDetails from "../../CampaignDetails/CampaignDetails";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import ViewSidebarRoundedIcon from "@mui/icons-material/ViewSidebarRounded";
import { setCollapsed } from "../../../Store/Project/ProjectSlice";
import { useLocation } from "react-router";

interface Props {
  children: any;
}

const Mainboard = ({ children }: Props) => {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;
  const dashboardScreen = useSelector((state: any) => state.project.dashboard);
  const subscriber = useSelector((state: any) => state.project.subscriber);
  const collapsed = useSelector((state: any) => state.project.collapsed);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef<any>(null);
  const [changeStyles, setChangeStyles] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
    const handleScroll = () => {
      const scrollTop = contentRef.current.scrollTop;
      if (scrollTop > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    contentRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (pathName !== "/email-dashboard/emails/create") {
      sessionStorage.setItem("typeMail", "");
    }
  }, [children]);

  const handleBackToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <div className={classes.wrapper}>
        <Topbar />
        <div className={classes.crumbNav} style={{ marginLeft: "10px" }}>
          <ViewSidebarRoundedIcon
            sx={{ transform: "rotate(180deg)", color: "#afadad" }}
          />
          <BreadCrumb />
        </div>
        <div
          className={classes.dataBoardWrapper}
          ref={contentRef}
          onClick={() => {
            if (changeStyles) {
              dispatch(setCollapsed(false));
            }
          }}
        >
          {children}
        </div>
        {isVisible && (
          <IconButton
            sx={{
              background: "4c6fff",
              color: "white",
              position: "fixed",
              right: "30px",
              bottom: "30px",
              "&:hover": {
                backgroundColor: "4c6fff",

                color: "white",
              },
            }}
            onClick={handleBackToTop}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default Mainboard;

import React, { useEffect, useRef, useState } from "react";
import styles from "./Landingpage.module.scss";
import ScrollHeader from "./ScrollHeader/ScrollHeader";
import { Button } from "@mui/material";
import image1 from "../../assets/Images/rightbgimage.svg";
import centerImage from "../../assets/Images/centeroverlapimage.svg";
import templeteImage from "../../assets/Images/templeteImage.svg";
import centercardImage from "../../assets/Images/centerbgimage.svg";
import Page2 from "./Page2/Page2";
import csSectionbg from "../../assets/Images/csSection.svg";
import PricingSection from "./PricingSection/PricingSection";
import BookMeetingSection from "./BookMeetingSection/BookMeetingSection";
import Footer from "./Footer/Footer";
import AnimAccordion from "./AnimAccordion/AnimAccordion";
import BackToTopButton from "../../components/BacktoTop/BackToTopComp";
import { useNavigate } from "react-router";
import MainLandingPageHeader from "../MainlandingPage/MainLandingPageHeader/MainLandingPageHeader";
import EmailHeroSection from "./EmailHeroSection/EmailHeroSection";

export default function LandingPage() {
  const [triggerScrollEffect, setTriggerScrollEffect] = useState(false);
  const [triggerScrollbeforeEffect, setTriggerScrollbeforeEffect] =
    useState(false);
  const [centercardtransform, setcentercardtransform] = useState("");
  const [centercardtransformbottom, setcentercardtransformbottom] =
    useState("");
  const [display, setdisplay] = useState(true);
  const pricingRef = useRef<any>(null);
  const templateRef = useRef<any>(null);
  const featuresRef = useRef<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (window.innerWidth < 1300) {
      setcentercardtransform("translate(-49.5%, -3.5%)");
      // setcentercardtransformbottom("")
    }
    if (window.innerWidth > 1300) {
      setcentercardtransform("translate(-49.5%, -11.5%)");
    }
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setTriggerScrollbeforeEffect(true);
        setTimeout(() => {
          setTriggerScrollEffect(true);
          if (window.innerWidth < 1300) {
            setcentercardtransform("translate(-190%, 60%)");
            // setcentercardtransformbottom("")
          }
          if (window.innerWidth > 1300) {
            setcentercardtransform("translate(-190%, 38.5%)");
          }
        }, 300);
      } else {
        setTriggerScrollbeforeEffect(false);
        setTimeout(() => {
          setTriggerScrollEffect(false);
          if (window.innerWidth < 1300) {
            setcentercardtransform("translate(-49.5%, -3.5%)");
            // setcentercardtransformbottom("")
          }
          if (window.innerWidth > 1300) {
            setcentercardtransform("translate(-49.5%, -11.5%)");
          }
        }, 300);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToRef = (ref: any) => {
    if (ref === "pricingRef") {
      pricingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (ref === "templateRef") {
      templateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (ref === "featuresRef") {
      featuresRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <MainLandingPageHeader scrollToRef={scrollToRef} />

      <section ref={templateRef}>
        <EmailHeroSection />
      </section>
      <section ref={templateRef}>
        <Page2 />
      </section>
      <section ref={featuresRef}>
        <AnimAccordion />
      </section>
      <section className={styles.customsection}>
        <div className={styles.csWrapper}>
          <div className={styles.csLeft}>
            <div className={styles.cstext1}>Personalize, not customize.</div>
            <div className={styles.cstext2}>
              The all-in-one platform to automate your marketing campaigns over
              Email.Ease of use, high value, outstanding support
            </div>
            <div className={styles.csButton} onClick={()=>navigate("/login")}>Get Started</div>
          </div>
          <div className={styles.csRight}>
            <img src={csSectionbg} alt="" className={styles.csSectionbg} />
          </div>
        </div>
      </section>
      <section ref={pricingRef}>
        <div id="pricingSection">
          <PricingSection />
        </div>
      </section>

      <BookMeetingSection />
      <BackToTopButton />
      <Footer />
    </>
  );
}

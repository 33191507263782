import React, { useEffect, useState } from "react";
import classes from "./AnimAccordion.module.scss";
import vd from "../../../assets/Images/textbg.mp4";
import arrowAccord from "../../../assets/Images/arrowAccord.svg";
import checkAccord from "../../../assets/Images/checkAccord.svg";
import acc3 from "../../../assets/Images/acc3.svg";
import accgroup1 from "../../../assets/Images/accgroup1.svg";
import accgroupat from "../../../assets/Images/accgroupat.svg";
import accgroupcard1 from "../../../assets/Images/accgroupcard1.svg";
import accgroupcard2 from "../../../assets/Images/accgroupcard2.svg";
import accgroupcard3 from "../../../assets/Images/accgroupcard3.svg";
import accgroupcard4 from "../../../assets/Images/accgroupcard4.svg";
import accgroup2main from "../../../assets/Images/accgroup2main.svg";
import accgroup2circle from "../../../assets/Images/accgroup2circle.svg";
import accgroup2card1 from "../../../assets/Images/accgroup2card1.svg";
import accgroup2card2 from "../../../assets/Images/accgroup2card2.svg";
import accgroup2card3 from "../../../assets/Images/accgroup2card3.svg";
import accgroup3main from "../../../assets/Images/accgroup3main.svg";
import accgroup3card1 from "../../../assets/Images/accgroup3card1.svg";
import accgroup3card2 from "../../../assets/Images/accgroup3card2.svg";
import accgroup3card3 from "../../../assets/Images/accgroup3card3.svg";
import accgroup3card4 from "../../../assets/Images/accgroup3card4.svg";
import accgroup3arrow1 from "../../../assets/Images/accgroup3arrow1.png";
import accgroup3arrow2 from "../../../assets/Images/accgroup3arrow2.png";
import accgroup3arrow3 from "../../../assets/Images/accgroup3arrow3.png";
import accgroup3arrow4 from "../../../assets/Images/accgroup3arrow4.png";
import accgroup4main from "../../../assets/Images/accgroup4main.png";
import accgroup4arc from "../../../assets/Images/accgroup4arc.svg";
import accgroup4card1 from "../../../assets/Images/accgroup4card1.svg";
import accgroup4card2 from "../../../assets/Images/accgroup4card2.svg";
import accgroup4card3 from "../../../assets/Images/accgroup4card3.svg";
import accgroup4card4 from "../../../assets/Images/accgroup4card4.svg";
import accgroup4card5 from "../../../assets/Images/accgroup4card5.svg";
import accBase from "../../../assets/Images/accBase.svg";
import { motion } from "framer-motion";

const accordData = [
  {
    title: "Improve this text in accordion",
    heading:
      "Enhance emails with E-notifiers' data insights. Create on-brand marketing emails effortlessly.",
    list: [
      {
        listData: "AI Smart Heading Generator ",
      },
      {
        listData: "AI Smart Text Generator ",
      },
      {
        listData: "AI Smart Buttons Generator ",
      },
    ],
  },
  {
    title: "Powering Your Marketing Success",
    heading:
      "Unlock smart campaigns with E-Notifiers. Experience ultra-reliable Transactional Email API at 99% delivery rate.",
    list: [
      {
        listData: "Marketing Platform",
      },
      {
        listData: "Transactional Email ",
      },
    ],
  },
  {
    title: "Track, analyze, optimize for more sales.",
    heading:
      "Leverage our analytics for precise campaigns that engage and convert a wider audience.",
    list: [
      {
        listData: "Open Rate ",
      },
      {
        listData: "Click Rate ",
      },
      {
        listData: "Bounce Rate",
      },
    ],
  },
];

const AnimAccordion = () => {
  const [open, setOpen] = useState(null);
  const [changeStyles, setChangeStyles] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
  }, []);

  const handleAccordionClick = (index: any) => {
    setOpen((prevIndex) => (prevIndex === index ? null : index));
  };
  // console.log(open, "acccc");
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.topbarWrap}>
          <div className={classes.heading}>
            EFFORTLESSLY CRAFT{" "}
            <div className={classes.spantext}>
              <video autoPlay={true} loop muted className={classes.video}>
                <source
                  src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/textbg.mp4"
                  type="video/mp4"
                />
              </video>
              <div className={classes.vdText}>IMPACTFUL</div>
            </div>{" "}
            <div className={classes.extendText}>EMAILS SWIFTLY</div>
          </div>
          <div className={classes.headingMobile}>
            EFFORTLESSLY CRAFT IMPACTFUL
            <div className={classes.extendText}>EMAILS SWIFTLY</div>
          </div>
        
          <div className={classes.subHeading}>
            Create visually appealing emails that drive clicks within minutes.
            Utilize a AI -power to produce comprehensive emails, or construct
            your own captivating templates using an intuitive drag-and-drop
            editor.
          </div>
        </div>
        <div className={classes.accordionWrap}>
          <div className={classes.accordionLeft}>
            {accordData.map((item, index) => (
              <>
                <div className={classes.division}>
                  <div className={classes.divisionClose}>
                    <p className={classes.accordText}>{item.title}</p>
                    <img
                      src={arrowAccord}
                      alt=""
                      className={classes.arrowAccord}
                      onClick={() => {
                        handleAccordionClick(index);
                      }}
                      style={{
                        transform:
                          open === index ? "rotate(0deg)" : "rotate(180deg)",
                      }}
                    />
                  </div>
                  {open === index && (
                    <motion.div
                      className={classes.divisionOpen}
                      style={{ display: open === index ? "flex" : "none" }}
                      initial={{ height: 200, opacity: 0 }}
                      animate={{ height: 240, opacity: 1 }}
                      exit={{ height: -200, opacity: 0 }}
                      transition={{ type: "spring", stiffness: 200 }}
                    >
                      <p className={classes.accordDetails}>{item.heading}</p>
                      {item.list.map((listItem) => (
                        <>
                          <div className={classes.accordDetailsListWrap}>
                            <img
                              src={checkAccord}
                              alt=""
                              className={classes.accordDetailsListIcn}
                            />
                            <div className={classes.accordDetailsList}>
                              {listItem.listData}{" "}
                            </div>
                          </div>
                        </>
                      ))}
                    </motion.div>
                  )}
                </div>
              </>
            ))}
          </div>
          <div className={classes.accordionRight}>
            {open === 0 && (
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
                className={classes.animationdivdefault3}
              >
                <img src={accgroup3main} alt="" className={classes.accImg} />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3card1}
                  alt=""
                  className={classes.accImgcard1}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3arrow1}
                  alt=""
                  className={classes.accgroup3arrow1}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3arrow2}
                  alt=""
                  className={classes.accgroup3arrow2}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3arrow3}
                  alt=""
                  className={classes.accgroup3arrow3}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3arrow4}
                  alt=""
                  className={classes.accgroup3arrow4}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3card2}
                  alt=""
                  className={classes.accImgcard2}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3card3}
                  alt=""
                  className={classes.accImgcard3}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup3card4}
                  alt=""
                  className={classes.accImgcard4}
                />
              </motion.div>
            )}
            {open === 1 && (
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
                className={classes.animationdivdefault4}
              >
                <img src={accgroup4arc} alt="" className={classes.accImg} />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup4main}
                  alt=""
                  className={classes.accgroup4main}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 1 }}
                  src={accgroup4card1}
                  alt=""
                  className={classes.accgroup4card1}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 3 }}
                  src={accgroup4card2}
                  alt=""
                  className={classes.accgroup4card2}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 4 }}
                  src={accgroup4card3}
                  alt=""
                  className={classes.accgroup4card3}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 2 }}
                  src={accgroup4card4}
                  alt=""
                  className={classes.accgroup4card4}
                />
                <motion.img
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 5 }}
                  src={accgroup4card5}
                  alt=""
                  className={classes.accgroup4card5}
                />
              </motion.div>
            )}
            {open === 2 && (
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
                className={classes.animationdiv}
              >
                <img src={accgroup1} alt="" className={classes.accImg1} />
                <motion.img
                  initial={{ scale: 0.9, opacity: 1 }}
                  animate={{ scale: 1.5, opacity: 1 }}
                  transition={{ duration: 2 }}
                  src={accgroupat}
                  alt=""
                  className={classes.accImgat}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: -80, opacity: 1 }}
                  // exit={{ y: 30, opacity: 0 }}
                  transition={{ delay: 1, duration: 1 }}
                  src={accgroupcard1}
                  alt=""
                  className={classes.accImgcard1}
                />
                <motion.img
                  initial={{ y: 100, opacity: 1 }}
                  animate={{ y: -10, opacity: 1 }}
                  // exit={{ y: 30, opacity: 0 }}
                  transition={{ delay: 2, duration: 1 }}
                  src={accgroupcard2}
                  alt=""
                  className={classes.accImgcard1}
                />
                <motion.img
                  initial={{ y: 160, opacity: 1 }}
                  animate={{ y: 60, opacity: 1 }}
                  exit={{ y: 30, opacity: 0 }}
                  transition={{ delay: 3, duration: 1 }}
                  src={accgroupcard3}
                  alt=""
                  className={classes.accImgcard1}
                />
                <motion.img
                  initial={{ y: 220, opacity: 1 }}
                  animate={{ y: 130, opacity: 1 }}
                  exit={{ y: 30, opacity: 0 }}
                  transition={{ delay: 4, duration: 1 }}
                  src={accgroupcard4}
                  alt=""
                  className={classes.accImgcard1}
                />
              </motion.div>
            )}
            {open !== 0 && open !== 1 && open !== 2 && (
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
                className={classes.animationdivdefault}
              >
                <img src={accgroup2main} alt="" className={classes.accImg} />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup2circle}
                  alt=""
                  className={classes.accImgcard2circle}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup2card1}
                  alt=""
                  className={classes.accImgcard2card1}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup2card2}
                  alt=""
                  className={classes.accImgcard2card2}
                />
                <motion.img
                  initial={{ y: 40, opacity: 1 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 200 }}
                  src={accgroup2card3}
                  alt=""
                  className={classes.accImgcard2card3}
                />
              </motion.div>
            )}

            <img src={accBase} alt="" className={classes.accImgbase} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimAccordion;

import React, { useState, useEffect } from "react";
import classes from "./GetVariable.module.scss";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDropzone } from "react-dropzone";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import uploadicon from "../../../assets/Images/uploadicon.svg";
import { Toaster, toast } from "react-hot-toast";
import csvimage from "../../../assets/Images/csvimage.svg";

import { SOURCE } from "../../../Utils/constants";
import Delete from "@mui/icons-material/Delete";
import {
  getVariablesAPICall,
  sendTransactionalEmailAPICall,
} from "../../../Utils/Apihelper";

const GetVariable = ({ mailName }) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [disableUpload, setdisableUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadloading, setuploadloading] = useState(false);
  const [csvContent, setCsvContent] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, [uploadloading]);

  const accepectedFileTypes: any = ".csv";

  const onDrop = (acceptedFilestype: any) => {
    if (acceptedFilestype[0].path.includes(".csv")) {
      setSelectedFile(acceptedFilestype[0]);
      setdisableUpload(true);
    } else {
      setdisableUpload(false);
      setSelectedFile(null);
      setTimeout(() => {
        setdisableUpload(true);
      }, 3000);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accepectedFileTypes,
  });

  const files = acceptedFiles.map((file: any) =>
    disableUpload ? (
      selectedFile && (
        <p key={file.path}>
          {file.path}{" "}
          <span>
            <Tooltip title="Delete File">
              <IconButton
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <Delete sx={{ color: "red" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
        </p>
      )
    ) : (
      <li key={file.path} style={{ color: "red" }}>
        Only .csv Files are accepected
      </li>
    )
  );

  const handleUploadFile = () => {
    const requestBody: any = new FormData();
    requestBody.append("mailName", mailName);
    requestBody.append("tags", "");
    requestBody.append("file", selectedFile);

    sendTransactionalEmailAPICall(requestBody)
      .then((res: any) => {
        navigate("/email-dashboard/emails");
        toast.success(res.data.message);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleDownload = () => {
    const obj = {
      mailName: mailName,
    };
    getVariablesAPICall(obj)
      .then((res: any) => {
        setCsvContent(res.data);
        downloadCsv(res.data);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.message);
        }
      });

    const downloadCsv = (csvContent) => {
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };
  };

  const handleCloseUploadModel = () => {
    setSelectedFile(null);
  };
  return (
    <>
      <Toaster />
      <div className={classes.wrapper}>
        <div className={classes.mainWrapper}>
          <div className={classes.uploadTextArea}>
            <p className={classes.uploadText}>Please Upload your file</p>
            <p className={classes.uploadsubText}>
              Select CSV documents to send
            </p>
          </div>

          <section className="container" style={{ width: "90%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "32px",
              }}
            >
              <div style={{ width: "40%" }}>
                <Button
                  onClick={handleDownload}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  Download Sample CSV
                </Button>
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                *To send the transactional email, download the sample csv
                generated based upon your choosen template and upload the
                updated file here.
              </div>
            </div>

            <div
              {...getRootProps()}
              className={classes.uploadArea}
              style={{ borderColor: "grey" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <FileUploadIcon fontSize="large" sx={{ fill: "grey" }} /> */}
                <img src={uploadicon} alt="" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input {...getInputProps()} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className={classes.uploadtext1}>
                    Select a file or drag and drop here
                  </p>
                  <p className={classes.uploadtext2}>
                    File size no more than 10MB
                  </p>
                </div>
              </div>
              <Button
                variant="outlined"
                sx={{
                  width: "max-content",
                  margin: "auto",
                  color: "white",
                  textTransform: "none",
                  backgroundColor: "#4C6FFF",
                  borderColor: "#4C6FFF",
                  "&:hover": {
                    backgroundColor: "#4C6FFF",
                    borderColor: "#4C6FFF",
                  },
                }}
              >
                Select File
              </Button>
            </div>
            <aside>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "50px",
                }}
              >
                {selectedFile && <img src={csvimage} alt="" />}
                <div>
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    {files}
                  </div>
                  <div style={{ width: "200%", marginLeft: "10px" }}>
                    {/* {uploadloading && <LinearProgressWithLabel value={progress} />} */}
                    {uploadloading && <span className={classes.loader}></span>}
                  </div>
                </div>
              </div>
            </aside>
          </section>
        </div>
        <div className={classes.buttonsection}>
          <Button
            onClick={handleCloseUploadModel}
            variant="outlined"
            sx={{ marginRight: "20px" }}
          >
            Cancel
          </Button>

          <Button
            disabled={!selectedFile}
            onClick={handleUploadFile}
            autoFocus
            sx={{
              background: "#4C6FFF",
              borderRadius: "10px",
              "&:hover": {
                background: "#4C6FFF",
              },
            }}
            variant="contained"
            endIcon={
              uploadloading ? (
                <CircularProgress
                  color="inherit"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                ""
              )
            }
          >
            Send
          </Button>
        </div>
      </div>
    </>
  );
};

export default GetVariable;

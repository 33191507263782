import React from "react";
import classes from "./MainLandingHero.module.scss";
import mlHero from "../../../assets/Images/MLHero.svg";
import mlGrid from "../../../assets/Images/MLgridicn.svg";
import mlCard from "../../../assets/Images/MLcard.svg";
import mlCard1 from "../../../assets/Images/MLcard1.svg";
import mlCard2 from "../../../assets/Images/MLCard2.svg";
import mlCard3 from "../../../assets/Images/MLcard3.svg";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

const MainLandingHero = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.left}>
          <div className={classes.title}>
            Reach Your Audience Anywhere, Anytime
          </div>
          <div className={classes.subtitle}>
            Connect with Your Customers on Their Preferred Channels
          </div>
          <div className={classes.btnWrap}>
            <div
              className={classes.btn1}
              onClick={() => {
                navigate("/register");
                ReactGA.event({
                  category: "TopbarGA",
                  action: "Signup Clicked",
                  label: "SignUp button",
                });
              }}
            >
              Sign Up for free
            </div>
            <div
              className={classes.btn2}
              onClick={() => {
                navigate("/contactus");
                ReactGA.event({
                  category: "TopbarGA",
                  action: "ContactUs Clicked",
                  label: "ContactUs button",
                });
              }}
            >
              Request a Demo
            </div>
          </div>
          <img src={mlGrid} alt="" style={{pointerEvents:"none"}}/>
        </div>

        <div className={classes.right}>
          <img src={mlHero} alt="" className={classes.rightImg} />
        </div>
      </div>

      <div className={classes.section2}>
        <p className={classes.topContainerText}>
          {/* WHY USE{" "}
          <div className={classes.spantext}>
            <video autoPlay={true} loop muted className={classes.video}>
              <source
                src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/textbg.mp4"
                type="video/mp4"
              />
            </video>
            <div className={classes.vdText}>E-NOTIFIERS !</div>
          </div> */}
          Why Use E-notifiers !
        </p>
        <div className={classes.subtitle}>
          Discover the Power of Our Cutting-Edge Features
        </div>
        <div className={classes.s2Wrap}>
          <div className={classes.s2Card}>
            <img src={mlCard} alt="" className={classes.s2CardImg} />
            <div className={classes.s2Title}>Marketing Platform</div>
            <div className={classes.s2Subtitle}>
              All-in-one solution for multi-channel marketing
            </div>
          </div>
          <div className={classes.s2Card}>
            <img src={mlCard1} alt="" className={classes.s2CardImg} />
            <div className={classes.s2Title}>Conversations</div>
            <div className={classes.s2Subtitle}>
              Personalized messaging for stronger connections.
            </div>
          </div>
          <div className={classes.s2Card}>
            <img src={mlCard2} alt="" className={classes.s2CardImg} />
            <div className={classes.s2Title}>Analytics and Reporting</div>
            <div className={classes.s2Subtitle}>
              Insights for smarter marketing decisions & solution.
            </div>
          </div>
          <div className={classes.s2Card}>
            <img src={mlCard3} alt="" className={classes.s2CardImg} />
            <div className={classes.s2Title}>Transactional Email</div>
            <div className={classes.s2Subtitle}>
              Reliable delivery for important communications
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLandingHero;

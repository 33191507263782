import React, { useEffect, useState } from "react";
import classes from "./EmailReports.module.scss";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import SubscribeDataTableComp from "../../components/SubscribeDataTableComp/SubscribeDataTableComp";
import SubscriberGroupDataTableComp from "../../components/SubscribeDataTableComp/SubscriberGroupDataTableComp";
import {
  advanceLogApicall,
  advanceLogGetApicall,
  advanceLogGetApicall2,
} from "../../Utils/Apihelper";
import Paper from "@mui/material/Paper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Toaster, toast } from "react-hot-toast";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { setCollapsed } from "../../Store/Project/ProjectSlice";
import { useDispatch } from "react-redux";
import { request } from "http";
import { ExportJsonCsv } from "react-export-json-csv";
import moment from "moment-timezone";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const options1 = ["Promotional", "Transactional"];

const headers = [
  {
    key: "mailName",
    name: "Mail Name",
  },
  {
    key: "groupname",
    name: "Group Name",
  },
  {
    key: "templatename",
    name: "Template Name",
  },
  {
    key: "timestamp",
    name: "Time stamp",
  },
  {
    key: "categorytype",
    name: "Mail type",
  },
  {
    key: "eventType",
    name: "Event Type",
  },
  {
    key: "to",
    name: "To",
  },
  {
    key: "from",
    name: "From",
  },
];
interface Props {
  logs?: boolean;
  maildata?: any;
}

const EmailReports = ({ logs, maildata }: Props) => {
  const [totaldatacount, setTotaldatacount] = useState(0);
  const [paginationCount, setpaginationCount] = useState(1);
  const [totalRows, settotalRows] = useState(1);
  const [mailName, setmailName] = useState([]);
  const [from, setfrom] = useState([]);
  const [groupName, setgroupName] = useState([]);
  const [templateName, settemplateName] = useState([]);
  const [subject, setsubject] = useState([]);
  const [eventType, seteventType] = useState([]);
  const [startdate, setstartdate] = useState<any>("");
  const [enddate, setenddate] = useState<any>("");
  const [options, setoptions] = useState<any>({});
  const [mailNameopt, setmailNameopt] = useState([]);
  const [fromopt, setfromopt] = useState([]);
  const [groupNameopt, setgroupNameopt] = useState([]);
  const [templateNameopt, settemplateNameopt] = useState([]);
  const [subjectopt, setsubjecoptt] = useState([]);
  const [eventTypeopt, seteventTypeopt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const dispatch = useDispatch();
  const planName = sessionStorage.getItem("planName");

  useEffect(() => {
    dispatch(setCollapsed(true));
  }, []);

  useEffect(() => {
    handleSearchAdvanceLogs();
    if (logs) {
      handleGetadvanceLogApicall2();
    } else {
      handleGetadvanceLogApicall();
    }
  }, [
    mailName,
    from,
    groupName,
    templateName,
    subject,
    startdate,
    enddate,
    eventType,
    paginationCount,
  ]);

  const handleGetadvanceLogApicall = () => {
    advanceLogGetApicall()
      .then((response: any) => {
        setoptions(response?.data?.data[0]);
        setmailNameopt(response.data.data[0].mailNames);
        setfromopt(response?.data?.data[0]?.tos);
        setgroupNameopt(response?.data?.data[0]?.groupnames);
        settemplateNameopt(response?.data?.data[0]?.templatenames);
        setsubjecoptt(response?.data?.data[0]?.subjects);
        seteventTypeopt(response?.data?.data[0]?.eventTypes);
      })
      .catch((error: any) => {});
  };

  const handleGetadvanceLogApicall2 = () => {
    const requestBody = {
      mailName: [maildata.data.mailName],
      to: [],
      groupname: [],
      templatename: [],
      subject: [],
      eventType: [],
    };
    advanceLogGetApicall2(requestBody)
      .then((response: any) => {
        setoptions(response?.data?.data[0]);

        setfromopt(response?.data?.data[0]?.tos);
        setgroupNameopt(response?.data?.data[0]?.groupnames);
        settemplateNameopt(response?.data?.data[0]?.templatenames);
        setsubjecoptt(response?.data?.data[0]?.subjects);
        seteventTypeopt(response?.data?.data[0]?.eventTypes);
      })
      .catch((error: any) => {});
  };

  const filteredSubjects = subjectopt.filter((subject) => subject !== null);
  const formatDate = (inputDateString: any) => {
    const inputDate = new Date(inputDateString);
    const indianTimeZoneOffset = 5.5 * 60 * 60 * 1000;

    const indianDate = new Date(inputDate.getTime() + indianTimeZoneOffset);

    const year = indianDate.getUTCFullYear();
    const month = String(indianDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(indianDate.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const formatDateTable = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off = new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };

  const columns = [
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Mail Name</div>,
      selector: "mailName",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.mailName}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Group Name </div>,
      selector: "groupname",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.groupname}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Template Name</div>
      ),
      selector: "templatename",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {row.templatename}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>TimeStamp</div>,
      selector: "timestamp",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{formatDateTable(row.timestamp)}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Mail Type</div>,
      selector: "categorytype",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.categorytype}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Event Type</div>,
      selector: "eventType",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.eventType}</div>
      ),
    },

    {
      name: <div style={{ fontSize: "13px", color: "black" }}>To</div>,
      selector: "to",
      sortable: true,

      cell: (row: any) => <div style={{ fontSize: "13px" }}>{row.to}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>From</div>,
      selector: "from",
      sortable: true,

      cell: (row: any) => <div style={{ fontSize: "13px" }}>{row.from}</div>,
    },
  ];

  const filteredColumns = columns.slice(1);
  const mailNamePayload = [maildata?.data?.mailName];

  const handleSearchAdvanceLogs = () => {
    const request = {
      mailName: logs ? mailNamePayload : mailName,
      to: from,
      groupname: groupName,
      templatename: templateName,
      subject: subject,
      eventType: eventType,
      startDate: startdate === "" ? startdate : formatDate(startdate),
      endDate: enddate === "" ? enddate : formatDate(enddate),
    };
    const req2 = {
      pageno: paginationCount,
      pagelimit: 10,
    };
    setLoading(true);
    advanceLogApicall(request, req2)
      .then((response: any) => {
        setLoading(false);
        setdata(response.data.data);
        settotalRows(Math.ceil(response.data.total / 20));
        setTotaldatacount(response.data.total);
      })
      .catch((error: any) => {
        // toast.error(error.response.data.message);
        setLoading(false);
        setdata([]);
      });
  };

  const clearFilter = () => {
    setmailName([]);
    setgroupName([]);
    settemplateName([]);
    setstartdate("");
    setenddate("");
    setsubject([]);
    seteventType([]);
    setfrom([]);
    handleSearchAdvanceLogs();
  };

  const handleDeleteChip = (deletedName, arrayToUpdate, setArrayToUpdate) => {
    const updatedArray = arrayToUpdate.filter((name) => name !== deletedName);
    setArrayToUpdate(updatedArray);
  };

  const handleDeletedateChip = (setstringupdate) => {
    setstringupdate("");
  };

  const formattedArrayData = data.map((item: any) => ({
    ...item,
    timestamp: formatDateTable(item.timestamp),
  }));

  const handlePageChange = (value: any, pagenumber: any) => {
    setpaginationCount(pagenumber);
  };

  return (
    <>
      <Toaster />
      <div className={classes.wrapper}>
        <div className={classes.filterSection}>
          <div className={classes.heading}>Advance Reports</div>
          <div className={classes.filters}>
            <Grid container spacing={4}>
              {!logs && (
                <>
                  <Grid item xs={6} md={3}>
                    <div className={classes.labels}>
                      Mail Name
                      <Autocomplete
                        multiple
                        size="small"
                        sx={{
                          marginTop: "12px",
                          borderRadius: "10px",
                          width: "-webkit-fill-available",
                          background: "#EDF2F7",
                          outline: "none",
                          fontSize: "16px",
                          fontWeight: 500,
                          padding: "0 10px",
                          color: "#000000",
                          boxShadow: "none",
                          "& .MuiOutlinedInput-input": {
                            // padding: "4.5px 4px 2.5px 8px",
                            paddingTop: "2px !important",
                            cursor: "pointer",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        PaperComponent={({ children }) => (
                          <Paper
                            style={{
                              width: "300px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, 0%)", // Set the desired width here
                              // Add any other styling you need
                            }}
                          >
                            {children}
                          </Paper>
                        )}
                        options={mailNameopt}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        value={mailName}
                        onChange={(event: any, newValue: any) => {
                          setmailName(newValue);
                        }}
                        id="disable-clearable"
                        popupIcon={
                          <ArrowDropDownIcon
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                          />
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={`${
                              mailName ? mailName.length : 0
                            } Selected`}
                          />
                        )}
                        renderTags={() => null}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={6} md={3}>
                <div className={classes.labels}>
                  Group Name
                  <Autocomplete
                    multiple
                    size="small"
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    options={groupNameopt}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    value={groupName}
                    onChange={(event: any, newValue: any) => {
                      setgroupName(newValue);
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${groupName.length} Selected`}
                      />
                    )}
                    renderTags={() => null}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.labels}>
                  To
                  <Autocomplete
                    multiple
                    size="small"
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    options={fromopt.slice(0, 500)}
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          width: "300px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, 0%)", // Set the desired width here
                          // Add any other styling you need
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    value={from}
                    onChange={(event: any, newValue: any) => {
                      setfrom(newValue);
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${from.length} Selected`}
                      />
                    )}
                    renderTags={() => null}
                  />
                </div>
              </Grid>

              <Grid item xs={6} md={3}>
                <div className={classes.labels}>
                  Template Name
                  <Autocomplete
                    multiple
                    size="small"
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    options={templateNameopt}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    value={templateName}
                    onChange={(event: any, newValue: any) => {
                      settemplateName(newValue);
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${templateName.length} Selected`}
                      />
                    )}
                    renderTags={() => null}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.labels}>
                  Subject
                  <Autocomplete
                    multiple
                    size="small"
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          width: "300px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, 0%)", // Set the desired width here
                          // Add any other styling you need
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    options={filteredSubjects}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    value={subject}
                    onChange={(event: any, newValue: any) => {
                      setsubject(newValue);
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${subject.length} Selected`}
                      />
                    )}
                    renderTags={() => null}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={3}>
                <div className={classes.labels}>
                  Event Type
                  <Autocomplete
                    multiple
                    size="small"
                    sx={{
                      marginTop: "12px",
                      borderRadius: "10px",
                      width: "-webkit-fill-available",
                      background: "#EDF2F7",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "0 10px",
                      color: "#000000",
                      boxShadow: "none",
                      "& .MuiOutlinedInput-input": {
                        // padding: "4.5px 4px 2.5px 8px",
                        paddingTop: "2px !important",
                        cursor: "pointer",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    options={eventTypeopt}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    value={eventType}
                    onChange={(event: any, newValue: any) => {
                      seteventType(newValue);
                    }}
                    id="disable-clearable"
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${eventType.length} Selected`}
                      />
                    )}
                    renderTags={() => null}
                  />
                </div>
              </Grid>
              {!logs && (
                <>
                  <Grid item xs={6} md={3}>
                    <div className={classes.labels}>
                      Start Date
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            marginTop: "4px",
                          }}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            maxDate={enddate}
                            sx={{
                              marginTop: "12px",
                              borderRadius: "10px",
                              width: "-webkit-fill-available",
                              background: "#EDF2F7",
                              outline: "none",
                              fontSize: "16px",
                              fontWeight: 500,
                              padding: "0 10px",
                              color: "#000000",
                              boxShadow: "none",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                              },
                            }}
                            onChange={(event: any) => {
                              setstartdate(event);
                            }}
                            value={startdate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className={classes.labels}>
                      End Date
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            marginTop: "4px",
                          }}
                        >
                          <DatePicker
                            minDate={startdate}
                            format="DD/MM/YYYY"
                            sx={{
                              marginTop: "12px",
                              borderRadius: "10px",
                              width: "-webkit-fill-available",
                              background: "#EDF2F7",
                              outline: "none",
                              fontSize: "16px",
                              fontWeight: 500,
                              padding: "0 10px",
                              color: "#000000",
                              boxShadow: "none",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                              },
                            }}
                            onChange={(event: any) => {
                              setenddate(event);
                            }}
                            value={enddate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {mailName.map((name) => (
              <Chip
                label={name}
                variant="outlined"
                onDelete={() => handleDeleteChip(name, mailName, setmailName)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            ))}
            {eventType.map((name) => (
              <Chip
                label={name}
                variant="outlined"
                onDelete={() => handleDeleteChip(name, eventType, seteventType)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            ))}
            {templateName.map((name) => (
              <Chip
                label={name}
                variant="outlined"
                onDelete={() =>
                  handleDeleteChip(name, templateName, settemplateName)
                }
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            ))}
            {groupName.map((name) => (
              <Chip
                label={name}
                variant="outlined"
                onDelete={() => handleDeleteChip(name, groupName, setgroupName)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            ))}
            {from.map((name) => (
              <Chip
                label={name}
                variant="outlined"
                onDelete={() => handleDeleteChip(name, from, setfrom)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            ))}
            {subject.map((name) => (
              <Chip
                label={name}
                variant="outlined"
                onDelete={() => handleDeleteChip(name, subject, setsubject)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            ))}
            {startdate !== "" && (
              <Chip
                label={formatDate(startdate)}
                variant="outlined"
                onDelete={() => handleDeletedateChip(setstartdate)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            )}
            {enddate !== "" && (
              <Chip
                label={formatDate(enddate)}
                variant="outlined"
                onDelete={() => handleDeletedateChip(setenddate)}
                sx={{ color: "#9E9595" }}
                deleteIcon={
                  <CloseIcon sx={{ width: "14px", fontWeight: "bold" }} />
                }
              />
            )}
          </div>

          <div className={classes.buttons}>
            <div className={classes.btn1} onClick={clearFilter}>
              Clear Filter
            </div>
            {/* <div className={classes.btn2} onClick={handleSearchAdvanceLogs}>
              Apply
            </div> */}
          </div>
        </div>

        <div className={classes.dataSection}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ padding: "10px", fontWeight: "500" }}>
              {totaldatacount === 0
                ? "No data found for the selected filters"
                : `Search Results: ${totaldatacount} data report found`}
            </div>
            {data.length !== 0 && planName !== "Basic" && (
              <ExportJsonCsv
                headers={headers}
                items={formattedArrayData}
                className={classes.export}
              >
                Export
              </ExportJsonCsv>
            )}
          </div>

          <SubscriberGroupDataTableComp
            data={data}
            columns={logs ? filteredColumns : columns}
            loading={loading}
            pagination={false}
            totalrows={totalRows}
            paginationCount={paginationCount}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default EmailReports;

import React, { useState } from "react";
import styles from "./Footer.module.scss";
import logo from "../../../assets/Images/logoEnotifiers.svg";
import facebook from "../../../assets/Images/facebook.svg";
import insta from "../../../assets/Images/insta.svg";
import twitter from "../../../assets/Images/twitter.svg";
import github from "../../../assets/Images/github.svg";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { subcribeAPICall } from "../../../Utils/Apihelper";
import { toast } from "react-hot-toast";

const Links1 = [
  "Features",
  "Templates",
  "Pricing & plans",
  "Marketing Platform",
  "Transactional Email",
];
const Links2 = ["E-Notifiers API"];
const Links3 = [
  {
    section: "About",
    link: "mindorigin.in",
  },
];
const Links4 = [
  {
    section: "Contact Us",
    link: "/contactus",
  },
  {
    section: "Help Center",
    link: "/helpcenter",
  },
  {
    section: "Pricing",
    link: "/pricings",
  },
];

export default function Footer() {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const user = process.env.REACT_APP_USERNAME;

  const handleEmail = (e) => {
    setemail(e.target.value);
  };

  const handleSubscribe = () => {
    const req = {
      userName: user ? user : "",
      email: email,
      name: "",
      groupName: "Enotifiers",
      source: "website",
    };
    subcribeAPICall(req)
      .then((res: any) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.topContainer}>
        <div className={styles.logoandsocialmedia}>
          <img src={logo} className={styles.footerlogo} />
          <div className={styles.socialmedia}>
            <div className={styles.socialmediabg}>
              <a
                href="https://www.facebook.com/people/Enotifiers/61557591835804/?is_tour_dismissed=true"
                target="_blank"
              >
                <img src={facebook} className={styles.socialmediaicons} />
              </a>
            </div>
            <div className={styles.socialmediabg}>
              <a
                href="https://www.instagram.com/enotifiers?utm_source=qr&igsh=NGNld2Jod2Y4cGk1"
                target="_blank"
              >
                <img src={insta} className={styles.socialmediaicons} />
              </a>
            </div>
            {/* <div className={styles.socialmediabg}>
              <img src={twitter} className={styles.socialmediaicons} />
            </div> */}
            {/* <div className={styles.socialmediabg}>
              <img src={github} className={styles.socialmediaicons} />
            </div> */}
            <div>
              <Button
                variant="contained"
                sx={{
                  background: "4c6fff",
                  borderRadius: "11px",
                  textTransform: "none",
                  padding: { xs: "10px 10px", sm: "10px 40px" },

                  "&:hover": { background: "4c6fff" },
                }}
              >
                More to come...
              </Button>
            </div>
          </div>
          <div className={styles.subscribeSection}>
            <div className={styles.textfeild}>
              <TextField
                id="outlined-basic"
                fullWidth
                label="Subscribe to our news"
                variant="outlined"
                value={email}
                onChange={handleEmail}
                sx={{
                  "& label.Mui-focused": {
                    color: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      color: "black",
                    },
                  },
                }}
              />
            </div>
            <div>
              <Button
                variant="contained"
                sx={{
                  background: "#000000",
                  borderRadius: "12px",
                  textTransform: "none",
                  padding: "6px 40px",

                  "&:hover": { background: "#000000" },
                }}
                onClick={() => {
                  email !== "" && handleSubscribe();
                }}
              >
                Subscribe
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          {/* <div className={styles.siteMapingSection}>
            <p className={styles.siteMapingSectionHeading}>Product</p>
            {Links1.map((a: any) => (
              <p className={styles.siteMapingSectionLinks}>{a}</p>
            ))}
          </div> */}

          {/* <div className={styles.siteMapingSection}>
            <p className={styles.siteMapingSectionHeading}>More</p>
            {Links2.map((a: any) => (
              <p
                className={styles.siteMapingSectionLinks}
                onClick={() => {
                  window.location.href = "https://docs.enotifiers.com";
                }}
              >
                {a}
              </p>
            ))}
          </div> */}
          <div className={styles.siteMapingSection}>
            <p className={styles.siteMapingSectionHeading}>More</p>
            {Links2.map((a: any) => (
              <p
                className={styles.siteMapingSectionLinks}
                onClick={() => {
                  window.location.href =
                    "https://docs.enotifiers.com/introduction/";
                }}
              >
                {a}
              </p>
            ))}

            <p
              className={styles.siteMapingSectionLinks}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </p>
            <p
              className={styles.siteMapingSectionLinks}
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </p>
            <p
              className={styles.siteMapingSectionLinks}
              onClick={() => {
                window.location.href = "https://docs.enotifiers.com/blog/";
              }}
            >
              Blogs
            </p>
          </div>
          <div className={styles.siteMapingSection}>
            <p className={styles.siteMapingSectionHeading}>Help</p>
            {Links4.map((a: any, i) => (
              <p
                className={styles.siteMapingSectionLinks}
                onClick={() => {
                  navigate(a.link);
                }}
              >
                {a.section}
              </p>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.bottomContainerLeft}>
          <p
            className={styles.privacyText}
            onClick={() => navigate("/privacypolicy")}
          >
            Privacy Policy
          </p>
          <p
            className={styles.privacyText}
            onClick={() => navigate("/termsandconditions")}
          >
            Terms of Use
          </p>
          <p
            className={styles.privacyText}
            onClick={() => navigate("/refundpolicy")}
          >
            Refund Policy
          </p>
        </div>
        <div>
          <p className={styles.copyrighttext}>
            © 2024 E-Notifiers. All rights reserved.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

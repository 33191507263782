import React, { useState, useEffect } from "react";
import classes from "./ImportCsv.module.scss";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDropzone } from "react-dropzone";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import uploadicon from "../../assets/Images/uploadicon.svg";

import { Toaster, toast } from "react-hot-toast";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import csvimage from "../../assets/Images/csvimage.svg";
import AutoCompleteComp from "../../components/AutoCompleteComp/AutoCompleteComp";
import {
  IGetAllGroupsResponse,
  ISingleGroup,
  getAllGroupsApi,
  createBulkSubscribersApi,
} from "../../Utils/Apihelper";
import { SOURCE } from "../../Utils/constants";
import SampleCsv from "../../assets/uploadSubscribersCSVSample.csv";
import Delete from "@mui/icons-material/Delete";

export default function ImportCsv() {
  const selectedGroupImport: any = sessionStorage.getItem(
    "selectedGroupImport"
  );

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [disableUpload, setdisableUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadloading, setuploadloading] = useState(false);
  const [groups, setgroups] = useState<ISingleGroup[]>([]);
  const [selectedGroup, setselectedGroup] = useState<string>(
    selectedGroupImport ? selectedGroupImport : ""
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, [uploadloading]);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    getAllGroupsApi().then((response: any) => {
      const responseData: IGetAllGroupsResponse = response.data;
      setgroups(responseData.data);
      // if (responseData.data.length) {
      //   setselectedGroup(responseData.data[0].groupName);
      // }
      // console.log("getAllGroupsApi", response.data);
    });
  };
  const accepectedFileTypes: any = ".csv";

  const onDrop = (acceptedFilestype: any) => {
    if (acceptedFilestype[0].path.includes(".csv")) {
      setSelectedFile(acceptedFilestype[0]);
      setdisableUpload(true);
    } else {
      setdisableUpload(false);
      setSelectedFile(null);
      setTimeout(() => {
        setdisableUpload(true);
      }, 3000);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accepectedFileTypes,
  });

  const files = acceptedFiles.map((file: any) =>
    disableUpload ? (
      selectedFile && (
        <p key={file.path}>
          {file.path}{" "}
          <span>
            <Tooltip title="Delete File">
              <IconButton
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </span>
        </p>
      )
    ) : (
      <li key={file.path} style={{ color: "red" }}>
        Only .csv Files are accepected
      </li>
    )
  );

  const handleUploadFile = () => {
    const requestBody: any = new FormData();
    requestBody.append("file", selectedFile);
    requestBody.append("source", SOURCE);
    requestBody.append("groupName", selectedGroup);
    createBulkSubscribersApi(requestBody)
      .then((response: any) => {
        toast.success(response.data.message);
        // window.alert("Add Subscribers to the group")
        navigate("/email-dashboard/subscriberList");
        // console.log("createBulkSubscribersApi", response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message)
      });
  };

  const csvData = "email,name"; 

  const handleDownload = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleCloseUploadModel = () => {
    setSelectedFile(null);
    navigate("/email-dashboard/subscriberList");
  };
  return (
    <>
      <Toaster />
      <div className={classes.wrapper}>
        <div className={classes.mainWrapper}>
          <div className={classes.uploadTextArea}>
            <p className={classes.uploadText}>Please Upload your file</p>
            <p className={classes.uploadsubText}>
              Select CSV documents to Import contacts
            </p>
          </div>

          <section className="container" style={{ width: "90%" }}>
            <div className={classes.filterSection}>
              <div className={classes.filterSectionAutoComplete}>
                <AutoCompleteComp
                  value={selectedGroup}
                  onChange={(event: any, newValue: string) => {
                    setselectedGroup(newValue);
                  }}
                  inputValue={selectedGroup}
                  options={groups.map((a) => a.groupName)}
                  placeholder={"Groups"}
                  label={"Groups"}
                />
              </div>
              <div>
                <Button
                  onClick={handleDownload}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  Download Sample CSV
                </Button>
              </div>
            </div>

            <div
              {...getRootProps()}
              className={classes.uploadArea}
              style={{ borderColor: "grey" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <FileUploadIcon fontSize="large" sx={{ fill: "grey" }} /> */}
                <img src={uploadicon} alt="" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input {...getInputProps()} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className={classes.uploadtext1}>
                    Select a file or drag and drop here
                  </p>
                  <p className={classes.uploadtext2}>
                    File size no more than 10MB
                  </p>
                </div>
              </div>
              <Button
                variant="outlined"
                sx={{
                  width: "max-content",
                  margin: "auto",
                  color: "white",
                  textTransform: "none",
                  backgroundColor: "#4C6FFF",
                  borderColor: "#4C6FFF",
                  "&:hover": {
                    backgroundColor: "#4C6FFF",
                    borderColor: "#4C6FFF",
                  },
                }}
              >
                Select File
              </Button>
            </div>
            <aside>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "50px",
                }}
              >
                {selectedFile && <img src={csvimage} alt="" />}
                <div>
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    {files}
                  </div>
                  <div style={{ width: "200%", marginLeft: "10px" }}>
                    {/* {uploadloading && <LinearProgressWithLabel value={progress} />} */}
                    {uploadloading && <span className={classes.loader}></span>}
                  </div>
                </div>
              </div>
            </aside>
          </section>
        </div>
        <div className={classes.buttonsection}>
          <Button
            onClick={handleCloseUploadModel}
            variant="outlined"
            sx={{ marginRight: "20px" }}
          >
            Cancel
          </Button>

          <Button
            disabled={!selectedFile || !selectedGroup}
            onClick={handleUploadFile}
            autoFocus
            sx={{
              background: "#4C6FFF",
              borderRadius: "10px",
              "&:hover": {
                background: "#4C6FFF",
              },
            }}
            variant="contained"
            endIcon={
              uploadloading ? (
                <CircularProgress
                  color="inherit"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                ""
              )
            }
          >
            Upload
          </Button>
        </div>
      </div>
    </>
  );
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import classes from "./GeneralBoard.module.scss";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeliveryBarChart from "../../components/DeliveryBarChart/DeliveryBarChart";
import EmailDataChart from "../../components/EmailDataChart/EmailDataChart";
import PerformanceDataChart from "../../components/PerformanceDataChart/PerformanceDataChart";
import { Box, Grid, Paper } from "@mui/material";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import {
  PaymentApi,
  alldataStatApi,
  allgraphStatisticsApi,
  getAllCatDetailsApi,
  getPromotionalStatDataApi,
  getPromotionalTableDataApi,
  getTransactionalStatDataApi,
  getTransactionalTableDataApi,
  GetAllStatsDashoardAPICall,
  getAWSreport,
} from "../../Utils/Apihelper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import whatsapptextImage from "../../assets/Images/whatsapptextimage.svg";
import smstextImage from "../../assets/Images/smstextimage.svg";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import dtc1 from "../../assets/Images/icon.svg";
import dtc2 from "../../assets/Images/icon (1).svg";
import dtc3 from "../../assets/Images/icon (2).svg";
import dtc4 from "../../assets/Images/icon (3).svg";
import dtc5 from "../../assets/Images/icon (4).svg";
import dtc6 from "../../assets/Images/icon (5).svg";
import dtc7 from "../../assets/Images/icon (6).svg";
import dtc8 from "../../assets/Images/icon (7).svg";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

// interface IUser {
//   stat: any;
// }
const mappingValues = {
  1: "all",
  2: "transactional",
  3: "promotional",
};
const GeneralBoard = () => {
  const [bgColor, setbgColor] = useState("rgb(235, 231, 231)");
  const [tab, setTab] = useState(1);
  const [stat, setStat] = useState({});
  const [tableData, setTableData] = useState([]);
  const [emailGraph, setEmailGraph] = useState([]);
  const [loading, setloading] = useState(false);
  const [report, setReport] = useState({});

  const [payUrl, setPayUrl] = useState("");
  const [dashboardStats, setDashBoardStats] = useState({
    all: {
      Send: 0,
      Delivery: 0,
      Open: 0,
      Bounce: 0,
      Click: 0,
      DeliveryDelay: 0,
      "Rendering Failure": 0,
      Reject: 0,
      Complaint: 0,
    },
    promotional: {
      Send: 0,
      Delivery: 0,
      Open: 0,
      Bounce: 0,
      Click: 0,
      DeliveryDelay: 0,
      "Rendering Failure": 0,
      Reject: 0,
      Complaint: 0,
    },
    transactional: {
      Send: 0,
      Delivery: 0,
      Open: 0,
      Bounce: 0,
      Click: 0,
      DeliveryDelay: 0,
      "Rendering Failure": 0,
      Reject: 0,
      Complaint: 0,
    },
  });

  const handlePayment = () => {
    function generateRandom6DigitNumber() {
      return Math.floor(100000 + Math.random() * 900000);
    }
    const randomNumber = generateRandom6DigitNumber();
    const reqBody = {
      mobile: "9632916893",
      paymentInstrument: { type: "PAY_PAGE" },
      merchantTransactionId: `${randomNumber}`,
      amount: "1",
    };
    PaymentApi(reqBody)
      .then((res) => {
        // console.log(res.data);
        setPayUrl(res.data.data.instrumentResponse.redirectInfo.url);
      })
      .catch((res) => {
        // console.log(res.data);
      });

    window.open(payUrl, "_blank", "width=600,height=700");
  };

  function activeTab(tabNumber) {
    setTab(tabNumber);
  }

  const Token = sessionStorage.getItem("ChangePwToken");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };

  useEffect(() => {
    // handleAllData();
    // getTableData();
    // graphdata();
    GetAllStatsDashoard();
    handleGetAWSReport();
  }, []);

  const GetAllStatsDashoard = () => {
    GetAllStatsDashoardAPICall()
      .then((response) => {
        // console.log("response", response.data.data);
        setDashBoardStats(response.data.data);
      })
      .catch((error) => {});
  };

  const handleAllData = () => {
    alldataStatApi()
      .then((res) => {
        // console.log(res.data);
        setStat(res.data.data);
      })
      .catch((res) => {
        // console.log(res.data);
      });
  };

  const getTableData = () => {
    setloading(true);
    getAllCatDetailsApi()
      .then((res) => {
        // console.log(res.data);
        setloading(false);
        setTableData(res.data.data);
      })
      .catch((res) => {
        setloading(false);
        // console.log(res.data);
      });
  };

  const handleGetAWSReport = () => {
    getAWSreport()
      .then((response) => {
        setReport(response.data.data);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  return (
    <>
      <div className={classes.tabsWrapper}>
        <div className={classes.tabsCatagory}>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(1);
              // getTableData();
              // handleAllData();
            }}
            style={{
              backgroundColor: tab === 1 ? "#4C6FFF" : "",
              color: tab == 1 ? "white" : "",
            }}
          >
            All
          </div>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(2);
              // transactionalTableData();
              // transactionalStatData();
            }}
            style={{
              backgroundColor: tab === 2 ? "#4C6FFF" : "",
              color: tab == 2 ? "white" : "",
            }}
          >
            Transactional
          </div>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(3);
              // promotionalTableData();
              // promotionalStatData();
            }}
            style={{
              backgroundColor: tab === 3 ? "#4C6FFF" : "",
              color: tab == 3 ? "white" : "",
            }}
          >
            Promotional
          </div>
        </div>
      </div>

      {/* <button
        onClick={() => {
          handlePayment();
        }}
      >
        Pay Here
      </button> */}
      <div id="demo2">
        <div className={classes.dataTabWrap}>
          <div className={classes.dataTab} >
            <div className={classes.dTCLeft}>
              <div className={classes.dTC1}>
                {dashboardStats[mappingValues[tab]].Send}
              </div>
              <div className={classes.dTC2}>Sent</div>
              {/* <div className={classes.dTC3}>23940 Emails</div> */}
            </div>
            <div className={classes.dTCRight}>
              <img src={dtc1} alt="" className={classes.dTCIcon} />
            </div>
          </div>
          <div className={classes.dataTab} id="demo5">
            <div className={classes.dTCLeft}>
              <div className={classes.dTC1}>
                {dashboardStats[mappingValues[tab]].Open}
              </div>
              <div className={classes.dTC2}>Open</div>
              {/* <div className={classes.dTC3}>23940 Emails</div> */}
            </div>
            <div className={classes.dTCRight}>
              <img src={dtc2} alt="" className={classes.dTCIcon} />
            </div>
          </div>
          <div className={classes.dataTab}>
            <div className={classes.dTCLeft}>
              <div className={classes.dTC1}>
                {dashboardStats[mappingValues[tab]].Click}
              </div>
              <div className={classes.dTC2}>Click</div>
              {/* <div className={classes.dTC3}>23940 Emails</div> */}
            </div>
            <div className={classes.dTCRight}>
              <img src={dtc3} alt="" className={classes.dTCIcon} />
            </div>
          </div>
          <div className={classes.dataTab}>
            <div className={classes.dTCLeft}>
              <div className={classes.dTC1}>
                {dashboardStats[mappingValues[tab]].Bounce}
              </div>
              <div className={classes.dTC2}>Bounce</div>
              {/* <div className={classes.dTC3}>23940 Emails</div> */}
            </div>
            <div className={classes.dTCRight}>
              <img src={dtc4} alt="" className={classes.dTCIcon} />
            </div>
          </div>
        </div>
        <div className={classes.deliveryTitle}>Delivery</div>

        <div className={classes.dataBoxWrap}>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1data}>
                  {" "}
                  {dashboardStats[mappingValues[tab]].Delivery}
                </div>
                <div className={classes.dBC1Label}>Delivery</div>
              </div>
              <div className={classes.dBCicon}>
                <img src={dtc5} alt="" className={classes.dBCimage} />
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1data}>
                  {dashboardStats[mappingValues[tab]].DeliveryDelay}
                </div>
                <div className={classes.dBC1Label}>Delivery Delay</div>
              </div>
              <div className={classes.dBCicon}>
                <img src={dtc6} alt="" className={classes.dBCimage} />
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1data}>
                  {dashboardStats[mappingValues[tab]]["Rendering Failure"]}
                </div>
                <div className={classes.dBC1Label}>Rendering Failure</div>
              </div>
              <div className={classes.dBCicon}>
                <img src={dtc7} alt="" className={classes.dBCimage} />
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1data}>
                  {dashboardStats[mappingValues[tab]].Complaint}
                </div>
                <div className={classes.dBC1Label}>Complaint</div>
              </div>
              <div className={classes.dBCicon}>
                <img src={dtc8} alt="" className={classes.dBCimage} />
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
        </div>

        {/* *********************? */}

        <div className={classes.deliveryTitle}>Account Info</div>

        <div className={classes.dataBoxWrapAW}>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Account Status</div>
                <div className={classes.dBC1data}>{report?.AccountStatus}</div>
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          {/* <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Account is in</div>
                <div className={classes.dBC1data}>
                  {report?.IsAccountInProduction === true
                    ? "Production"
                    : "Sandbox"}
                </div>
              </div>
            </div>
            <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div>
          </div> */}
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Per day quota</div>
                <div className={classes.dBC1data}>{report?.PerDayQuota}</div>
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Send rate limit</div>
                <div className={classes.dBC1data}>{report?.SendRateLimit}</div>
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Sent in last 24hrs</div>
                <div className={classes.dBC1data}>
                  {report?.SentInLast24Hours}
                </div>
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Sending</div>
                <div className={classes.dBC1data}>
                  {report?.IsSendingEnabled === true ? "Enabled" : "Disabled"}
                </div>
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
          <div className={classes.dataBoxContainer}>
            <div className={classes.dBC1AW}>
              <div className={classes.dBC1Wrap}>
                <div className={classes.dBC1Label}>Review Stage</div>
                <div className={classes.dBC1data}>{report?.ReviewStage}</div>
              </div>
            </div>
            {/* <div className={classes.dBC2}>
              <p></p>
              <p>10.2</p>
              <p> 23940 delivered</p>
            </div> */}
          </div>
        </div>
      </div>

      <div className={classes.commingsoonSection}>
        <p className={classes.commingsoonSectionHeader}>
          Additional Ways to enhance your business growth
        </p>

        <div className={classes.cardsSection}>
          <div className={classes.cardLeft}>
            <video autoPlay={true} loop muted className={classes.video}>
              <source
                src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/dashboardwhatsappvideo.mov"
                type="video/mp4"
              />
            </video>
            <div className={classes.cardLeftContent}>
              <img src={whatsapptextImage} className={classes.clcImage} />
              <p className={classes.clcHeader}>
                Forge stronger connections through WhatsApp campaigns
              </p>
              <p className={classes.clcDesc}>
                Execute a WhatsApp campaign to connect with your audience on a
                broad scale, engaging them directly on the world's most popular
                messaging app.
              </p>
              <div className={classes.comingsoonbutton}>Coming Soon</div>
            </div>
          </div>
          <div className={classes.cardLeft}>
            <video autoPlay={true} loop muted className={classes.video}>
              <source
                src="https://enotifiers.s3.ap-south-1.amazonaws.com/assets/images/dashboardsmsvideo.mov"
                type="video/mp4"
              />
            </video>
            <div className={classes.cardLeftContent}>
              <img src={smstextImage} className={classes.clcImage} />
              <p className={classes.clcHeader}>
                Connect with a broad audience through SMS at scale.
              </p>
              <p className={classes.clcDesc}>
                Connect with your audience on a large scale through WhatsApp,
                reaching them directly on the world's most widely used messaging
                platform.
              </p>
              <div className={classes.comingsoonbutton}>Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
      {/* <DataTableComponent tableData={tableData} loading={loading} /> */}
    </>
  );
};

export default GeneralBoard;

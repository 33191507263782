import React from "react";
import Support from "../../components/Pages/Support/Support";
import ScrollHeader from "../LandingPage/ScrollHeader/ScrollHeader";
import Footer from "../LandingPage/Footer/Footer";
import MainLandingPageHeader from "../MainlandingPage/MainLandingPageHeader/MainLandingPageHeader";

const HelpCenter = () => {
  return (
    <>
      <MainLandingPageHeader />
      <div style={{
        padding:"4rem"
      }}>
        <Support />
      </div>
      <Footer/>
    </>
  );
};

export default HelpCenter;

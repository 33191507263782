import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import classes from "./Dashboard.module.scss";
import Mainboard from "./Mainboard/Mainboard";
import DashSidebar from "./DashSidebar/DashSidebar";
import PremiumModal from "../../components/Modals/PremiumModal/PremiumModal";

const Dashboard = () => {
 
  return (
    <>
      <div className={classes.wrapper}>
        <div>
          <DashSidebar />
        </div>
        <div style={{ width: "-webkit-fill-available",backgroundColor:"#F7F9FC" }}>
          <Mainboard>
            <Outlet />
          </Mainboard>
        </div>
      </div>
      <PremiumModal/>
    </>
  );
};

export default Dashboard;

import signUp from "../../assets/Images/registerrightimage.svg";
import classes from "./ChangePassword.module.scss";
import { useFormik } from "formik";
import { confirmpwSchema, signUpSchema } from "../../Schemas/Schemas";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import {
  changeForgetPasswordAPICall,
  changePasswordApi,
} from "../../Utils/Apihelper";
import { CircularProgress } from "@mui/material";
import { Toaster, toast } from "react-hot-toast";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import { useState } from "react";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [success, setSuccess] = useState(false);
  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: confirmpwSchema,
      onSubmit: (values, action) => {
        // console.log("values", values);
        action.resetForm();

        const requestBody = {
          password: values.password,
        };
        const Token = sessionStorage.getItem("ChangePwToken");
        const config = {
          headers: { Authorization: `Bearer ${Token}` },
        };

        changeForgetPasswordAPICall(requestBody)
          .then((res) => {
            setSuccess(true);
            navigate("/login");
            toast.success(res.data.message);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      },
    });
  return (
    <>
      <Toaster />
      {success ? (
        <SuccessModal
          heading={"Password Changed!"}
          subHeading1={"Your password has been successfully changed"}
          subHeading2={"Please login with your new password"}
        />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.modal}>
              <div className={classes.modalContainer}>
                <div className={classes.modalLeft}>
                  <div className={classes.loginData}>
                    <h1 className={classes.modalTitle}>Change Password</h1>

                    <form onSubmit={handleSubmit}>
                      <div className={classes.inputBlock}>
                        <OutlinedInput
                          type={showPassword ? "text" : "password"}
                          autoComplete="off"
                          name="password"
                          id="password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {!showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          placeholder="Enter New Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "4c6fff",
                            },
                          }}
                        />

                        {errors.password && touched.password ? (
                          <p className={classes.formError}>{errors.password}</p>
                        ) : null}
                      </div>

                      <div className={classes.inputBlock}>
                        <OutlinedInput
                          type="password"
                          autoComplete="off"
                          name="confirm_password"
                          id="confirm_password"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "4c6fff",
                            },
                          }}
                        />

                        {errors.confirm_password && touched.confirm_password ? (
                          <p className={classes.formError}>
                            {errors.confirm_password}
                          </p>
                        ) : null}
                      </div>

                      <div className={classes.modalButtons}>
                        <Button
                          type="submit"
                          size="lg"
                          style={{ padding: "14px 50px" }}
                          sx={{
                            backgroundColor: "4c6fff",
                            "&:disabled": {
                              backgroundColor: "#b9c7ff",
                            },
                            "&:hover": {
                              backgroundColor: "4c6fff",
                            },
                          }}
                          loadingIndicator={
                            <CircularProgress
                              sx={{ color: "#4c6fff" }}
                              size={"25px"}
                            />
                          }
                        >
                          Reset Password
                        </Button>
                        <a href="#" className={classes.link}>
                          <Link
                            to={"/login"}
                            style={{
                              textDecoration: "underline",
                              color: "4c6fff",
                            }}
                          >
                            Back to Login?
                          </Link>
                        </a>
                      </div>
                    </form>
                    <p className={classes.signUp}>
                      Don't have an account?{" "}
                      <a href="#" className={classes.link}>
                        <Link
                          to={"/register"}
                          style={{
                            textDecoration: "underline",
                            color: "4c6fff",
                          }}
                        >
                          Sign Up now
                        </Link>
                      </a>
                    </p>
                  </div>
                </div>
                <div className={classes.modalRight}>
                  <img src={signUp} alt="" className={classes.signupImage} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChangePassword;

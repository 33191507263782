import { Box, Grid } from "@mui/material";
import classes from "./Campaigns.module.scss";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import newsLetter from "../../assets/Images/banner.png";
import AddIcon from "@mui/icons-material/Add";
import { data } from "../../assets/data";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCampaign,
  setCampaignNamerx,
  setClone,
  setDashboard,
  setEmailType,
  setOptinvalues,
  setPurpose,
  setSourceEmail,
  setSubject,
  setTemplatename,
  setType,
} from "../../Store/Project/ProjectSlice";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import {
  catagoryStatApi,
  createCategoryApi,
  deleteAllCampaignApi,
  deleteCampaignApi,
  duplicateCatagoryApi,
  getAllCatDetailsApi,
  getAllPromotionalCategoriesApi,
  getAllTemplateApi,
  getAllTransactionalCategoriesApi,
  getAlltemplateDetailsApi,
  getCatagoryDetailsApi,
  getPromotionalTableDataApi,
  getTransactionalTableDataApi,
  getVerifiedEmailApi,
  getallcatagoryApi,
} from "../../Utils/Apihelper";
import Alert from "@mui/material/Alert";
import AlertBox from "../../components/AlertBox/AlertBox";
import toast, { Toaster } from "react-hot-toast";
import deleteimage from "../../assets/Images/deleteimage.svg";
import editimage from "../../assets/Images/editimage.svg";
import copyimage from "../../assets/Images/cloneIcon.svg";

const mailType = ["Public", "Private"];
const allCampType = ["promotional", "transactional"];
const optin = ["Single Optin", "Double Optin"];

const dummyData =[
  {
    "categoryName": "Category 1",
    "createdAt": "2024-01-18T12:00:00Z",
    "categoryType": "Type A",
    "Statistics": {
      "open_rate": 0.25,
      "click_rate": 0.1,
      "bounce_rate": 0.05
    }
  },
  {
    "categoryName": "Category 2",
    "createdAt": "2024-01-18T12:30:00Z",
    "categoryType": "Type B",
    "Statistics": {
      "open_rate": 0.3,
      "click_rate": 0.15,
      "bounce_rate": 0.03
    }
  },
  {
    "categoryName": "Category 3",
    "createdAt": "2024-01-18T13:00:00Z",
    "categoryType": "Type C",
    "Statistics": {
      "open_rate": 0.22,
      "click_rate": 0.12,
      "bounce_rate": 0.07
    }
  },
  {
    "categoryName": "Category 4",
    "createdAt": "2024-01-18T13:30:00Z",
    "categoryType": "Type A",
    "Statistics": {
      "open_rate": 0.28,
      "click_rate": 0.09,
      "bounce_rate": 0.08
    }
  },
  {
    "categoryName": "Category 5",
    "createdAt": "2024-01-18T14:00:00Z",
    "categoryType": "Type B",
    "Statistics": {
      "open_rate": 0.35,
      "click_rate": 0.18,
      "bounce_rate": 0.02
    }
  },
  {
    "categoryName": "Category 6",
    "createdAt": "2024-01-18T14:30:00Z",
    "categoryType": "Type C",
    "Statistics": {
      "open_rate": 0.20,
      "click_rate": 0.11,
      "bounce_rate": 0.06
    }
  },
  {
    "categoryName": "Category 7",
    "createdAt": "2024-01-18T15:00:00Z",
    "categoryType": "Type A",
    "Statistics": {
      "open_rate": 0.26,
      "click_rate": 0.14,
      "bounce_rate": 0.04
    }
  },
  {
    "categoryName": "Category 8",
    "createdAt": "2024-01-18T15:30:00Z",
    "categoryType": "Type B",
    "Statistics": {
      "open_rate": 0.32,
      "click_rate": 0.16,
      "bounce_rate": 0.01
    }
  },
  {
    "categoryName": "Category 9",
    "createdAt": "2024-01-18T16:00:00Z",
    "categoryType": "Type C",
    "Statistics": {
      "open_rate": 0.24,
      "click_rate": 0.13,
      "bounce_rate": 0.09
    }
  },
  {
    "categoryName": "Category 10",
    "createdAt": "2024-01-18T16:30:00Z",
    "categoryType": "Type A",
    "Statistics": {
      "open_rate": 0.29,
      "click_rate": 0.17,
      "bounce_rate": 0.03
    }
  },
  {
    "categoryName": "Category 11",
    "createdAt": "2024-01-18T17:00:00Z",
    "categoryType": "Type B",
    "Statistics": {
      "open_rate": 0.33,
      "click_rate": 0.19,
      "bounce_rate": 0.06
    }
  },
  {
    "categoryName": "Category 12",
    "createdAt": "2024-01-18T17:30:00Z",
    "categoryType": "Type C",
    "Statistics": {
      "open_rate": 0.21,
      "click_rate": 0.10,
      "bounce_rate": 0.05
    }
  },
  {
    "categoryName": "Category 13",
    "createdAt": "2024-01-18T18:00:00Z",
    "categoryType": "Type A",
    "Statistics": {
      "open_rate": 0.27,
      "click_rate": 0.15,
      "bounce_rate": 0.07
    }
  },
  {
    "categoryName": "Category 14",
    "createdAt": "2024-01-18T18:30:00Z",
    "categoryType": "Type B",
    "Statistics": {
      "open_rate": 0.31,
      "click_rate": 0.16,
      "bounce_rate": 0.04
    }
  },
  {
    "categoryName": "Category 15",
    "createdAt": "2024-01-18T19:00:00Z",
    "categoryType": "Type C",
    "Statistics": {
      "open_rate": 0.23,
      "click_rate": 0.12,
      "bounce_rate": 0.08
    }
  }
]

const Campaigns = () => {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [allTemplate, setAllTemplate] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [catName, setCatName] = useState([]);
  const [email, setemail] = useState<string | null>(null);
  const [template, settemplate] = useState<string | null>(null);
  const [categoryName, setcategoryName] = useState<string | null>(null);
  const [subject, setsubject] = useState<string | null>(null);
  const [purpose, setpurpose] = useState<string | null>(null);
  const [type, settype] = useState<string | null>(null);
  const [campType, setCampType] = useState<string | null>(null);
  const [optinValues, setOptinValue] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState("");
  const [deleteAll, setDeleteAll] = useState(false);
  const [fail, setFail] = useState(false);
  const [clonedCamp, setClonedCamp] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tab, setTab] = useState(2);
  const campaign = useSelector((state: any) => state.project.campaign);

  const [openClone, setOpenClone] = useState(false);

  const handleClickOpenClone = () => {
    setOpenClone(true);
  };

  const handleCloseClone = () => {
    setOpenClone(false);
  };

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  const navigate = useNavigate();

  const Token = sessionStorage.getItem("ChangePwToken");
  const config = {
    headers: { Authorization: `Bearer ${Token}` },
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteAll(false);
  };

  const handleGetallCategoryApi = () => {
    setLoader(true);
    setTimeout(() => {
      getallcatagoryApi(config)
        .then((res) => {
        //  console.log(res.data);
          setCatName(res.data.data);
          setLoader(false);
        })
        .catch((res) => {
        //  console.log(res.data);
          setLoader(false);
          toast.error("Couldn't load campaigns");
        });
      getAllCatDetailsApi()
        .then((res) => {
        //  console.log(res.data);
          setTableData(res.data.data);
        })
        .catch((res) => {
        //  console.log(res.data);
        });
    }, 1110);
  };

  const handleGetAllTransactionalApi = () => {
    setLoader(true);
    getTransactionalTableDataApi()
      .then((res) => {
       // console.log(res.data);
        setTableData(res.data.data);
        setLoader(false);
      })
      .catch((res) => {
      //  console.log(res.data);
        toast.error("Couldn't load campaigns");
        setLoader(false);
      });
  };

  const handleGetAllPromotionalApi = () => {
    setLoader(true);
    getPromotionalTableDataApi()
      .then((res) => {
       // console.log(res.data);
        setTableData(res.data.data);
        setLoader(false);
      })
      .catch((res) => {
       // console.log(res.data);
        toast.error("Couldn't load campaigns");
        setLoader(false);
      });
  };

  useEffect(() => {
    handleGetAllTransactionalApi();
    dispatch(setClone(false));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);

    getVerifiedEmailApi()
      .then((res) => {
       // console.log(res);
        setAllEmails(res.data.data);
      })
      .catch((res) => {
      //  console.log(res.data);
      });

    getAllTemplateApi(config)
      .then((res) => {
        setAllTemplate(res.data.data);
      })
      .catch((res) => {
      //  console.log(res.data);
      });
  };
  const handleClose = () => {
    setOpen(false);
    setemail("");
    setpurpose("");
    setsubject("");
    settemplate("");
    settype("");
    setcategoryName("");
    setOptinValue("");
    setCampType("");
  };

  const handleCampaignClick = (name: any) => {
    navigate("/email-dashboard/emails/campaignDetails");
    dispatch(setCampaign(name));
    localStorage.setItem("campaign", name);
  };
  const filteredData = dummyData.filter((data: any) =>
    data.categoryName.toLowerCase().includes(query.trim())
  );

  const handleClone = () => {
    const reqbody = {
      originalcategoryName: title,
      duplicatecategoryName: clonedCamp,
    };
    duplicateCatagoryApi(reqbody)
      .then((res) => {
       // console.log(res.data);
        if (res.data.code === "200") {
          toast.success("Cloned Successsfully");
        } else {
          toast.error(res.data.data.message);
        }
        setClonedCamp("");
        handleGetallCategoryApi();
        handleCloseClone();
      })
      .catch((res) => {
      //  console.log(res.data);
        toast.error("Clone Failed");
        handleCloseClone();
      });
  };

  const handleDelete = () => {
    const deleteApiBody: any = {
      categoryName: title,
    };
   // console.log(deleteApiBody);
    deleteCampaignApi(deleteApiBody)
      .then((res) => {
       // console.log(res.data);
        setSuccess(true);
        if (res.data.code === "200") {
          toast.success(res.data.message);
        } else {
          toast.error("Delete Failed");
        }

        handleGetallCategoryApi();
        setOpenDeleteDialog(false);
      })
      .catch((res) => {
       // console.log(res.data);
        setFail(true);
        setOpenDeleteDialog(false);
      });
  };

  const handleCreateCategory = () => {
    const creationBody = {
      sourceEmail: email,
      categoryName: categoryName,
      templateName: template,
      subject: subject,
      purpose: purpose,
      type: type,
      optin: "Single Optin",
      categoryType: campType,
    };
    createCategoryApi(creationBody, config)
      .then((res) => {
       // console.log(res.data);
        if (res.data.code === "200") {
          toast.success("Campaign Created");
          setOpen(false);
          handleGetallCategoryApi();
        } else {
          toast.error(res.data.data.message);
        }
      })
      .catch((res) => {
      //  console.log(res.data);
        setFail(true);
        setOpen(false);
        toast.error("Campaign couldn't be created");
      });
  };
  const campaignName = {
    categoryName: campaign,
  };
  const handleEditDetails = (catname: any) => {
    const reqbody = {
      categoryName: catname,
    };

    getCatagoryDetailsApi(reqbody, config)
      .then((res) => {
        dispatch(setSourceEmail(res.data.data.sourceEmail));
        dispatch(setSubject(res.data.data.subject));
        dispatch(setPurpose(res.data.data.purpose));
        dispatch(setOptinvalues(res.data.data.optin));
        dispatch(setTemplatename(res.data.data.templateName));
        dispatch(setType(res.data.data.type));
        dispatch(setCampaignNamerx(res.data.data.categoryName));
        dispatch(setEmailType(res.data.data.categoryType));
      })
      .catch((res) => {
       // console.log(res.data);
      });
  };

  // const handleClone = () => {
  //   handleClickOpen();
  //   handleCloneDetails();
  // };

  setTimeout(() => {
    success && setSuccess(false);
    fail && setFail(false);
  }, 4000);

  // const promotionalTableData = () => {
  //   getPromotionalTableDataApi()
  //     .then((res) => {
  //       console.log(res.data);
  //       setTableData(res.data.data);
  //     })
  //     .catch((res) => {
  //       console.log(res.data);
  //     });
  // };

  // const transactionalTableData = () => {
  //   getTransactionalTableDataApi()
  //     .then((res) => {
  //       console.log(res.data);
  //       setTableData(res.data.data);
  //     })
  //     .catch((res) => {
  //       console.log(res.data);
  //     });
  // };

  return (
    <>
      <Toaster />

      <>
        <Box sx={{ flexGrow: 1 }} mb={4} mt={1}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.campaignsWrapper}>
                <div className={classes.topbar}>
                  <div className={classes.topbarLeft}>
                    <div className={classes.tabsWrapper}>
                      <div className={classes.tabsCatagory}>
                        {/* <div
                          className={classes.tabsItem}
                          onClick={() => {
                            activeTab(1);
                            handleGetallCategoryApi();
                            setLoader(true);
                          }}
                          style={{
                            backgroundColor: tab === 1 ? "#4C6FFF" : "",
                            color: tab == 1 ? "white" : "",
                          }}
                        >
                          All
                        </div> */}
                        <div
                          className={classes.tabsItem}
                          onClick={() => {
                            activeTab(2);
                            handleGetAllTransactionalApi();
                          }}
                          style={{
                            backgroundColor: tab === 2 ? "#4C6FFF" : "",
                            color: tab == 2 ? "white" : "",
                          }}
                        >
                          Transactional
                        </div>
                        <div
                          className={classes.tabsItem}
                          onClick={() => {
                            activeTab(3);
                            handleGetAllPromotionalApi();
                          }}
                          style={{
                            backgroundColor: tab === 3 ? "#4C6FFF" : "",
                            color: tab == 3 ? "white" : "",
                          }}
                        >
                          Promotional
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.buttonsearchwrapper}>
                    <button
                      className={classes.topbarRight}
                      onClick={handleClickOpen}
                    >
                      <AddIcon sx={{ color: "white" }} fontSize="small" />
                      <div className={classes.createText}>Create New</div>{" "}
                    </button>
                    <div className={classes.tableHeader}>
                      <div className={classes.headerRight}>
                        {" "}
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                        <input
                          type="text"
                          className={classes.inputBox}
                          placeholder="Search...."
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.tableWrapper}>
                  {loader ? (
                    <>
                      <span className={classes.loader}></span>
                    </>
                  ) : (
                    <Grid container spacing={2}>
                      {filteredData.map((data: any) => (
                        <Grid item xs={12} sm={4}>
                          <div className={classes.tableItem}>
                            <div
                              className={classes.left}
                              onClick={() =>
                                handleCampaignClick(data.categoryName)
                              }
                            >
                              <div className={classes.tableCenter}>
                                <div className={classes.titleWrap}>
                                  <div className={classes.title}>
                                    {data.categoryName &&
                                      data.categoryName
                                        .charAt(0)
                                        .toUpperCase() +
                                        data.categoryName.slice(1)}
                                  </div>
                                  <div className={classes.subTitle}>
                                    Created on{" "}
                                    {data.createdAt &&
                                      data.createdAt.slice(0, 10)}
                                  </div>
                                </div>
                                <div className={classes.titleWrap}>
                                  <div className={classes.title2}>
                                  Recipient: Firstock Employees
                                  </div>
                                  <div className={classes.subTitle}>
                                    {data.categoryType &&
                                      data.categoryType
                                        .charAt(0)
                                        .toUpperCase() +
                                        data.categoryType.slice(1)}
                                  </div>
                                </div>
                                <div className={classes.countWrapper}>
                                  <div className={classes.recipientWrap}>
                                    <div className={classes.recipientTitle}>
                                      All Count
                                    </div>
                                    <div className={classes.recipientData}>
                                      {data.Statistics &&
                                      data.Statistics.all_count
                                        ? data.Statistics &&
                                          data.Statistics.all_count
                                        : 0}
                                    </div>
                                  </div>
                                  <div className={classes.recipientWrap}>
                                    <div className={classes.recipientTitle}>
                                      Opened
                                    </div>
                                    <div className={classes.recipientData}>
                                      {data.Statistics &&
                                      data.Statistics.open_count
                                        ? data.Statistics &&
                                          data.Statistics.open_count
                                        : 0}
                                    </div>
                                  </div>
                                  <div className={classes.recipientWrap}>
                                    <div className={classes.recipientTitle}>
                                      Clicked
                                    </div>
                                    <div className={classes.recipientData}>
                                      {data.Statistics &&
                                      data.Statistics.click_count
                                        ? data.Statistics &&
                                          data.Statistics.click_count
                                        : 0}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classes.tableRight}>
                              <Tooltip
                                title="Clone"
                                onClick={() => {
                                  handleClickOpenClone();
                                  setTitle(data.categoryName);
                                }}
                              >
                                <IconButton>
                                  <img
                                    src={copyimage}
                                    className={classes.iconimages} 
                                    alt=""
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Edit"
                                onClick={() => {
                                  handleCampaignClick(data.categoryName);
                                  dispatch(setClone(true));
                                  handleEditDetails(data.categoryName);
                                }}
                              >
                                <IconButton>
                                  <img
                                    src={editimage}
                                    className={classes.iconimages}
                                    alt=""
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Delete"
                                onClick={() => {
                                  handleClickOpenDeleteDialog();
                                  setTitle(data.categoryName);
                                }}
                              >
                                <IconButton>
                                  <img
                                    src={deleteimage}
                                    className={classes.iconimages}
                                    alt=""
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </>

      <Dialog
        open={open}
        aria-labelledby="draggable-dialog-title"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle
          style={{ cursor: "move", fontSize: "30px" }}
          id="draggable-dialog-title"
        >
          <div className={classes.addSubTitle}>Create New Campaign</div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.addSubNote}>
            Want to design a new template for your Email?{" "}
            <a
              href=""
              onClick={() => {
                navigate("/email-dashboard/templates");
              }}
            >
              Create Template
            </a>
          </div>
          <DialogContentText sx={{ mt: "20px" }}>
            <div className={classes.addSubWrap}>
              <TextField
                required
                id="outlined-required"
                label="Name of the campaign"
                fullWidth
                size="small"
                value={categoryName}
                onChange={(event: any) => {
                  const newValue = event.target.value.replace(/\s/g, "_");
                  setcategoryName(newValue);
                }}
              />

              <Autocomplete
                id="combo-box-demo"
                options={allEmails}
                size="small"
                renderInput={(params) => <TextField {...params} label="From" />}
                onChange={(event: any, newValue: any) => {
                  setemail(newValue);
                }}
                value={email}
              />
              <TextField
                required
                id="outlined-required"
                label="Subject"
                fullWidth
                size="small"
                value={subject}
                onChange={(event: any) => {
                  setsubject(event.target.value);
                }}
              />
              <TextField
                required
                id="outlined-required"
                label="Purpose"
                fullWidth
                size="small"
                value={purpose}
                onChange={(event: any) => {
                  setpurpose(event.target.value);
                }}
              />

              <Autocomplete
                id="combo-box-demo"
                options={mailType}
                size="small"
                renderInput={(params) => <TextField {...params} label="Type" />}
                onChange={(event: any, newValue: any) => {
                  settype(newValue);
                }}
                value={type}
              />
              <Autocomplete
                id="combo-box-demo"
                options={allCampType}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Email Type" />
                )}
                onChange={(event: any, newValue: any) => {
                  setCampType(newValue);
                }}
                value={campType}
              />

              <Autocomplete
                id="combo-box-demo"
                options={allTemplate}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Select Template" />
                )}
                onChange={(event: any, newValue: any) => {
                  settemplate(newValue);
                }}
                // getOptionLabel={(option:any) => option.name}
                value={template}
              />
              <Autocomplete
                id="combo-box-demo"
                options={optin}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Optin" />
                )}
                onChange={(event: any, newValue: any) => {
                  setOptinValue(newValue);
                }}
                value={optinValues}
              />

              {/* <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="This person gave me permission to email them"
                labelPlacement="end"
              /> */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleCreateCategory}>Create </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"red"}>
          Delete Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteAll
              ? " Are you sure you want to delete all Templates? "
              : " Are you sure you want to delete this Template?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            onClick={() => {
              handleDelete();
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openClone}>
        <DialogTitle>Clone Campaign</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            To clone this category, please enter campaign name here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Campaign Name"
            type="email"
            fullWidth
            size="small"
            value={clonedCamp}
            onChange={(event) => {
              const newValue = event.target.value.replace(/\s/g, "_");
              setClonedCamp(newValue);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClone}>Cancel</Button>
          <Button
            onClick={() => {
              handleClone();
            }}
          >
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Campaigns;
